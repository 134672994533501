import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";

@Component({
  selector: "app-instructions-dialog",
  templateUrl: "./instructions-dialog.component.html",
  styleUrls: ["./instructions-dialog.component.css"],
})
export class InstructionsDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}
}
