import { StockItemCosting } from "./../models/costing-sheet.models";
import { CostingChannelConfig } from "./../models/CostingChannelConfig";
import { ChannelFee } from "./../../features/cost-sheet/channel-fees/add-new-channel-fee/add-new-channel-fee.component";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ShippingFee } from "../../features/cost-sheet/shipping-fees/add-new-shipping-fee/add-new-shipping-fee.component";

@Injectable({
  providedIn: "root",
})
export class CostSheetService {
  constructor(private http: HttpClient) {}

  private getCostInfoUrl = "api/cost/getCostInfo";
  public getCostInfo(): Observable<StockItemCosting[]> {
    return this.http.get<StockItemCosting[]>(this.getCostInfoUrl);
  }

  private getCostInfoExcelUrl = "api/cost/getCostInfoExcel";
  public getCostInfoExcel(): Observable<Blob> {
    return this.http.get(this.getCostInfoExcelUrl, {
      responseType: "blob",
    });
  }

  private getChannelsUrl = "api/cost/getChannels";
  public getChannels(): Observable<any> {
    return this.http.get<any[]>(this.getChannelsUrl);
  }

  private overridePostageCostUrl = "api/cost/overridePostageCost";
  public overridePostageCost(obj): Observable<any> {
    return this.http.put(this.overridePostageCostUrl, obj);
  }

  private getPostalServicesUrl = "api/cost/getPostalServices";
  public getPostalServices(): Observable<any> {
    return this.http.get<any[]>(this.getPostalServicesUrl);
  }

  private getShippingFeesUrl = "api/cost/getShippingFees";
  public getShippingFees(): Observable<any> {
    return this.http.get<any[]>(this.getShippingFeesUrl);
  }

  private getPackageGroupsUrl = "api/cost/getPackageGroups";
  public getPackageGroups(): Observable<any> {
    return this.http.get<any[]>(this.getPackageGroupsUrl);
  }

  private updateShippingFeeUrl = "api/cost/updateShippingFee";
  public updateShippingFee(shippingFee: ShippingFee) {
    return this.http.put<any>(this.updateShippingFeeUrl, shippingFee);
  }

  private removeShippingFeeRuleUrl = "api/cost/removeShippingFeeRule";
  public removeShippingFeeRule(id: number) {
    return this.http.delete(`${this.removeShippingFeeRuleUrl}/${id}`);
  }

  private addShippingFeeUrl = "api/cost/addShippingFee";
  public addShippingFee(newShippingFee: ShippingFee) {
    return this.http.post<any>(this.addShippingFeeUrl, newShippingFee);
  }

  private updateChannelFeeUrl = "api/cost/updateChannelFee";
  public updateChannelFee(channelFee: ChannelFee) {
    return this.http.put<any>(this.updateChannelFeeUrl, channelFee);
  }

  private removeChannelFeeUrl = "api/cost/removeChannelFee";
  public removeChannelFee(id: number) {
    return this.http.delete(`${this.removeChannelFeeUrl}/${id}`);
  }

  private getChannelFeesUrl = "api/cost/getChannelFees";
  public getChannelFees(): Observable<any> {
    return this.http.get<any[]>(this.getChannelFeesUrl);
  }

  private addChannelFeeUrl = "api/cost/addChannelFee";
  public addChannelFee(newChannelFee: ChannelFee) {
    return this.http.post<any>(this.addChannelFeeUrl, newChannelFee);
  }

  private resetOverridedPostageCostUrl = "api/cost/resetOverridedPostageCost";
  public resetOverridedPostageCost(costingSheetUserData) {
    return this.http.post<any>(
      this.resetOverridedPostageCostUrl,
      costingSheetUserData
    );
  }

  private updateCostingChannelConfigurationUrl =
    "api/cost/updateCostingChannelConfiguration";
  public updateCostingChannelConfiguration(model: CostingChannelConfig) {
    return this.http.put<any>(this.updateCostingChannelConfigurationUrl, model);
  }

  private getCostingChannelConfigUrl = "api/cost/getCostingChannelConfig";
  public getCostingChannelConfig(
    channelId: number
  ): Observable<CostingChannelConfig> {
    return this.http.get<CostingChannelConfig>(
      this.getCostingChannelConfigUrl + `?channelId=${channelId}`
    );
  }
}
