<div>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Add new User</mat-panel-title>
    </mat-expansion-panel-header>
    <app-new-user
      (newUserIsSupplier)="true"
      (userAdded)="getUserConfigs()"
    ></app-new-user>
  </mat-expansion-panel>
  <mat-card class="container">
    <mat-card-title>User tabs</mat-card-title>
    <mat-card-subtitle>select any user in list to change permissions:</mat-card-subtitle>
    <mat-card-content>
      <div class="container-content d-flex">
        <mat-selection-list class="user-list" [multiple]="false"
                            (selectionChange)="onSelectedUserChange($event)">
          <mat-list-option *ngFor="let u of userConfigs" [value]="u">
            {{u.email}}
          </mat-list-option>
        </mat-selection-list>
        <div class="selected-user" *ngIf="selectedUser">
          <p> Selected user: <b>{{selectedUser.email}}</b></p>

          <mat-card>
            <mat-card-content>
              <div class="d-flex-column w-full">
                <mat-form-field appearance="outline">
                  <mat-label>Allowed Tabs</mat-label>
                  <mat-select [compareWith]="compare" [formControl]="allowedTabsCtrl"
                              (selectionChange)="onSelectedUserAllowedTabsChange($event)" multiple>
                    <mat-option *ngFor="let tab of tabs" [value]="tab">
                      {{tab.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Default tab(s) - will be opened when user logs in</mat-label>
                  <mat-select [compareWith]="compare" [formControl]="defaultTabsCtrl"
                              (selectionChange)="onSelectedUserDefaultTabsChange($event)" multiple>
                    <mat-option *ngFor="let tab of selectedUserDefaultTabs" [value]="tab">
                      {{tab.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
<!--                <mat-form-field appearance="outline">-->
                  <mat-checkbox class="mg-b16" [(ngModel)]="selectedUser.exportToExcelAllowed">Export to Excel allowed</mat-checkbox>
<!--                </mat-form-field>-->
                <div class="d-flex-column gap-16">
                  <button mat-raised-button color="primary" (click)="updateUser()">Update permissions & default tabs</button>
                  <button mat-raised-button color="warn" (click)="removeUser()">Remove user</button>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <app-update-password [email]="selectedUser.email"></app-update-password>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
