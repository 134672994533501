import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, filter, distinctUntilChanged, tap, takeUntil, switchMap, finalize } from 'rxjs/operators';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { PriceService } from 'src/app/shared/services/price.service';

@Component({
  selector: 'app-price-change-rules',
  templateUrl: './price-change-rules.component.html',
  styleUrls: ['./price-change-rules.component.css']
})
export class PriceChangeRulesComponent implements OnInit {

  public filteredItems: any[];
  public selectedItem: any;
  public loading: boolean;
  public destroyedComponentSubj = new Subject<void>();
  public searchItemsCtrl = new UntypedFormControl();
  public searchItemField: string;

  public selectedPrice: any;

  public dataSource = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  columns: any[];
  displayedColumns: string[];

  ruleTypeToggle: boolean = false;
  amountToChange: number = 0;
  ruleDuration: number = 1;


  constructor(private inventoryService: InventoryService, private priceService: PriceService, private notify: SnackBarService) {
    this.columns = [
      // { name: 'SKU', value: 'sku' },
      // { name: 'Description', value: 'description' },
      { name: 'Source', value: 'source' },
      { name: 'Subsource', value: 'subSource' },
      { name: 'Tag', value: 'tag' },
      { name: 'Channel Price', value: 'price' },
      { name: '', value: 'select' }
    ];
    this.selection = new SelectionModel<any>(true, []);
    this.displayedColumns = this.columns.map((col) => col.value);
  }

  ngOnInit(): void {
    this.searchItemsCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((term) => term && term.toString().trim().length > 0),
        distinctUntilChanged(),
        tap(() => {
          this.filteredItems = [];
          this.loading = true;
        }),
        takeUntil(this.destroyedComponentSubj),
        switchMap((value: string) =>
          this.inventoryService.searchStockItems(value)
            .pipe(
              finalize(() => {
                this.loading = false;
              })
            )
        )
      ).subscribe((data: any[]) => {
        if (data == undefined) {
          this.filteredItems = [];
        } else {
          this.filteredItems = data;
        }
      });
  }

  selectedFiltereditemChange(params) {
    this.selectedItem = this.filteredItems.find((x) => x.sku == params.value);
    this.getInventoryItemPrices(this.selectedItem.id);
  }

  getInventoryItemPrices(stockItemId: string): void {
    this.inventoryService.GetInventoryItemPrices(stockItemId).subscribe(
      result => {
        this.dataSource = new MatTableDataSource(result);
      }
    )
  }

  setSelectedRow(item: any) {
    this.selectedPrice = item;
  }

  addNewRule(): void {

    if (this.selection.selected.length <= 0) {
      this.notify.warning('Please select at least one channel');
      return;
    }
    if (this.amountToChange <= 0 || this.ruleDuration <= 0) {
      this.notify.warning('Please fill all fields');
      return;
    }

    let rules: any[] = [];

    this.selection.selected.forEach(i => {
      rules.push({
        stockItemId: i.stockItemId,
        durationInDays: this.ruleDuration,
        type: this.ruleTypeToggle ? 1 : 0,
        source: i.source,
        subsource: i.subSource,
        tag: i.tag,
        amountToChange: this.amountToChange
      });
    });

    this.priceService.addPriceChangeRules(rules).subscribe(result => {
      this.notify.success("New rule successfully added.")
    })
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}
