import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LoaderService } from "src/app/core/services/loader.service";
import { RepositoryService } from "src/app/core/services/repository.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { CostSheetService } from "../../../../shared/services/cost-sheet.service";

export interface ShippingFee {
  id?: number;
  postalServiceId?: string;
  specialChannelId?: number;
  postalServiceName?: string;
  baseCost: number;
  fuelSurchargePercentage: number;
  extraBoxSurcharge: number;
  surchargePerKilogram: number;
  width: number;
  height: number;
  depth: number;
  minWeight: number;
  maxWeight: number;
  packagingWeight: number;
  criteriaLength?: string;
  criteriaWeight?: string;
}

@Component({
  selector: "app-add-new-shipping-fee",
  templateUrl: "./add-new-shipping-fee.component.html",
  styleUrls: ["./add-new-shipping-fee.component.css"],
})
export class AddNewShippingFeeComponent implements OnInit {
  public shippingFeeForm: UntypedFormGroup;
  public selectedPostalService?: any;
  public services: any[];
  public packageGroups: any[];
  public packageTypes: any[];
  public channels: any[];
  public selectedChannel?: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private costSheetService: CostSheetService,
    private snackBar: SnackBarService,
    private dialogRef: MatDialogRef<AddNewShippingFeeComponent>
  ) {}

  ngOnInit(): void {
    this.shippingFeeForm = new UntypedFormGroup({
      baseCost: new UntypedFormControl(0, [
        Validators.required,
        Validators.maxLength(20),
      ]),
      fuelSurcharge: new UntypedFormControl(0, [
        Validators.required,
        Validators.maxLength(20),
      ]),
      extraBoxSurcharge: new UntypedFormControl(0, [
        Validators.required,
        Validators.maxLength(20),
      ]),
      surchargePerKilogram: new UntypedFormControl(0, [
        Validators.required,
        Validators.maxLength(20),
      ]),
      width: new UntypedFormControl(0.0, [
        Validators.required,
        Validators.maxLength(20),
      ]),
      height: new UntypedFormControl(0.0, [
        Validators.required,
        Validators.maxLength(20),
      ]),
      depth: new UntypedFormControl(0.0, [
        Validators.required,
        Validators.maxLength(20),
      ]),
      minWeight: new UntypedFormControl(0.0, [
        Validators.required,
        Validators.maxLength(20),
      ]),
      maxWeight: new UntypedFormControl(0.0, [
        Validators.required,
        Validators.maxLength(20),
      ]),
      packagingWeight: new UntypedFormControl(0.0, [
        Validators.required,
        Validators.maxLength(20),
      ]),
    });
    this.services = this.data.postalServices;
    this.packageGroups = this.data.packageGroups;
    this.channels = this.data.channels;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.shippingFeeForm.controls[controlName].hasError(errorName);
  };

  createShippingFee(params) {
    if (!this.selectedPostalService && !this.selectedChannel) {
      this.snackBar.openSnackBar(
        "You need to select postal service or channel first",
        "Warning"
      );
      return;
    }
    let newShippingFee: ShippingFee = {
      postalServiceId:
        this.selectedPostalService?.id ??
        "00000000-0000-0000-0000-000000000000",
      specialChannelId: this.selectedChannel?.channelId ?? 0,
      baseCost: params.baseCost,
      fuelSurchargePercentage: params.fuelSurcharge,
      extraBoxSurcharge: params.extraBoxSurcharge,
      surchargePerKilogram: params.surchargePerKilogram,
      width: params.width,
      height: params.height,
      depth: params.depth,
      minWeight: params.minWeight,
      maxWeight: params.maxWeight,
      packagingWeight: params.packagingWeight,
    };

    this.costSheetService.addShippingFee(newShippingFee).subscribe(
      (result) => {
        this.snackBar.openSnackBar("Successfully added", "Shipping Fee Rule");
        this.dialogRef.close({ value: true });
      },
      (error) => {
        this.snackBar.openSnackBar("Something went wrong", "Error");
        console.error(error);
        this.dialogRef.close();
      }
    );
  }
}
