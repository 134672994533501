import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from 'src/app/shared/services/file.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';

@Component({
  selector: 'app-spec-sheet-images',
  templateUrl: './spec-sheet-images.component.html',
  styleUrls: ['./spec-sheet-images.component.css']
})
export class SpecSheetImagesComponent implements OnInit {

  images: any;
  categories = [
    { name: "White Background", value: "white-background" },
    { name: "White Background with size", value: "white-background-with-size" },
    { name: "Life Style Photo", value: "lifestyle" },
    { name: "Close Up Photo", value: "closeup" },
    { name: "High Res Photo", value: "highres" },
  ];

  selected: any[];

  constructor(
    private http: HttpClient,
    private fileService: FileService,
    private snackbar: SnackBarService,
    public dialogRef: MatDialogRef<SpecSheetImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selected = [];
  }

  ngOnInit(): void {
    this.getImages();
  }

  getImages() {
    this.http.get<any>('api/file/getStockItemImagesById/' + this.data.stockItemId).subscribe(result => {
      this.images = result;
      result.forEach(r => {
        this.images[r.categoryName] = r;
      });
    }, error => {
    })
  }

  selectImage(item): void {
    if (this.selected.length > 2) {
      this.snackbar.warning('Should be no more than 3 images');
      this.selected.splice(this.selected.indexOf(item), 1);
      return;
    }
    if (this.selected.indexOf(item) === -1) {
     this.selected.push(item);
    }
    else this.selected.splice(this.selected.indexOf(item), 1);
  }

  confirmSelection(): void {
    this.dialogRef.close(this.selected);
  }
}
