import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewPurchaseOrder } from '../generate-po/generate-po.component';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ContainerType } from '../purchase-order-create-dialog/purchase-order-create-dialog.component';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { PoConfirmAddComponent } from './po-confirm-add/po-confirm-add.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ItemToPO, Procurement } from '../procurement-new.component';

interface TimeRange {
  name: string;
  value: number;
}

export interface ItemToPOForecast extends ItemToPO {
  dateToOrder?: string;
  percentage?: number;
}

@Component({
  selector: 'app-time-period-forecast',
  templateUrl: './time-period-forecast.component.html',
  styleUrls: ['./time-period-forecast.component.css'],
  providers: [DatePipe],
})
export class TimePeriodForecastComponent implements OnInit {
  http: HttpClient;
  baseUrl: string;

  selectedRange: TimeRange;
  ranges: TimeRange[] = [
    { name: '90 days', value: 90 },
    { name: '120 days', value: 120 },
    { name: '150 days', value: 150 },
    { name: '180 days', value: 180 },
  ];

  types: ContainerType[] = [
    { name: '20 ft', value: 28 },
    { name: '40 ft', value: 56 },
    { name: '40 ft high cube', value: 68 },
  ];
  selectedType: ContainerType;
  selectedContainerIndex: number;
  orders: NewPurchaseOrder[];
  itemsToOrder: ItemToPOForecast[];
  selectedOrder: NewPurchaseOrder;
  itemDataSource = new MatTableDataSource<any>();
  itemDisplayedColumns: string[] = ['sku', 'title', 'qty', 'cbm', 'totalCbm'];
  itemSelection = new SelectionModel<any>(true, []);
  orderDataSource = new MatTableDataSource<any>();
  orderDisplayedColumns: string[] = [
    'reference',
    'containerSize',
    'deliveryDate',
    'contUsage',
    'contUsagePercentage',
  ];
  orderSelection = new SelectionModel<any>(false, []);
  lastPONumber = 0;
  transitTime = 30;
  productionTime = 35;
  minimumWeeksCover = 2;
  loadingData = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    this.selectedType = this.types[2];
    // get last PO number
    this.loadingData = true;
    this.http
      .get<number>(this.baseUrl + 'api/procurement/getLastPONumber/')
      .subscribe(
        (result) => {
          this.loadingData = false;
          this.lastPONumber = result;
        },
        (error) => {
          this.loadingData = false;
          console.error(error);
        }
      );
  }

  confirmContainer() {
    const dialogRef = this.dialog.open(PoConfirmAddComponent, {
      data: {
        purchaseOrder: this.orders[this.selectedContainerIndex],
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.value === 'confirmed') {
        this.http
          .post<any>(
            this.baseUrl + 'api/procurement/createPurchaseOrder',
            this.orders[this.selectedContainerIndex]
          )
          .subscribe(
            (r) => {
              this.openSnackBar('Succesfully created', 'Purchase Order');

              if (this.selectedContainerIndex > -1) {
                this.orders.splice(this.selectedContainerIndex, 1);
                this.orderDataSource = new MatTableDataSource<any>(this.orders);
                this.selectedContainerIndex = -2;
              }
            },
            (error) => {
              console.error(error);
              this.openSnackBar('Something went wrong (', '');
            }
          );
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  selectedRangeChange(range) {
    const weeksAmount = Math.floor(range.value / 7);

    this.orders = [];

    // const itemsToOrder = this.getQtyToOrderForPeriod(
    //   this.data.items,
    //   weeksAmount
    // );
    // this.orders = this.getContainers(itemsToOrder, weeksAmount);

    // // Test
    this.orders = this.newForecastFunction(this.data.items, weeksAmount);

    // 

    for (let i = 0; i < this.orders.length; i++) {
      this.orders[i].containerUsage = this.getTotalCbm(
        this.orders[i].items,
        'cbm',
        'qty'
      ).toFixed(2);
      this.orders[i].containerUsagePercentage = parseFloat(
        (
          (100 * this.orders[i].containerUsage) /
          this.orders[i].containerSize
        ).toFixed(2)
      );
    }

    this.orderDataSource = new MatTableDataSource<any>(this.orders);

    this.itemDataSource = new MatTableDataSource<any>(this.orders[0].items);
  }

  determineDatesWhenOutOfStock(items: Procurement[], weeksAmount: number) {
    let dates = [];

    items.forEach((item) => {
      for (let i = 4; i < weeksAmount; i++) {
        if (item.details[2].weekInfo[i] < item.details[0].weekInfo[i]) {
          let date = new Date();
          date.setDate(date.getDate() + i * 7);
          dates.push({ key: item.stockItemId, value: date });
          break;
        }
      }
    });

    return dates;
  }

  determineOrderDate(
    items: Procurement[],
    weeksAmount: number,
    datesOutOfStock: any[],
    transitTime: number,
    productionTime: number
  ) {
    let orderDates: any[] = [];

    items.forEach((item) => {
      let orderDate =
        (datesOutOfStock.find((i) => i.key === item.stockItemId)?.value ?? 0) -
        (transitTime + productionTime);
      orderDates.push({ key: item.stockItemId, value: orderDate });
      // orderDates.push(orderDate);
    });

    return Math.min.apply(null, orderDates);
  }

  determineQtyToOrder(
    items: Procurement[],
    datesOutOfStock: any[],
    weeksAmount: number
  ) {
    let itemsToOrder: ItemToPOForecast[] = [];

    datesOutOfStock.forEach((item) => {
      let itemToOrder =
        items.find((i) => i.stockItemId === item.key && i.isHidden == false) ??
        null;
      if (itemToOrder !== null) {
        let qtyToOrder =
          itemToOrder.details[0].weekInfo[0] * 4 - itemToOrder.stockLevel;

        if (qtyToOrder > 0) {
          let mappedItemToOrder: ItemToPOForecast = {
            sku: itemToOrder.sku,
            qty: qtyToOrder,
            title: itemToOrder.title,
            stockItemId: itemToOrder.stockItemId,
            cbm: itemToOrder.cbm,
          };

          itemsToOrder.push(mappedItemToOrder);
        }
      }
    });
    return itemsToOrder;
  }

  newGetItemsForOrders(items: Procurement[], period: number) {
    let weeksNumbers = [];
    items.forEach(item => {
      let weekToOrder = 0;
      let itemWeekConsumption = item.details[1].weekInfo[0] > 0 ? item.details[1].weekInfo[0] : item.details[0].weekInfo[0];
      if (itemWeekConsumption === 0) {
        weekToOrder = 0;
      } else {
        weekToOrder = item.stockLevel / itemWeekConsumption;
      }
      let weeksNumber = { stockItemId: item.stockItemId, week: weekToOrder };
      weeksNumbers.push(weeksNumber);
    });

    return weeksNumbers;
  }

  newGetItemsToOrder(weekNumbers: any[], items: Procurement[]) {
    let itemsToOrder = weekNumbers.sort((a, b) => a.week - b.week);
  }

  newForecastFunction(items: Procurement[], weeksAmount: number) {

    let ordersResult = [];

    // 1. Get amount of cycles according to minimum weeks cover
    let period = Math.floor(weeksAmount / this.minimumWeeksCover);

    // 2. Get qty and orders for every period
    for (let i = 0; i < period; i++) {
      let itemsToOrder: ItemToPOForecast[] = [];
      items.forEach(item => {
        if (!item.isHidden) {
          const qtyForPeriod = (item.details[1].weekInfo[i] > 0 ? item.details[1].weekInfo[i] : item.soldStatsLast8Weeks) * this.minimumWeeksCover;
          const totalCbmPerItem = item.cbm * qtyForPeriod;
          const mappedItemToOrder: ItemToPOForecast = {
            sku: item.sku,
            qty: qtyForPeriod,
            title: item.title,
            stockItemId: item.stockItemId,
            cbm: item.cbm,
          };

          itemsToOrder.push(mappedItemToOrder);
        }
      });

      const totalQty: number = itemsToOrder.map(a => a.qty).reduce(function (a, b) {
        return (a + b);
      });


      itemsToOrder.forEach(item => {
        item.percentage = (100 * item.qty / totalQty);
      });


      // let containers = this.getContainers(itemsToOrder, this.minimumWeeksCover);
      let containers = this.packFirstFit(itemsToOrder, i);

      if (containers.length > 0) {
        containers.forEach(container => {
          itemsToOrder.forEach(itemToOrder => {
            // If item exist in container
            let ito = container.items.find(x => x.stockItemId === itemToOrder.stockItemId);
            if (!ito) {
              let newItem: ItemToPOForecast = {
                sku: itemToOrder.sku,
                qty: 1,
                title: itemToOrder.title,
                stockItemId: itemToOrder.stockItemId,
                cbm: itemToOrder.cbm,
              };
              container.items.push(newItem);
            }
          });
          // Check usage, %
          while ((100 * this.getTotalCbm(container.items, 'cbm', 'qty') / this.selectedType.value) < 100) {
            container.items.forEach(item => {
              item.qty++;
            });
          }

          if ((100 * this.getTotalCbm(container.items, 'cbm', 'qty') / this.selectedType.value) > 100) {
            for (let z = 0; z < container.items.length; z++) {
              container.items[z].qty--;
              if ((100 * this.getTotalCbm(container.items, 'cbm', 'qty') / this.selectedType.value) <= 100) {
                break;
              }

            }
          }

          ordersResult.push(container);
        })
        // ordersResult.concat(containers);
        // return containers;

      }

    }
    return ordersResult;
    // return this.getContainers(itemsToOrder, weeksAmount);

  }

  getQtyToOrderForPeriod(items: Procurement[], weeksAmount: number) {
    const period = Math.floor(weeksAmount);
    const itemsToOrder: ItemToPOForecast[] = [];
    let totalCbm = 0;
    items.forEach((item) => {
      if (!item.isHidden) {
        const qtyForPeriod = item.details[0].weekInfo[0] * period;
        const totalCbmPerItem = item.cbm * qtyForPeriod;
        totalCbm += totalCbmPerItem;

        const mappedItemToOrder: ItemToPOForecast = {
          sku: item.sku,
          qty: qtyForPeriod,
          title: item.title,
          stockItemId: item.stockItemId,
          cbm: item.cbm,
        };

        itemsToOrder.push(mappedItemToOrder);
      }
    });

    let itemsToOrderFiltered = new Array<ItemToPOForecast>();

    itemsToOrder.forEach((item) => {
      const totalCbmPerItem = item.cbm * item.qty;
      const cbmPerContainer =
        totalCbmPerItem / (totalCbm / this.selectedType.value);
      item.qty = Math.floor(cbmPerContainer / item.cbm);
      if (item.qty > 0) {
        itemsToOrderFiltered.push(item);
      }
    });

    // return itemsToOrder;
    return itemsToOrderFiltered;
  }

  getContainers(itemsToOrder: ItemToPOForecast[], weeksAmount: number) {
    let lastNumber = this.lastPONumber;

    let bins: NewPurchaseOrder[] = [];
    const period = Math.floor(weeksAmount / this.minimumWeeksCover);
    for (let i = 0; i < period; i++) {
      let totalCbm = parseFloat(this.getTotalCbm(itemsToOrder, 'cbm', 'qty'));
      let containerUsagePercentage = parseFloat(
        ((100 * totalCbm) / this.selectedType.value).toFixed(2)
      );
      let allItemsChecked = false;
      while (containerUsagePercentage < 100 && !allItemsChecked) {
        for (let j = 0; j < itemsToOrder.length; j++) {
          if (
            parseFloat(
              (
                (100 * (totalCbm + itemsToOrder[j].cbm)) /
                this.selectedType.value
              ).toFixed(2)
            ) <= 100
          ) {
            itemsToOrder[j].qty++;
            totalCbm += itemsToOrder[j].cbm;
            containerUsagePercentage = parseFloat(
              ((100 * totalCbm) / this.selectedType.value).toFixed(2)
            );
          }
        }

        allItemsChecked = true;

        if (containerUsagePercentage >= 100) {
          break;
        }
      }

      lastNumber++;
      let date = new Date();
      date.setDate(
        date.getDate() + i * this.minimumWeeksCover * 7 + this.transitTime + this.productionTime
      );
      const formattedDate = this.datepipe.transform(date, 'dd/MM/yyyy');

      let newBin: NewPurchaseOrder = {
        supplierId: this.data.supplierId,
        items: itemsToOrder,
        deliveryDate: formattedDate,
        reference: lastNumber.toString(),
        poNumber: lastNumber,
        containerSize: this.selectedType.value,
        containerType: this.selectedType.name,
        conversionRate: 1.0
      };
      bins.push(newBin);
    }

    return bins;
  }

  getTotalCbm(items, propA, propB) {
    return items.reduce(function (a, b) {
      return a + b[propA] * b[propB];
    }, 0);
  }

  packFirstFit(itemsToOrder: ItemToPOForecast[], periodIndex: number): NewPurchaseOrder[] {
    let poNumber = this.lastPONumber;
    let poNumberInt = poNumber + periodIndex + 1;

    let date = new Date();
    date.setDate(date.getDate() + (periodIndex * this.minimumWeeksCover) * 7 + this.transitTime + this.productionTime);
    let formattedDate = this.datepipe.transform(date, 'dd/MM/yyyy');

    let bins: NewPurchaseOrder[] = [];
    let newBin: NewPurchaseOrder = {
      supplierId: this.data.supplierId,
      items: [],
      deliveryDate: formattedDate,
      reference: poNumberInt.toString(),
      poNumber: poNumberInt,
      containerSize: this.selectedType.value,
      containerType: this.selectedType.name,
      conversionRate: 1.0
    };
    poNumberInt++;
    bins.push(newBin);

    for (let i = 0; i < itemsToOrder.length; i++) {
      const item = itemsToOrder[i];
      if (item.qty < 1) {
        continue;
      }
      let itemQty = item.qty;
      while (itemQty > 0) {
        let found = false;

        for (let j = 0; j < bins.length; j++) {
          const freeSpace =
            bins[j].containerSize -
            this.getTotalCbm(bins[j].items, 'qty', 'cbm');
          if (freeSpace > 0 && item.cbm < freeSpace) {
            const indexOfItem = bins[j].items.indexOf(
              bins[j].items.find((x) => x.stockItemId === item.stockItemId)
            );
            if (indexOfItem < 0 || isNaN(indexOfItem)) {
              let it: ItemToPOForecast = {
                sku: item.sku,
                qty: 1,
                title: item.title,
                stockItemId: item.stockItemId,
                cbm: item.cbm,
              };
              bins[j].items.push(it);
            } else {
              bins[j].items[indexOfItem].qty++;
            }
            itemQty--;
            found = true;
            break;
          }
        }

        if (!found) {
          const newBin1: NewPurchaseOrder = {
            supplierId: '',
            items: [],
            deliveryDate: formattedDate,
            reference: (++poNumberInt).toString(),
            poNumber: poNumberInt,
            containerSize: this.selectedType.value,
            containerType: this.selectedType.name,
            conversionRate: 1.0
          };
          bins.push(newBin1);
          const it: ItemToPOForecast = {
            sku: item.sku,
            qty: 1,
            title: item.title,
            stockItemId: item.stockItemId,
            cbm: item.cbm,
          };
          bins[bins.length - 1].items.push(it);
        }
      }
    }
    this.lastPONumber += bins.length;
    return bins;
  }

  isFits(bin: NewPurchaseOrder, item: ItemToPOForecast) {
    const freeSpace =
      bin.containerSize - this.getTotalCbm(bin.items, 'cbm', 'qty');
    if (item.cbm > freeSpace) {
      return false;
    } else {
      return true;
    }
  }

  setSelectedRow(value): void {
    this.selectedContainerIndex = value;
    this.itemDataSource = new MatTableDataSource<any>(
      this.orders[this.selectedContainerIndex].items
    );
  }

  removeContainer(): void {
    if (this.selectedContainerIndex > -1) {
      this.orders.splice(this.selectedContainerIndex, 1);
      this.orderDataSource = new MatTableDataSource<any>(this.orders);
      this.selectedContainerIndex = -2;
    }
  }

  isAllSelected() {
    const numSelected = this.orderSelection.selected.length;
    const numRows = this.orderDataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.orderSelection.clear()
      : this.orderDataSource.data.forEach((row) =>
        this.orderSelection.select(row)
      );
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.orderSelection.isSelected(row) ? 'deselect' : 'select'
      } row ${row.position + 1}`;
  }
}
