<mat-card *ngIf="suppliers && suppliers.length > 0">
  <mat-card-header>
    <mat-card-title>Barcode Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>Shows barcodes for all items, can choose by supplier</mat-card-subtitle>
  </mat-card-header>

  <div class="row">
    <div class="col">
      <mat-form-field class="selectSupplier">
        <mat-label>Supplier:</mat-label>
        <mat-select [(value)]="selectedSupplier" [disabled]="loading">
          <mat-option *ngFor="let supplier of suppliers" [value]="supplier">
            {{ supplier.supplierName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col">
      <button mat-raised-button color="primary" (click)="downloadReport()" [disabled]="loading" class="download">
        Download Report
      </button>
    </div>
  </div>
</mat-card>