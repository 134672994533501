import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { UserConfig } from '../models/user-config';
import { RegistrationUserModel } from 'src/app/core/models/registration-user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public currentUserConfig: UserConfig;

  constructor(@Inject("BASE_URL") private baseUrl: string,
  private http: HttpClient) { }

  addUser(user: UserConfig) {
    return this.http.post<UserConfig>("api/user", user);
  }

  updateUser(email: string, user: UserConfig) {
    return this.http.put<UserConfig>(`api/user/update/${email}`, user);
  }

  removeUser(email: string) {
    return this.http.delete(`api/user/${email}`);
  }

  getUsers() {
    return this.http.get<RegistrationUserModel[]>(`api/user/get-users`);
  }

  getUserConfigs() {
    return this.http.get<UserConfig[]>('api/user');
  }

  getUser(email: string) {
    return this.http.get<UserConfig>(`api/user/${email}`);
  }

  updateFavouriteReports(email: string, reports: string[]) {
    return this.http.post(`api/user/favorite-reports/${email}`, { reports: reports });
  }
}
