import { Component, Inject, OnInit } from "@angular/core";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from 'file-saver-es';
@Component({
  selector: "app-pallete-report",
  templateUrl: "./pallete-report.component.html",
  styleUrls: ["./pallete-report.component.css"],
})
export class PalleteReportComponent implements OnInit {
  loadingReport = false;
  baseUrl: string;
  hideDownloadButton = true;
  currentDate = new Date();

  constructor(
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    private snackbar: SnackBarService
  ) {
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    this.loadingReport = false;
  }

  downloadFile() {
    this.loadingReport = true;

    return this.reportsService.getPalletEXCEL().subscribe(
      (result: any) => {
        if (result.type != "text/plain") {
          this.loadingReport = false;
          var blob = new Blob([result]);
          let file = "6.1 - Pallet report.xlsx";

          saveAs(blob, file);
        } else {
          this.snackbar.error("File not found in Blob!");
          this.loadingReport = false;
        }
      },
      () => {
        this.snackbar.warning("Items not found");
        this.loadingReport = false;
      }
    );
  }
}
