<mat-card>
  <mat-card-header>
    <mat-card-title>Wayfair Sales Weekly Report</mat-card-title>
    <mat-card-subtitle
      >Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle
    >
    <mat-card-subtitle>
      Shows weekly sales for the last 26 weeks
    </mat-card-subtitle>
  </mat-card-header>

  <div class="col">
    <button
      mat-raised-button
      color="primary"
      (click)="downloadReport()"
      [disabled]="loading"
      class="download"
    >
      Download Report
    </button>
  </div>
</mat-card>
