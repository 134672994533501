import { Component, OnInit } from '@angular/core';
import { HelperService } from "./../../../shared/services/helper.service";
import { ReportsService } from "./../../../shared/services/reports.service";
import { saveAs } from 'file-saver-es';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-wowcher-allocation-transfer',
  templateUrl: './wowcher-allocation-transfer.component.html',
  styleUrls: ['./wowcher-allocation-transfer.component.css']
})
export class WowcherAllocationTransferComponent implements OnInit {
  loadingReport = false;
  currentDate = new Date();

  constructor(
    private helper: HelperService,
    private reportsService: ReportsService
  ) { }

  ngOnInit(): void {
  }

  downloadAllocation() {
    this.reportsService
      .getWowcherAllocationReport()
      .subscribe((response) => {
        this.helper.saveFile(response);
      });
  }

  downloadTransfer() {
    this.reportsService
      .getWowcherTransferReport()
      .subscribe((response) => {
        this.helper.saveFile(response);
      });
  }

}
