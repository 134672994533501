<div class="dialog-wrapper">
  <mat-card>
    <mat-card-header>

      <mat-card-title>Purchase Order - {{data.purchaseOrder.poNumber}}</mat-card-title>
      <mat-card-subtitle>{{data.purchaseOrder.supplierReferenceNumber}}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>

      <div class="items-grid-wrapper">
        <ag-grid-angular style="width: 100%; height: 240px;" class="ag-theme-balham" [rowData]="rowData"
          [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)">

        </ag-grid-angular>
      </div>
      <p>Total - {{data.purchaseOrder.amount}} USD</p>
      <p>Converted Total - {{data.purchaseOrder.convertedGrandTotal}} GBP</p>

    </mat-card-content>
    <mat-card-actions>
      <button mat-button mat-dialog-close>Close</button>
      <button mat-button (click)="generatePDF()">Open Container Paller Report</button>
    </mat-card-actions>
  </mat-card>
</div>