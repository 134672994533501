import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AgGridModule } from "ag-grid-angular";
import { MaterialModule } from "../../../material/material.module";
import { ShippingFeesComponent } from "./shipping-fees.component";

@NgModule({
  declarations: [ShippingFeesComponent],
  exports: [ShippingFeesComponent],
  imports: [CommonModule, MaterialModule, AgGridModule],
})
export class ShippingFeesModule {}
