import { Component, Inject } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';

@Component({
  selector: 'app-select-email-recepients-dialog',
  templateUrl: './select-email-recepients-dialog.component.html',
  styleUrls: ['./select-email-recepients-dialog.component.scss']
})
export class SelectEmailRecepientsDialogComponent {

  public recepients: string;
  public addOnBlur = true;
  public recepientsArr: string[] = [];
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(
    public dialogRef: MatDialogRef<SelectEmailRecepientsDialogComponent>,
    private notifier: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      if (!this.isEmailValid(value)) {
        this.notifier.warning('Please add valid email');
        return;
      }
      this.recepientsArr.push(value);
    }
    event.chipInput!.clear();
  }

  remove(r: string): void {
    const index = this.recepientsArr.indexOf(r);
    if (index >= 0) {
      this.recepientsArr.splice(index, 1);
    }
  }

  sendToMe(): void {
    this.send(['kabir@doodle-products.com']);
  }

  sendToRecepients(): void {
    this.send(this.recepientsArr);
  }

  sendToMeAndRecepients(): void {
    this.recepientsArr.push('kabir@doodle-products.com');
    this.send(this.recepientsArr);
  }

  send(recepients: string[]): void {
    if (recepients.length < 1) {
      this.notifier.warning('Please add recepients');
      return;
    };
    this.dialogRef.close(recepients);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  isEmailValid(email: string) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) return true;
    return false;
  }
}
