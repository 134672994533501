import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';

@Component({
  selector: 'app-send-emails-dialog',
  templateUrl: './send-emails-dialog.component.html',
  styleUrls: ['./send-emails-dialog.component.scss']
})
export class SendEmailsDialogComponent {

  public subjectControl = new FormControl(
    `Unexpected Delay in Today's Delivery`
  );
  public bodyControl = new FormControl(
    `Dear recepient,

    I hope this email finds you well. 
    We sincerely apologize for any inconvenience this may cause, 
    but we regret to inform you that the delivery scheduled for today has encountered an unexpected delay.
    
    Thank you for your understanding.`
  );

  constructor(
    public dialog: MatDialogRef<SendEmailsDialogComponent>,
    private snackbar: SnackBarService,
  ) { }

  public send(): void {
    if (!this.subjectControl.value || !this.bodyControl.value) {
      return this.snackbar.warning('Please fill all fields');
    }
    this.dialog.close({
      subject: this.subjectControl.value,
      body: this.bodyControl.value,
    })
  }
}
