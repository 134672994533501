import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { SupplierProductScreen } from '../../../shared/models/product-screen.models';
import { POL, Port } from '../../../shared/models/extended-properties.models';

@Component({
  selector: "app-suppliers-state-configuration",
  templateUrl: "./suppliers-state-configuration.component.html",
  styleUrls: ["./suppliers-state-configuration.component.scss"],
})
export class SuppliersStateConfigurationComponent implements OnInit {

  public suppliers: Array<SupplierProductScreen>;
  public ports: Array<POL>;
  public selectedSupplierPorts = new Array<POL>();
  constructor(
    public dialogRef: MatDialogRef<SuppliersStateConfigurationComponent>,
    private inventoryService: InventoryService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    debugger;
    this.suppliers.forEach((i) => {
      this.selectedSupplierPorts.push(this.ports.find(x => x.id === i.portId));
    })
  }

  onToggle(supplier) {
    supplier.active = !supplier.active;
    this.inventoryService
      .ChangeSupplierState(supplier.pkSupplierID, supplier.active, supplier.portId)
      .subscribe(() => {
        this.snackBarService.success(
          `${supplier.supplierName} has became ${
            supplier.active ? "active" : "inactive"
          }`
        );
      });
  }

  selectedPortChange(port: POL, supplier: SupplierProductScreen): void {
    debugger;
    this.inventoryService
        .ChangeSupplierState(supplier.pkSupplierID, supplier.active, port.id)
        .subscribe(() => {
          this.snackBarService.success(
              'Success'
          );
        });
  }

  close() {
    this.dialogRef.close(this.suppliers);
  }
}
