<mat-card>
  <mat-card-header>
    <mat-card-title>Total cbm and total weight by order</mat-card-title>
    <mat-card-subtitle
      >Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle
    >
    <mat-card-subtitle
      >We put in tracking numbers and we can get order details, customer name,
      total cbm, total weight, postcode</mat-card-subtitle
    >
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <input
        type="text"
        class="tracking-numbers"
        placeholder="Tracking numbers"
        [(ngModel)]="trackingNumbers"
      />
      <button
        mat-raised-button
        color="primary"
        (click)="downloadReport()"
        style="float: right"
      >
        Download report
      </button>
    </div>
  </mat-card-content>
</mat-card>
