import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Credentials } from 'src/app/core/models/credentials.model';
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.css']
})
export class LoginLayoutComponent {

  errorMessage = '';
  destroySubj = new Subject();

  authorizationForm = new UntypedFormGroup({
    emailInput: new UntypedFormControl('', [
      Validators.required,
      Validators.email]),
    passwordInput: new UntypedFormControl('',
      [Validators.required,
      Validators.minLength(8)]),
  });

  constructor(
    private authenticationWebService: AuthService,
    private router: Router) { }

  onSubmit() {
    if (this.authorizationForm.valid) {
      const credentials: Credentials = {
        email: this.authorizationForm.value.emailInput,
        password: this.authorizationForm.value.passwordInput
      };

      this.authenticationWebService.AuthorizeAppUser(credentials)
        .subscribe((response) => {
            this.router.navigate(['/home', response.email]);
        }, errorResponse => {
          this.errorMessage = "Please provide valid credentials";
        });
    } else {
      this.errorMessage = 'Please provide valid credentials';
    }
  }
}
