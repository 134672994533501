<mat-card>
    <div class="row">
        <div class="col">
            <button mat-raised-button color="primary" (click)="addChannelFee()" [disabled]="loadingData">Add new channel
                fee</button>
        </div>

        <div class="col">
            <button mat-raised-button color="primary" *ngIf="selectedItem" (click)="removeSelectedFee()"> Remove
            </button>
        </div>

    </div>
</mat-card>

<div class="grid-wrapper">
    <ag-grid-angular style="width: 100%; height: 500px;" id="channelFeesGrid" class="ag-theme-balham"
        [rowData]="rowData" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [getRowHeight]="getRowHeight"
        [rowSelection]="rowSelection" (selectionChanged)="onSelectionChanged($event)"
        (cellValueChanged)="onCellValueChanged($event)" [headerHeight]="headerHeight"
        (firstDataRendered)="onFirstDataRendered($event)" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>