import { Component, ElementRef, NgModule, OnInit, ViewChild } from '@angular/core';
import { FileService } from 'src/app/shared/services/file.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { MatTableDataSource } from '@angular/material/table';
import { PoPropsService } from 'src/app/shared/services/po-props.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ChangeDetectorRef } from '@angular/core';

export interface DatesImportModel {
    dockingDate: Date;
    expectedDeliverySlot: Date;
    goodsReadyDate: Date;
    poNumber: string;
    shippingDate: Date;
}

@Component({
    selector: 'purchase-dates',
    templateUrl: './purchase-dates.component.html',
    styleUrls: ['./purchase-dates.component.css']
})

export class PurchaseDatesComponent implements OnInit {
    @ViewChild("inputFile") inputFile: ElementRef;
    results: DatesImportModel[];
    displayedColumns: string[] =
        ['poNumber', 'goodsReadyDate', 'shippingDate', 'dockingDate', 'expectedDeliverySlot'];
    errors: any;
    show = true;
    dataSource = new MatTableDataSource<DatesImportModel>();
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    ngAfterViewInit() {
    }

    constructor(
        private fileService: FileService,
        private snackBar: SnackBarService,
        private poPropsService: PoPropsService,
        public dialogRef: MatDialogRef<PurchaseDatesComponent>,
        private cdr: ChangeDetectorRef) { }

    ngOnInit() { }



    selectFile(): void {
        this.inputFile.nativeElement.click();
    }


    submit(): void {
        if (this.results.length > 0) {
            this.poPropsService.updateDates(this.results).subscribe(
                (res) => {
                    this.snackBar.success("Dates updated");
                    this.dialogRef.close();
                },
                error => {
                    this.snackBar.error("Something went wrong!");
                });
        }
    }

    uploadFile(event) {
        let file = event.target.files[0];
        if (file.size > 10485760) {
            this.snackBar.warning("File size cannot be more than 10 MB");
            return;
        }
        const formData = new FormData();

        let fileExtension = file.name.substring(file.name.indexOf("."));
        if (fileExtension !== ".xlsx") {
            this.snackBar.warning("File should be an xlsx sheet");
            return;
        }
        let fileKey = `uploadedFile`;
        formData.append(fileKey, file);

        this.fileService.uploadPODatesImport(formData).subscribe(
            (res) => {
                this.results = res.results;
                this.errors = res.errors;
                this.dataSource = new MatTableDataSource<DatesImportModel>(this.results);
                this.cdr.detectChanges();
                this.dataSource.paginator = this.paginator;
                this.cdr.detectChanges();

                setTimeout(
                    () => {
                        this.dataSource.paginator = this.paginator;
                        this.cdr.detectChanges();
                    });
            });

    }
}