import { Component, Inject, OnInit, Optional } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { GridApi, GridOptions } from "ag-grid-community";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { InventoryService } from "src/app/shared/services/inventory.service";
import {
  Column,
  Filter,
  FilterType,
  SortDirection,
  View,
} from "./../../../shared/models/product-screen.models";
import { AddColumnDialogComponent } from "./add-column-dialog/add-column-dialog.component";

@Component({
  selector: "app-create-view-dialog",
  templateUrl: "./create-view-dialog.component.html",
  styleUrls: ["./create-view-dialog.component.scss"],
})
export class CreateViewDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateViewDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private inventoryService: InventoryService
  ) {}
  viewModel: View = { id: 0, title: "", columns: [], filters: [] };

  possibleColumns: Column[];
  possibleFilters: Filter[] = [];
  ngOnInit(): void {
    this.possibleColumns = this.data.columns;

    if (this.data.view) {
      this.viewModel = this.data.view;
    }
  }

  /* <--------------------------------------------------COLUMNS SECTION-------------------------------------------------------> */
  columnGridApi: GridApi;
  columnGridOptions: GridOptions;

  onColumnGridReady(params) {
    this.columnGridApi = params.api;
    this.columnGridApi.sizeColumnsToFit();

    if (this.data.view) {
      this.viewModel.columns.forEach((column: any) => {
        if (
          this.inventoryService.notSortablePropsProductScreen.includes(
            column.propertyName
          )
        ) {
          return null;
        }

        column.sortDirectionEnumKey = SortDirection[column.sortDirection]
          ? SortDirection[column.sortDirection].toLowerCase() === "none"
            ? SortDirection[column.sortDirection]
            : `${SortDirection[column.sortDirection]}ending`
          : null;
      });

      this.columnGridApi.applyTransaction({
        add: this.viewModel.columns,
      });

      this.possibleColumns = this.possibleColumns.filter(
        (x) => !this.viewModel.columns.map((x) => x.name).includes(x.name)
      );

      this.possibleFilters = this.viewModel.columns.filter(
        (x) =>
          !this.inventoryService.notSortablePropsProductScreen.includes(
            x.propertyName
          )
      ) as any;
    }
  }

  columnDefs = [
    { field: "name", suppressSizeToFit: true, resizable: true, rowDrag: true },
    {
      headerName: "Sort Direction",
      field: "sortDirectionEnumKey",
      suppressSizeToFit: true,
      resizable: true,
      editable: (params) => this.isPropertySortable(params),
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: this.getColumnOrderDropdown(),
      },
    },
  ];

  getColumnOrderDropdown(): string[] {
    let enumKeys = [];
    for (var log in SortDirection) {
      if (isNaN(Number(log))) {
        log = log.toLowerCase() === "none" ? log : `${log}ending`;
        enumKeys.push(log);
      }
    }
    return enumKeys;
  }

  columnRowData = [];
  selectedColumnItem;
  onColumnSelectionChanged(event) {
    let selectedRow = this.columnGridApi.getSelectedRows();
    if (selectedRow) {
      this.selectedColumnItem = selectedRow[0];
    }
  }

  addColumn() {
    if (!this.possibleColumns) {
      return;
    }
    const dialogRef = this.dialog.open(AddColumnDialogComponent, {
      data: this.possibleColumns,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.columnGridApi.applyTransaction({
          add: result,
        });
        this.possibleColumns = this.possibleColumns.filter(
          (x) => !result.map((x) => x.name).includes(x.name)
        );
        this.possibleFilters.push(...result);
      }
    });
  }

  deleteSelectedColumnRow() {
    if (!this.selectedColumnItem) {
      return;
    }
    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.columnGridApi.applyTransaction({
          remove: [this.selectedColumnItem],
        });
        this.possibleColumns.push(this.selectedColumnItem);

        this.filterGridApi.forEachNode((node) => {
          if (node.data.name === this.selectedColumnItem.name) {
            this.filterGridApi.applyTransaction({
              remove: [this.selectedColumnItem],
            });
          }
        });
        this.possibleFilters = this.possibleFilters.filter(
          (x) => x.name !== this.selectedColumnItem.name
        );
      }
    });
  }

  onColumnCellValueChanged(event) {
    if (!event.data.sortDirectionEnumKey) {
      return;
    }

    event.data.sortDirection =
      SortDirection[event.data.sortDirectionEnumKey.replace("ending", "")];
  }

  /* <--------------------------------------------------COLUMNS SECTION END-------------------------------------------------------> */

  /* <--------------------------------------------------FILTERS SECTION-------------------------------------------------------> */
  filterGridApi: GridApi;
  filterGridOptions: GridOptions;

  onFilterGridReady(params) {
    this.filterGridApi = params.api;
    this.filterGridApi.sizeColumnsToFit();
    if (this.data.view) {
      this.viewModel.filters.forEach((filter: any) => {
        filter.condition = FilterType[filter.filterType];
      });

      this.filterGridApi.applyTransaction({
        add: this.viewModel.filters,
      });
      this.possibleFilters = this.possibleFilters.filter(
        (x) => !this.viewModel.filters.map((x) => x.name).includes(x.name)
      );
      if (
        this.viewModel.filters.findIndex((x) => x.columnName === "SKU") === -1
      ) {
        this.possibleFilters.unshift({
          id: 0,
          propertyName: "SKU",
          name: "SKU",
          viewId: this.viewModel.id,
        } as any);
      }
      if (
        this.viewModel.filters.findIndex((x) => x.columnName === "Title") === -1
      ) {
        this.possibleFilters.unshift({
          id: 0,
          propertyName: "Title",
          name: "Title",
          viewId: this.viewModel.id,
        } as any);
      }
      return;
    }

    if (this.data.filters && this.data.filters.length > 0) {
      this.data.filters.forEach((filter: any) => {
        filter.condition = FilterType[filter.filterType];
      });

      this.filterGridApi.applyTransaction({
        add: this.data.filters,
      });
    }
  }

  filterColumnDefs = [
    { field: "name", resizable: true, suppressSizeToFit: true },
    {
      field: "condition",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: this.getFilterConditionsDropdown(),
      },
      suppressSizeToFit: true,
      resizable: true,
    },
    {
      field: "value",
      editable: true,
      resizable: true,
      suppressSizeToFit: true,
    },
  ];

  getFilterConditionsDropdown(): string[] {
    let enumKeys = [];
    for (var log in FilterType) {
      if (isNaN(Number(log))) {
        enumKeys.push(log);
      }
    }
    return enumKeys;
  }

  filterRowData = [];
  selectedFilterItem;
  onFilterSelectionChanged(event) {
    let selectedRow = this.filterGridApi.getSelectedRows();
    if (selectedRow) {
      this.selectedFilterItem = selectedRow[0];
    }
  }

  addFilter() {
    if (!this.possibleFilters) {
      return;
    }
    const dialogRef = this.dialog.open(AddColumnDialogComponent, {
      data: this.possibleFilters,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        result.forEach((x) => (x.columnName = x.propertyName));
        result.forEach((x) => (x.condition = FilterType[x.filterType]));
        this.filterGridApi.applyTransaction({
          add: result,
        });
        this.possibleFilters = this.possibleFilters.filter(
          (x) => !result.map((x) => x.name).includes(x.name)
        );
      }
    });
  }

  deleteSelectedFilterRow() {
    if (!this.selectedFilterItem) {
      return;
    }

    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.filterGridApi.applyTransaction({
          remove: [this.selectedFilterItem],
        });
        this.possibleFilters.push(this.selectedFilterItem);
      }
    });
  }

  onFilterCellValueChanged(event) {
    event.data.filterType = FilterType[event.data.condition];
  }

  /* <--------------------------------------------------FILTERS SECTION END-------------------------------------------------------> */

  save() {
    if (!this.viewModel.title) {
      this.snackBarService.warning("Please, fill in the view title");
      return;
    }

    if (this.viewModel.title.length > 60) {
      this.snackBarService.warning(
        "The title can not be longer than 60 characters"
      );
      return;
    }

    let validationError: boolean = false;
    this.filterGridApi.forEachNode((node) => {
      if (!node.data.condition || !node.data.value) {
        this.snackBarService.warning("Please, fill in the filter correctly");
        validationError = true;
      }
    });
    if (validationError) {
      return;
    }

    this.viewModel.columns = [];
    this.viewModel.filters = [];
    this.columnGridApi.forEachNode((node) => {
      this.viewModel.columns.push(node.data);
    });
    this.filterGridApi.forEachNode((node) => {
      this.viewModel.filters.push(node.data);
    });
    this.dialogRef.close(this.viewModel);
  }

  cancel() {
    this.dialogRef.close();
  }

  isPropertySortable(params) {
    if (
      this.inventoryService.notSortablePropsProductScreen.includes(
        params.data.propertyName
      )
    ) {
      return false;
    }
    return true;
  }
}
