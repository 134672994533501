import {
  Component,
  ElementRef,
  Input,
  isDevMode,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ChangeDetectorRef } from "@angular/core";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { FileService } from "src/app/shared/services/file.service";
import { saveAs } from "file-saver-es";

@Component({
  selector: "app-item-instructions",
  templateUrl: "./item-instructions.component.html",
  styleUrls: ["./item-instructions.component.css"],
})
export class ItemInstructionsComponent implements OnInit, OnChanges {
  @ViewChild("inputFile") inputFile: ElementRef;

  @Input() stockItemId: string;
  itemInfo: any;
  selectedFiles: FileList;
  instructions: any[];
  selected: any;

  constructor(
    private fileService: FileService,
    private inventoryService: InventoryService,
    private snackBar: SnackBarService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}
  
  ngOnChanges() {
    this.initialData();
  }
  
  ngOnInit(): void {
    if (this.stockItemId) {
      return;
    }

    this.stockItemId = isDevMode()
      ? "b94d1ea0-5133-48ba-b345-deebadda1204"
      : sessionStorage.getItem("lwkr-stockitemid-instruction");

    this.initialData();
  }
  
  initialData() {
    this.inventoryService
      .getItemTitleByid(this.stockItemId)
      .subscribe((result) => {
        this.itemInfo = result;
      });

    this.getInstructions();
  }

  add() {
    this.inputFile.nativeElement.click();
  }

  uploadFiles(event) {
    this.selectedFiles = event.target.files;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.uploadImage(this.selectedFiles[i]);
    }
  }

  uploadImage(file) {
    if (file.size > 52428800) {
      this.snackBar.warning("File size cannot be more than 50 MB");
      return;
    }

    const formData = new FormData();
    if (!this.itemInfo) {
      this.itemInfo.sku = "_";
      this.itemInfo.title = "_";
    }

    let itemTitle = this.itemInfo.title.replace(/\s/g, "_");
    let timeStamp = moment().format("YYMMDDHHmmssSSS");
    let fileExtension = file.name.substring(file.name.indexOf("."));
    let fileKey = `${this.stockItemId}/${this.itemInfo.sku}_${itemTitle}_${file.name}_${timeStamp}${fileExtension}`;

    formData.append(fileKey, file);

    this.fileService.uploadInstruction(formData).subscribe((result) => {
      this.instructions.push(result.url.replace("/images/", "/instructions/"));
      this.cd.detectChanges();
      this.snackBar.success(
        `${file.name.replace(/\s/g, "")} was added successfully`
      );
    });
  }

  getInstructions() {
    this.fileService
      .getInstructionsByItemId(this.stockItemId)
      .subscribe((result) => {
        this.instructions = result;
      });
  }

  open(url) {
    window.open(url);
  }

  downloadAllInstructions() {

    this.instructions.forEach(i => {
      this.downloadInstruction(i);
    });
    
  }

  downloadInstruction(key: string): void {
    this.fileService.downloadInstruction(key.replace("https://lw-images.s3-eu-west-1.amazonaws.com/", "")).subscribe((response) => {
      const responseBlob = new Blob([response], { type: response.type });
        saveAs(responseBlob, this.prettifyFileName(key));
    })
  }

  prettifyFileName(fileName: string) {
    return fileName.split("/").pop();
  }

  remove(key) {
    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fileService.removeImageByItemId(this.stockItemId, key).subscribe(
          () => {
            this.instructions = this.instructions.filter((x) => x !== key);
            this.cd.detectChanges();
            this.snackBar.success("Successfully removed.");
          },
          () => {
            this.snackBar.error("Error");
          }
        );
      }
    });
  }

  humanaze(url) {
    return url.replace(
      "https://lw-images.s3-eu-west-1.amazonaws.com/instructions/" +
        this.stockItemId +
        "/",
      ""
    );
  }
}
