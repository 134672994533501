import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { RepositoryService } from "src/app/core/services/repository.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { GroupedReports, ReportDto } from "../../shared/models/ReportDto";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatMenuTrigger } from "@angular/material/menu";
import { UserService } from "src/app/shared/services/user.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit, OnDestroy {
  constructor(
    private reportsService: ReportsService,
    private repositoryService: RepositoryService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private snackBar: SnackBarService
  ) { }

  groupedReportsInitial: GroupedReports[] = [
    {
      number: 0,
      description: 'Favourites',
      reports: []
    },
    {
      number: 1,
      description: "Overall Sales",
      reports: [],
    },
    {
      number: 2,
      description: "Stock Value",
      reports: [],
    },
    {
      number: 3,
      description: "Payment Overview",
      reports: [],
    },
    {
      number: 4,
      description: "Channel Sales",
      reports: [],
    },
    {
      number: 5,
      description: "POs",
      reports: [],
    },
    {
      number: 6,
      description: "Container/ Pallet",
      reports: [],
    },
    {
      number: 7,
      description: "FBA Stock Value",
      reports: [],
    },
    {
      number: 8,
      description: "Product Details",
      reports: [],
    },
    {
      number: 9,
      description: "Customer Order",
      reports: [],
    },
    {
      number: 10,
      description: "Supplier Info",
      reports: [],
    },
    {
      number: 11,
      description: "WOW Stock",
      reports: [],
    },
    {
      number: 12,
      description: "UK Carriers",
      reports: [],
    },
    {
      number: 13,
      description: "Stock Take",
      reports: [],
    },
    {
      number: 14,
      description: "Seller Flex Stock Qty",
      reports: [],
    },
    {
      number: 15,
      description: "Resend/Returns/Refunds",
      reports: [],
    },
  ];

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;

  contextMenuPosition = { x: '0px', y: '0px' };

  destroyedComponentSubj = new Subject<void>();

  groupedReports: GroupedReports[] = [];
  selectedGroup: string = "All";

  isRouterOutlet: boolean = false;


  ngOnInit(): void {
    this.repositoryService
      .getList("api/scheduledTasks/getAttachmentTypes")
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe((result) => {
        result.forEach((report) => {

          if (this.userService.currentUserConfig.favouriteReports.includes(report.number)) {
            this.groupedReportsInitial[0].reports.push(report);
          }

          this.groupedReportsInitial.forEach((grouppedReport) => {
            if (
              report.number.toString().split(".")[0] ===
              grouppedReport.number.toString()
            ) {
              grouppedReport.reports.push(report);
            }
          });
        });
        this.groupedReports = this.groupedReportsInitial;
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((val: NavigationEnd) => {
        if (val.url.toLowerCase().includes("/reports/")) {
          this.isRouterOutlet = true;
        } else {
          this.isRouterOutlet = false;
        }
      });
  }

  navigateToReportOutlet() {
    // this.router.navigateByUrl('/reports(reportOutlet:carrier-report)');

    this.router.navigate(['reports', { outlets: { reportOutlet: ['carrier-report'] } }]);
  }

  ngOnDestroy(): void {
    this.destroyedComponentSubj.next();
    this.destroyedComponentSubj.complete();
  }

  groupBySelection(groupTitle: string) {
    this.selectedGroup = groupTitle;

    if (groupTitle === "All") {
      this.groupedReports = this.groupedReportsInitial;
    } else {
      this.groupedReports = this.groupedReportsInitial.filter(
        (x) => x.description === groupTitle
      );
    }
  }

  onContextMenu(event: MouseEvent, item: any, group: GroupedReports) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  makeFavourite(report: any): void {
    const i = this.userService.currentUserConfig.favouriteReports.indexOf(report.number);
    if (i === -1) {

    
    this.userService.currentUserConfig.favouriteReports.push(report.number);
    this.userService.updateFavouriteReports(this.userService.currentUserConfig.email, this.userService.currentUserConfig.favouriteReports).subscribe(
      result => {
        this.groupedReports[0].reports.push(report);
        this.snackBar.success('Successfully added');
      }
    )

  }
  }

  removeFromFavourites(report: any): void {
    const i = this.userService.currentUserConfig.favouriteReports.indexOf(report.number);
    if (i !== -1) {
      this.userService.currentUserConfig.favouriteReports.splice(i, 1);
      this.userService.updateFavouriteReports(this.userService.currentUserConfig.email, this.userService.currentUserConfig.favouriteReports).subscribe(
        result => {
          const index = this.groupedReports[0].reports.indexOf(report);
          if (index !== -1) {
            this.groupedReports[0].reports.splice(index, 1);
          }
          this.snackBar.success('Successfully removed');
        }
      )
    }
    
  }
}
