import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { RepositoryService } from "src/app/core/services/repository.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { CostSheetService } from "../../../../shared/services/cost-sheet.service";

export interface ChannelFee {
  id?: number;
  title: number;
  channelId: number;
  channelName?: string;
  channelFeeType: number;
  channelFeeTypeName?: string;
  feeValue: number;
}

@Component({
  selector: "app-add-new-channel-fee",
  templateUrl: "./add-new-channel-fee.component.html",
  styleUrls: ["./add-new-channel-fee.component.css"],
})
export class AddNewChannelFeeComponent implements OnInit {
  public channelFeeForm: UntypedFormGroup;
  public selectedChannel: { id: number; name: string };
  public selectedFeeType: { id: number; name: string };
  channels: { id: number; name: string }[];
  feeTypes: { id: number; name: string }[];

  constructor(
    private repo: RepositoryService,
    private costSheetService: CostSheetService,
    private snackBar: SnackBarService,
    private dialogRef: MatDialogRef<AddNewChannelFeeComponent>
  ) {}

  ngOnInit(): void {
    this.channelFeeForm = new UntypedFormGroup({
      title: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(100),
      ]),
      feeValue: new UntypedFormControl(0, [
        Validators.required,
        Validators.maxLength(20),
      ]),
    });

    this.channels = [
      { id: 1, name: "AmazonUK" },
      { id: 2, name: "Ebay" },
      { id: 3, name: "Wayfair" },
      { id: 4, name: "Amazon FBA" },
    ];

    this.feeTypes = [
      { id: 0, name: "Constant" },
      { id: 1, name: "Percentage" },
    ];
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.channelFeeForm.controls[controlName].hasError(errorName);
  };

  createChannelFee(params) {
    if (!this.selectedChannel) {
      this.snackBar.openSnackBar("You need to select channel first", "Warning");
      return;
    }
    let newChannelFee: ChannelFee = {
      title: params.title,
      channelId: this.selectedChannel.id,
      channelFeeType: this.selectedFeeType.id,
      feeValue: params.feeValue,
    };

    this.costSheetService.addChannelFee(newChannelFee).subscribe(
      (result) => {
        this.snackBar.openSnackBar("Successfully added", "Channel Fee");
        this.dialogRef.close({ value: true });
      },
      (error) => {
        this.snackBar.openSnackBar("Something went wrong", "Error");
        console.error();
        this.dialogRef.close();
      }
    );
  }
}
