import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, filter, distinctUntilChanged, tap, takeUntil, switchMap, finalize } from 'rxjs/operators';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { Channel } from 'src/app/shared/models/channel';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-price-changer',
  templateUrl: './price-changer.component.html',
  styleUrls: ['./price-changer.component.css']
})
export class PriceChangerComponent implements OnInit {

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public filteredItems: any[];
  public selectedItem: any;
  public loading: boolean;
  public destroyedComponentSubj = new Subject<void>();
  public searchItemsCtrl = new UntypedFormControl();
  public searchItemField: string;
  public channels: Channel[];
  public selectedChannel: Channel;
  public amountToChange: number = 0;
  public linkedComboMainPartsIds: string[];
  public dataSource = new MatTableDataSource();
  public selection = new SelectionModel<any>(true, []);
  public columns: any[];
  public displayedColumns: string[];
  public selectedPricesForAllChannels: any[];

  constructor(private inventoryService: InventoryService, private snackbar: SnackBarService, private dialog: MatDialog) {
    this.columns = [
      { name: 'Combo SKU', value: 'sku' },
      { name: 'Description', value: 'description' },
      { name: 'Parent SKU', value: 'comboMainPartsku' },
      { name: 'Child Qty', value: 'compositionQuantity' },
      { name: 'Source', value: 'source' },
      { name: 'Current Parent Price', value: 'channelPrice' },
      { name: 'Current Child Price', value: 'comboMainPartchannelPrice' },
      { name: 'Price After Change', value: 'newPrice' },
      { name: '', value: 'select' }
    ];
    this.displayedColumns = this.columns.map((col) => col.value);
  }

  ngOnInit(): void {

    this.inventoryService.getChannels().subscribe(result => {
      this.channels = result;
      this.channels.unshift({
        id: 0,
        source: "All",
        subsource: "",
      });
    })

    this.searchItemsCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((term) => term && term.toString().trim().length > 0),
        distinctUntilChanged(),
        tap(() => {
          this.filteredItems = [];
          this.loading = true;
        }),
        takeUntil(this.destroyedComponentSubj),
        switchMap((value: string) =>
          this.inventoryService.searchStockItems(value)
            .pipe(
              finalize(() => {
                this.loading = false;
              })
            )
        )
      ).subscribe((data: any[]) => {
        if (data == undefined) {
          this.filteredItems = [];
        } else {
          this.filteredItems = data;
        }
      });
  }


  selectedFiltereditemChange(params) {
    this.selectedItem = this.filteredItems.find((x) => x.sku == params.value);
    this.getLinkedItems(this.selectedItem.id, this.selectedChannel.source, this.selectedChannel.subsource, this.selectedChannel.tag);
  }

  selectedChannelChange(channel: Channel): void {
    this.selectedChannel = channel;
    this.selectedItem = null;
    this.searchItemField = null;
    this.dataSource.data = [];
    this.amountToChange = 0;
  }

  getLinkedItems(stockItemId: string, source: string, subsource: string, tag?: string) {
    this.inventoryService.getComboItemsWithChannelPrices(stockItemId, source, subsource, tag).subscribe((result: any[]) => {

      if (this.selectedChannel.source === 'All') {
        this.selectedItem.channelPrice = result.filter(i => i.stockItemId === this.selectedItem.id)[0].channelPrice;
        this.selectedPricesForAllChannels = result.filter(i => i.stockItemId === this.selectedItem.id);
        const allowedSources = [...new Set(this.selectedPricesForAllChannels.map(i => i.source))];
        const allowedSubsources = [...new Set(this.selectedPricesForAllChannels.map(i => i.subsource))];
        const allowedTags = [...new Set(this.selectedPricesForAllChannels.map(i => i.tag))];
        this.dataSource = new MatTableDataSource(result.filter(i => allowedSources.includes(i.source) && allowedSubsources.includes(i.subsource) && allowedTags.includes(i.tag)));
      } else {
        this.selectedItem.channelPrice = result.filter(i => i.stockItemId === this.selectedItem.id)[0].channelPrice;
        this.dataSource = new MatTableDataSource(result.filter(i => i.comboMainPart != null && i.comboMainPart != undefined));
        this.dataSource = new MatTableDataSource(result);
      }

      this.dataSource.sort = this.sort;
      this.masterToggle();

      if (this.amountToChange !== 0) {
        this.amountChanged();
      }
    });
  }

  getLinkedComboMainPartIds(stockItemId: string) {
    this.inventoryService.getLinkedComboMainPartIds(stockItemId).subscribe(result => {
      this.linkedComboMainPartsIds = result;
    });
  }


  amountChanged() {
    if (this.selectedChannel.source === 'All') {
      this.dataSource.data.forEach((i: any) => {
        let mattressPrices = [...this.selectedPricesForAllChannels];
        let mattressChannelPrice = mattressPrices.filter(x => x.source === i.source && x.subsource === i.subsource && x.tag === i.tag)?.[0];
        if (mattressChannelPrice) {
          if (i.stockItemId === mattressChannelPrice.stockItemId) {
            i.newPrice = (this.selectedItem.channelPrice + this.amountToChange).toFixed(2);
          } else {
            i.newPrice = (i.comboMainPart?.channelPrice + i.compositionQuantity * (this.selectedItem.channelPrice + this.amountToChange)).toFixed(2);
            if (i.sku === 'KA1048 + KA744 + KA746') {
              i.newPrice = i.channelPrice + this.amountToChange;
            }
          }

          if (i.stockItemId !== this.selectedItem.id && ( i.comboMainPart == null || isNaN(i.comboMainPart?.channelPrice))) {
            this.selection.deselect(i);
          }
        }
      });

    } else {
      if ((this.selectedItem.channelPrice + this.amountToChange) <= 0) {
        this.snackbar.error("Price can't be lower than 0!");
        return;
      }
      this.dataSource.data.forEach((i: any) => {
        if (i.comboMainPart) {
          i.newPrice = (i.comboMainPart.channelPrice + i.compositionQuantity * (this.selectedItem.channelPrice + this.amountToChange)).toFixed(2);
          if (i.sku === 'KA1048 + KA744 + KA746') {
            i.newPrice = i.channelPrice + this.amountToChange;
          }
        } else {
        }
      });
    }

  }

  updatePrices() {
    if (this.amountToChange === 0) {
      this.snackbar.warning("You need to set amount of change first");
      return;
    }

    if ((this.selectedItem.channelPrice + this.amountToChange) <= 0) {
      this.snackbar.error("Price can't be lower than 0!");
      return;
    }

    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        let pricesToUpdate = [];
        if (this.selectedChannel.source !== 'All') {
          this.dataSource.data.forEach((i: any) => {
            if (i.stockItemId !== this.selectedItem.id) {
              const newPrice = (i.comboMainPart.channelPrice + i.compositionQuantity * (this.selectedItem.channelPrice + this.amountToChange)).toFixed(2);
              if (this.selection.isSelected(i) && i.newPrice > 0) {
                pricesToUpdate.push({
                  stockItemId: i.stockItemId,
                  source: i.source,
                  subsource: i.subsource,
                  tag: i.tag,
                  price: newPrice
                });
              }
            }
          });

          if ((this.amountToChange + this.selectedItem.channelPrice).toFixed(2) > 0) {
            pricesToUpdate.push({
              stockItemId: this.selectedItem.id,
              source: this.selectedChannel.source,
              subsource: this.selectedChannel.subsource,
              tag: this.selectedChannel.tag,
              price: (this.amountToChange + this.selectedItem.channelPrice).toFixed(2)
            });
          }
          
        } else {
          this.dataSource.data.forEach((i: any) => {
            const newPrice = isNaN(parseFloat(i.newPrice)) ? 0 : parseFloat(i.newPrice).toFixed(2);
            if (!this.selectedPricesForAllChannels.includes(y => y.stockItemId === i.stockItemId)) {
              if (this.selection.isSelected(i) && newPrice > 0) {
                pricesToUpdate.push({
                  stockItemId: i.stockItemId,
                  sku: i.sku,
                  source: i.source,
                  subsource: i.subsource,
                  tag: i.tag,
                  price: newPrice,
                });
              }
            }

          });
        }
        if (pricesToUpdate && pricesToUpdate.length > 0) {
          this.inventoryService.updateChannelPrices(pricesToUpdate).subscribe(result => {
            this.snackbar.success("Prices were updated.");
          });
        } else {
          this.snackbar.warning("There is no price to update - please check everything");
        }
        
      }
    });

  }

  getNewPriceForSelectedItem(): number {
    return (this.selectedItem.channelPrice + this.amountToChange).toFixed(2);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}