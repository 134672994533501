import { Component } from "@angular/core";

@Component({
  selector: "disabled-checkbox-renderer",
  template: `<input
    *ngIf="params.value"
    type="checkbox"
    [checked]="params.value"
    disabled
    style="filter: invert(100%) hue-rotate(18deg) brightness(4.7);"
  />`,
})
export class DisabledCheckboxRenderer {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }
}
