<mat-card>
    <mat-card-header>
        <mat-card-title>Stock Take Random Report</mat-card-title>
        <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
        <mat-card-subtitle>This is a list of items that we have had in stock in the past 3 months</mat-card-subtitle>
    </mat-card-header>
    <div style="display: flex; flex-direction: row; align-items: flex-end;">


        <div class="col">
            <button mat-raised-button color="primary" (click)="downloadReport()" [disabled]="loading"
                style="float: right">
                Download report
            </button>
        </div>
    </div>
</mat-card>
<br />