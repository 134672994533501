<mat-card>
  <mat-card-header>
    <mat-card-title>UK Freight Cost report</mat-card-title>
    <mat-card-subtitle
      >Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle
    >
    <mat-card-subtitle>
      Shows what was sent out by Tuffnels over a certain period and the costs															
    </mat-card-subtitle>
  </mat-card-header>
  <div class="row">
    <div class="col">
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range: </mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
          />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
          >Invalid start date</mat-error
        >
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
          >Invalid end date</mat-error
        >
      </mat-form-field>

    </div>

    <div class="col">
      <button
        mat-raised-button
        color="primary"
        (click)="downloadFile()"
        [disabled]="loadingReport"
        style="float: right"
      >
        Download report
      </button>
    </div>
  </div>
</mat-card>
