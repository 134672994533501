import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { PurchaseService } from 'src/app/shared/services/purchase.service';

@Component({
  selector: 'app-all-po-docs',
  templateUrl: './all-po-docs.component.html',
  styleUrls: ['./all-po-docs.component.scss'],
})
export class AllPoDocsComponent implements OnInit, OnDestroy {
  constructor(
    private purchaseService: PurchaseService,
    public dialog: MatDialog
  ) { }

  public selectedPurchase: any;
  public purchaseOrders: any[] = [];
  public searchPurchase: string;
  public searchItemsCtrl = new UntypedFormControl();
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.searchItemsCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((term) => term && term.toString().trim().length > 0),
        distinctUntilChanged(),
        tap(() => {
          this.purchaseOrders = [];
        }),
        takeUntil(this.destroy$),
        switchMap((value) =>
          this.purchaseService.getPurchaseOrdersBySubstring(value)
        )
      )
      .subscribe((response) => {
        this.purchaseOrders = response;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadDocuments(purchase) {
    this.selectedPurchase = purchase;
  }
}
