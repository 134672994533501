<mat-card>
  <!-- <mat-progress-bar *ngIf="loadingReport" mode="indeterminate"></mat-progress-bar> -->

  <mat-card-header>
    <mat-card-title>Bed Weekly Sales Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>Time range: {{ period[0] | date:'dd/MM/yy' }} - {{ period[1] | date:'dd/MM/yy' }}
    </mat-card-subtitle>
    <mat-card-subtitle>
      Bed categories, 10 previous weekly sales with stock level
    </mat-card-subtitle>
  </mat-card-header>

  <div class="row">
    <div class="col">
      <button mat-raised-button color="primary" (click)="downloadFile()" [disabled]="loadingReport" style="float: left">
        Download Excel
      </button>
    </div>

    <div class="col">
      <button mat-raised-button color="primary" (click)="getBedWeeklySales()" [disabled]="loadingReport"
        style="float: right">
        Generate Report
      </button>
    </div>
  </div>
</mat-card>
<br />

<div [hidden]="!reportItems">
  <mat-card>
    <div class="row">
      <div [hidden]="!reportItems">
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column.name">
                            <md-header-cell *matHeaderCellDef>{{ column.displayedName }}</md-header-cell>
                            <md-cell *matCellDef="let item">{{ item[column.name] }}</md-cell>
                        </ng-container> -->

            <ng-container matColumnDef="sku">
              <th mat-header-cell *matHeaderCellDef>SKU</th>
              <td mat-cell *matCellDef="let item">{{ item.sku }}</td>
            </ng-container>

            <ng-container matColumnDef="itemDescription">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let item">
                {{ item.itemDescription }}
              </td>
            </ng-container>

            <ng-container matColumnDef="categoryName">
              <th mat-header-cell *matHeaderCellDef>Category name</th>
              <td mat-cell *matCellDef="let item">{{ item.categoryName }}</td>
            </ng-container>

            <ng-container matColumnDef="week10">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[3].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week1 }}</td>
            </ng-container>

            <ng-container matColumnDef="week9">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[4].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week2 }}</td>
            </ng-container>

            <ng-container matColumnDef="week8">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[5].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week3 }}</td>
            </ng-container>

            <ng-container matColumnDef="week7">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[6].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week4 }}</td>
            </ng-container>

            <ng-container matColumnDef="week6">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[7].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week5 }}</td>
            </ng-container>

            <ng-container matColumnDef="week5">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[8].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week6 }}</td>
            </ng-container>

            <ng-container matColumnDef="week4">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[9].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week7 }}</td>
            </ng-container>

            <ng-container matColumnDef="week3">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[10].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week8 }}</td>
            </ng-container>

            <ng-container matColumnDef="week2">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[11].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week9 }}</td>
            </ng-container>

            <ng-container matColumnDef="week1">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[12].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week10 }}</td>
            </ng-container>

            <ng-container matColumnDef="averageSales">
              <th mat-header-cell *matHeaderCellDef>Average Sales</th>
              <td mat-cell *matCellDef="let item">{{ item.averageSales }}</td>
            </ng-container>

            <ng-container matColumnDef="stockLvl">
              <th mat-header-cell *matHeaderCellDef>Stock Level</th>
              <td mat-cell *matCellDef="let item">{{ item.stockLvl }}</td>
            </ng-container>

            <ng-container matColumnDef="cbmPerItem">
              <th mat-header-cell *matHeaderCellDef>CBM per item</th>
              <td mat-cell *matCellDef="let item">{{ item.cbmPerItem }}</td>
            </ng-container>

            <ng-container matColumnDef="cbmStock">
              <th mat-header-cell *matHeaderCellDef>CBM in Stock</th>
              <td mat-cell *matCellDef="let item">{{ item.cbmStock }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay" class="table-row"></tr>
          </table>
        </div>
      </div>
    </div>
  </mat-card>
</div>