<!-- <div *ngIf="loadingReport" style="align-items: center; z-index: 100;">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div> -->
<mat-card>
  <mat-card-header>
    <mat-card-title>Weekly Sales Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>Time range: {{ period[0] | date: "dd/MM/yy" }} -
      {{ period[1] | date: "dd/MM/yy" }}</mat-card-subtitle>
    <mat-card-subtitle>
      Shows total sales of products in pieces for the last 52 weeks. Total stock level in column C and Average 8 weeks
      sales
    </mat-card-subtitle>
  </mat-card-header>

  <div class="row">
    <div class="col">
      <button mat-raised-button color="primary" (click)="downloadReport()" [disabled]="loadingReport"
        style="float: left">
        Download Report
      </button>

      <button mat-raised-button color="primary" (click)="downloadReportNew()" [disabled]="loadingReport"
        style="float: left">
        Download Report Fresh Sales
      </button>
    </div>

    <div class="col">
      <button mat-raised-button color="primary" (click)="getWeeklySales()" [disabled]="loadingReport"
        style="float: right">
        Show Report
      </button>
    </div>
  </div>
</mat-card>
<br />

<div [hidden]="!reportItems">
  <mat-card>
    <div class="row">
      <div [hidden]="!reportItems">
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Category Column -->
            <ng-container matColumnDef="categoryName">
              <th mat-header-cell *matHeaderCellDef>Category</th>
              <td mat-cell *matCellDef="let item">{{ item.categoryName }}</td>
            </ng-container>

            <!-- SKU Column -->
            <ng-container matColumnDef="sku">
              <th mat-header-cell *matHeaderCellDef>SKU</th>
              <td mat-cell *matCellDef="let item">{{ item.sku }}</td>
            </ng-container>

            <!-- StockLevel Column -->
            <ng-container matColumnDef="stockLevel">
              <th mat-header-cell *matHeaderCellDef>Stock Level</th>
              <td mat-cell *matCellDef="let item">{{ item.stockLvl }}</td>
            </ng-container>

            <!-- Title Column -->
            <ng-container matColumnDef="itemTitle">
              <th mat-header-cell *matHeaderCellDef>Title</th>
              <td mat-cell *matCellDef="let item">{{ item.itemTitle }}</td>
            </ng-container>

            <!-- Week43 Column -->
            <ng-container matColumnDef="week43">
              <th mat-header-cell *matHeaderCellDef>Week 43</th>
              <td mat-cell *matCellDef="let item">{{ item.week43 }}</td>
            </ng-container>
            <!-- Week44 Column -->
            <ng-container matColumnDef="week44">
              <th mat-header-cell *matHeaderCellDef>Week 44</th>
              <td mat-cell *matCellDef="let item">{{ item.week44 }}</td>
            </ng-container>
            <!-- Week45 Column -->
            <ng-container matColumnDef="week45">
              <th mat-header-cell *matHeaderCellDef>Week 45</th>
              <td mat-cell *matCellDef="let item">{{ item.week45 }}</td>
            </ng-container>
            <!-- Week46 Column -->
            <ng-container matColumnDef="week46">
              <th mat-header-cell *matHeaderCellDef>Week 46</th>
              <td mat-cell *matCellDef="let item">{{ item.week46 }}</td>
            </ng-container>
            <!-- Week47 Column -->
            <ng-container matColumnDef="week47">
              <th mat-header-cell *matHeaderCellDef>Week 47</th>
              <td mat-cell *matCellDef="let item">{{ item.week47 }}</td>
            </ng-container>
            <!-- Week48 Column -->
            <ng-container matColumnDef="week48">
              <th mat-header-cell *matHeaderCellDef>Week 48</th>
              <td mat-cell *matCellDef="let item">{{ item.week48 }}</td>
            </ng-container>
            <!-- Week49 Column -->
            <ng-container matColumnDef="week49">
              <th mat-header-cell *matHeaderCellDef>Week 49</th>
              <td mat-cell *matCellDef="let item">{{ item.week49 }}</td>
            </ng-container>
            <!-- Week50 Column -->
            <ng-container matColumnDef="week50">
              <th mat-header-cell *matHeaderCellDef>Week 50</th>
              <td mat-cell *matCellDef="let item">{{ item.week50 }}</td>
            </ng-container>
            <!-- Week51 Column -->
            <ng-container matColumnDef="week51">
              <th mat-header-cell *matHeaderCellDef>Week 51</th>
              <td mat-cell *matCellDef="let item">{{ item.week51 }}</td>
            </ng-container>
            <!-- Week52 Column -->
            <ng-container matColumnDef="week52">
              <th mat-header-cell *matHeaderCellDef>Week 52</th>
              <td mat-cell *matCellDef="let item">{{ item.week52 }}</td>
            </ng-container>

            <!-- Cost Price Column -->
            <ng-container matColumnDef="averageSale">
              <th mat-header-cell *matHeaderCellDef>Average Sale</th>
              <td mat-cell *matCellDef="let item">{{ item.averageSale }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns" [ngClass]="{ gray: even }"
              class="table-row"></tr>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="!hideDownloadButton" class="row">
      <div class="col">
        <p>Report contains {{ reportItems.length }} items</p>
      </div>
    </div>
  </mat-card>
</div>