import { DatePipe } from "@angular/common";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { RepositoryService } from "src/app/core/services/repository.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import {
  OrderItem,
  POL,
  PurchaseCmbx,
  PurchaseData,
  PurchaseItem,
  StockItem,
} from "src/app/shared/models/purchase.models";
import { HelperService } from "src/app/shared/services/helper.service";
import { PurchaseDocsComponent } from "../../po-props/purchase-docs/purchase-docs.component";
import { DropdownItemsRenderer } from "../cell-renderers/po-dropdown.component";
import { RemoveBtnRenderer } from "../cell-renderers/po-remove-btn-renderer.component";
import { POItemsBySupplierService } from "../po-creation/po-items.service";
import { POPropsDialogComponent } from "./po-props-dialog/po-props-dialog.component";
import { POService } from "./po.service";
import { saveAs } from "file-saver-es";
import {
  parseDateTime,
  purchaseOrderNumberComparator,
} from "src/app/shared/utils/functions";
import { getHeaderTemplate } from "src/app/shared/utils/ag-grid-helper";
import { QcPhotosDialogComponent } from "../../po-props/qc-photos-dialog/qc-photos-dialog.component";
import { PurchaseService } from "src/app/shared/services/purchase.service";
import { Supplier } from "src/app/shared/models/Supplier";
import { UserService } from "src/app/shared/services/user.service";
import { SelectRecepientComponent } from "./select-recepient/select-recepient.component";

@Component({
  selector: "app-po-view",
  templateUrl: "./po-view.component.html",
  styleUrls: ["./po-view.component.scss"],
  providers: [DatePipe],
})
export class POViewComponent implements OnInit, OnDestroy {
  loadingData = false;
  changesWereMade: boolean = false;
  http: HttpClient;
  baseUrl: string;
  public disableBoxQtyAlign: boolean = false;

  // mat-field input PO data
  poNumber;
  dateOrder;

  referenceOfPO;
  goodsReadyDate;

  referenceOfPO2;

  supplierInvoiceNumber;

  port;
  status;
  supplier;

  containerSize;
  conversionRate;
  minDate;

  // ports: mat-select
  ports: POL[];
  selectedPort: POL;

  // locations: mat-select
  locations: Location[];
  selectedLocation: Location;

  // suppliers mat-select
  suppliers: Supplier[];
  selectedSupplier: Supplier;

  // items mat-select
  stockItems: StockItem[];
  selectedItem: StockItem;

  // container size mat-select
  containerSizes: string[] = ["N/A", "20 FT", "40 FT", "40 FT HQ", "PART"];
  selectedContainerSize: string;

  // statuses mat-select
  statuses: string[] = ["PENDING", "OPEN", "DELIVERED"];
  selectedStatus: string;

  // for excel and pdf
  lastPOId: string;
  hasNotLastPOId = function (): boolean {
    return this.lastPOId == null;
  };

  changePONumber: string;

  // mat-autocomplete
  filteredPOs: Observable<PurchaseCmbx[]>;
  purchaseOrders: PurchaseCmbx[];
  searchPOField;

  posCtrl = new UntypedFormControl();

  currentPO: PurchaseData;

  //#region ValueGetters
  valueTotalCBMFormatter = function (params) {
    if (params.data.stockItemID == "dropdown") {
      return "";
    }

    return parseFloat(params.data.totalCBM).toFixed(4);
  };

  valueWeightFormatter = function (params) {
    if (params.data.stockItemID == "dropdown") {
      return "";
    }

    return parseFloat(params.data.weight).toFixed(2);
  };

  valueCBMFormatter = function (params) {
    if (
      params.data.stockItemID == "total" ||
      params.data.stockItemID == "dropdown"
    ) {
      return "";
    } else {
      return parseFloat(params.data.cbm).toFixed(4);
    }
  };

  // grid: remove totals for required fields
  totalValueGetterUnitCost = function (params) {
    if (
      params.data.stockItemID == "total" ||
      params.data.stockItemID == "dropdown"
    ) {
      return "";
    } else {
      return parseFloat(params.data.unitCost).toFixed(2);
    }
  };

  totalValueGetterCTNQTY = function (params) {
    if (
      params.data.stockItemID == "total" ||
      params.data.stockItemID == "dropdown"
    ) {
      return "";
    } else {
      return params.data.cartonQty;
    }
  };

  dropdownValueTotalCBM = function (params) {
    if (params.data.stockItemID == "dropdown") {
      return "";
    } else {
      return parseFloat(params.data.totalCBM).toFixed(4);
    }
  };

  // navigateToNextCell = function (params) {
  //   const previousCell = params.previousCellPosition,
  //       suggestedNextCell = params.nextCellPosition;
  //   let nextRowIndex, renderedRowCount;
  //
  //   switch (params.key) {
  //     case TAB:
  //       nextRowIndex = previousCell.rowIndex + 1;
  //       renderedRowCount = params.api.getModel().getRowCount();
  //       if (nextRowIndex >= renderedRowCount) {
  //         return null;
  //       } // returning null means don't navigate
  //
  //       return {
  //         rowIndex: nextRowIndex,
  //         column: previousCell.column,
  //         rowPinned: previousCell.rowPinned,
  //       };
  //     default:
  //       throw Error(
  //           'this will never happen, navigation is always one of the 4 keys above'
  //       );
  //   }
  // }

  dropdownValueWeight(params) {
    if (params.data.stockItemID == "dropdown") {
      return "";
    } else if (params.data.stockItemID == "total") {
      return parseFloat(
        this.rowData
          .reduce((prev, cur) => prev + cur.weight * cur.quantity, 0)
          .toFixed(2)
      );
    } else {
      return params.data.weight * params.data.quantity;
    }
  }

  dropdownValueLineTotal = function (params) {
    if (params.data.stockItemID == "dropdown") {
      return "";
    } else {
      return parseFloat(params.data.lineTotal).toFixed(2);
    }
  };

  dropdownValueGetterQty = function (params) {
    if (params.data.stockItemID == "dropdown") {
      return "";
    } else {
      return params.data.quantity;
    }
  };
  //#endregion

  // grid: Columns
  columnDefs = [
    {
      headerName: "SKU",
      field: "sku",
      colId: "items",
      width: 100,
      editable: false,
      sort: "asc",
      comparator: purchaseOrderNumberComparator,
      cellRenderer: "dropdownItemsRenderer",
    },
    // { headerName: "Factory Item No", field: "factoryItemNo", width: 100 },
    { headerName: "Title", field: "description", width: 180 },
    {
      headerName: "QTY",
      editable: true,
      field: "quantity",
      width: 40,
      sortable: true,
      valueGetter: this.dropdownValueGetterQty,
    },
    {
      headerName: "Unit Cost",
      editable: true,
      field: "unitCost",
      width: 60,
      sortable: true,
      valueGetter: this.totalValueGetterUnitCost,
    },
    {
      headerName: "Carton QTY",
      field: "cartonQty",
      width: 40,
      sortable: true,
      valueGetter: this.totalValueGetterCTNQTY,
    },
    {
      headerName: "CBM",
      field: "cbm",
      width: 40,
      sortable: true,
      valueFormatter: this.valueCBMFormatter,
    },
    {
      headerName: "Total CBM",
      field: "totalCBM",
      width: 55,
      sortable: true,
      valueGetter: this.dropdownValueTotalCBM,
    },
    {
      headerName: "Weight",
      field: "weight",
      width: 60,
      sortable: true,
      valueGetter: (params) => {
        return this.dropdownValueWeight(params);
      },
      valueFormatter: digitFormatter,
    },
    {
      headerName: "Line Total",
      field: "lineTotal",
      width: 60,
      sortable: true,
      valueGetter: this.dropdownValueLineTotal,
    },
    {
      headerName: "",
      field: "value",
      colId: "params",
      width: 35,
      editable: false,
      cellRenderer: "removeBtnRenderer",
    },
  ];

  // grid: orderItems
  rowData: OrderItem[] = [];

  // grid: headers height
  public headerHeight;

  // grid: def header styles
  public defaultColDef;

  // grid: for updating and editing grid order items data
  public gridApi;

  public frameworkComponents;

  // grid - pin to bottom Total
  public pinnedBottomRowData;

  destroyedComponentSubj = new Subject<void>();

  // selected items for PO
  orderItems: OrderItem[] = [];
  public poService: POService;
  constructor(
    http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private appService: POItemsBySupplierService,
    _poService: POService,
    private router: Router,
    private repo: RepositoryService,
    private helper: HelperService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private snackBar: SnackBarService,
    private purchaseService: PurchaseService,
    public currentUserService: UserService
  ) {
    this.poService = _poService;
    this.http = http;
    this.baseUrl = baseUrl;
    this.headerHeight = 50;

    this.frameworkComponents = {
      removeBtnRenderer: RemoveBtnRenderer,
      dropdownItemsRenderer: DropdownItemsRenderer,
    };

    this.defaultColDef = {
      resizable: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      tooltipComponent: "GridTooltipComponent",
      headerComponentParams: {
        template: getHeaderTemplate(),
      },
    };

    this.appService.currentStatus = null;
    this.pinnedBottomRowData = [];
  }

  // Select suppliers and POL on load
  ngOnInit(): void {
    this.getPurchaseOrders();
    this.getSuppliers();
    this.getPOLs();

    this.dateOrder = new Date();
    this.goodsReadyDate = new Date();
    this.selectedContainerSize = this.containerSizes[0];
  }

  ngOnDestroy() {
    this.appService.currentStatus = null;
    this.destroyedComponentSubj.next();
    this.destroyedComponentSubj.complete();
  }

  //#region mat-select get data for cmbxes
  getSuppliers() {
    this.repo
      .getList("api/inventory/suppliers/")
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(
        (result) => {
          this.suppliers = result;

          this.selectedSupplier = this.suppliers[0];

          this.selectedSupplierChange(this.selectedSupplier);
          this.selectedPOChange(this.poService.currentPurchase);

          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Cannot get suppliers");
        }
      );
  }

  // mat-select: Cascade and set account ref
  selectedSupplierChange(value): void {
    this.loadingData = true;

    if (this.rowData != null && this.rowData.length > 1) {
      this.rowData = [];
      this.createEmptyRow();
      this.getTotalRow();
    }

    this.repo
      .getList(
        "api/po/getStockItemsBySupplier/" + this.selectedSupplier.pkSupplierID
      )
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(
        (result) => {
          this.stockItems = result;
          this.selectedItem = this.stockItems[0];
          this.loadingData = false;

          this.appService.stockItems = result;
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Something went wrong");
        }
      );
  }

  // mat-select GET POLs
  getPOLs(): void {
    this.repo
      .getList("api/purchaseProps/POLs")
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(
        (result) => {
          this.ports = result;

          var na = new POL();
          na.name = "N/A";
          na.transitTime = 0;

          this.ports.unshift(na);

          this.selectedPort = this.ports[0];

          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Cannot get POLs");
        }
      );
  }

  //#endregion

  //#region Grid actions
  // grid: create empty row with combo box
  createEmptyRow() {
    let itemEmptyRow = {
      stockItemID: "dropdown",
      sku: "",
      description: "",
      cartonQty: 0,
      cbm: 0,
      taxRate: 0,
      weight: 0,
      weightPerItem: 0,
      unitCost: 0,
      quantity: 0,
      totalCBM: 0,
      lineTotal: 0,
      hide: true,
      factoryItemNo: "",
    };

    let itemsToUpdate = [];
    itemsToUpdate.push(itemEmptyRow);

    if (this.rowData == null) this.rowData = [];
    this.rowData.push(itemEmptyRow);

    this.gridApi.applyTransaction({ add: itemsToUpdate });
    this.gridApi.redrawRows();
  }

  // grid init
  onGridReady(params) {
    this.gridApi = params.api;

    this.gridApi.sizeColumnsToFit();

    this.createEmptyRow();
    this.getTotalRow();
  }

  onRowDataUpdated(event) {
    this.updateTotalRow();
  }

  // grid: calculating total
  updateTotalRow() {
    if (this.rowData.length && this.rowData.length > 0) {
      var totalIndex = this.rowData.findIndex((x) => x.stockItemID === "total");

      if (totalIndex != -1) {
        this.rowData.splice(totalIndex, 1);
        this.gridApi.setRowData(this.rowData);
      }

      this.getTotalRow();
    }
  }

  // grid: add total row
  getTotalRow() {
    let totalRow = {
      stockItemID: "total",
      sku: "Total - ",
      description: "",
      cartonQty: 0,
      cbm: 0,
      taxRate: 0,
      weight: 0,
      weightPerItem: 0,
      unitCost: 0,
      quantity: 0,
      totalCBM: 0,
      lineTotal: 0,
      hide: true,
    };

    if (this.rowData != null) {
      totalRow.quantity = this.rowData.reduce(
        (prev, cur) => prev + parseInt(cur.quantity.toString(), 0),
        0
      );

      totalRow.totalCBM = parseFloat(
        this.rowData.reduce((prev, cur) => prev + cur.totalCBM, 0).toFixed(4)
      );
      totalRow.lineTotal = parseFloat(
        this.rowData.reduce((prev, cur) => prev + cur.lineTotal, 0).toFixed(2)
      );

      totalRow.weight = parseFloat(
        this.rowData.reduce((prev, cur) => prev + cur.weight, 0).toFixed(2)
      );
    }

    var data = [];
    data.push(totalRow);
    this.pinnedBottomRowData = data;
    this.gridApi.setPinnedBottomRowData(data);
  }

  // grid: remove items
  clearGrid(): void {
    this.rowData = [];

    this.gridApi.applyTransaction({ update: this.rowData });
    this.gridApi.redrawRows();
  }

  onCellValueChanged(event) {
    // if (this.currentPO.status != "DELIVERED") {
    //   this.poService.poViewChangesSubject.next(true);
    // }
    if (
      event.data.stockItemID != "dropdown" &&
      event.data.stockItemID != "total"
    ) {
      this.updatePurchaseItem(event.data);
      
      let comboItemSku = event.data.sku.split(" ")[0];

    this.rowData.forEach((item, index) => {
      if (item.sku !== event.data.sku && item.sku.startsWith(comboItemSku) && !this.disableBoxQtyAlign) {
        item.quantity = event.data.quantity;
        this.updatePurchaseItem(item);
      }
    });
    this.gridApi.refreshCells();
      this.updateTotalRow();
    }

    // this.updatePO();
  }

  updatePurchaseItem(item: OrderItem) {
    if (this.currentPO != null) {
      if (this.currentPO.status === "DELIVERED") {
        this.snackBar.warning(
          "If PO DELIVERED you cannot change quantity and cost of items"
        );

        var oldItem = this.currentPO?.items?.filter((current, index) => {
          return current?.stockItemID == item?.stockItemID;
        })[0];

        if (oldItem != null) {
          item.quantity = oldItem.qty;
          item.unitCost = oldItem.unitPrice;

          this.setUpdateItem(item);
        }
      } else {
        if (item?.quantity < 0 || item?.unitCost < 0) {
          this.snackBar.warning("Wrong value");
          item.quantity = item.cartonQty;
        } else {
          this.setUpdateItem(this.recalculateValues(item));
        }
      }
    }
  }

  recalculateValues(item: OrderItem): OrderItem {
    item.unitCost = parseFloat(parseFloat(item.unitCost.toString()).toFixed(2));
    item.lineTotal = item.quantity * item.unitCost;
    item.totalCBM = parseFloat(
      (
        item.cbm *
        (parseInt(item.quantity.toString()) /
          parseInt(item.cartonQty.toString()))
      ).toString()
    );

    item.totalCBM = this.helper.valueIsNaN(item.totalCBM);
    item.lineTotal = this.helper.valueIsNaN(item.lineTotal);
    item.weight = this.helper.valueIsNaN(item.weight);

    return item;
  }

  setUpdateItem(item: OrderItem) {
    this.gridApi.applyTransaction({ update: [item] });
    // this.gridApi.redrawRows();
  }

  //#endregion

  //#region PO Actions

  // btn: duplicate required PO
  duplicatePO(): void {
    this.loadingData = true;
    this.repo
      .create(`api/po/duplicatePOByNumber/${this.currentPO.purchaseID}`, {})
      .subscribe(
        (result: any) => {
          this.loadingData = false;
          this.snackBar.success(
            "PO " + result.purchaseNumber.toString() + " created successfully"
          );
          this.poService.currentPurchase = result.purchaseId;

          this.router.navigate(["/home/po-view"], {
            queryParams: { componentIndex: result.purchaseNumber.toString() },
          });
        },
        (error) => {
          this.loadingData = false;

          if (error.status === 404) {
            this.snackBar.neutral(
              `PO with number ${this.poNumber} was not found`,
              ""
            );
          } else {
            this.snackBar.error(
              "Something went wrong when duplicating " +
              this.poNumber +
              " order."
            );
          }
        }
      );
  }

  //btn: Go back (PO List)
  goBack() {
    this.router.navigateByUrl(this.poService.returnUrl);
  }

  // stopEditingAndExecuteMethod(methodName: string) {
  //   let methodToExecute: any;
  //   const $this = this;
  //   switch (methodName) {
  //     case "updatePO": {
  //       methodToExecute = function () {
  //         $this.updatePO();
  //       };
  //       break;
  //     }
  //     case "duplicatePO": {
  //       methodToExecute = function () {
  //         $this.duplicatePO();
  //       };
  //       break;
  //     }
  //     case "getPurchasePDF": {
  //       methodToExecute = function () {
  //         $this.getPurchasePDF();
  //       };
  //       break;
  //     }
  //     case "getPurchasePDFwithPhotos": {
  //       methodToExecute = function () {
  //         $this.getPurchasePdfWithPhotos();
  //       };
  //       break;
  //     }
  //     case "createPOToExcel": {
  //       methodToExecute = function () {
  //         $this.createPOToExcel();
  //       };
  //       break;
  //     }

  //     default:
  //       break;
  //   }
  //   this.loadingData = true;
  //   this.updatePOObservable()?.subscribe((x) => {
  //     this.loadingData = false;
  //     this.poService.poViewChangesSubject.next(false);
  //     methodToExecute();
  //   });
  // }

  sendToEmail(purchaseId: string): void {
    this.dialog.open(SelectRecepientComponent, {
      data: {
        purchaseId: this.currentPO.purchaseID,
      },
    });
  }

  // btn: SAVE PO changes
  updatePOObservable() {
    if (this.rowData != null && this.rowData.length > 1) {
      var selectedStatusIndex = this.statuses.indexOf(this.selectedStatus, 0);
      var currentStatusIndex = this.statuses.indexOf(this.currentPO.status, 0);

      if (currentStatusIndex > selectedStatusIndex) {
        this.snackBar.error(
          "PO can`t be updated. \n Status can be updated only PENDING to OPEN or OPEN to DELIVERED or PENDING to DELIVERED."
        );
      } else {
        var purchase = this.createPurchase();

        return this.repo.update("api/po/updatePO", purchase);
      }
    } else {
      this.snackBar.warning("Please, add items to PO.");
    }
  }

  updatePO(): void {
    var selectedStatusIndex = this.statuses.indexOf(this.selectedStatus, 0);
    var currentStatusIndex = this.statuses.indexOf(this.currentPO.status, 0);

    if (currentStatusIndex > selectedStatusIndex) {
      return this.snackBar.error(
        "PO can`t be updated. \n Status can be updated only PENDING to OPEN or OPEN to DELIVERED or PENDING to DELIVERED."
      );
    }
    this.loadingData = true;

    var purchase = this.createPurchase();

    this.repo.update("api/po/updatePO", purchase)
      .subscribe(
        (poInfo) => {
          this.loadingData = false;

          if (poInfo != null) {
            this.snackBar.success(poInfo.result);
            this.currentPO.status = this.selectedStatus;
            this.appService.currentStatus = this.selectedStatus;
          }
        },
        (error) => {
          this.loadingData = false;
          console.error(error);
          this.snackBar.error("Something went wrong. ");
        }
      );

    // this.updatePOObservable().subscribe(
    //   (poInfo) => {
    //     this.loadingData = false;

    //     if (poInfo != null) {
    //       this.snackBar.success(poInfo.result);
    //       this.currentPO.status = this.selectedStatus;
    //       this.appService.currentStatus = this.selectedStatus;
    //     }
    //   },
    //   (error) => {
    //     this.loadingData = false;
    //     console.error(error);
    //     this.snackBar.error("Something went wrong. ");
    //   }
    // );
  }

  createPurchase(): PurchaseData {
    var purchase = new PurchaseData();
    purchase.purchaseID = this.currentPO.purchaseID;
    purchase.supplierID = this.selectedSupplier.pkSupplierID;
    purchase.supplierName = this.selectedSupplier.supplierName;
    purchase.conversionRate = this.currentPO.conversionRate;
    purchase.containerSize =
      this.selectedContainerSize != null ? this.selectedContainerSize : "N/A";

    purchase.reference = this.referenceOfPO == null ? " " : this.referenceOfPO;
    purchase.reference2 =
      this.referenceOfPO2 == null ? " " : this.referenceOfPO2;
    purchase.supplierInvoiceNumber = this.supplierInvoiceNumber;

    purchase.orderDateString = this.dateOrder;
    purchase.goodsReadyDateString = this.convertDate(
      this.goodsReadyDate.toString()
    );

    purchase.port = this.selectedPort != null ? this.selectedPort.name : "N/A";

    purchase.orderNumber = this.poNumber.toString();

    purchase.status = this.selectedStatus;

    var newItems = [];

    if (this.rowData != null) {
      this.rowData.forEach((item, index) => {
        if (item.stockItemID != "total" && item.stockItemID != "dropdown") {
          var newItem = new PurchaseItem();

          newItem.stockItemID = item.stockItemID;
          newItem.qty = item.quantity; // == null ? 1 : parseInt(item.quantity.toString(), 1);
          newItem.taxRate = item.taxRate;
          newItem.lineTotal = item.lineTotal;
          newItem.unitPrice = item.unitCost;

          newItems.push(newItem);
        }
      });
    }

    purchase.items = newItems;

    return purchase;
  }

  convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  //#endregion

  //#region PO - converting
  createPOToExcel(): void {
    this.loadingData = true;
    if (this.poNumber != null) {
      this.repo
        .get("api/po/createExcelForPO/" + this.currentPO.purchaseID, {
          responseType: "blob",
        })
        .subscribe(
          (result: any) => {
            if (result.type !== "text/plain") {
              const blob = new Blob([result]);
              const fileName = `PO_${this.poNumber}_${this.selectedSupplier.supplierName}.xlsx`;
              saveAs(blob, fileName);
            } else {
              this.snackBar.error("File not found in Blob!");
            }
            this.loadingData = false;
          },
          (error: any) => {
            this.loadingData = false;
            if (error.status === 404) {
              this.snackBar.error(
                `PO with number ${this.poNumber} was not found`
              );
            } else {
              this.snackBar.error("Something went wrong.");
            }
          }
        );
    }

    this.loadingData = false;
  }

  tabToNextCell(params) {
    var previousCell = params.previousCellPosition,
      lastRowIndex = previousCell.rowIndex,
      nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex + 1,
      renderedRowCount = params.previousCellPosition.column.gridApi
        .getModel()
        .getRowCount(),
      result;
    if (nextRowIndex < 0) {
      nextRowIndex = -1;
    }
    if (nextRowIndex >= renderedRowCount) {
      nextRowIndex = renderedRowCount - 1;
    }
    result = {
      rowIndex: nextRowIndex,
      column: previousCell.column,
      floating: previousCell.floating,
    };
    // this.updatePurchaseItem(params.data);
    // this.updateTotalRow();

    // const comboItemSku = this.rowData[params.previousCellPosition.rowIndex].sku.split(" ")[0];

    // let comboItemSku = params.data.sku.split(" ")[0];

    // this.rowData.forEach((item, index) => {
    //   if (item.sku !== params.data.sku && item.sku.startsWith(comboItemSku) && !this.disableBoxQtyAlign) {
    //     item.quantity = params.data.quantity;
    //     this.updatePurchaseItem(item);
    //   }
    // });
      // this.updateTotalRow();
      // params.event.preventDefault();
      // params.event.stopPropagation();
    return result;
  }

  createInvoiceExcel(): void {
    this.loadingData = true;
    if (this.poNumber != null) {
      this.repo
        .get("api/po/getPurchaseInvoiceExcel/" + this.currentPO.purchaseID, {
          responseType: "blob",
        })
        .subscribe(
          (result: any) => {
            if (result.type !== "text/plain") {
              const blob = new Blob([result]);
              const fileName = `PO_${this.poNumber}_Invoice.xlsx`;
              saveAs(blob, fileName);
            } else {
              this.snackBar.error("File not found in Blob!");
            }
            this.loadingData = false;
          },
          (error: any) => {
            this.loadingData = false;
            if (error.status === 404) {
              this.snackBar.error(
                `PO with number ${this.poNumber} was not found`
              );
            } else {
              this.snackBar.error("Something went wrong.");
            }
          }
        );
    }

    this.loadingData = false;
  }

  createPackingListExcel(): void {
    this.loadingData = true;
    if (this.poNumber != null) {
      this.repo
        .get(
          "api/po/getPurchasePackingListExcel/" + this.currentPO.purchaseID,
          {
            responseType: "blob",
          }
        )
        .subscribe(
          (result: any) => {
            if (result.type !== "text/plain") {
              const blob = new Blob([result]);
              const fileName = `PO_${this.poNumber}_PackingList.xlsx`;
              saveAs(blob, fileName);
            } else {
              this.snackBar.error("File not found in Blob!");
            }
            this.loadingData = false;
          },
          (error: any) => {
            this.loadingData = false;
            if (error.status === 404) {
              this.snackBar.error(
                `PO with number ${this.poNumber} was not found`
              );
            } else {
              this.snackBar.error("Something went wrong.");
            }
          }
        );
    }

    this.loadingData = false;
  }

  getPurchasePdfWrapper(endpoint: string) {
    this.loadingData = true;
    if (this.poNumber != null) {
      this.http
        .get<Blob>(this.baseUrl + endpoint, {
          responseType: "blob" as "json",
          observe: "response",
        })
        .subscribe((response: HttpResponse<Blob>) => {
          this.loadingData = false;
          let binaryData = [];
          binaryData.push(response.body);
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: "blob" })
          );
          downloadLink.setAttribute(
            "download",
            `PO_${this.currentPO.orderNumber}_${this.selectedSupplier.supplierName}.pdf`
          );
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });
    }
  }

  getPurchasePDF(): void {
    const endpoint = `api/po/getPurchasePDF/${this.currentPO.purchaseID}`;
    this.getPurchasePdfWrapper(endpoint);
  }

  getPurchasePdfWithPhotos() {
    const endpoint = `api/po/getPurchasePdfWithPhotos/${this.currentPO.purchaseID}`;
    this.getPurchasePdfWrapper(endpoint);
  }

  //#region POs dropdown
  clearSearchField() {
    this.searchPOField = "";
  }

  // GET Purchase orders
  getPurchaseOrders(): void {
    this.loadingData = true;

    this.repo
      .getList("api/po/getAllPurchases")
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(
        (result) => {
          this.purchaseOrders = result;

          this.filteredPOs = this.posCtrl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterPO(value))
          );

          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Something went wrong.");
        }
      );
  }

  // GET and SET selected purchase data (fill grid)
  selectedPOChange(value) {
    this.changesWereMade = false;
    var selectedPO = null;

    if (this.purchaseOrders != null) {
      selectedPO = this.purchaseOrders.filter(function (item) {
        return item.externalInvoiceNumber == value;
      })[0];
    }

    if (selectedPO != null || this.poService.currentPurchase != null) {
      var purchaseID = selectedPO != null ? selectedPO.pkPurchaseID : "";

      if (this.poService.currentPurchase != null) {
        purchaseID = this.poService.currentPurchase;
      }

      this.loadingData = true;

      this.http
        .get<PurchaseData>(
          this.baseUrl + "api/po/getPurchaseOrderDataByID/" + purchaseID
        )
        .pipe(takeUntil(this.destroyedComponentSubj))
        .subscribe(
          (result) => {
            if (result != null) {
              this.selectedSupplier = this.suppliers.filter((item, index) => {
                return item.pkSupplierID === result.supplierID;
              })[0];

              this.selectedSupplierChange(this.selectedSupplier);

              this.currentPO = result;

              this.loadExtProps();
              this.poNumber = result.orderNumber;

              this.dateOrder = result.orderDate;

              this.goodsReadyDate = parseDateTime(result.goodsReadyDateString);

              this.referenceOfPO =
                result.reference === "0" || result.reference == " "
                  ? ""
                  : result.reference;
              this.referenceOfPO2 =
                result.reference2 === "0" || result.reference2 == " "
                  ? ""
                  : result.reference2;

              this.supplierInvoiceNumber =
                result.supplierInvoiceNumber === "0" ||
                  result.supplierInvoiceNumber == " "
                  ? ""
                  : result.supplierInvoiceNumber;

              this.selectedStatus = result.status;

              this.appService.currentStatus = this.selectedStatus;

              this.selectedPort = this.ports.filter((item, index) => {
                return item.name.toLowerCase() === result.port.toLowerCase();
              })[0];

              // if container size 40ft HQ
              if (
                result.containerSize[2] != " " &&
                result.containerSize !== "PART"
              ) {
                result.containerSize = result.containerSize.toUpperCase();
                result.containerSize =
                  result.containerSize.slice(0, 2) +
                  " " +
                  result.containerSize.slice(2, result.containerSize.length);
              }

              this.selectedContainerSize =
                result.containerSize === "0" || result.containerSize == " "
                  ? "N/A"
                  : result.containerSize.toUpperCase();

              this.poNumber = this.currentPO.orderNumber;

              //fill by data
              this.clearGrid();

              this.createEmptyRow();

              if (result.items != null) {
                result.items.forEach((stockItem, index) => {
                  var item = new OrderItem();
                  item.stockItemID = stockItem.stockItemID;

                  item.sku = stockItem.sku;
                  item.description = stockItem.description;
                  item.cartonQty = stockItem.ctnqty > 0 ? stockItem.ctnqty : 1;
                  item.cbm = parseFloat(stockItem.cbm.toFixed(4).toString());
                  item.unitCost = stockItem.unitPrice;
                  item.quantity = stockItem.qty;
                  item.weight = stockItem.weight;
                  item.factoryItemNo = stockItem.factoryItemNo;

                  item.taxRate = stockItem.taxRate;
                  item.hide = false;

                  item.totalCBM = parseFloat(
                    ((item.quantity / item.cartonQty) * item.cbm).toFixed(4)
                  );
                  item.lineTotal = parseFloat(stockItem.lineTotal.toFixed(2));

                  // IF NaN then 0
                  item.totalCBM = this.helper.valueIsNaN(item.totalCBM);
                  item.lineTotal = this.helper.valueIsNaN(item.lineTotal);
                  item.weight = this.helper.valueIsNaN(item.weight);

                  this.rowData.push(item);
                });
              }

              this.gridApi.applyTransaction({ add: this.rowData });
              this.gridApi.redrawRows();

              this.searchPOField = "";

              this.updateTotalRow();
              this.poService.currentPurchase = null;
            } else {
              this.snackBar.error("There is no PO with this number.");
            }

            this.loadingData = false;
          },
          (error) => {
            this.loadingData = false;

            this.snackBar.error("Something went wrong");
          }
        );
    }
  }

  // mat-autocomplete: searching required purchase order LIKE
  private _filterPO(value: string): PurchaseCmbx[] {
    if (value == "" || value == null) return this.purchaseOrders;

    const filterValue = value.toLowerCase();
    return this.purchaseOrders.filter(
      (option) =>
        option.externalInvoiceNumber.toLowerCase().indexOf(filterValue) >= 0
    );
  }
  //#endregion

  openQcPhotosDialog(): void {
    this.dialog.open(QcPhotosDialogComponent, {
      data: {
        purchaseId: this.currentPO.purchaseID,
        purchaseNumber: this.currentPO.orderNumber,
      },
    });
  }

  // open ext properties list
  openExtPropsDialog() {
    const dialogRef = this.dialog.open(POPropsDialogComponent, {
      height: "75%",
      width: "70%",
      data: {
        purchaseID: this.currentPO.purchaseID,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != null) {
        if (result.updated == true) {
          this.updateExtProps();
        }
      }
    });
  }

  openPurchaseDocuments(): void {
    this.dialog.open(PurchaseDocsComponent, {
      data: {
        purchaseId: this.currentPO.purchaseID,
        purchaseNumber: this.currentPO.orderNumber,
        hblExtendedProperty:
          this.extendedPropertiesForCurrentPurchaseOrder.filter(
            (x) => x.propertyName.toUpperCase() === "HBL"
          )[0] ?? " ",
      },
    });
  }

  extendedPropertiesForCurrentPurchaseOrder: any[];
  loadExtProps() {
    this.loadingData = true;
    this.repo
      .getList(
        "api/procurement/purchaseOrderExtendedProps/" +
        this.currentPO.purchaseID
      )
      .subscribe(
        (result) => {
          if (result && result.length > 0) {
            this.extendedPropertiesForCurrentPurchaseOrder = result;
          }
          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Cannot get extended properties");
        }
      );
  }
  updateExtProps() {
    this.loadingData = true;

    if (
      this.extendedPropertiesForCurrentPurchaseOrder &&
      this.extendedPropertiesForCurrentPurchaseOrder.length > 0
    ) {
      var ref = this.extendedPropertiesForCurrentPurchaseOrder.filter(
        (item, index) => {
          return item.propertyName == "REFERENCE";
        }
      )[0];
      this.referenceOfPO = ref != null ? ref.propertyValue : "";

      var ref2 = this.extendedPropertiesForCurrentPurchaseOrder.filter(
        (item, index) => {
          return item.propertyName == "REFERENCE2";
        }
      )[0];
      this.referenceOfPO2 = ref2 != null ? ref2.propertyValue : "";

      var suppInvNo = this.extendedPropertiesForCurrentPurchaseOrder.filter(
        (item, index) => {
          return item.propertyName == "SUPPLIER REFERENCE CODE";
        }
      )[0];

      this.supplierInvoiceNumber =
        suppInvNo != null ? suppInvNo.propertyValue : "";

      // SET Goods ready date
      let goodsReadyDate =
        this.extendedPropertiesForCurrentPurchaseOrder.filter((item, index) => {
          return item.propertyName == "GOODS READY DATE";
        })[0].propertyValue;

      this.goodsReadyDate = parseDateTime(goodsReadyDate);

      // SET POL
      var port = this.extendedPropertiesForCurrentPurchaseOrder.filter(
        (item, index) => {
          return item.propertyName == "POL";
        }
      )[0].propertyValue;

      this.selectedPort = this.ports.filter((item, index) => {
        return item.name == port;
      })[0];

      // SET container size

      var containerSize = this.extendedPropertiesForCurrentPurchaseOrder.filter(
        (item, index) => {
          return item.propertyName == "CONTAINER SIZE";
        }
      )[0].propertyValue;

      if (containerSize[2] != " ") {
        containerSize = containerSize.toUpperCase();
        containerSize =
          containerSize.slice(0, 2) +
          " " +
          containerSize.slice(2, containerSize.length);
      }

      this.selectedContainerSize =
        containerSize === "0" || containerSize == " "
          ? "N/A"
          : containerSize.toUpperCase();

      this.loadingData = false;
    }
  }

  // clear all after creating new order and generate new number
  clearFields(): void {
    this.poNumber = "";

    this.dateOrder = new Date();
    this.goodsReadyDate = new Date();

    this.referenceOfPO = "";
    this.referenceOfPO2 = "";
    this.supplierInvoiceNumber = "";

    this.rowData = [];
    this.gridApi.setRowData(this.rowData);
    this.gridApi.redrawRows();

    this.getTotalRow();
  }
}

function digitFormatter(params) {
  if (isNaN(params.value) || params.data.stockItemID === "dropdown") {
    return "";
  }
  return parseFloat(params.value)
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
