import { Component, OnDestroy } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { IAfterGuiAttachedParams } from "ag-grid-community";
import { POItemsBySupplierService } from "../po-creation/po-items.service";
import { HelperService } from "src/app/shared/services/helper.service";

import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { POService } from "../po-view/po.service";

@Component({
  selector: "remove-btn-renderer",
  template: `
    <button
      *ngIf="
        params1.data.stockItemID != 'total' &&
        params1.data.stockItemID != 'dropdown'
      "
      mat-icon-button
      type="button"
      onclick="this.blur()"
      (click)="checkedHandler($event)"
    >
      <mat-icon mat-icon matTooltip="remove item">delete</mat-icon>
    </button>
  `,
})
export class RemoveBtnRenderer implements ICellRendererAngularComp {
  constructor(
    private poService: POService,
    private poItemsService: POItemsBySupplierService,
    private helper: HelperService,
    private snackBar: SnackBarService
  ) {}

  refresh(params: any): boolean {
    return false;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error("Remove btn: Method not implemented.");
  }

  public params1: any;

  agInit(params: any): void {
    this.params1 = params;
  }

  // delete required row
  checkedHandler(event) {
    if (this.poItemsService.currentStatus != "DELIVERED") {
      // selected data
      var selectedItem = this.params1.data;

      // rowData
      var rowData = this.params1.api.gridOptionsWrapper.gridOptions.rowData;

      // remove selected row
      if (selectedItem != null && selectedItem.stockItemID != "dropdown") {
        var index = rowData.indexOf(selectedItem);

        if (index != -1) {
          rowData.splice(index, 1);
        }

        var arr = [];
        arr.push(selectedItem);

        // update grid data
        this.poService.poViewChangesSubject.next(true);
        this.params1.api.applyTransaction({ remove: arr });
        this.params1.api.redrawRows();
      }
    } else {
      this.snackBar.openSnackBar(
        "If PO DELIVERED you cannot remove items",
        "Purchase item"
      );
    }
  }
}
