<mat-card>
    <mat-card-header>
        <mat-card-subtitle>

        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <cron-editor [formControl]="cronForm" [options]="cronOptions"></cron-editor>
        <mat-divider></mat-divider>
        <p>Description:</p><h4>{{descript(cronForm.value)}}</h4>

    </mat-card-content>

    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="addSchedule()">Add New</button>
    </mat-card-actions>

</mat-card>