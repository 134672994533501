import { Component, OnInit, Inject } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import {
  MatSnackBarVerticalPosition,
  MatSnackBarHorizontalPosition,
} from "@angular/material/snack-bar";
import { DatePipe } from "@angular/common";
import { HelperService } from "src/app/shared/services/helper.service";
import { BedWeeklySale } from "../../../shared/models/BedWeeklySale";
import { Column } from "../../../shared/models/Column";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from 'file-saver-es';

@Component({
  selector: "app-bed-weekly-sales",
  templateUrl: "./bed-weekly-sales.component.html",
  styleUrls: ["./bed-weekly-sales.component.css"],
  providers: [DatePipe]
})
export class BedWeeklySalesComponent implements OnInit {
  loadingReport = false;
  baseUrl: string;
  reportItems: BedWeeklySale[];
  hideDownloadButton = true;
  period: [Date, Date];

  dataSource = new MatTableDataSource<BedWeeklySale>();

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  displayedColumns: Column[] = [
    { name: "sku", displayedName: "SKU" },
    { name: "itemDescription", displayedName: "Description" },
    { name: "categoryName", displayedName: "Category Name" },
    { name: "averageSales", displayedName: "Average Sales" },
    { name: "stockLvl", displayedName: "Stock Level" },
    { name: "cbmPerItem", displayedName: "CBM Per Item" },
    { name: "cbmStock", displayedName: "CBM Stock" },
  ];

  columnsToDisplay: string[] = this.displayedColumns.map((col) => col.name);

  currentDate = new Date();

  constructor(
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    public datepipe: DatePipe,
    private helper: HelperService
  ) {
    this.baseUrl = baseUrl;
    this.period = this.helper.GetStartEndOfPeriodByWeekStart(2, 10 * 7);
  }

  ngOnInit(): void { }

  fillColumns(): void {
    this.displayedColumns = [];
    this.displayedColumns = new Array<Column>(17);

    this.displayedColumns[0] = { name: "sku", displayedName: "SKU" };
    this.displayedColumns[1] = {
      name: "itemDescription",
      displayedName: "Description",
    };
    this.displayedColumns[2] = {
      name: "categoryName",
      displayedName: "Category Name",
    };

    let i = 3;

    for (let j = 10; j > 0; j--) {
      var now = new Date();
      now.setDate(now.getDate() - (j + 1) * 7);
      var startDay = 5; //0 = sunday, 1 = monday etc.
      var d = now.getDay(); //get the current day

      var weekStart = new Date(
        now.valueOf() - (d <= 0 ? 7 - startDay : d - startDay) * 86400000
      ); //rewind to start day
      var weekEnd = new Date(weekStart.valueOf() + 6 * 86400000);

      const formattedDateFrom = this.datepipe.transform(weekStart, "dd/MM");
      const formattedDateTo = this.datepipe.transform(weekEnd, "dd/MM");

      this.displayedColumns[i] = {
        name: "week" + j.toString(),
        displayedName:
          "Week " +
          j.toString() +
          "- " +
          formattedDateFrom +
          " - " +
          formattedDateTo.toString(),
      };
      i = i + 1;
    }

    this.displayedColumns[13] = {
      name: "averageSales",
      displayedName: "Average Sales",
    };
    this.displayedColumns[14] = {
      name: "stockLvl",
      displayedName: "Stock Level",
    };
    this.displayedColumns[15] = {
      name: "cbmPerItem",
      displayedName: "CBM Per Item",
    };
    this.displayedColumns[16] = {
      name: "cbmStock",
      displayedName: "CBM Stock",
    };

    this.columnsToDisplay = this.displayedColumns.map((col) => col.name);
  }

  getBedWeeklySales(): void {
    //alert(this.dateString);
    this.loadingReport = true;

    this.reportsService.getBedItemsDataForTheLast10Weeks().subscribe(
      (result) => {
        this.reportItems = result;
        this.fillColumns();
        this.dataSource = new MatTableDataSource<BedWeeklySale>(
          this.reportItems
        );
        this.loadingReport = false;
        this.hideDownloadButton = false;
      },
      (error) => {
        console.error(error);
        this.loadingReport = false;
        //this.openSnackBar('Something went wrong (', '');
      }
    );
  }

  downloadFile() {
    this.loadingReport = true;
    return this.reportsService
      .getBedItemsDataForTheLast10WeeksEXCEL()
      .subscribe(
        (result: any) => {
          if (result.type != "text/plain") {
            this.loadingReport = false;
            let filename = result.headers
              .get("content-disposition")
              .split(";")[1]
              .split("=")[1]
              .replace(/\"/g, "");
            var blob = new Blob([result.body]);
            saveAs(blob, filename);
          } else {
            alert("File not found in Blob!");
          }
        },
        (error) => {
          console.error(error);
          this.loadingReport = false;
        }
      );
  }
}
