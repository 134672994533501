<div class="wrapper">
  <div class="dropdown">
    <mat-form-field class="search-field">
      <input matInput placeholder="Search" aria-label="State" [matAutocomplete]="auto" [formControl]="searchItemsCtrl"
        [(ngModel)]="searchPurchase" />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let purchaseOrder of purchaseOrders" [value]="purchaseOrder.externalInvoiceNumber"
          (click)="loadDocuments(purchaseOrder); searchPurchase = ''">
          {{ purchaseOrder.externalInvoiceNumber }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <div class="body-section" *ngIf="this.selectedPurchase">
    <div class="header">
      <b>PO {{ this.selectedPurchase.externalInvoiceNumber }}</b>
    </div>
    <mat-tab-group>
      <mat-tab label="Documents">
        <div class="documents">
          <app-purchase-docs [purchaseId]="this.selectedPurchase.pkPurchaseID"
            [purchaseNumber]="this.selectedPurchase.externalInvoiceNumber"
            [hblExtendedProperty]="this.selectedPurchase.hbl"></app-purchase-docs>
        </div>
      </mat-tab>
      <mat-tab label="QC-Photos">
        <div class="qc-photos">
          <app-qc-photos [purchaseId]="this.selectedPurchase.pkPurchaseID"
            [purchaseNumber]="this.selectedPurchase.externalInvoiceNumber"></app-qc-photos>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>