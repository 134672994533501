<div *ngIf="loadingData; else elseBlock">
  <mat-spinner></mat-spinner>
</div>

<ng-template #elseBlock>
  <div class="dialog-container">
    <mat-list *ngFor="let po of purchaseOrders; let i = index">
      <mat-list-item>
        <div class="row">
          <div class="col">
            <h4>Reference: {{ po.externalInvoiceNumber }}</h4>
          </div>
          <div class="col">
            <h4>SKU: {{ po.sku }}</h4>
          </div>
          <div class="col">
            <h4>Quantity: {{ po.quantity }}</h4>
          </div>
        </div>
      </mat-list-item>

      <mat-list-item>
        <div class="row">
          <div class="col">Goods ready date:</div>
          <div class="col">
            <div *ngIf="po.goodsReadyDate; else notCreated">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  type="text"
                  [value]="po.goodsReadyDate.propertyValue"
                  [(ngModel)]="po.goodsReadyDate.propertyValue"
                />
              </mat-form-field>
            </div>

            <ng-template #notCreated>
              <p>Not created yet</p>
            </ng-template>
          </div>

          <div class="col"></div>
        </div>
      </mat-list-item>

      <mat-list-item>
        <div class="row">
          <div class="col">Shipping date:</div>
          <div class="col">
            <div *ngIf="po.shippingDate; else notCreated">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  type="text"
                  [value]="po.shippingDate.propertyValue"
                  [(ngModel)]="po.shippingDate.propertyValue"
                />
              </mat-form-field>
            </div>

            <ng-template #notCreated>
              <p>Not created yet</p>
            </ng-template>
          </div>
          <div class="col"></div>
        </div>
      </mat-list-item>

      <mat-list-item>
        <div class="row">
          <div class="col">Docking date:</div>
          <div class="col">
            <div *ngIf="po.dockingDate; else notCreated">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  type="text"
                  [value]="po.dockingDate.propertyValue"
                  [(ngModel)]="po.dockingDate.propertyValue"
                />
              </mat-form-field>
            </div>

            <ng-template #notCreated>
              <p>Not created yet</p>
            </ng-template>
          </div>
          <div class="col"></div>
        </div>
      </mat-list-item>

      <mat-list-item>
        <div class="row">
          <div class="col">Expected delivery slot:</div>
          <div class="col">
            <div *ngIf="po.expectedDeliverySlot; else notCreated">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  type="text"
                  [value]="po.expectedDeliverySlot.propertyValue"
                  [(ngModel)]="po.expectedDeliverySlot.propertyValue"
                />
              </mat-form-field>
            </div>

            <ng-template #notCreated>
              <p>Not created yet</p>
            </ng-template>
          </div>
          <div class="col">
            <button
              mat-raised-button
              color="primary"
              (click)="calculateDates(po)"
            >
              Calculate Dates
            </button>
          </div>
        </div>
      </mat-list-item>

      <mat-list-item>
        <div class="row">
          <div class="col">Booked:</div>
          <div class="col">
            <div *ngIf="po.booked; else notCreated">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  type="text"
                  [value]="po.booked.propertyValue"
                  [(ngModel)]="po.booked.propertyValue"
                />
              </mat-form-field>
            </div>

            <ng-template #notCreated>
              <p>Not created yet</p>
            </ng-template>
          </div>
          <div class="col">
            <button
              mat-raised-button
              color="primary"
              (click)="editPurchaseOrder(po)"
            >
              Edit
            </button>
          </div>
        </div>
      </mat-list-item>

      <mat-list-item>
        <div class="row">
          <div class="col">Shipped:</div>
          <div class="col">
            <div *ngIf="po.shipped; else notCreated">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  type="text"
                  [value]="po.shipped.propertyValue"
                  [(ngModel)]="po.shipped.propertyValue"
                />
              </mat-form-field>
            </div>

            <ng-template #notCreated>
              <p>Not created yet</p>
            </ng-template>
          </div>
          <div class="col">
            <button
              mat-raised-button
              color="primary"
              (click)="setPropsToSpecificDate(po)"
            >
              Set to 01/01/20
            </button>
          </div>
        </div>
      </mat-list-item>

      <mat-list-item>
        <div class="row">
          <div class="col">Vessel Name:</div>
          <div class="col">
            <div *ngIf="po.vesselName; else notCreated">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  type="text"
                  [value]="po.vesselName.propertyValue"
                  [(ngModel)]="po.vesselName.propertyValue"
                />
              </mat-form-field>
            </div>

            <ng-template #notCreated>
              <p>Not created yet</p>
            </ng-template>
          </div>

          <div class="col">
            <button
              mat-raised-button
              color="primary"
              (click)="saveProperty(po)"
            >
              Save
            </button>
          </div>
        </div>
      </mat-list-item>

      <mat-divider *ngIf="i < purchaseOrders.length - 1"></mat-divider>
    </mat-list>
  </div>
</ng-template>
