import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { saveAs } from "file-saver-es";
import * as moment from "moment";
import { Channel } from "src/app/shared/models/channel";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { ReportsService } from "../../../shared/services/reports.service";
@Component({
    selector: "total-resends-refunds-returns-numbers",
    templateUrl: "./total-resends-refunds-returns-numbers.component.html",
    styleUrls: ["./total-resends-refunds-returns-numbers.component.css"],
})
export class TotalResendsRefundsReturnsNumbers implements OnInit {
    loadingReport = false;
    baseUrl: string;
    hideDownloadButton = true;

    horizontalPosition: MatSnackBarHorizontalPosition = "center";
    verticalPosition: MatSnackBarVerticalPosition = "top";
    currentDate = new Date();
    //
    range = new UntypedFormGroup({
        start: new UntypedFormControl(),
        end: new UntypedFormControl(),
    });

    channels: any[];
    selectedChannel: Channel;

    constructor(
        private reportsService: ReportsService,
        private invetoryService: InventoryService,
        @Inject("BASE_URL") baseUrl: string,
        private _snackBar: MatSnackBar
    ) {
        this.baseUrl = baseUrl;
    }


    selectedChannelChange(value): void {
    }

    ngOnInit(): void {

            this.channels = [
                {
                    id: 0,
                    name: 'All channels'
                },
                {
                    id: 1,
                    name: 'AMAZON - MWS'
                },
                {
                    id: 2,
                    name: 'EBAY'
                },
                {
                    id: 3,
                    name: 'WAYFAIR'
                },
                {
                    id: 4,
                    name: 'AMAZON FBA'
                },
                {
                    id: 5,
                    name: 'WOWCHER'
                },
                {
                    id: 6,
                    name: 'WOOCOMMERCE - hometreatsuk.com'
                },
                {
                    id: 7,
                    name: 'WOOCOMMERCE - https://bedsale.com'
                },
            ];

    }

    downloadFile() {
        if (this.range.value["start"] == null || this.range.value["end"] == null) {
            this.openSnackBar("Pick the date range please.", "");
        } else {
            this.loadingReport = true;

            var dstart = this.range.value["start"];
            var dend = this.range.value["end"];
            return this.reportsService
                .getResendsReturnsRefundsEXCELnew(moment(dstart).format("YYYY-MM-DD"), moment(dend).format("YYYY-MM-DD"), this.selectedChannel.id)
                .subscribe(
                    (result: any) => {
                        if (result.type != "text/plain") {
                            this.loadingReport = false;
                            let filename = result.headers
                                .get("content-disposition")
                                .split(";")[1]
                                .split("=")[1]
                                .replace(/\"/g, "");
                            var blob = new Blob([result.body]);
                            saveAs(blob, filename);
                        } else {
                            alert("File not found in Blob!");
                        }
                    },
                    (error) => {
                        console.error(error);
                        this.loadingReport = false;
                        this.openSnackBar("Somesthing went wrong (", "");
                    }
                );
        }
    }

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }
}
