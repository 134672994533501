<form [formGroup]="profileForm" (ngSubmit)="onSubmit()" *ngIf="profileForm">
  <span class="tip">All values must be positive</span>
  <div class="form-group">
    <label for="channelFee">Channel fee: </label>
    <input id="channelFee" type="number" formControlName="channelFee" min="0" />
  </div>
  <div class="alert">
    <div
      *ngIf="
        profileForm.invalid && profileForm.get('channelFee').errors?.required
      "
      class="error"
    >
      - Channel fee is required
    </div>
    <div
      *ngIf="profileForm.invalid && profileForm.get('channelFee').errors?.min"
      class="error"
    >
      - Channel fee should be greater that 0
    </div>
  </div>

  <div class="form-group">
    <label for="advertisingFee">Advertising fee: </label>

    <input
      id="advertisingFee"
      type="number"
      formControlName="advertisingFee"
      min="0"
    />
  </div>
  <div class="alert">
    <div
      *ngIf="
        profileForm.invalid &&
        profileForm.get('advertisingFee').errors?.required
      "
      class="error"
    >
      - Advertising fee is required
    </div>
    <div
      *ngIf="
        profileForm.invalid && profileForm.get('advertisingFee').errors?.min
      "
      class="error"
    >
      - Advertising fee should be greater that 0
    </div>
  </div>

  <div class="form-group">
    <label for="transactionFee">Transaction fee: </label>
    <input
      id="transactionFee"
      type="number"
      formControlName="transactionFee"
      min="0"
    />
  </div>
  <div class="alert">
    <div
      *ngIf="
        profileForm.invalid &&
        profileForm.get('transactionFee').errors?.required
      "
      class="error"
    >
      - Transaction fee is required
    </div>
    <div
      *ngIf="
        profileForm.invalid && profileForm.get('transactionFee').errors?.min
      "
      class="error"
    >
      - Transaction fee should be greater that 0
    </div>
  </div>

  <div class="buttons">
    <button type="submit" [disabled]="!profileForm.valid">Submit</button>
    <button type="button" (click)="cancel()">Cancel</button>
  </div>
</form>
