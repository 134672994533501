import { CostingChannelConfig } from "./../models/CostingChannelConfig";
import { ChannelFee } from "./../../features/cost-sheet/channel-fees/add-new-channel-fee/add-new-channel-fee.component";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ShippingFee } from "../../features/cost-sheet/shipping-fees/add-new-shipping-fee/add-new-shipping-fee.component";

@Injectable({
  providedIn: "root",
})
export class CostSheetNewService {
  constructor(private http: HttpClient) {}

  private baseRoute = "api/costNew/";

  private getCostInfoUrl = "getCostInfo";
  public getCostInfo(): Observable<any> {
    return this.http.get<any[]>(this.baseRoute + this.getCostInfoUrl);
  }

  private getCostInfoExcelUrl = "getCostInfoExcel";
  public getCostInfoExcel(): Observable<Blob> {
    return this.http.get(this.baseRoute + this.getCostInfoExcelUrl, {
      responseType: "blob",
    });
  }

  private getChannelsUrl = "getChannels";
  public getChannels(): Observable<any> {
    return this.http.get<any[]>(this.baseRoute + this.getChannelsUrl);
  }

  private overridePostageCostUrl = "overridePostageCost";
  public overridePostageCost(obj): Observable<any> {
    return this.http.put(this.baseRoute + this.overridePostageCostUrl, obj);
  }

  private getPostalServicesUrl = "getPostalServices";
  public getPostalServices(): Observable<any> {
    return this.http.get<any[]>(this.baseRoute + this.getPostalServicesUrl);
  }

  private getShippingFeesUrl = "getShippingFees";
  public getShippingFees(): Observable<any> {
    return this.http.get<any[]>(this.baseRoute + this.getShippingFeesUrl);
  }

  private getPackageGroupsUrl = "getPackageGroups";
  public getPackageGroups(): Observable<any> {
    return this.http.get<any[]>(this.baseRoute + this.getPackageGroupsUrl);
  }

  private updateShippingFeeUrl = "updateShippingFee";
  public updateShippingFee(shippingFee: ShippingFee) {
    return this.http.put<any>(
      this.baseRoute + this.updateShippingFeeUrl,
      shippingFee
    );
  }

  private removeShippingFeeRuleUrl = "removeShippingFeeRule";
  public removeShippingFeeRule(id: number) {
    return this.http.delete(
      `${this.baseRoute}${this.removeShippingFeeRuleUrl}/${id}`
    );
  }

  private addShippingFeeUrl = "addShippingFee";
  public addShippingFee(newShippingFee: ShippingFee) {
    return this.http.post<any>(
      this.baseRoute + this.addShippingFeeUrl,
      newShippingFee
    );
  }

  private updateChannelFeeUrl = "updateChannelFee";
  public updateChannelFee(channelFee: ChannelFee) {
    return this.http.put<any>(
      this.baseRoute + this.updateChannelFeeUrl,
      channelFee
    );
  }

  private removeChannelFeeUrl = "removeChannelFee";
  public removeChannelFee(id: number) {
    return this.http.delete(
      `${this.baseRoute}${this.removeChannelFeeUrl}/${id}`
    );
  }

  private getChannelFeesUrl = "getChannelFees";
  public getChannelFees(): Observable<any> {
    return this.http.get<any[]>(this.baseRoute + this.getChannelFeesUrl);
  }

  private addChannelFeeUrl = "addChannelFee";
  public addChannelFee(newChannelFee: ChannelFee) {
    return this.http.post<any>(
      this.baseRoute + this.addChannelFeeUrl,
      newChannelFee
    );
  }

  private resetOverridedPostageCostUrl = "resetOverridedPostageCost";
  public resetOverridedPostageCost(costingSheetUserData) {
    return this.http.post<any>(
      this.baseRoute + this.resetOverridedPostageCostUrl,
      costingSheetUserData
    );
  }

  private updateCostingChannelConfigurationUrl =
    "updateCostingChannelConfiguration";
  public updateCostingChannelConfiguration(model: CostingChannelConfig) {
    return this.http.put<any>(
      this.baseRoute + this.updateCostingChannelConfigurationUrl,
      model
    );
  }

  private getCostingChannelConfigUrl = "getCostingChannelConfig";
  public getCostingChannelConfig(
    channelId: number
  ): Observable<CostingChannelConfig> {
    return this.http.get<CostingChannelConfig>(
      this.baseRoute +
        this.getCostingChannelConfigUrl +
        `?channelId=${channelId}`
    );
  }
}
