<div #container id="date-time-picker-container">
  <div class="d-flex-row gap-8 pd-8">
    <mat-calendar [(selected)]="selectedDateTime" [startAt]="selectedDateTime"></mat-calendar>
    <div class="d-flex-column">
      <mat-checkbox *ngIf="params['allowTime']" class="mg-l16" [(ngModel)]="isTBC"> TBC </mat-checkbox>
      <div *ngIf="params['allowTime'] && !isTBC" class="d-flex-center pd-8 gap-8">
        <mat-form-field class="date-field po-form-field">
          <input matInput type="number" min="0" max="23" [formControl]="hoursControl" />
        </mat-form-field>
        <span><b>:</b></span>
        <mat-form-field class="date-field po-form-field">
          <input matInput min="0" max="59" type="number" [formControl]="minutesControl" />
        </mat-form-field>
      </div>
      <div *ngIf="params['allowTime'] && !isTBC" class="d-flex-center gap-4 pd-l8 pd-r8 mg-b4">
        <div (click)="setTime(7, 30)" class="time-chip app-pointer pd-4">07:30</div>
        <div (click)="setTime(9, 30)" class="time-chip app-pointer pd-4">09:30</div>
        <div (click)="setTime(11, 30)" class="time-chip app-pointer pd-4">11:30</div>
        <div (click)="setTime(14, 0)" class="time-chip app-pointer pd-4">14:00</div>
      </div>
      <div class="d-flex-sp-btw d-flex-vert-center pd-8 mg-8">
        <button mat-stroked-button (click)="cancel()">Cancel</button>
        <button mat-stroked-button (click)="set()">Set</button>
      </div>
    </div>
  </div>
</div>