<mat-card>
    <!-- <mat-progress-bar *ngIf="loadingReport" mode="indeterminate"></mat-progress-bar> -->
    <mat-card-header>
        <mat-card-title>Returns, Resends, Refunds by channel report</mat-card-title>
        <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
        <mat-card-subtitle>Sales by channel with returns, resends, refunds were you can choose what dates to choose sales from</mat-card-subtitle>
    </mat-card-header>
    <div class="row">
        <mat-form-field appearance="fill">
            <mat-label>Enter a date range: </mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>

        <div class="col">
            <mat-form-field class="selectSupplier" style="margin-left: 40px;">
                <mat-label>Channel:</mat-label>
                <mat-select [(value)]="selectedChannel" (selectionChange)="selectedChannelChange($event.value)"
                    [disabled]="loadingReport">
                    <mat-option *ngFor="let channel of channels" [value]="channel">
                        {{ channel.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col">
            <button mat-raised-button color="primary" (click)="downloadFile()" [disabled]="loadingReport"
                style="float: right">
                Download report
            </button>
        </div>
    </div>
</mat-card>
<br />