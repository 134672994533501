import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { LoaderService } from 'src/app/core/services/loader.service';
import { RepositoryService } from 'src/app/core/services/repository.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { getHeaderTemplate } from 'src/app/shared/utils/ag-grid-helper';

export interface Port {
  id?: number;
  name: string;
}

@Component({
  selector: 'app-ports',
  templateUrl: './ports.component.html',
  styleUrls: ['./ports.component.css']
})
export class PortsComponent implements OnInit {

  ports: Port[];
  loadingData = false;
  selectedItem: Port;

  public gridApi;
  public gridColumnApi;
  public columnDefs;
  public defaultColDef;
  public rowData = [];
  public headerHeight;
  public columnTypes;
  public rowSelection;

  portForm: UntypedFormGroup;

  constructor(
    private repo: RepositoryService,
    private snackBar: SnackBarService,
    public dialog: MatDialog,
    private loader: LoaderService) {
    this.defaultColDef = {
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      headerComponentParams: {
        template: getHeaderTemplate()
      },
    };
    this.columnDefs = [
      { headerName: 'Name', field: 'name', resizable: true, editable: true }
    ];
    this.headerHeight = 60;

    this.rowSelection = 'single';

    this.columnTypes = {
      numberColumn: {
        filter: 'agNumberColumnFilter',
      }
    };


  }

  ngOnInit(): void {
    this.portForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.maxLength(60)]),
      transitTime: new UntypedFormControl(31, [Validators.required, Validators.maxLength(3)])
    });
  }

  addPort(params) {

    let newPort: Port = {
      name: params.name
    };

    let apiUrl = 'api/purchaseProps/addPort';
    this.repo.create(apiUrl, newPort).subscribe(result => {
      this.snackBar.openSnackBar('Successfully added', 'Port');
      this.getPorts();
    },
      error => {
        this.snackBar.openSnackBar('Something went wrong', 'Error');
        console.error();
      });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.portForm.controls[controlName].hasError(errorName);
  }

  updatePort(port: any) {
    this.repo
      .update("api/purchaseProps/updatePort", port)
      .subscribe((result) => {

        this.snackBar.openSnackBar("Successfully updated", "Port");
      },
        error => {
          this.snackBar.openSnackBar("Something went wrong", "Error");
        });
  }

  removeSelectedPort() {
    const dialogData = new ConfirmDialogModel('Confirm Action', 'Are you sure?');

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loader.show();
        this.repo.delete(`api/purchaseProps/removePort/${this.selectedItem.id}`).subscribe(result => {
          let itemsToRemove: any[] = [];
          itemsToRemove.push(this.selectedItem);
          this.gridApi.applyTransaction({ remove: itemsToRemove });
          this.loader.hide();
          this.snackBar.openSnackBar('Succesfully removed', 'Port');
        },
          error => {
            this.loader.hide();
            this.snackBar.openSnackBar('Something went wrong', 'Error');
          })
      }
    });
  }

  getPorts() {
    this.loadingData = true;
    this.repo.getList('api/purchaseProps/ports').subscribe(result => {
      this.ports = result;
      let api = this.gridApi;
      api.setRowData([]);
      api.applyTransaction({ add: this.ports });
      this.loadingData = false;
    },
      error => {
        this.snackBar.openSnackBar('Something went wrong', 'Error');
        this.loadingData = false;
      })
  }

  // // // Grid actions
  onCellValueChanged(event) {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    this.updatePort(event.data);
  }

  getRowData() {
    let rowData = [];
    this.gridApi.forEachNode(function (node) {
      rowData.push(node.data);
    });
    return rowData;
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onSelectionChanged(params) {
    let selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow) {
      this.selectedItem = selectedRow[0];
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(30);
    this.gridApi.sizeColumnsToFit();

    this.getPorts();
  }

  getRowHeight(params) {
    return 30;
  }

}
