<mat-card>
  <mat-card-header>
    <mat-card-title>FBA UK stock</mat-card-title>
    <mat-card-subtitle
      >Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle
    >
    <mat-card-subtitle
      >Shows only the items that are in FBA AMZ UK stock</mat-card-subtitle
    >
  </mat-card-header>

  <div class="row">
    <div class="col">
      <button
        mat-raised-button
        color="primary"
        (click)="downloadReport()"
        [disabled]="loading"
        class="download"
      >
        Download Report
      </button>
    </div>
  </div>
</mat-card>
