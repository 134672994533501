import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private http: HttpClient
  ) { }

  upload(file: FormData, category: string) {
    return this.http.post<any>("api/file/upload/" + category, file, {});
  }

  uploadPODatesImport(file: FormData) {
    return this.http.post<any>("api/PurchaseProps/parseFileToDatesImport", file, {});
  }

  uploadInstruction(file: FormData) {
    return this.http.post<any>("api/file/upload/instructions", file, {});
  }

  uploadPurchaseDoc(purchaseId: string, category: string, file: FormData) {
    return this.http.post<any>(`api/file/uploadPurchaseDoc/${purchaseId}/${category}`, file, {});
  }

  removeImageByItemId(stockItemId: string, key: string) {
    return this.http.post('api/file/removeImage', { stockItemId: stockItemId, key: key });
  }

  getInstructionsByItemId(stockItemId: string) {
    return this.http.get<any>('api/file/getStockItemInstructionsById/' + stockItemId);
  }

  getItemImagesByItemId(stockItemId: string) {
    return this.http.get<any>('api/file/getStockItemImagesById/' + stockItemId);
  }

  getPurchaseDocsById(purchaseId: string) {
    return this.http.get<any>('api/file/getPurchaseDocsById/' + purchaseId);
  }

  getPurchaseQcPhotosById(purchaseId: string) {
    return this.http.get<any>('api/file/getPurchaseQcPhotosById/' + purchaseId);
  }

  uploadPurchaseQcPhotosDoc(purchaseId: string, category: string, file: FormData) {
    return this.http.post<any>(`api/file/uploadPurchaseQcPhotosDoc/${purchaseId}/${category}`, file, {});
  }

  downloadPurchaseQcPhotosDoc(key: string) {
    return this.http.get(`api/file/getPurchaseQcPhotosDocByKey/${key}`, {
      responseType: "blob"
    });
  }

  downloadInventoryFile(key: string) {
    return this.http.get(`api/file/getInventoryByKey?key=${key}`, {
      responseType: "blob"
    });
  }

  removePurchaseQcPhotosDoc(purchaseId: string, key: string) {
    return this.http.post('api/file/removePurchaseQcPhotosDoc', { stockItemId: purchaseId, key: key });
  }

  getFileListForOrder(purchaseId: string,
    optionalExtendedPropertyName: string = '',
    optionalExtendedPropertyValue: string = ''): Observable<string[]> {
    const httpOptions = {
      params: new HttpParams()
        .set('extendedPropertyName', optionalExtendedPropertyName)
        .set('extendedPropertyValue', optionalExtendedPropertyValue)
    };
    return this.http.get<any[]>(`api/file/getPurchaseDocsByIdV2/${purchaseId}`, httpOptions);
  }

  downloadPurchaseDoc(key: string) {
    return this.http.get(`api/file/getPurchaseDocByKey/${key}`, {
      responseType: "blob"
    });
  }

  downloadInstruction(key: string) {
    return this.http.get(`api/file/downloadInstruction/${key}`, {
      responseType: "blob"
    });
  }

  removePurchaseDoc(purchaseId: string, key: string) {
    return this.http.post('api/file/removePurchaseDoc', { stockItemId: purchaseId, key: key });
  }

  rename(key: string, newKey: string) {
    return this.http.post(`api/file/rename/${key}/${newKey}`, {});
  }

  copyToSpecSheet(key: string, newKey: string) {
    return this.http.post(`api/file/copyToSpecSheet`, { key: key, newKey: newKey });
  }

  getAllPurchasesFiles(): Observable<string[]> {
    return this.http.get<string[]>('api/file/getAllPurchasesFiles');
  }

}
