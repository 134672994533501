<ag-grid-angular mat-dialog-content #grdExtProps style="height: 85%;" class="ag-theme-balham" (selectionChanged)="onSelectionChanged($event)"
[headerHeight]="headerHeight" [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)" [rowSelection]="rowSelection"
(cellValueChanged)="onCellValueChanged($event)" [rowData]="rowData" [columnDefs]="columnDefs">
</ag-grid-angular>

<div style="display: flex; justify-content: space-between; width: 100%; margin-top: 10px;">

    <div>
        <button mat-raised-button color="primary" [disabled]="loadingData"
            style="margin-right: 25px; margin-bottom: 10px;" (click)="addExtendedProperty()">
            <mat-icon>add</mat-icon>
            <span style="margin-left: 10px;">Add property</span>
        </button>
        <button mat-raised-button color="primary" [disabled]="loadingData"
            style="margin-right: 25px; margin-bottom: 10px;" (click)="removeExtendedProperty()">
            <mat-icon>delete</mat-icon>
            <span style="margin-left: 10px;">Delete property</span>
        </button>
    </div>

    <div>
        <button mat-raised-button color="primary" [disabled]="loadingData || currentPO == null" (click)="updateExtProps()">
        <mat-icon>update</mat-icon>
            <span style="margin-left: 10px;">Update</span>
        </button>

        <button mat-raised-button color="accent" style="margin-left: 10px;" [disabled]="loadingData || currentPO == null" [mat-dialog-close]="{ updated: isUpdated, data: rowData }" mat-dialog-close>
            <mat-icon>cancel</mat-icon>
                <span style="margin-left: 10px;">Cancel</span>
        </button>
    </div>
</div>