<mat-card>
  <!-- <mat-progress-bar *ngIf="loadingReport" mode="indeterminate"></mat-progress-bar> -->

  <mat-card-header>
    <mat-card-title>Slow Selling Lines Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>
      Slow selling items with number of weeks sales in Column E. Red lines are stock received in the last 8 weeks
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <button mat-raised-button color="primary" (click)="downloadFile()" [disabled]="loadingReport">
          Download report
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br />