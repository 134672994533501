import { Component, OnInit } from "@angular/core";
import { ReportsService } from "src/app/shared/services/reports.service";
import { HelperService } from "src/app/shared/services/helper.service";

@Component({
  selector: "app-cash-flow-report",
  templateUrl: "./cash-flow-report.component.html",
  styleUrls: ["./cash-flow-report.component.css"],
})
export class CashFlowReportComponent implements OnInit {
  constructor(
    private reportsService: ReportsService,
    private helper: HelperService
  ) {}

  currentDate = new Date();
  ngOnInit(): void {}

  downloadReport(): void {
    this.reportsService.getCashFlowReport().subscribe((response) => {
      this.helper.saveFile(response);
    });
  }
}
