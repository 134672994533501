<mat-card>
  <mat-card-header>
    <mat-card-title>Photo Per SKU Report</mat-card-title>
    <mat-card-subtitle
      >Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle
    >
    <mat-card-subtitle>
      Shows amount of photos in different categories
    </mat-card-subtitle>
  </mat-card-header>
  <div class="row">
    <div class="col">
      <button
        mat-raised-button
        color="primary"
        (click)="downloadFile()"
        [disabled]="loadingReport"
        style="float: right"
      >
        Download report
      </button>
    </div>
  </div>
</mat-card>
