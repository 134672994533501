import { Component, OnInit } from "@angular/core";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from "file-saver-es";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import * as moment from "moment";
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: "app-shipping-forwarder",
  templateUrl: "./shipping-forwarder.component.html",
  styleUrls: ["./shipping-forwarder.component.css"],
})
export class ShippingForwarderComponent implements OnInit {
  loadingReport = false;
  public freightUSDUnpaid: boolean = false;
  currentDate = new Date();
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });
  constructor(
    private snackBarService: SnackBarService,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {}

  downloadFile() {
    if (this.range.value["start"] == null || this.range.value["end"] == null) {
      this.snackBarService.warning("Pick the date range please.");
      return;
    }

    var dstart = this.range.value["start"];
    var dend = this.range.value["end"];

    this.loadingReport = true;
    return this.reportsService
      .GetShippingForwarderReport(
        moment(dstart).format("YYYY-MM-DD"),
        moment(dend).format("YYYY-MM-DD"),
        this.freightUSDUnpaid
      )
      .subscribe(
        (result: Blob) => {
          if (result.type != "text/plain") {
            this.loadingReport = false;
            var blob = new Blob([result]);
            let file = "3.5 Shipping Forwarder.xlsx";

            saveAs(blob, file);
          } else {
            this.snackBarService.neutral("File not found in Blob!", "");
          }
        },
        (error) => {
          if (error.status === 404) {
            this.snackBarService.neutral(
              "There is no result for current filter ",
              ""
            );
          }
          this.loadingReport = false;
        }
      );
  }

  onCheckboxChange(event): void {
    this.freightUSDUnpaid = event.checked;
  }
}
