import { Component, OnInit } from "@angular/core";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from "file-saver-es";

@Component({
  selector: "app-wow-costing",
  templateUrl: "./wow-costing.component.html",
  styleUrls: ["./wow-costing.component.scss"],
})
export class WowCostingComponent implements OnInit {
  loadingReport = false;
  currentDate = new Date();

  constructor(
    private snackBarService: SnackBarService,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {}

  downloadFile() {
    this.loadingReport = true;
    return this.reportsService.GetWowCostingReport().subscribe(
      (result: Blob) => {
        if (result.type != "text/plain") {
          this.loadingReport = false;
          var blob = new Blob([result]);
          let file = "11.2 WOW costing report.xlsx";

          saveAs(blob, file);
        } else {
          this.snackBarService.neutral("File not found in Blob!", "");
        }
      },
      (error) => {
        if (error.status === 404) {
          this.snackBarService.neutral(
            "There is no result for current filter ",
            ""
          );
        }
        this.loadingReport = false;
      }
    );
  }
}
