import { HelperService } from 'src/app/shared/services/helper.service';
import { Supplier } from "./../../../shared/models/Supplier";
import { SnackBarService } from "./../../../core/services/snack-bar.service";
import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { saveAs } from "file-saver-es";
import { ReportsService } from "../../../shared/services/reports.service";
import * as moment from "moment";

@Component({
  selector: 'app-amazon-sales-dates-set',
  templateUrl: './amazon-sales-dates-set.component.html',
  styleUrls: ['./amazon-sales-dates-set.component.scss']
})
export class AmazonSalesDatesSetComponent implements OnInit {

  constructor(
    private snackBarService: SnackBarService,
    private reportsService: ReportsService,
    private helper: HelperService
  ) { }

  currentDate = new Date();
  loading: boolean = false;
  suppliers: Supplier[];
  selectedSupplier: Supplier;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  ngOnInit(): void {
  }

  downloadReport() {
    if (!this.range.value["start"] || !this.range.value["end"]) {
      this.snackBarService.warning("Pick the date range please.");
      return;
    }

    this.loading = true;

    var dstart = this.range.value["start"];
    var dend = this.range.value["end"];

    this.reportsService
      .GetAmazonSalesDatesSetReport(
        moment(dstart).format("YYYY-MM-DD"),
        moment(dend).format("YYYY-MM-DD")
      )
      .subscribe(
        (response) => {
          this.helper.saveFile(response);
        },
        (error) => {
          this.loading = false;
          if (error.status === 404) {
            this.snackBarService.neutral(
              "There is no result for current filter",
              ""
            );
          }
        }
      );
  }

}
