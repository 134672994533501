import { Component, Inject, OnInit } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from "file-saver-es";
@Component({
  selector: "app-slow-selling-lines",
  templateUrl: "./slow-selling-lines.component.html",
  styleUrls: ["./slow-selling-lines.component.css"],
})
export class SlowSellingLinesComponent implements OnInit {
  loadingReport = false;
  baseUrl: string;
  hideDownloadButton = true;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  constructor(
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    private _snackBar: MatSnackBar
  ) {
    this.baseUrl = baseUrl;
  }

  currentDate = new Date();
  ngOnInit(): void {
    this.loadingReport = false;
  }

  downloadFile() {
    this.loadingReport = true;

    return this.reportsService.getSlowSellingLinesEXCEL().subscribe(
      (result: any) => {
        if (result.type != "text/plain") {
          this.loadingReport = false;
          var blob = new Blob([result]);
          let file = "1.4 - Slow Selling Lines.xlsx";

          saveAs(blob, file);
        } else {
          this._snackBar.open("File not found in Blob!", "", {
            duration: 3000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      },
      (error) => {
        this._snackBar.open("Items not found. List is empty.", "", {
          duration: 3000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });

        this.loadingReport = false;
      }
    );
  }
}
