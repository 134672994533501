<div class="dialog-container">
    <h2 mat-dialog-title>{{ data.purchaseNumber }} - Documents</h2>
    <mat-dialog-content class="mat-typography">
        <mat-selection-list #docs [multiple]="false">
            <div class="category-container" *ngFor="let i of docsByCategories">
                <h4 matSubheader>
                    <button mat-button color="primary" class="upload-button" (click)="addDoc(i.categoryName)">
                        Upload</button>{{ i.categoryName }}
                </h4>
                <mat-divider></mat-divider>
                <mat-list-option *ngFor="let y of i.images" [value]="y">
                    {{ prettifyFileName(y.name) }}
                </mat-list-option>
            </div>
        </mat-selection-list>

        <input name="file" id="file" (change)="uploadFiles($event)" type="file" multiple #inputFile hidden />
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-button (click)="close()" class="close">Close</button>

        <button *ngIf="docs.selectedOptions.selected[0]" mat-raised-button color="primary"
            (click)="downloadFile(docs.selectedOptions.selected[0]?.value)">
            Download
        </button>

        <button *ngIf="docs.selectedOptions.selected[0]" mat-raised-button color="primary"
            (click)="remove(docs.selectedOptions.selected[0]?.value.key)">
            Remove
        </button>
    </mat-dialog-actions>
</div>