<mat-card>
  <mat-card-header>
    <mat-card-title>Container Pallet Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>Can choose PO to show pallet unloading configs</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <mat-form-field class="selectSupplier">
          <mat-label>Purchase Order:</mat-label>
          <mat-select [(value)]="selectedPO" [disabled]="loadingReport">
            <mat-option *ngFor="let po of POs" [value]="po">
              {{ po.externalInvoiceNumber }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col">
        <button mat-raised-button color="primary" (click)="downloadReport()" [disabled]="loadingReport"
          style="float: right">
          Download report
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br />