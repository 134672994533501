<mat-card>
  <!-- <mat-progress-bar *ngIf="loadingReport" mode="indeterminate"></mat-progress-bar> -->
  <mat-card-header>
    <mat-card-title>Supplier Item Info</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>Shows product info including comodity codes</mat-card-subtitle>
  </mat-card-header>
  <div class="row">
    <div class="col">
      <mat-form-field class="selectSupplier">
        <mat-label>Supplier:</mat-label>
        <mat-select [(value)]="selectedSupplier" (selectionChange)="selectedSupplierChange($event.value)"
          [disabled]="loadingReport">
          <mat-option *ngFor="let supp of suppliers" [value]="supp">
            {{ supp.supplierName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col">
      <button mat-raised-button color="primary" (click)="downloadFile()" [disabled]="loadingReport"
        style="float: right">
        Download report
      </button>
    </div>
  </div>
</mat-card>
<br />