import { HelperService } from "./../../../shared/services/helper.service";
import { ReportsService } from "./../../../shared/services/reports.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cash-flow-with-further-info-report",
  templateUrl: "./cash-flow-with-further-info-report.component.html",
  styleUrls: ["./cash-flow-with-further-info-report.component.css"],
})
export class CashFlowWithFurtherInfoReportComponent implements OnInit {
  constructor(
    private reportsService: ReportsService,
    private helper: HelperService
  ) {}

  currentDate = new Date();
  ngOnInit(): void {}

  downloadReport(): void {
    this.reportsService
      .GetCashFlowWithFurstherInfoReport()
      .subscribe((response) => {
        this.helper.saveFile(response);
      });
  }
}
