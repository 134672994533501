import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { NewInventoryItem } from 'src/app/shared/models/newInventoryItem';
import { InventoryService } from 'src/app/shared/services/inventory.service';

@Component({
  selector: 'app-add-new-item',
  templateUrl: './add-new-item.component.html',
  styleUrls: ['./add-new-item.component.css'],
})
export class AddNewItemComponent implements OnInit {

  categories: any[];
  selectedCategory: any;
  newSKU: string;

  public form: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private inventoryService: InventoryService,
    private snackBar: SnackBarService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {


    combineLatest([
      this.inventoryService.getAvailableSKUforNewItem(),
      this.inventoryService.getCategories()
    ]).subscribe(([sku, categories]) => {
      this.newSKU = sku.newSKU;
      this.categories = categories;
      const CashedSelectedCategory = localStorage.getItem('@AddNewItemComponentUISelectedCategoryID');

      if (CashedSelectedCategory) {
        this.selectedCategory = this.categories.find(c => `${c.categoryId}` === CashedSelectedCategory);
      }
      else {
        this.selectedCategory = this.categories[0];
        localStorage.setItem('@AddNewItemComponentUISelectedCategoryID', `${this.categories[0].categoryId}`);
      }


      this.form = new UntypedFormGroup({
        sku: new UntypedFormControl(this.newSKU, [Validators.required, Validators.minLength(3)]),
        title: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
        category: new UntypedFormControl(this.selectedCategory, [Validators.required]),
      });

      this.form.patchValue({ 'category': this.selectedCategory });
      this.form.patchValue({ 'sku': this.newSKU });


    });
  }

  selectedCategoryChange(value): void {
    localStorage.setItem('@AddNewItemComponentUISelectedCategoryID', `${value.categoryId}`);
  }


  create(formValue): void {

    if (!this.form.valid) {
      this.snackBar.warning("Please fill all fields!");
      return;
    }

    let newItem: NewInventoryItem = {
      sku: formValue.sku,
      title: formValue.title,
      categoryId: formValue.category.categoryId
    }

    this.inventoryService.addNewItem(newItem).subscribe(result => {
      this.snackBar.success(`New item ${newItem.sku} successfully added`);
      this.dialog.closeAll();
    });
  }

}
