<mat-card>
    <!-- <mat-progress-bar *ngIf="loadingReport" mode="indeterminate"></mat-progress-bar> -->
    <mat-card-header>
        <mat-card-title>Stock Take Low QTY Report</mat-card-title>
        <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
        <mat-card-subtitle>A report of stock which shows items with just 10 pieces or less</mat-card-subtitle>
    </mat-card-header>
    <div style="display: flex; flex-direction: row; align-items: flex-end;">


        <div class="col">
            <button mat-raised-button color="primary" (click)="downloadReport()" [disabled]="loading"
                style="float: right">
                Download report
            </button>
        </div>
    </div>
</mat-card>
<br />

<div id='footer-template' style='font-size:10px !important; color:#808080; padding-left:10px'>
    
        <span class='pageNumber'></span>
        <span class='totalPages'></span>
</div>

<div id="footer-template" style="font-size:10px !important; color:#808080; padding-left:10px"><span class="date"></span><span class="title"></span><span class="url"></span><span class="pageNumber"></span><span class="totalPages"></span></div>