<div class="multiple-selection-tip">ctrl + click = multiple selection</div>

<div class="search-container">
  <input
    type="text"
    [(ngModel)]="quickFilter"
    placeholder="Search"
    class="search-field"
  />
  <mat-icon
    (click)="quickFilter = ''"
    *ngIf="quickFilter && quickFilter.length > 0"
  >
    clear
  </mat-icon>
</div>

<ag-grid-angular
  class="ag-theme-balham"
  (gridReady)="onGridReady($event)"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  rowSelection="multiple"
  [gridOptions]="gridOptions"
  (selectionChanged)="onSelectionChanged($event)"
  [quickFilterText]="quickFilter"
>
</ag-grid-angular>

<div class="buttons">
  <button class="add" [disabled]="!selectedItems" (click)="addColumn()">
    Add
  </button>
  <button class="cancel" (click)="cancel()">Cancel</button>
</div>
