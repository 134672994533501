<mat-card>
  <mat-card-header>
    <mat-card-title>Stock Value - Parents Only</mat-card-title>
    <mat-card-subtitle
      >Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle
    >
    <mat-card-subtitle
      >Stock report including all seller flex qty and takings out odd carton values</mat-card-subtitle
    >
  </mat-card-header>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="fill">
        <mat-label>Report date</mat-label>
        <input
          matInput
          [matDatepicker]="picker3"
          [formControl]="date"
          (dateChange)="setDate($event.value)"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="picker3"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col">
      <button
        mat-raised-button
        color="primary"
        (click)="downloadReport()"
        [disabled]="loadingReport"
        style="float: left"
      >
        Download Report
      </button>
    </div>
  </div>
</mat-card>
