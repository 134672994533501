import { Injectable } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class SnackBarService {
  private horizontalPosition: MatSnackBarHorizontalPosition = "center";
  private verticalPosition: MatSnackBarVerticalPosition = "top";

  constructor(private snackbar: MatSnackBar) {}

  success(message: string) {
    this.openSnackBar(message, "Success", "snackbar-success");
  }

  warning(message: string) {
    this.openSnackBar(message, "Warning", "snackbar-warning");
  }

  error(message: string) {
    this.openSnackBar(message, "Error", "snackbar-error");
  }

  neutral(message: string, action: string) {
    this.openSnackBar(message, action, "snackbar-neutral");
  }

  openSnackBar(message: string, action: string, className?: string) {
    this.snackbar.open(message, action, {
      duration: 3000,
      panelClass: [className, "snackbar-config"],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
