<mat-card>
    <mat-card-header>
        <mat-card-title>Return Reason Report</mat-card-title>
        <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
        <mat-card-subtitle>Shows the return reason for each SKU
        </mat-card-subtitle>
    </mat-card-header>
    <div class="row">
        <div class="col">
            <mat-form-field appearance="fill">
                <mat-label>Enter a date range: </mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="Start date" />
                  <input matEndDate formControlName="end" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
          
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field class="search-field">
                <input matInput placeholder="Search" aria-label="State" [matAutocomplete]="auto"
                    [formControl]="searchItemsCtrl" [(ngModel)]="searchItemField">
                <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="selectedFiltereditemChange($event.option); searchItemField = '';">
                    <mat-option *ngIf="loading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!loading">
                        <mat-option *ngFor="let item of filteredItems" [value]="item.sku">
                            <span><b>{{item.sku}}</b> ({{item.title}})</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <div class="col" *ngIf="selectedItem">
            {{selectedItem.sku}}
        </div>
        <div class="col">
            <mat-form-field class="po-form-field">
                <mat-label>Category:</mat-label>
                <mat-select [(value)]="selectedCategory" (selectionChange)="selectedCategoryChange($event.value)"
                  [disabled]="loading">
                  <mat-option *ngFor="let cat of categories" [value]="cat">
                    {{ cat.categoryName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="col">
            <button *ngIf="selectedItem || selectedCategory" mat-raised-button color="primary" (click)="downloadReport()"
                [disabled]="loading">
                Download report
            </button>
        </div>
    </div>
</mat-card>
