<h2 mat-dialog-title>Price Change</h2>
<mat-dialog-content class="content">


  <div class="top-row">

    <mat-form-field appearance="standard">
      <mat-label>Channel:</mat-label>
      <mat-select [(value)]="selectedChannel" (selectionChange)="selectedChannelChange($event.value)"
                  class="channel-select"
                  [disabled]="loading">
        <mat-option *ngFor="let c of channels" [value]="c">
          {{ c.source }} {{ c.tag }} - {{ c.subsource }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="standard" class="search-field">
      <!-- <input matInput placeholder="Search" aria-label="State" [matAutocomplete]="auto" [formControl]="searchItemsCtrl" [(ngModel)]="searchItemField" [disabled]="!selectedChannel"> -->
      <mat-label>{{selectedItem?.title}}</mat-label>
      <input matInput placeholder="Search" aria-label="State" [matAutocomplete]="auto" [formControl]="searchItemsCtrl"
             [(ngModel)]="searchItemField">
      <mat-hint align="start">{{selectedItem?.title}}</mat-hint>
      <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedFiltereditemChange($event.option); searchItemField = '';"> -->
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedFiltereditemChange($event.option);">
        <mat-option *ngIf="loading" class="is-loading">Loading...</mat-option>
        <ng-container *ngIf="!loading">
          <mat-option *ngFor="let item of filteredItems" [value]="item.sku">
            <span><b>{{item.sku}}</b> ({{item.title}})</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="standard" *ngIf="this.selectedItem && this.selectedItem.channelPrice">
      <mat-label>Current Price</mat-label>
      <input matInput readonly="true" type="number" placeholder="0" value="0" [value]="selectedItem.channelPrice">
    </mat-form-field>

    <!-- <button mat-raised-button class="amountButton">-</button> -->

    <mat-form-field appearance="standard">
      <mat-label>Amount to change</mat-label>
      <input matInput type="number" placeholder="0" step="0.01" [(ngModel)]="amountToChange" (change)="amountChanged()">
      <mat-hint align="start">negtive if you want to reduce</mat-hint>
    </mat-form-field>

    <!-- <button mat-raised-button class="amountButton">+</button> -->

    <mat-form-field appearance="standard" *ngIf="this.selectedItem && this.selectedItem.channelPrice">
      <mat-label>Price after change</mat-label>
      <input matInput readonly="true" type="number" placeholder="0" [value]="getNewPriceForSelectedItem()">
    </mat-form-field>

  </div>

  <div *ngIf="dataSource.data.length && dataSource.data.length > 0">
    <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2" [hidden]="!dataSource.data">

      <!-- <ng-container *ngFor="let column of columns" [matColumnDef]="column.value">
          <mat-header-cell *matHeaderCellDef mat-sort-header> {{column.name}} </mat-header-cell>
          <mat-cell *matCellDef="let item">{{item[column.value]}}</mat-cell>
      </ng-container> -->

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sku">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Combo SKU</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.sku}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="source">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Source</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.source}} {{element.subsource}} {{element.tag}} </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="subsource">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Subsource </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.subsource}} </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="channelPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Combo Price</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.channelPrice | number: '1.0-2'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="comboMainPartsku">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Parent SKU</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.comboMainPart?.sku}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="comboMainPartchannelPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Parent Price</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.comboMainPart?.channelPrice | number: '1.0-2'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="compositionQuantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Child Qty</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.compositionQuantity}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="newPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header> New Price</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.newPrice | number: '1.0-2'}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-raised-button color="primary" (click)="updatePrices()">Apply</button>
</mat-dialog-actions>
