<h4 mat-dialog-title>Suppliers state & default port</h4>
<div class="suppliers" *ngIf="suppliers?.length && ports?.length && selectedSupplierPorts?.length">
    <div class="supplier" *ngFor="let supplier of suppliers;let i = index;">
        <span>{{ supplier.supplierName }}</span>
        <mat-slide-toggle
                [checked]="supplier.active"
                (change)="onToggle(supplier)"
                color="primary" class="mg-r24"></mat-slide-toggle>
        <mat-form-field class="kr-form-field">
            <mat-label>Port:</mat-label>
            <mat-select [(value)]="selectedSupplierPorts[i]" (selectionChange)="selectedPortChange($event.value, supplier)"
            >
                <mat-option *ngFor="let port of ports" [value]="port">
                    {{ port.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<mat-dialog-actions>
    <button mat-stroked-button (click)="close()">Close</button>
</mat-dialog-actions>
