import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface Purchase {
  purchaseID: string;
}

@Injectable({
  providedIn: "root",
})
export class POService {
  currentPurchase: string;
  goBack: boolean = false;
  returnUrl: string;

  constructor() {
    this.currentPurchase = null;
  }

  poViewChangesSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);


  public purchaseOrderCreatedSubject = new BehaviorSubject(false);
}
