<mat-card>
    <mat-card-header>
        <mat-card-title>Seller Flex Report Low Qty</mat-card-title>
        <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
        <mat-card-subtitle>Shows stock that has been allocated to Seller Flex - Low Qty</mat-card-subtitle>
    </mat-card-header>

    <div class="row">
        <div class="col">
            <button mat-raised-button color="primary" (click)="downloadReport()" [disabled]="loading" class="download">
                Download Report
            </button>
        </div>
    </div>
</mat-card>
