import { Component, OnInit } from '@angular/core';
import { ReportsService } from "./../../../shared/services/reports.service";
import { InventoryService } from "./../../../shared/services/inventory.service";
import { HelperService } from "./../../../shared/services/helper.service";
import { Supplier } from '../../../shared/models/Supplier';
import { map } from 'rxjs';

@Component({
  selector: 'app-amazon-shipping-report',
  templateUrl: './amazon-shipping-report.component.html',
  styleUrls: ['./amazon-shipping-report.component.css']
})
export class AmazonShippingReportComponent implements OnInit {
  public selectedSupplier: Supplier;
  public suppliers: Supplier[] = [];
  public loading: boolean = false;
  currentDate = new Date();

  constructor(
    private reportsService: ReportsService,
    private inventoryService: InventoryService,
    private helper: HelperService
  ) { }

  ngOnInit(): void {
    this.inventoryService
      .getSuppliers()
      .pipe(
        map((suppliers: Supplier[]) => {
          suppliers.unshift({ pkSupplierID: "all", supplierName: "All" });
          return suppliers;
        })
      )
      .subscribe(
        (result) => {
          this.suppliers = result;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  downloadReport(): void {
    this.reportsService
      .getAmazonShippingReport(this.selectedSupplier.pkSupplierID)
      .subscribe((response) => {
        this.helper.saveFile(response);
      });
  }

}
