import { Component, OnInit } from '@angular/core';
import { ReportsService } from "../../../shared/services/reports.service";
import { SnackBarService } from "../../../core/services/snack-bar.service";
import { HelperService } from "../../../shared/services/helper.service";
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-seller-flex-stock-new',
  templateUrl: './seller-flex-stock-new.component.html',
  styleUrls: ['./seller-flex-stock-new.component.scss']
})
export class SellerFlexStockNewComponent implements OnInit {

  constructor(
    private snackBarService: SnackBarService,
    private reportsService: ReportsService,
    private helper: HelperService
  ) {}
  currentDate = new Date();
  loading: boolean = false;

  ngOnInit(): void {}

  downloadReport() {
    this.reportsService
      .getSellerFlexStockReportNew()
      .subscribe((response: HttpResponse<Blob>) => {
        this.helper.saveFile(response);
      });
  }

}