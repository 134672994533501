import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { RepositoryService } from "src/app/core/services/repository.service";
import { HelperService } from "src/app/shared/services/helper.service";
import { DatePipe } from "@angular/common";
import { UntypedFormControl } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { getHeaderTemplate } from "src/app/shared/utils/ag-grid-helper";

class PurchaseData {
  purchaseID: string;
  poNumber: string;
  freight: number;
  freightUSD: number;
  profitValue: number;
  profitPercent: number;
  goodsValueUSD: number;
  goodsValue: number;
  duty: number;
  //profitPercent: number;
  exchangeRate: number;
  totalCBM: number;
  items: PurchaseDataItem[];
}

class PurchaseDataItem {
  stockItemID: string;
  sku: string;
  description: string;
  qty: number;
  unitPrice: number;
  ctnqty: number;
  cbm: number;
  cbmPerLine: number;
  landedCost: number;
  sellingPrice: number;
  profitPerPiece: number;
  totalProfit: number;
  postageCost: number;
}

class Purchase {
  externalInvoiceNumber: string;
  pkPurchaseID: string;
  supplierName: string;
}

@Component({
  selector: "app-po-landed-cost",
  templateUrl: "./po-landed-cost.component.html",
  styleUrls: ["./po-landed-cost.component.css"],
  providers: [DatePipe],
})
export class POLandedCostComponent implements OnInit, OnDestroy {
  loadingData = false;
  http: HttpClient;
  baseUrl: string;
  hideDownloadButton = true;
  filteredPOs: Observable<Purchase[]>;
  purchaseOrders: Purchase[];
  itemsCtrl = new UntypedFormControl();
  currentPO: PurchaseData;
  destroyedComponentSubj = new Subject<void>();
  searchItemField;
  freightCost;
  freightUSD;
  goodsValueUSD;
  profitValue;
  dutyCost;
  goodsValue;
  profitPercent;
  exchangeRate;
  totalCBM;
  currentPONumber;
  frameworkComponents: any = {};
  rowData: PurchaseDataItem[];
  valueCBMFormatter = function (params) {
    return parseFloat(params.data.cbmPerLine).toFixed(4);
  };
  totalValueGetterDescription = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.description;
    }
  };
  totalValueGetterCBM = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.cbm.toFixed(6);
    }
  };
  totalValueGetterUnitPrice = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.unitPrice.toFixed(2);
    }
  };
  totalValueGetterCtnqty = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.ctnqty == 0 ? 1 : params.data.ctnqty;
    }
  };
  totalValueGetterLandedCost = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.landedCost.toFixed(2);
    }
  };
  totalValueGetterSelPrice = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.sellingPrice.toFixed(2);
    }
  };

  totalValueGetterProfitPerPiece = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.profitPerPiece.toFixed(2);
    }
  };

  totalValueGetterTotalProfit = function (params) {
    return params.data.totalProfit.toFixed(2);
  };

  // SKU Sort - from lowest to highest
  SKUComparator(val1, val2) {
    var po1 = val1.substring(0, 2);
    var isCA1 = /[a-zA-Z]/.test(po1); // IF PO starts with 'CA%'

    var po2 = val2.substring(0, 2);
    var isCA2 = /[a-zA-Z]/.test(po2); // IF PO starts with 'CA%'

    let a = 0;
    let b = 0;
    var num1 = "";
    var num2 = "";

    if (isCA1) {
      num1 = val1.substr(2);
    } else {
      num1 = val1;
    }

    a = parseInt(num1);

    if (isNaN(a)) {
      a = -1;
    }

    if (isCA2) {
      num2 = val2.substr(2);
    } else {
      num2 = val2;
    }

    b = parseInt(num2);

    if (isNaN(b)) {
      b = -1;
    }

    if (a == b) {
      return 0;
    }
    return a > b ? 1 : -1;
  }

  // grid: Columns
  columnDefs = [
    {
      headerName: "Code",
      field: "sku",
      minWidth: 90,
      sortable: true,
      sort: "asc",
      comparator: this.SKUComparator,
    },
    {
      headerName: "Name",
      field: "description",
      minWidth: 90,
      valueGetter: this.totalValueGetterDescription,
    },
    { headerName: "Quantity", field: "qty", minWidth: 40, sortable: true },
    {
      headerName: "Unit price USD",
      field: "unitPrice",
      minWidth: 40,
      sortable: true,
      valueGetter: this.totalValueGetterUnitPrice,
    },
    {
      headerName: "Carton QTY",
      field: "ctnqty",
      minWidth: 40,
      sortable: true,
      valueGetter: this.totalValueGetterCtnqty,
    },
    {
      headerName: "CBM Per Line",
      field: "cbmPerLine",
      minWidth: 40,
      sortable: true,
      valueFormatter: this.valueCBMFormatter,
    },
    {
      headerName: "Landed Cost GBP",
      field: "landedCost",
      minWidth: 60,
      sortable: true,
      valueGetter: this.totalValueGetterLandedCost,
    },
    {
      headerName: "Selling Price",
      field: "sellingPrice",
      minWidth: 60,
      sortable: true,
      valueGetter: this.totalValueGetterSelPrice,
    },
    {
      headerName: "Profit Per Piece",
      field: "profitPerPiece",
      minWidth: 60,
      sortable: true,
      valueGetter: this.totalValueGetterProfitPerPiece,
    },
    {
      headerName: "Total Profit",
      field: "totalProfit",
      minWidth: 60,
      sortable: true,
      valueGetter: this.totalValueGetterTotalProfit,
    },
  ];

  // grid: headers height
  public headerHeight;

  // grid: def header styles
  public defaultColDef;

  // grid: for updating and editing grid order items data
  public gridApi;

  // grid - pin to bottom Total
  public pinnedBottomRowData;

  constructor(
    http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    public datepipe: DatePipe,
    private repo: RepositoryService,
    private helper: HelperService,
    private snackBar: SnackBarService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
    this.headerHeight = 50;
    this.defaultColDef = {
      resizable: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      tooltipComponent: "GridTooltipComponent",
      headerComponentParams: {
        template: getHeaderTemplate(),
      },
    };
    this.currentPONumber = "";
    this.getPurchaseOrders();
    this.pinnedBottomRowData = [];
  }

  // Select suppliers and locations on load
  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroyedComponentSubj.next();
    this.destroyedComponentSubj.complete();
  }
  // grid init
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.getTotalRow();
  }

  // GET and SET selected purchase data
  selectedPOChange(value) {
    var selectedPO = this.purchaseOrders.filter(function (item) {
      return item.externalInvoiceNumber == value;
    })[0];

    if (selectedPO != null) {
      this.loadingData = true;

      this.http
        .get<PurchaseData>(
          this.baseUrl +
            "api/po/getPurchaseOrderWithRequiredLandedCostByID/" +
            selectedPO.pkPurchaseID
        )
        .subscribe(
          (result) => {
            if (result != null) {
              this.currentPO = result;
              this.currentPONumber = result.poNumber;
              this.freightCost = this.helper.valueIsNaN(result.freight);
              this.freightUSD = this.helper.valueIsNaN(result.freightUSD);
              this.goodsValueUSD = result.goodsValueUSD.toFixed(2);
              this.goodsValue = (
                result.goodsValueUSD / result.exchangeRate
              ).toFixed(2);

              this.dutyCost = result.duty;

              this.exchangeRate = result.exchangeRate;

              //fill by data
              this.clearGrid();

              if (result.items != null) {
                result.items.forEach((item, index) => {
                  item.cbmPerLine = parseFloat(
                    ((item.qty / item.ctnqty) * item.cbm).toString()
                  );
                  item.landedCost = item.landedCost;

                  item.cbmPerLine = this.helper.valueIsNaN(item.cbmPerLine);
                  item.landedCost = this.helper.valueIsNaN(item.landedCost);

                  this.rowData.push(item);
                });
              }

              this.totalCBM = this.helper.valueIsNaN(
                parseFloat(
                  this.rowData
                    .reduce(
                      (prev, cur) =>
                        prev + parseFloat(cur.cbmPerLine.toString()),
                      0
                    )
                    .toFixed(4)
                )
              );
              this.recalculateProfitTotal();

              this.gridApi.applyTransaction({ add: this.rowData });
              this.gridApi.redrawRows();

              // this.recalculatePO();

              this.updateTotalRow();
            } else {
              this.snackBar.error("There is no PO with this number.");
            }

            this.loadingData = false;
          },
          (error) => {
            this.loadingData = false;

            this.snackBar.error("Something went wrong");
          }
        );
    }
  }

  clearGrid(): void {
    this.rowData = [];
    this.gridApi.applyTransaction({ update: this.rowData });
    this.gridApi.redrawRows();
  }

  private _filterItems(value: string): Purchase[] {
    if (value == "" || value == null) return this.purchaseOrders;

    const filterValue = value.toLowerCase();
    return this.purchaseOrders.filter(
      (option) =>
        option.externalInvoiceNumber.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  // grid: add total row
  getTotalRow() {
    let totalRow = {
      stockItemID: "total",
      sku: "Total - ",
      description: "",
      qty: 0,
      unitPrice: 0,
      ctnqty: 0,
      cbm: 0,
      cbmPerLine: 0,

      landedCost: 0,
      sellingPrice: 0,
      profitPerPiece: 0,
      totalProfit: 0,
    };

    if (this.rowData != null) {
      totalRow.qty = this.rowData.reduce(
        (prev, cur) => prev + parseInt(cur.qty.toString(), 0),
        0
      );
      totalRow.cbmPerLine = parseFloat(
        this.rowData
          .reduce(
            (prev, cur) => prev + parseFloat(cur.cbmPerLine.toString()),
            0
          )
          .toFixed(4)
      );
      totalRow.totalProfit = parseFloat(
        this.rowData
          .reduce(
            (prev, cur) => prev + parseFloat(cur.totalProfit.toString()),
            0
          )
          .toFixed(2)
      );

      this.totalCBM = parseFloat(
        this.rowData
          .reduce(
            (prev, cur) => prev + parseFloat(cur.cbmPerLine.toString()),
            0
          )
          .toFixed(4)
      );
      this.recalculateProfitTotal();
    }

    var data = [];
    data.push(totalRow);
    this.pinnedBottomRowData = data;
    this.gridApi.setPinnedBottomRowData(data);
  }

  // grid: recalculating total
  updateTotalRow() {
    if (this.rowData.length && this.rowData.length > 0) {
      var totalIndex = this.rowData.findIndex((x) => x.sku == "Total - ");
      if (totalIndex != -1) {
        this.rowData.splice(totalIndex, 1);
        this.gridApi.setRowData(this.rowData);
      }
      this.getTotalRow();
    }
  }

  // get Purchase orders
  getPurchaseOrders(): void {
    this.loadingData = true;

    this.repo
      .getList("api/po/getAllPurchases")
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(
        (result) => {
          this.purchaseOrders = result;
          this.filteredPOs = this.itemsCtrl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterItems(value))
          );
          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;
          this.snackBar.error("Something went wrong");
        }
      );
  }

  // recalc landed cost
  recalculatePO() {
    this.loadingData = true;
    var totalCBM = parseFloat(this.totalCBM);
    var duty = parseFloat(this.dutyCost);
    var freightUSD = parseFloat(this.freightUSD);
    var freight = parseFloat(this.freightCost);
    var exchangeRate = parseFloat(this.exchangeRate);
    if (
      isNaN(duty) ||
      isNaN(freight) ||
      isNaN(freightUSD) ||
      isNaN(exchangeRate)
    ) {
      this.snackBar.warning("Wrong value in one of the fields");
      this.loadingData = false;
    } else {
      let recalculatedItems = [];
      this.http
        .post<any>(
          this.baseUrl +
            "api/cost/getPurchaseItemsLandedCost/", {
              purchaseId: this.currentPO.purchaseID,
              freight: this.freightCost,
              freightUSD: this.freightUSD,
              duty: this.dutyCost,
              totalPurchaseCBM: this.totalCBM,
              conversionRate: this.exchangeRate,
              purchaseItems: this.currentPO.items.map(i => i.stockItemID)
            }
        )
        .subscribe(
          (result) => {
            if (result != null) {
              recalculatedItems = result;

              this.rowData.forEach((item, index) => {
                // // old
                // item.landedCost =
                //   item.unitPrice / exchangeRate +
                //   ((freightUSD / exchangeRate + freight + duty) / totalCBM) *
                //     (item.cbm / item.ctnqty);

                // // test, not correct
                item.landedCost = recalculatedItems.find(x => x.stockItemId == item.stockItemID)?.landedCost;

                // // test, acording to Kabir's new formula
                // let gbpCost = item.unitPrice / exchangeRate;
                // let freightCost = freightUSD / this.totalCBM * item.cbmPerLine / item.qty;
                // item.landedCost = gbpCost + freightCost;
        
                item.profitPerPiece =
                  item.sellingPrice / 1.2 -
                  item.landedCost -
                  item.sellingPrice * 0.15 -
                  item.postageCost;
                item.totalProfit = item.profitPerPiece * item.qty;
        
                item.landedCost = this.helper.valueIsNaN(item.landedCost);
                item.profitPerPiece = this.helper.valueIsNaN(item.profitPerPiece);
                item.totalProfit = this.helper.valueIsNaN(item.totalProfit);
              });
              this.gridApi.applyTransaction({ update: this.rowData });
              this.gridApi.redrawRows();
              this.recalculateProfitTotal();
              this.updateTotalRow();
              this.loadingData = false;
              this.snackBar.success("Updated")
            }
          }
        ); 
      ;
    }
  }

  recalculateProfitTotal() {
    this.profitValue = this.helper.valueIsNaN(
      parseFloat(
        this.rowData
          .reduce(
            (prev, cur) => prev + parseFloat(cur.totalProfit.toString()),
            0
          )
          .toFixed(2)
      )
    );
    this.profitPercent = this.helper
      .valueIsNaN((100 / this.goodsValue) * this.profitValue)
      .toFixed(2);
  }
  // matInput in mat-select-autocomplete
  clearSearchField() {
    this.searchItemField = "";
  }
}
