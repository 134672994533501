import { HttpResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ReportsService } from "src/app/shared/services/reports.service";
import { HelperService } from "src/app/shared/services/helper.service";

@Component({
  selector: "app-weekly-sales-new",
  templateUrl: "./weekly-sales-new.component.html",
  styleUrls: ["./weekly-sales-new.component.css"],
})
export class WeeklySalesNewComponent implements OnInit {
  constructor(
    private reportsService: ReportsService,
    private helper: HelperService
  ) {}

  ngOnInit(): void {}

  downloadReport() {
    this.reportsService
      .getWeeklySalesNew()
      .subscribe((response: HttpResponse<Blob>) => {
        this.helper.saveFile(response);
      });
  }
}
