import { Component, Inject, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Supplier } from "src/app/shared/models/Supplier";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { saveAs } from "file-saver-es";
@Component({
  selector: "app-barcode-report",
  templateUrl: "./barcode-report.component.html",
  styleUrls: ["./barcode-report.component.scss"],
})
export class BarcodeReportComponent implements OnInit {
  constructor(
    @Inject("BASE_URL") private baseUrl: string,
    private http: HttpClient,
    private inventoryService: InventoryService,
    private snackBarService: SnackBarService,
    private reportsService: ReportsService
  ) {}
  currentDate = new Date();
  loading: boolean = false;
  suppliers: Supplier[];
  selectedSupplier: Supplier;

  ngOnInit(): void {
    this.loading = true;
    this.inventoryService.getSuppliers().subscribe(
      (result) => {
        this.suppliers = result;
        this.suppliers.unshift({
          pkSupplierID: "all",
          supplierName: "All",
        });
        this.selectedSupplier = this.suppliers[0];
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.error(error);
      }
    );
  }

  downloadReport() {
    this.loading = true;
    this.reportsService
      .getBarcodeReport(this.selectedSupplier.pkSupplierID)
      .subscribe(
        (result) => {
          if (result.type != "text/plain") {
            this.loading = false;
            var blob = new Blob([result]);
            let file = "8.1 - Barcode Report" + ".xlsx";

            saveAs(blob, file);
          } else {
            alert("File not found in Blob!");
          }
        },
        (error) => {
          this.loading = false;
          if (error.status === 404) {
            this.snackBarService.neutral(
              "There is no result for supplier " +
                this.selectedSupplier.supplierName,
              ""
            );
          }
        }
      );
  }
}
