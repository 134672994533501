<mat-card>
    <mat-card-header>
        <mat-card-title>Seller Flex Allocation Report</mat-card-title>
        <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
        <mat-card-subtitle>
            Produces data for Seller Flex allocation
        </mat-card-subtitle>
    </mat-card-header>
    <div class="row">
        <div class="col">
            <button mat-raised-button color="primary" (click)="downloadAllocation()" [disabled]="loadingReport"
                style="float: right">
                Download Allocation Report
            </button>
        </div>
    </div>
</mat-card>