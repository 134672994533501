import { Component, OnInit } from '@angular/core';
import { ReportsService } from "../../../shared/services/reports.service";
import { SnackBarService } from "../../../core/services/snack-bar.service";
import { HelperService } from "../../../shared/services/helper.service";
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-arrow',
  templateUrl: './arrow.component.html',
  styleUrls: ['./arrow.component.css']
})
export class ArrowComponent implements OnInit {

  constructor(
    private snackBarService: SnackBarService,
    private reportsService: ReportsService,
    private helper: HelperService
  ) {}
  currentDate = new Date();
  loading: boolean = false;
  ngOnInit(): void {
  }

  downloadReportAirdrie() {
    this.reportsService
      .getArrowAirdrie()
      .subscribe((response: HttpResponse<Blob>) => {
        this.helper.saveFile(response);
      });
  }

  downloadReportEnfield() {
    this.reportsService
      .getArrowEnfield()
      .subscribe((response: HttpResponse<Blob>) => {
        this.helper.saveFile(response);
      });
  }

  downloadReportWigan() {
    this.reportsService
      .getArrowWigan()
      .subscribe((response: HttpResponse<Blob>) => {
        this.helper.saveFile(response);
      });
  }

  downloadReportWorcester() {
    this.reportsService
      .getArrowWorcester()
      .subscribe((response: HttpResponse<Blob>) => {
        this.helper.saveFile(response);
      });
  }

}
