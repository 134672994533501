import { POService } from "./../po-view/po.service";
import { Component, OnDestroy } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { IAfterGuiAttachedParams } from "ag-grid-community";
import { POItemsBySupplierService } from "../po-creation/po-items.service";
import { HelperService } from "src/app/shared/services/helper.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { StockItem, OrderItem } from "src/app/shared/models/purchase.models";

@Component({
  selector: "cmbx-items-renderer",
  template: ` <div style="display: inline-flex;" (click)="onClick($event)">
    <mat-form-field
      *ngIf="params1.data.stockItemID == 'dropdown'"
      class="selectItem"
    >
      <mat-label>Items:</mat-label>

      <input
        type="text"
        placeholder="Search item"
        style="width: 120px !important;"
        matInput
        *ngIf="params1.data.stockItemID == 'dropdown'"
        [formControl]="itemsCtrl"
        (click)="onClick($event)"
        [matAutocomplete]="auto"
        [(ngModel)]="searchItemField"
      />

      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        (optionSelected)="selectedStockItem($event.option.value)"
        [panelWidth]="400"
      >
        <mat-option
          *ngFor="let option of filteredItems | async"
          [value]="option.sku"
        >
          {{ option.sku }} - {{ option.description }}
        </mat-option>
      </mat-autocomplete>

      <button
        mat-button
        mat-icon-button
        matSuffix
        *ngIf="searchItemField"
        (click)="clearSearchField()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <span *ngIf="params1.data.stockItemID != 'dropdown'" class="selectItem">
      {{ params1.data.sku }}
    </span>
  </div>`,
})
export class DropdownItemsRenderer implements ICellRendererAngularComp {
  refresh(params: any): boolean {
    return false;
  }

  itemCtrl = new UntypedFormControl();
  itemsCtrl = new UntypedFormControl();
  itemField;

  selectedItem: StockItem;

  constructor(
    private poService: POService,
    private poItemsService: POItemsBySupplierService,
    private helper: HelperService,
    private snackBar: SnackBarService
  ) {
    this.stockItems = poItemsService.stockItems;
    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterItems(value))
    );
  }

  hideAutocomplete: boolean = false;

  searchItemField;

  stockItems: StockItem[];

  // mat-select
  public params1: any;

  // mat-select: items data
  public items: any;

  filteredItems: Observable<StockItem[]>;
  public value: any;

  public clearButton: boolean;

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error("Remove btn: Method not implemented.");
  }

  agInit(params: any): void {
    this.params1 = params;
    this.filteredItems = params.options;

    // if (params.data.sku != 'dropdown') { this.itemField = params.data.sku; this.itemCtrl.disable(); }

    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterItems(value))
    );
  }

  // mat-autocomplete: Add selected item to grid
  selectedStockItem(valueSku): void {
    if (this.poItemsService.currentStatus != "DELIVERED") {
      // rowData
      var rowData = this.params1.api.gridOptionsWrapper.gridOptions.rowData;

      // search if items already in grid
      var itemInGrid = rowData.filter(function (item) {
        return item.sku == valueSku;
      })[0];

      if (itemInGrid == null) {
        // search in list of items
        var stockItem = this.stockItems.filter(function (item) {
          return item.sku == valueSku;
        })[0];

        //if parent item or child element  was chosen
        var valueChilds = this.stockItems.filter(function (item) {
          return (
            item.sku
              .toUpperCase()
              .includes(valueSku.split(" ")[0].toUpperCase()) &&
            item.sku.toUpperCase().includes("box".toUpperCase())
          );
        });

        if (stockItem != null) {
          if (rowData == null) rowData = [];

          var itemsToUpdate = [];

          if (valueChilds != null && valueChilds.length > 0) {
            valueChilds.forEach((child, index) => {
              var newItemInGrid = rowData.filter(function (item) {
                return item.sku == child.sku;
              })[0];

              if (newItemInGrid == null) {
                var newItem = this.createPurchaseItem(child);

                itemsToUpdate.push(newItem);
                rowData.push(newItem);
              }
            });
          } else {
            var item = this.createPurchaseItem(stockItem);

            rowData.push(item);
            itemsToUpdate.push(item);
          }

          this.poService.poViewChangesSubject.next(true);
          // update grid with items
          this.params1.api.applyTransaction({ add: itemsToUpdate });
          this.params1.api.redrawRows();
          this.poItemsService.stockItems = this.poItemsService.stockItems?.filter(i => i.sku !== item.sku);
        } else {
          this.snackBar.warning("There is no item in dropdown.");
        }
      } else {
        this.snackBar.neutral("Selected item is already in grid", "");
      }
    } else {
      this.snackBar.warning(
        "If PO DELIVERED you cannot change quantity and cost of items"
      );
    }

    this.clearSearchField();
  }

  createPurchaseItem(stockItem: StockItem): OrderItem {
    var item = new OrderItem();

    item.stockItemID = stockItem.stockItemID;
    item.sku = stockItem.sku;
    item.description =
      stockItem.description == "0" ? "" : stockItem.description;
    item.cartonQty = stockItem.ctnqty > 0 ? stockItem.ctnqty : 1;
    item.cbm = parseFloat(stockItem.cbm.toFixed(4));
    item.unitCost = stockItem.purchasePrice;
    item.quantity = stockItem.ctnqty > 0 ? stockItem.ctnqty : 1;
    item.factoryItemNo = stockItem.factoryItemNo;

    // var weightPerItemKg = parseFloat(stockItem.weight.toString()) / 1000;
    // item.weight = parseFloat(
    //   (weightPerItemKg * parseFloat(item.quantity.toString()))
    //     .toFixed(2)
    //     .toString()
    // ); // in g
    item.weight = stockItem.weight;

    item.taxRate = stockItem.taxRate;
    item.hide = false;

    item.totalCBM = (item.quantity / item.cartonQty) * item.cbm;

    item.lineTotal = this.helper.valueIsNaN(
      parseInt(item.quantity.toString(), 0) *
        parseFloat(item.unitCost.toString())
    );

    // IF NaN then 0
    item.totalCBM = this.helper.valueIsNaN(item.totalCBM);
    item.lineTotal = this.helper.valueIsNaN(item.lineTotal);
    item.weight = this.helper.valueIsNaN(item.weight);

    return item;
  }

  onClick(event) {
    this.stockItems = this.poItemsService.stockItems;

    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterItems(value))
    );
  }

  // mat-select-autocomplete - search items by key
  private _filterItems(value: string): StockItem[] {
    this.stockItems = this.poItemsService.stockItems;

    if (value == "" || value == null) return this.stockItems;

    const filterValue = value.toLowerCase();

    return this.stockItems.filter(
      (option) => option.sku.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  clearSearchField(): void {
    this.searchItemField = "";
  }
}
