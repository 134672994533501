export class StockItemSupplierInfo {
    id: string;
    stockItemId: string;
    supplierId: string;
    cbm: number;
    cartonQuantity: number;
    purchasePriceUSD: number;

    constructor(model: Partial<StockItemSupplierInfo>) {
        this.id = model.id;
        this.stockItemId = model.stockItemId;
        this.supplierId = model.supplierId;
        this.cbm = model.cbm;
        this.cartonQuantity = model.cartonQuantity;
        this.purchasePriceUSD = model.purchasePriceUSD;
    }
}