import { ReportsService } from "../../../shared/services/reports.service";
import { SnackBarService } from "../../../core/services/snack-bar.service";
import { Component, OnInit } from "@angular/core";
import { saveAs } from "file-saver-es";
@Component({
  selector: "app-fba-europe-stock",
  templateUrl: "./fba-europe-stock.component.html",
  styleUrls: ["./fba-europe-stock.component.scss"],
})
export class FbaEuropeStockComponent implements OnInit {
  constructor(
    private snackBarService: SnackBarService,
    private reportsService: ReportsService
  ) {}
  currentDate = new Date();
  loading: boolean = false;

  ngOnInit(): void {}

  downloadReport() {
    this.loading = true;
    this.reportsService.GetFbaEuropeStockReport().subscribe(
      (result) => {
        if (result.type != "text/plain") {
          this.loading = false;
          var blob = new Blob([result]);
          let file = "7.4 FBA Europe stock.xlsx";

          saveAs(blob, file);
        } else {
          alert("File not found in Blob!");
        }
      },
      (error) => {
        this.loading = false;
        if (error.status === 404) {
          this.snackBarService.error("Something went wrong");
        }
      }
    );
  }
}
