import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, UntypedFormControl } from "@angular/forms";
import * as moment from "moment";
import { SnackBarService } from "./../../../core/services/snack-bar.service";
import { ReportsService } from "./../../../shared/services/reports.service";
import { saveAs } from "file-saver-es";

@Component({
  selector: "app-stock-value-parents-only",
  templateUrl: "./stock-value-parents-only.component.html",
  styleUrls: ["./stock-value-parents-only.component.scss"],
})
export class StockValueParentsOnlyComponent implements OnInit {
  baseUrl: string;
  loadingReport = false;
  hideDownloadButton = true;

  currentDate = new Date();
  date = new UntypedFormControl(new Date());
  serializedDate = new UntypedFormControl(new Date().toISOString());
  doDate: string;

  constructor(
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    private snackbar: SnackBarService
  ) {
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {}

  setDate(date: string) {
    this.doDate = date ? date : "";
  }

  downloadReport(): any {
    this.loadingReport = true;
    const parsedDate = moment(this.date.value).format("DD-MM-YY");
    return this.reportsService
      .getStockValueParentsOnly(
        moment(this.date.value).format("YYYY-MM-DD")
      )
      .subscribe(
        (result: any) => {
          if (result.type != "text/plain") {
            this.loadingReport = false;
            var blob = new Blob([result]);
            let file =
              "2.3 - StockValueParentsOnlyReport - " + parsedDate + ".xlsx";
            saveAs(blob, file);
          } else {
            this.snackbar.error("Something went wrong (");
          }
        },
        (error) => {
          console.error(error);
          this.loadingReport = false;
          this.snackbar.error("Something went wrong (");
        }
      );
  }
}
