import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-forecast-override',
  templateUrl: './forecast-override.component.html',
  styleUrls: ['./forecast-override.component.css']
})
export class ForecastOverrideComponent implements OnInit {

  forecastOverride: number = 0;

  constructor(public dialogRef: MatDialogRef<ForecastOverrideComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  passValue(): void {
    this.dialogRef.close({value: this.forecastOverride});
  }

}
