<mat-card>
  <!-- <mat-progress-bar *ngIf="loadingReport" mode="indeterminate"></mat-progress-bar> -->

  <mat-card-header>
    <mat-card-title>Total Bed Sales By Category Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>Time range: {{ period[0] | date: "dd/MM/yy" }} -
      {{ period[1] | date: "dd/MM/yy" }}
    </mat-card-subtitle>

    <mat-card-subtitle>
      Sales of bed range by channel for the past 4 weeks
    </mat-card-subtitle>
  </mat-card-header>

  <div class="row">
    <!-- <div class="col">
            <mat-form-field appearance="fill">
                <mat-label>Report date</mat-label>
                <input matInput [matDatepicker]="picker" [value]="date.value" (dateChange)="ChangeDate($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div> -->

    <div class="col">
      <button mat-raised-button color="primary" (click)="downloadFile()" [disabled]="loadingReport" style="float: left">
        Download Excel
      </button>
    </div>

    <div class="col">
      <button mat-raised-button color="primary" (click)="getBedWeeklySalesByCategory()" [disabled]="loadingReport"
        style="float: right">
        Generate Report
      </button>
    </div>
  </div>
</mat-card>
<br />

<div [hidden]="!reportItems">
  <mat-card>
    <div class="row">
      <div [hidden]="!reportItems">
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="sku">
              <th mat-header-cell *matHeaderCellDef>SKU</th>
              <td mat-cell *matCellDef="let item">{{ item.sku }}</td>
            </ng-container>

            <ng-container matColumnDef="itemDescription">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let item">
                {{ item.itemDescription }}
              </td>
            </ng-container>

            <ng-container matColumnDef="stockLvl">
              <th mat-header-cell *matHeaderCellDef>Stock Level</th>
              <td mat-cell *matCellDef="let item">{{ item.stockLvl }}</td>
            </ng-container>

            <!--Week 1-->
            <ng-container matColumnDef="week1Total">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[2].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week1Total }}</td>
            </ng-container>

            <ng-container matColumnDef="week1Amazon">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[3].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week1Amazon }}</td>
            </ng-container>

            <ng-container matColumnDef="week1Ebay">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[4].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week1Ebay }}</td>
            </ng-container>

            <ng-container matColumnDef="week1WayFair">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[5].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week1WayFair }}</td>
            </ng-container>

            <ng-container matColumnDef="week1Other">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[6].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week1Other }}</td>
            </ng-container>

            <ng-container matColumnDef="columnsToDisplay1">
              <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="2">
                First group
              </th>
            </ng-container>

            <!--Week 2-->
            <ng-container matColumnDef="week2Total">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[7].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week2Total }}</td>
            </ng-container>

            <ng-container matColumnDef="week2Amazon">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[8].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week2Amazon }}</td>
            </ng-container>

            <ng-container matColumnDef="week2Ebay">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[9].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week2Ebay }}</td>
            </ng-container>

            <ng-container matColumnDef="week2WayFair">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[10].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week2WayFair }}</td>
            </ng-container>

            <ng-container matColumnDef="week2Other">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[11].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week2Other }}</td>
            </ng-container>

            <!--Week 3-->
            <ng-container matColumnDef="week3Total">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[12].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week3Total }}</td>
            </ng-container>

            <ng-container matColumnDef="week3Amazon">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[13].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week3Amazon }}</td>
            </ng-container>

            <ng-container matColumnDef="week3Ebay">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[14].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week3Ebay }}</td>
            </ng-container>

            <ng-container matColumnDef="week3WayFair">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[15].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week3WayFair }}</td>
            </ng-container>

            <ng-container matColumnDef="week3Other">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[16].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week3Other }}</td>
            </ng-container>

            <!-- Week 4-->
            <ng-container matColumnDef="week4Total">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[17].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week4Total }}</td>
            </ng-container>

            <ng-container matColumnDef="week4Amazon">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[18].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week4Amazon }}</td>
            </ng-container>

            <ng-container matColumnDef="week4Ebay">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[19].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week4Ebay }}</td>
            </ng-container>

            <ng-container matColumnDef="week4WayFair">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[20].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week4WayFair }}</td>
            </ng-container>

            <ng-container matColumnDef="week4Other">
              <th mat-header-cell *matHeaderCellDef>
                {{ displayedColumns[21].displayedName }}
              </th>
              <td mat-cell *matCellDef="let item">{{ item.week4Other }}</td>
            </ng-container>

            <ng-container matColumnDef="cbmPerItem">
              <th mat-header-cell *matHeaderCellDef>CBM per item</th>
              <td mat-cell *matCellDef="let item">{{ item.cbmPerItem }}</td>
            </ng-container>

            <ng-container matColumnDef="cbmStock">
              <th mat-header-cell *matHeaderCellDef>CBM in Stock</th>
              <td mat-cell *matCellDef="let item">{{ item.cbmStock }}</td>
            </ng-container>

            <!-- Header row 1st group - SKU, Description -->
            <ng-container matColumnDef="header-row-first-group">
              <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="2"></th>
            </ng-container>

            <!-- Header row 2nd group - Week1 Total -->
            <ng-container matColumnDef="header-row-second-group">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="1">
                Week4
              </th>
            </ng-container>

            <!-- Header row 3rd group - Week1 -->
            <ng-container matColumnDef="header-row-third-group">
              <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="4">
                {{ columnsToDisplayDates[0].displayedName }}
              </th>
            </ng-container>

            <!-- Header row 4th group - Week2 -->
            <ng-container matColumnDef="header-row-fourth-group">
              <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="1">
                Week3
              </th>
            </ng-container>

            <!-- Header row 5 group - Week2 -->
            <ng-container matColumnDef="header-row-fifth-group">
              <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="4">
                {{ columnsToDisplayDates[1].displayedName }}
              </th>
            </ng-container>

            <!-- Header row 6 group - Week3 -->
            <ng-container matColumnDef="header-row-sixth-group">
              <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="1">
                Week2
              </th>
            </ng-container>

            <!-- Header row 7 group - Week3 -->
            <ng-container matColumnDef="header-row-seventh-group">
              <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="4">
                {{ columnsToDisplayDates[2].displayedName }}
              </th>
            </ng-container>

            <!-- Header row 8 group - Week4 -->
            <ng-container matColumnDef="header-row-eighth-group">
              <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="1">
                Week1
              </th>
            </ng-container>

            <!-- Header row 9 group - Week4 -->
            <ng-container matColumnDef="header-row-nineth-group">
              <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="4">
                {{ columnsToDisplayDates[3].displayedName }}
              </th>
            </ng-container>

            <!-- Header row 10 group - CBMs -->
            <ng-container matColumnDef="header-row-cbm-group">
              <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="4"></th>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="
                [
                  'header-row-first-group',
                  'header-row-second-group',
                  'header-row-third-group',
                  'header-row-fourth-group',
                  'header-row-fifth-group',
                  'header-row-sixth-group',
                  'header-row-seventh-group',
                  'header-row-eighth-group',
                  'header-row-nineth-group',
                  'header-row-cbm-group'
                ];
                sticky: true
              "></tr>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay" class="table-row"></tr>
          </table>
        </div>
      </div>
    </div>
  </mat-card>
</div>