import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from "file-saver-es";
import * as moment from "moment";
import { Supplier } from "src/app/shared/models/Supplier";

@Component({
  selector: "app-payment-report",
  templateUrl: "./payment-report.component.html",
  styleUrls: ["./payment-report.component.scss"],
})
export class PaymentReportComponent implements OnInit {
  constructor(
    private inventoryService: InventoryService,
    private snackBarService: SnackBarService,
    private reportsService: ReportsService
  ) {}
  minDate: Date = new Date();
  currentDate = new Date();
  loading: boolean = false;
  suppliers: Supplier[];
  selectedSupplier: Supplier;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  ngOnInit(): void {
    this.minDate.setMonth(this.minDate.getMonth() - 6);
    this.loading = true;
    this.inventoryService.getSuppliers().subscribe(
      (result) => {
        this.suppliers = result;
        this.suppliers.unshift({
          pkSupplierID: "all",
          supplierName: "All",
        });
        this.selectedSupplier = this.suppliers[0];
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.error(error);
      }
    );
  }

  downloadReport() {
    if (this.range.value["start"] == null || this.range.value["end"] == null) {
      this.snackBarService.warning("Pick the date range please.");
      return;
    }

    this.loading = true;

    var dstart = this.range.value["start"];
    var dend = this.range.value["end"];

    this.reportsService
      .GetPaymentReport(
        this.selectedSupplier.pkSupplierID,
        moment(dstart).format("YYYY-MM-DD"),
        moment(dend).format("YYYY-MM-DD")
      )
      .subscribe(
        (result) => {
          if (result.type != "text/plain") {
            this.loading = false;
            var blob = new Blob([result]);
            let file = "3.3 Payment Report" + ".xlsx";

            saveAs(blob, file);
          } else {
            alert("File not found in Blob!");
          }
        },
        (error) => {
          this.loading = false;
          if (error.status === 404) {
            this.snackBarService.neutral(
              "There is no result for current filter",
              ""
            );
          }
        }
      );
  }
}
