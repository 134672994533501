<div class="reports-wrapper" *ngIf="!isRouterOutlet; else routerOutletSection">
  <div class="selection-section">
    <div class="groups-for-selection">
      <div
        class="group"
        [ngClass]="{ active: selectedGroup === 'All' }"
        (click)="groupBySelection('All')"
      >
        All
      </div>
      <div
        *ngFor="let group of groupedReportsInitial"
        class="group"
        [ngClass]="{ active: selectedGroup === group.description }"
        (click)="groupBySelection(group.description)"
      >
        {{ group.description }}
      </div>
    </div>
  </div>

  <div class="reports-section">
    <div *ngFor="let grouppedReport of groupedReports" class="groupped-report">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="group-description">
              {{ grouppedReport.number }}.{{ grouppedReport.description }}</span
            >
          </mat-panel-title>
          <mat-panel-description> </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="reports-under-specific-group">
          <a
            *ngFor="let report of grouppedReport.reports"
            routerLink="{{ report.url }}"
            class="report"
            [matTooltip]="report.description"
            matTooltipPosition="right"
            (contextmenu)="onContextMenu($event, report, grouppedReport)"
          >
            {{ report.number.toString().split(".")[1] }} - {{ report.title }}
          </a>
        </div>
      </mat-expansion-panel>
    </div>
  </div>

  <div style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
	<ng-template matMenuContent let-item="item">
		<button mat-menu-item (click)="makeFavourite(item)">Make favourite</button>
    <button mat-menu-item (click)="removeFromFavourites(item)">Remove from favourites</button>
	</ng-template>

</mat-menu>
</div>

<ng-template #routerOutletSection>
  <div class="router-outlet">
    <a class="go-back" routerLink="/reports">
      <mat-icon>arrow_back</mat-icon>
      <span>Reports</span>
    </a>
  </div>
</ng-template>
