<mat-card>
    <mat-card-header>
      <mat-card-title>New Items Weekly Sales Report</mat-card-title>
      <!-- <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle> -->
      <mat-card-subtitle>Shows weekly sales (6 weeks) of New items on each channel</mat-card-subtitle>
    </mat-card-header>
    <div class="row">
      <div class="col">
        <button mat-raised-button color="primary" (click)="downloadReport()">
          Download report
        </button>
      </div>
    </div>
  </mat-card>
