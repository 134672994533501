import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AgGridAngular } from 'ag-grid-angular';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RepositoryService } from 'src/app/core/services/repository.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { HelperService } from "src/app/shared/services/helper.service";
import { POExtProps, Update_POExtProps, POL, Port } from '../../../../shared/models/extended-properties.models';
import { getHeaderTemplate } from 'src/app/shared/utils/ag-grid-helper';

@Component({
  selector: 'app-po-props-dialog',
  templateUrl: './po-props-dialog.component.html',
  styleUrls: ['./po-props-dialog.component.css'],
  providers: [DatePipe]
})
export class POPropsDialogComponent implements OnInit {
  currentPO: string;
  loadingData: boolean = false;

  rowData: POExtProps[];

  @ViewChild('grdExtProps') grdExtProps: AgGridAngular;

  // grid: Columns
  columnDefs = [
    { headerName: 'Property Name', field: 'propertyName', editable: true },
    { headerName: 'Property Value', field: 'propertyValue', editable: true }
  ];

  // grid: headers height
  public headerHeight;

  // grid: def header styles
  public defaultColDef;

  // grid: for updating and editing grid order items data
  public gridApi;

  //grid: selection
  public rowSelection = 'single';
  public selectedItem: POExtProps;

  emptyProps: number = 1;

  isUpdated: boolean = false;

  public POLs: POL[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { purchaseID: string },
    private helper: HelperService, private snackBar: SnackBarService, private repo: RepositoryService) {
    this.currentPO = data.purchaseID;

    this.headerHeight = 50;
    this.defaultColDef = {
      resizable: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      tooltipComponent: 'GridTooltipComponent',
      headerComponentParams: {
        template: getHeaderTemplate()
      },
    };

    this.getPOExtProps();

    this.getPOLs();
  }

  ngOnInit(): void {

  }

  getPOExtProps() {
    this.loadingData = true;

    this.repo.getList('api/procurement/purchaseOrderExtendedProps/' + this.currentPO).subscribe(result => {
      this.rowData = result.sort((a, b) => a.propertyName > b.propertyName ? 1 : -1);

      this.gridApi.setRowData(this.rowData);
      this.gridApi.redrawRows();

      this.gridApi.sizeColumnsToFit();

      this.gridApi.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));

      this.selectedItem = this.rowData[0];

      this.loadingData = false;
    }, error => {
      this.loadingData = false;

      this.snackBar.openSnackBar('Cannot get extended properties', 'Error');
    });
  }

  // add to grid empty row
  addExtendedProperty() {
    if (this.rowData.length < 50) {
      var emptyItem = new POExtProps()
      emptyItem.rowId = 0;
      emptyItem.propertyName = "PROPERTY " + this.emptyProps;
      emptyItem.propertyValue = "";
      emptyItem.purchaseId = this.currentPO;

      this.rowData.push(emptyItem);

      this.emptyProps = this.emptyProps + 1;

      var itemsToAdd = [];
      itemsToAdd.push(emptyItem);

      this.gridApi.applyTransaction({ add: itemsToAdd });
      this.gridApi.redrawRows();

      var nodes = this.gridApi.getRenderedNodes();
      var node = this.gridApi.getRowNode(nodes[nodes.length - 1].id);
      node.setSelected(true);

      this.selectedItem = this.rowData[this.rowData.length - 1];
    }
    else {
      this.snackBar.openSnackBar('MAX count of extended properties is 50.', 'Extended properties');
    }
  }

  // removes selected item
  removeExtendedProperty() {
    if (this.selectedItem != null) {
      var index = this.rowData.indexOf(this.selectedItem, 0);

      if (index != -1) {
        var itemsToDel = [];
        itemsToDel.push(this.selectedItem);

        this.gridApi.applyTransaction({ remove: itemsToDel });
        this.gridApi.redrawRows();

        this.rowData.splice(index, 1);
      }
    }
  }

  updateExtProps() {
    this.loadingData = true;

    var request = new Update_POExtProps();
    request.purchaseID = this.currentPO;
    request.items = this.rowData;

    this.repo.update('api/po/updatePOExtendedProperties/', request)
      .subscribe(result => {
        this.loadingData = false;

        this.snackBar.openSnackBar('Successfuly updated.', 'Extended properties');
        this.isUpdated = true;
      }, error => {
        this.loadingData = false;

        this.snackBar.openSnackBar('Something went wrong.', 'Error');
      });
  }

  getPOLs() {
    this.repo.getList('api/purchaseProps/POLs').subscribe(result => {
      this.POLs = result;
    },
      error => {
        this.snackBar.error('Cant load POLs');
      })
  }

  // checking: if propery name exists with this name 
  onCellValueChanged(event) {
    var data = event.data;

    var columnEdited = event.colDef.field;

    if (columnEdited != "propertyValue") {
      var equal_names = this.rowData.filter((item, index) => { return item.propertyName == event.newValue; });

      if (equal_names.length > 1) {
        this.snackBar.openSnackBar('Names of extended properties must be unique.', 'Extended properties');

        data.propertyName = event.oldValue;

        var itemstoUpdate = [];
        itemstoUpdate.push(data);

        this.gridApi.applyTransaction({ update: itemstoUpdate });

        return;
      }
    } else {
      if (data.propertyName == "GOODS READY DATE") {
        let isValid = this.helper.isValidDate(data.propertyValue);

        if (!isValid) {
          this.gridApi.undoCellEditing();

          data.propertyValue = event.oldValue;
          var itemtoUpdate = [];
          itemtoUpdate.push(data);

          this.gridApi.applyTransaction({ update: itemtoUpdate });

          this.snackBar.openSnackBar('Invalid date', '');
          return;
        }

        if (isValid && event.newValue !== event.oldValue) {

          let propsToUpdate = [];

          var shippingDate = this.rowData.filter((item, index) => {
            return item.propertyName == "SHIPPING DATE";
          })[0];

          var dockingDate = this.rowData.filter((item, index) => {
            return item.propertyName == "DOCKING DATE";
          })[0];

          var expectedDeliverySlot = this.rowData.filter((item, index) => {
            return item.propertyName == "EXPECTED DELIVERY SLOT";
          })[0];

          var polProp = this.rowData.filter((item, index) => {
            return item.propertyName == "POL";
          })[0];

          let pol = polProp != null ? this.POLs.find(x => x.name === polProp.propertyValue) : null;

          let transitTime = pol?.transitTime ?? 35;

          if (shippingDate != null) {
            shippingDate.propertyValue = this.helper.generateShippingDate(this.helper.asDate(event.newValue));
            propsToUpdate.push(shippingDate);
          }

          if (dockingDate != null) {
            dockingDate.propertyValue = this.helper.generateDockingDate(this.helper.asDate(shippingDate.propertyValue), transitTime);
            propsToUpdate.push(dockingDate);
          }

          if (expectedDeliverySlot != null) {
            expectedDeliverySlot.propertyValue = this.helper.generateExpectedDeliverySlot(this.helper.asDate(dockingDate.propertyValue), 21);
            propsToUpdate.push(expectedDeliverySlot);
          }

          if (propsToUpdate != null && propsToUpdate.length > 0) {
            this.gridApi.applyTransaction({ update: propsToUpdate });
            this.gridApi.redrawRows();
          }
        }
      }
    }
  }

  onSelectionChanged(params) {
    let selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow) {
      this.selectedItem = selectedRow[0];
    }
  }

  // grid init
  onGridReady(params) {
    this.gridApi = params.api;

    this.gridApi.sizeColumnsToFit();
  }
}
