import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { PurchaseService } from 'src/app/shared/services/purchase.service';
import { UserService } from 'src/app/shared/services/user.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';


@Component({
  selector: 'app-select-recepient',
  templateUrl: './select-recepient.component.html',
  styleUrls: ['./select-recepient.component.css']
})
export class SelectRecepientComponent implements OnInit {
  recepients: string;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  recepientsArr: string[] = [];

  constructor(public dialogRef: MatDialogRef<SelectRecepientComponent>,
    private purchaseService: PurchaseService,
    public currentUserService: UserService,
    private snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      if (!this.isEmailValid(value)) {
        this.snackBar.warning('Please add valid email');
        return;
      }
      this.recepientsArr.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(r: string): void {
    const index = this.recepientsArr.indexOf(r);

    if (index >= 0) {
      this.recepientsArr.splice(index, 1);
    }
  }


  sendToMe(): void {
    this.send([this.currentUserService.currentUserConfig.email]);
  }

  sendToRecepients(): void {
    this.send(this.recepientsArr);
  }

  sendToMeAndRecepients(): void {
    this.recepientsArr.push(this.currentUserService.currentUserConfig.email);
    this.send(this.recepientsArr);
  }

  send(recepients: string[]): void {
    if (recepients.length < 1) return;
    
    this.purchaseService
      .sendPurchaseWithSpecSheetsToEmail(this.data.purchaseId, recepients)
      .subscribe((result) => {
        this.snackBar.neutral(
          "Purchase with sheets will be sent in few minutes",
          ""
        );
        this.dialogRef.close();
      });
  }


  cancel(): void {
    this.dialogRef.close();
  }

   isEmailValid(email: string) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) return true;
    return false;
}

}
