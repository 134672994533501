<mat-grid-list cols="3" rowHeight="65px">
  <mat-grid-tile>
    <div class="col">
      <mat-form-field style="width: 78%">
        <mat-label>Search</mat-label>
        <input
          #searchInput
          matInput
          type="text"
          [(ngModel)]="searchQuery"
          (keyup)="onFilterTextBoxChanged(searchInput.value)"
          [disabled]="!showQuickFilter"
        />
        <button
          mat-button
          *ngIf="searchQuery"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSearchFilter()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field style="width: 78%">
        <mat-label>Supplier:</mat-label>
        <mat-select
          [(value)]="selectedSupplier"
          (selectionChange)="selectedSupplierChange($event.value)"
          [disabled]="loadingData || !showQuickFilter"
        >
          <mat-option *ngFor="let supp of suppliers" [value]="supp">
            {{ supp.supplierName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field style="width: 78%">
        <mat-label>Category:</mat-label>
        <mat-select
          [(value)]="selectedCategory"
          (selectionChange)="selectedCategoryChange($event.value)"
          [disabled]="loadingData || !showQuickFilter"
        >
          <mat-option *ngFor="let cat of categories" [value]="cat">
            {{ cat.categoryName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-grid-tile>
</mat-grid-list>

<div class="po-list-buttons-section">
  <div class="statuses">
    <mat-checkbox
      class="status-margin"
      [(ngModel)]="statusAll"
      (ngModelChange)="getAllPOs()"
      >All</mat-checkbox
    >
    <mat-checkbox
      class="status-margin"
      [(ngModel)]="statusPending"
      (ngModelChange)="getPendingPOs()"
      >PENDING
    </mat-checkbox>
    <mat-checkbox
      class="status-margin"
      [(ngModel)]="statusOpen"
      (ngModelChange)="getOpenPOs()"
      >OPEN
    </mat-checkbox>
    <mat-checkbox
      class="status-margin"
      [(ngModel)]="statusDelivered"
      (ngModelChange)="getDeliveredPOs()"
      >DELIVERED
    </mat-checkbox>
  </div>

  <div class="another-buttons">
    <button
      mat-raised-button
      color="primary"
      [disabled]="selectedItem == null || loadingData"
      style="margin-right: 25px"
      (click)="openExtPropsDialog()"
    >
      Extended Properties
    </button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="
        selectedItem == null || loadingData || selectedItem.status != 'OPEN'
      "
      style="margin-right: 25px"
      (click)="deliverAllItems()"
    >
      <mat-icon>check_circle</mat-icon>
      <span style="margin-left: 10px">Deliver All</span>
    </button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="selectedItem == null || loadingData"
      style="margin-right: 25px"
      (click)="editPO()"
    >
      <mat-icon>edit</mat-icon>
      <span style="margin-left: 10px">Edit PO</span>
    </button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="
        selectedItem == null ||
        loadingData ||
        selectedItem.status === 'DELIVERED'
      "
      style="margin-right: 25px"
      (click)="removePurchaseOrder(selectedItem)"
    >
      <mat-icon>edit</mat-icon>
      <span style="margin-left: 10px">Delete PO</span>
    </button>
  </div>
</div>

<div class="grid-wrapper">
  <ag-grid-angular
    id="grdOrderItems"
    style="width: 100%; height: 520px"
    class="ag-theme-balham"
    [frameworkComponents]="frameworkComponents"
    [headerHeight]="headerHeight"
    [rowSelection]="rowSelection"
    [defaultColDef]="defaultColDef"
    (selectionChanged)="onSelectionChanged($event)"
    (gridReady)="onGridReady($event)"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    (rowDoubleClicked)="onRowDoubleClick($event)"
    (firstDataRendered)="dataIsRendered()"
  >
  </ag-grid-angular>
</div>
