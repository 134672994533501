<mat-card>
    <!-- <mat-progress-bar *ngIf="loadingReport" mode="indeterminate"></mat-progress-bar> -->
    <mat-card-header>
        <mat-card-title>Sales by Channel for SKU Report</mat-card-title>
        <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
        <mat-card-subtitle>Sales by channel were you can choose what dates to choose sales from</mat-card-subtitle>
    </mat-card-header>
    <div class="row">
        <mat-form-field appearance="fill">
            <mat-label>Enter a date range: </mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>

        <mat-form-field class="search-field" style="margin-top: 14px; margin-left: 20px;">
            <input matInput placeholder="Search" aria-label="State" [matAutocomplete]="auto"
                [formControl]="searchItemsCtrl" [(ngModel)]="searchItemField">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedFiltereditemChange($event.option);">
                <mat-option *ngIf="loadingData" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!loadingData">
                    <mat-option *ngFor="let item of filteredItems" [value]="item.sku">
                        <span><b>{{item.sku}}</b> ({{item.title}})</span>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>


        <div class="col">
            <button mat-raised-button color="primary" (click)="downloadFile()" [disabled]="loadingReport"
                style="float: right">
                Download report
            </button>
        </div>
    </div>
</mat-card>
<br />