
import { DatePipe } from "@angular/common";
import { Component, ViewEncapsulation, ViewChildren, ChangeDetectionStrategy, AfterViewInit } from "@angular/core";
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, MomentDateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { ICellEditorAngularComp } from "ag-grid-angular";
import * as moment from 'moment';
import { parseDateTime, parseDateTimeSimple } from './../../../shared/utils/functions';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'date-editor-cell',
  templateUrl: './date-picker-calendar.component.html',
  styleUrls: ['./date-picker-calendar.component.css'],
  providers: [
    // `MomentDateTimeAdapter` can be automatically provided by importing
    // `OwlMomentDateTimeModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },

    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    DatePipe
  ]
})
export class DatePickerCalendarComponent implements ICellEditorAngularComp {

  constructor(public datepipe: DatePipe) { }

  private params: any;
  public dateValue: any;
  public selected: boolean = false;
  @ViewChildren('myInput') vc;
  public selectedDate: any = new Date();
  agInit(params: any): void {
    this.params = params;

    this.dateValue = parseDateTime(this.params.value);
  }

  getValue(): any {
    if (!this.selectedDate) {
      return null;
    }
    if (moment(this.selectedDate).isValid()) {
      return moment(this.selectedDate).format('DD/MM/YY');
    }

    return this.selectedDate;
  }

  isPopup(): boolean {
    return true;
  }


  set() {
    this.selected = true;
    this.params.api.stopEditing(false);
  }
  reset() {
    this.selected = true
    this.selectedDate = null;
    this.params.api.stopEditing(false);
  }

  cancel() {
    this.selected = true;
    this.params.api.stopEditing(true);
  }

  onClick(date: any) {
    this.selectedDate = date.toDate();
  }

}
