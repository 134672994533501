import { Component, OnInit, Inject } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarVerticalPosition,
  MatSnackBarHorizontalPosition,
} from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { HelperService } from "src/app/shared/services/helper.service";
import { WeeklySale } from "../../../shared/models/WeeklySale";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from 'file-saver-es';
import { HttpResponse } from "@angular/common/http";
@Component({
  selector: "app-weekly-sales",
  templateUrl: "./weekly-sales.component.html",
  styleUrls: ["./weekly-sales.component.scss"],
})
export class WeeklySalesComponent implements OnInit {
  loadingReport = false;
  baseUrl: string;
  reportItems: WeeklySale[];
  hideDownloadButton = true;
  dataSource = new MatTableDataSource<WeeklySale>();
  period: [Date, Date];

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  displayedColumns: string[] = [
    "sku",
    "itemTitle",
    "stockLevel",
    "week43",
    "week44",
    "week45",
    "week46",
    "week47",
    "week48",
    "week49",
    "week50",
    "week51",
    "week52",
    "averageSale",
    "categoryName",
  ];

  currentDate = new Date();

  constructor(
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    private _snackBar: MatSnackBar,
    private helper: HelperService
  ) {
    this.baseUrl = baseUrl;
    this.period = this.helper.GetStartEndOfPeriodByWeekStart(2, 52 * 7);
  }

  ngOnInit(): void { }

  downloadReportNew() {
    this.reportsService
      .getSalesByWeekReport()
      .subscribe((response: HttpResponse<Blob>) => {
        this.helper.saveFile(response);
      });
  }

  getWeeklySales(): void {
    this.loadingReport = true;

    this.reportsService.getWeeklySales().subscribe(
      (result) => {
        this.reportItems = result;
        this.dataSource = new MatTableDataSource<WeeklySale>(this.reportItems);
        this.loadingReport = false;
        this.hideDownloadButton = false;
      },
      (error) => {
        console.error(error);
        this.loadingReport = false;
        this.openSnackBar("Something went wrong (", "");
      }
    );
  }

  downloadReport() {
    this.loadingReport = true;
    return this.reportsService.getWeeklySalesExcel().subscribe(
      (result: any) => {
        if (result.type != "text/plain") {
          this.loadingReport = false;
          let filename = result.headers
            .get("content-disposition")
            .split(";")[1]
            .split("=")[1]
            .replace(/\"/g, "");
          var blob = new Blob([result.body]);
          saveAs(blob, filename);
        } else {
          alert("File not found in Blob!");
        }
      },
      (error) => {
        console.error(error);
        this.loadingReport = false;
        this.openSnackBar("Somesthing went wrong (", "");
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
