<mat-grid-list cols="4" rowHeight="70px">

    <mat-grid-tile>
        <div class="col">
            <mat-form-field class="selectPO">
                <mat-label>Purchase Orders:</mat-label>

                <input type="text" placeholder="Search item" [disabled]="loadingData" matInput [formControl]="itemsCtrl"
                    [matAutocomplete]="auto" [(ngModel)]="searchItemField">

                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                    (optionSelected)="selectedPOChange($event.option.value); searchItemField = '';">
                    <mat-option *ngFor="let option of filteredPOs | async" [value]="option.externalInvoiceNumber">
                        {{ option.externalInvoiceNumber }} - {{ option.supplierName }}
                    </mat-option>
                </mat-autocomplete>

                <button mat-button mat-icon-button matSuffix (click)="clearSearchField()" *ngIf="searchItemField">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </mat-grid-tile>
    <mat-grid-tile>
        <div class="col">
            <mat-form-field class="poNumber">
                <input matInput placeholder="PO #" [disabled]="true" [(ngModel)]="currentPONumber">
            </mat-form-field>
        </div>
    </mat-grid-tile>

    <mat-grid-tile>
        <div class="col">
            <mat-form-field class="profitValue">
                <input matInput placeholder="Profit Value" [disabled]="true" [(ngModel)]="profitValue">
            </mat-form-field>
        </div>
    </mat-grid-tile>

    <mat-grid-tile>
        <div class="col">
            <mat-form-field class="freightUSD">
                <input matInput placeholder="Freight USD" [(ngModel)]="freightUSD">
            </mat-form-field>
        </div>
    </mat-grid-tile>

    <mat-grid-tile>
        <div class="col">
            <mat-form-field class="goodsValueUSD">
                <input matInput placeholder="Goods Value USD" [disabled]="true" [(ngModel)]="goodsValueUSD">
            </mat-form-field>
        </div>
    </mat-grid-tile>

    <mat-grid-tile>
        <div class="col">
            <mat-form-field class="profitPercent">
                <input matInput placeholder="Profit" [disabled]="true" [(ngModel)]="profitPercent">
                <span matSuffix> %</span>
            </mat-form-field>
        </div>
    </mat-grid-tile>

    <mat-grid-tile>
        <div class="col">
            <mat-form-field class="freightCost">
                <input matInput placeholder="Freight" [(ngModel)]="freightCost">
            </mat-form-field>
        </div>
    </mat-grid-tile>

    <mat-grid-tile>
        <div class="col">
            <mat-form-field class="goodsValue">
                <input matInput placeholder="Goods Value (GBP)" [disabled]="true" [(ngModel)]="goodsValue">
            </mat-form-field>
        </div>
    </mat-grid-tile>

    <mat-grid-tile>
        <div class="col">
            <mat-form-field class="totalCBM">
                <input matInput placeholder="Total CBM" [disabled]="true" [(ngModel)]="totalCBM">
            </mat-form-field>
        </div>
    </mat-grid-tile>

    <mat-grid-tile>
        <div class="col">
            <mat-form-field class="dutyCost">
                <input matInput placeholder="Duty cost" [(ngModel)]="dutyCost">
            </mat-form-field>
        </div>
    </mat-grid-tile>

    <mat-grid-tile>
        <div class="col">
            <mat-form-field class="exchangeRate">
                <input matInput placeholder="Exchange Rate" [(ngModel)]="exchangeRate">
            </mat-form-field>
        </div>
    </mat-grid-tile>

    <mat-grid-tile>
        <div class="col">
            <button mat-raised-button color="primary" (click)="recalculatePO()" [disabled]="loadingData">
                Calculate
            </button>
        </div>
    </mat-grid-tile>
</mat-grid-list>

<div class="grid-wrapper">
</div>
<ag-grid-angular id="grdOrderItems" class="ag-theme-balham ag-grid-table"
    [pinnedBottomRowData]="pinnedBottomRowData" [frameworkComponents]="frameworkComponents"
    [headerHeight]="headerHeight" [defaultColDef]="defaultColDef" (gridReady)="onGridReady($event)"
    [rowData]="rowData" [columnDefs]="columnDefs">
</ag-grid-angular>