import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { NewPurchaseOrder } from '../../generate-po/generate-po.component';

@Component({
  selector: 'app-po-confirm-add',
  templateUrl: './po-confirm-add.component.html',
  styleUrls: ['./po-confirm-add.component.css']
})
export class PoConfirmAddComponent implements OnInit {
  http: HttpClient;
  baseUrl: any;
  newPurchaseOrder: NewPurchaseOrder;

  itemDataSource = new MatTableDataSource<any>();
  itemDisplayedColumns: string[] = ['sku', 'title', 'qty', 'cbm', 'totalCbm'];
  itemSelection = new SelectionModel<any>(true, []);

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(public dialogRef: MatDialogRef<PoConfirmAddComponent>, http: HttpClient,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, @Inject('BASE_URL') baseUrl: string,
    private _snackBar: MatSnackBar) { this.http = http; this.baseUrl = baseUrl; }

  ngOnInit(): void {
    this.newPurchaseOrder = this.data.purchaseOrder;
    this.itemDataSource = new MatTableDataSource<any>(this.newPurchaseOrder.items);
  }

  createPurchaseOrder() {
    this.dialogRef.close({value: 'confirmed'});
  }

  

}
