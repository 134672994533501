<mat-card>
    <mat-card-header>
        <mat-card-title>Amazon Sales Dates Set Report</mat-card-title>
        <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
        <mat-card-subtitle>
            Shows sales for Amazon items just for Amazon Europe
        </mat-card-subtitle>
    </mat-card-header>

    <div class="col">
        <mat-form-field appearance="fill">
            <mat-label>Enter a date range: </mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
    </div>
    <div class="col">
        <button mat-raised-button color="primary" (click)="downloadReport()" [disabled]="loading" class="download">
            Download Report
        </button>
    </div>
</mat-card>