<mat-card>
  <mat-card-header> </mat-card-header>

  <mat-card-content>
    <div class="table-container">
      <table
        mat-table
        *ngIf="dataSource"
        [dataSource]="dataSource"
        class="mat-elevation-z0"
      >
        <ng-container
          *ngFor="let column of columns"
          [matColumnDef]="column.value"
        >
          <mat-header-cell *matHeaderCellDef>
            {{ column.name }}
          </mat-header-cell>
          <mat-cell *matCellDef="let item"> {{ item[column.value] }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="selection.toggle(row)"
          [ngClass]="{
            hovered: row.hovered,
            highlighted: selection.isSelected(row)
          }"
          (mouseover)="row.hovered = true"
          (mouseout)="row.hovered = false"
        ></mat-row>
      </table>
    </div>

    <div class="row">
      <form>
        <mat-form-field appearance="outline">
          <mat-label>Reports</mat-label>
          <mat-select [(value)]="selectedReports" multiple>
            <mat-option *ngFor="let r of reports" [value]="r"
              >{{ r.number }} - {{ r.title }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Schedules</mat-label>
          <mat-select [(ngModel)]="selectedSchedule" name="schedule">
            <mat-option *ngFor="let s of schedules" [value]="s.id">
              {{ s.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Recepients</mat-label>
          <mat-select
            [(value)]="selectedRecepients"
            multiple
            (click)="getRecepients()"
          >
            <mat-option *ngFor="let r of recepients" [value]="r">{{
              r.userName
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </mat-card-content>

  <mat-card-actions>
    <button
      mat-raised-button
      color="primary"
      (click)="addTask()"
      [disabled]="loading"
    >
      Add New
    </button>
    <button
      *ngIf="selection.selected[0]"
      mat-raised-button
      color="primary"
      (click)="removeTask()"
      [disabled]="loading"
    >
      Remove
    </button>

    <button
      *ngIf="selection.selected[0]"
      mat-raised-button
      color="primary"
      (click)="sendNow(selection.selected[0].id)"
      [disabled]="loading"
    >
      Send Now
    </button>
  </mat-card-actions>
</mat-card>
