<h2 mat-dialog-title>All Price Rules</h2>
<mat-dialog-content class="content">


    
    <div *ngIf="dataSource.data.length && dataSource.data.length > 0">
        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2" [hidden]="!dataSource.data">

            <ng-container matColumnDef="sku">
                <mat-header-cell *matHeaderCellDef mat-sort-header> SKU </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.sku}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="source">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Source </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.source}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="subSource">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Subsource </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.subsource}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tag">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Tag </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.tag}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="amountToChange">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Value </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.amountToChange}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Created Date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.createdDate | date: 'dd/MM/yyyy'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="durationInDays">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Duration In Days </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.durationInDays}} </mat-cell>
            </ng-container>
            

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)"  (click)="setSelectedRow(row)"
                [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
        </mat-table>
    </div>

    

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
    <button mat-raised-button color="primary" *ngIf="selectedRule"  (click)="removeRule()">Remove rule</button>
</mat-dialog-actions>
