<mat-card>
  <mat-card-header>
    <mat-card-title>Import Payment Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>USD payment per week</mat-card-subtitle>
  </mat-card-header>
  <div class="row">
    <div class="col">
      <button mat-raised-button color="primary" (click)="downloadFile()" [disabled]="loadingReport">
        Download report
      </button>
    </div>
  </div>
</mat-card>