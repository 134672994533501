<div class="dialog-wrapper">
  <!-- <div *ngIf="loadingData" style="align-items: center; z-index: 100">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div> -->

  <div class="grid-wrapper">
    <ag-grid-angular
      style="width: 100%; height: 240px"
      id="POLsGrid"
      class="ag-theme-balham"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [getRowHeight]="getRowHeight"
      [rowSelection]="rowSelection"
      (selectionChanged)="onSelectionChanged($event)"
      (cellValueChanged)="onCellValueChanged($event)"
      [headerHeight]="headerHeight"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  </div>

  <mat-card>
    <button
      mat-raised-button
      color="primary"
      *ngIf="selectedItem"
      (click)="removeSelectedPOL()"
      style="float: right"
    >
      Remove Port
    </button>

    <form
      [formGroup]="polForm"
      autocomplete="off"
      novalidate
      (ngSubmit)="addPOL(polForm.value)"
    >
      <mat-form-field>
        <input
          matInput
          type="text"
          placeholder="Name"
          formControlName="name"
          id="name"
        />
        <mat-error *ngIf="hasError('name', 'required')"
          >Name is required</mat-error
        >
        <mat-error *ngIf="hasError('name', 'maxlength')"
          >You have more than 60 characters</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          type="number"
          placeholder="Transit Time, days"
          formControlName="transitTime"
          id="transitTime"
        />
        <mat-error *ngIf="hasError('transitTime', 'required')"
          >Transit Time is required</mat-error
        >
        <mat-error *ngIf="hasError('transitTime', 'maxlength')"
          >You have more than 3 characters
        </mat-error>
      </mat-form-field>
      <mat-card-actions>
        <button mat-raised-button color="primary" [disabled]="!polForm.valid">
          Create
        </button>
        <button mat-raised-button mat-dialog-close>Close</button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
