import { Component, OnInit, Inject } from "@angular/core";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from "file-saver-es";
@Component({
  selector: "app-container-report",
  templateUrl: "./container-report.component.html",
  styleUrls: ["./container-report.component.css"],
})
export class ContainerReportComponent implements OnInit {
  loadingReport = false;
  baseUrl: string;
  currentDate = new Date();
  period: [Date, Date];

  constructor(
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    private _snackBar: SnackBarService
  ) {
    this.baseUrl = baseUrl;
    this.period = this.getDatesTodayAndWeekLater();
  }

  //Initializing and get suppliers
  ngOnInit(): void {
    this.loadingReport = false;
  }

  //Shows containers for a 7 day period from when the report is run
  getDatesTodayAndWeekLater(): [Date, Date] {
    let start = new Date();
    let end = new Date();
    end.setDate(start.getDate() + 6);
    return [start, end];
  }

  downloadContainerReport() {
    this.loadingReport = true;
    return this.reportsService.getContainerReport().subscribe(
      (result: any) => {
        if (result.type != "text/plain") {
          this.loadingReport = false;
          var blob = new Blob([result]);
          let file = "6.3 - Container Report.xlsx";

          saveAs(blob, file);
        } else {
          this._snackBar.error("File not found in Blob!");
          this.loadingReport = false;
        }
      },
      () => {
        this._snackBar.warning("Items not found. List is empty.");
        this.loadingReport = false;
      }
    );
  }
}
