<div *ngIf="loadingData" style="align-items: center; z-index: 100">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<mat-card>
  <div class="row">
    <div class="col">
      <button
        mat-raised-button
        color="primary"
        (click)="addShippingFee()"
        [disabled]="loadingData"
      >
        Add new shipping fee rule
      </button>
    </div>

    <div class="col">
      <button
        mat-raised-button
        color="primary"
        *ngIf="selectedItem"
        (click)="removeSelectedRule()"
      >
        Remove
      </button>
    </div>
  </div>
</mat-card>

<div class="grid-wrapper">
  <ag-grid-angular
    style="width: 100%; height: calc(70vh - 1px);"
    id="shippingFeesGrid"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [getRowHeight]="getRowHeight"
    [rowSelection]="rowSelection"
    (selectionChanged)="onSelectionChanged($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    [headerHeight]="headerHeight"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
  >
  </ag-grid-angular>
</div>
