import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "src/app/material/material.module";
import { QcPhotosComponent } from "./qc-photos.component";

@NgModule({
  declarations: [QcPhotosComponent],
  exports: [QcPhotosComponent],
  imports: [CommonModule, FormsModule, MaterialModule],
})
export class QcPhotosModule {}
