<h4> {{data.sku}} - Edit Extended Properties</h4>

<div *ngIf="loadingData; else elseBlock">
    <mat-spinner></mat-spinner>
</div>

<ng-template #elseBlock>
    <div class="dialog-container">

        <div class="property-container">
            <mat-grid-list cols="3" rowHeight="3:1">

                <div *ngFor="let prop of extProps">

                    <mat-grid-tile>
                        <p>{{prop.propertyName}}:</p>
                    </mat-grid-tile>
    
                    <mat-grid-tile>
                        <mat-form-field appearance="standard">
                            <input matInput type="text" [value]="prop.propertyValue" [(ngModel)]="prop.propertyValue">
                        </mat-form-field>
                    </mat-grid-tile>

                    <div *ngIf="prop.propertyName == 'Production Time' || prop.propertyName == 'Shipping Time'; else elseEmptyTile">
                        <mat-grid-tile>
                            <mat-slide-toggle [(ngModel)]="prop.applicableToOther">Set to all supplier`s items</mat-slide-toggle>
                        </mat-grid-tile>
                    </div>

                    <ng-template #elseEmptyTile>
                        <mat-grid-tile>

                        </mat-grid-tile>
                    </ng-template>

                </div>

                <mat-grid-tile [colspan]="3" [rowspan]="1">
                    <div mat-dialog-actions>
                        <button mat-button mat-dialog-close>Close</button>
                        <button mat-raised-button (click)="updateProperties()">Save</button>
                    </div>
                </mat-grid-tile>

            </mat-grid-list>

        </div>
    </div>

</ng-template>