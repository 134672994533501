<mat-card *ngIf="suppliers && suppliers.length > 0">
  <mat-card-header>
    <mat-card-title>Payment Report</mat-card-title>
    <mat-card-subtitle
      >Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle
    >
    <mat-card-subtitle
      >Shows containers that are not paid for a certain time
      period</mat-card-subtitle
    >
  </mat-card-header>

  <div class="row">
    <div class="col">
      <mat-form-field class="selectSupplier">
        <mat-label>Supplier:</mat-label>
        <mat-select [(value)]="selectedSupplier" [disabled]="loading">
          <mat-option *ngFor="let supplier of suppliers" [value]="supplier">
            {{ supplier.supplierName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col">
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range: </mat-label>
        <mat-date-range-input
          [formGroup]="range"
          [rangePicker]="picker"
          [min]="minDate"
        >
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
          />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
          >Invalid start date</mat-error
        >
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
          >Invalid end date</mat-error
        >
      </mat-form-field>
    </div>
    <div class="col">
      <button
        mat-raised-button
        color="primary"
        (click)="downloadReport()"
        [disabled]="loading"
        class="download"
      >
        Download Report
      </button>
    </div>
  </div>
</mat-card>
