<div class="view-container">
  <div class="title">
    <span>View title</span>
    <input type="text" [(ngModel)]="viewModel.title" />
  </div>

  <div class="columns">
    <div class="buttons">
      <span>Columns</span>
      <button class="add" (click)="addColumn()">Add</button>
      <button
        class="delete"
        [disabled]="!selectedColumnItem"
        (click)="deleteSelectedColumnRow()"
      >
        Delete
      </button>
    </div>
    <ag-grid-angular
      class="ag-theme-balham"
      (gridReady)="onColumnGridReady($event)"
      [rowData]="columnRowData"
      [columnDefs]="columnDefs"
      rowSelection="single"
      [gridOptions]="columnGridOptions"
      (selectionChanged)="onColumnSelectionChanged($event)"
      (cellValueChanged)="onColumnCellValueChanged($event)"
      rowDragManaged="true"
    >
    </ag-grid-angular>
  </div>
  <div class="filters">
    <div class="buttons">
      <span>Filters</span>
      <button class="add" (click)="addFilter()">Add</button>
      <button
        class="delete"
        [disabled]="!selectedFilterItem"
        (click)="deleteSelectedFilterRow()"
      >
        Delete
      </button>
    </div>
    <ag-grid-angular
      class="ag-theme-balham"
      (gridReady)="onFilterGridReady($event)"
      [rowData]="filterRowData"
      [columnDefs]="filterColumnDefs"
      rowSelection="single"
      [gridOptions]="filterGridOptions"
      (selectionChanged)="onFilterSelectionChanged($event)"
      (cellValueChanged)="onFilterCellValueChanged($event)"
      [stopEditingWhenGridLosesFocus]="true"
    >
    </ag-grid-angular>
  </div>
  <div class="buttons">
    <button class="save" (click)="save()">Save</button>
    <button class="cancel" (click)="cancel()">Cancel</button>
  </div>
</div>
