<mat-card>
    <mat-card-header>
        Update user password
    </mat-card-header>
    <form [formGroup]="updatepasswordForm" (submit)="updatePassword()" (reset)="closeUpdatePassword()">
        <mat-card-content>
            <table cellspacing="0">
                <tr>
                    <mat-form-field class="example-full-width">
                        <mat-label>New password</mat-label>
                        <input matInput [type]="hidePasswordInput ? 'password' : 'text'" formControlName="passwordInput"
                            required>
                        <button type="button" mat-icon-button matSuffix (click)="hidePasswordInput = !hidePasswordInput"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordInput">
                            <mat-icon>{{hidePasswordInput ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="updatepasswordForm.controls.passwordInput.hasError('required')">
                            Please enter password for new user
                        </mat-error>
                        <mat-error
                            *ngIf="updatepasswordForm.controls.passwordInput.hasError('minlength') && !updatepasswordForm.controls.passwordInput.hasError('required')">
                            Your password needs to be at least 8 characters long
                        </mat-error>
                    </mat-form-field>
                </tr>
                <tr>
                    <mat-form-field class="example-full-width">
                        <mat-label>Confirm new password</mat-label>
                        <input matInput [type]="hidePasswordConfirmationInput ? 'password' : 'text'"
                            formControlName="passwordConfirmationInput" [errorStateMatcher]="matcher" required>
                        <button type="button" mat-icon-button matSuffix
                            (click)="hidePasswordConfirmationInput = !hidePasswordConfirmationInput"
                            [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePasswordConfirmationInput">
                            <mat-icon>{{hidePasswordConfirmationInput ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error
                            *ngIf="updatepasswordForm.controls.passwordConfirmationInput.dirty && updatepasswordForm.hasError('notSame')">
                            Passwords do not match
                        </mat-error>
                    </mat-form-field>
                </tr>
            </table>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" type="submit" [disabled]="updatepasswordForm.invalid">Update password</button>
            <button mat-stroked-button type="reset">Close</button>
        </mat-card-actions>
    </form>
</mat-card>
