<div class="dialog-container">
  <div class="form-container" *ngIf="newSKU && newSKU.length !== 0">
    <form [formGroup]="form" (submit)="create(form.value)">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label> SKU
        </mat-label>
        <input matInput [(value)]="newSKU" formControlName="sku" required>
        <mat-error *ngIf="form.controls.sku.invalid">
          <span *ngIf="form.controls.sku.errors.required">This field is mandatory.</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="form-field" appearance="outline">
        <mat-label> Title
        </mat-label>
        <input matInput formControlName="title" type="title">
        <mat-error *ngIf="form.controls.title.touched && form.controls.title.invalid">
          <span *ngIf="form.controls.title.errors.required">This field is mandatory.</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Category:</mat-label>
        <mat-select [(value)]="selectedCategory" (selectionChange)="selectedCategoryChange($event.value)"
          formControlName="category" type="category">
          <mat-option *ngFor="let c of categories" [value]="c">
            {{ c.categoryName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit">Create</button>
      <button mat-raised-button mat-dialog-close color="secondary">Cancel</button>
    </form>
  </div>
</div>