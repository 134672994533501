<mat-card>
    <mat-card-header>
      <mat-card-title>Stock Value GBP - Summary</mat-card-title>
      <mat-card-subtitle
        >Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle
      >
      <mat-card-subtitle>
        Stock value new version with summary. Report from sep 2022 shows all summary
      </mat-card-subtitle>
    </mat-card-header>
  
    <div class="col">
        <mat-form-field color="accent" appearance="standard" class="kr-form-field">
            <mat-label>Report Date</mat-label>
            <input matInput [matDatepicker]="datePicker" [(ngModel)]="date"
                [ngModelOptions]="{standalone: true}">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col">
      <button
        mat-raised-button
        color="primary"
        (click)="downloadSummaryReport()"
        [disabled]="loading"
        class="download"
      >
        Send Report
      </button>
    </div>
  </mat-card>