<div class="form-wrapper">
    <form [formGroup]="shippingFeeForm" autocomplete="off" novalidate (ngSubmit)="createShippingFee(shippingFeeForm.value)" fxLayout="column wrap"
    fxLayoutAlign="center center" fxLayoutGap="10px">

    <mat-card-title>
        Add new shipping fee rule
    </mat-card-title>

<mat-card-content>

    <mat-form-field>
        <mat-label>Postal Service:</mat-label>
        <mat-select [(value)]="selectedPostalService">
            <mat-option *ngFor="let s of services" [value]="s">
                {{s.postalServiceName}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Channel:</mat-label>
        <mat-select [(value)]="selectedChannel">
            <mat-option *ngFor="let c of channels" [value]="c">
                {{c.title}}
            </mat-option>
        </mat-select>
    </mat-form-field>

  <mat-form-field>
      <input matInput type="number" placeholder="Base Cost, GBP" formControlName="baseCost" id="baseCost">
      <!-- <mat-hint align="end">GBP</mat-hint> -->
      <mat-error *ngIf="hasError('baseCost', 'required')">baseCost is required</mat-error>
      <mat-error *ngIf="hasError('baseCost', 'maxlength')">You have more than 60 characters</mat-error>
  </mat-form-field>

  <mat-form-field>
      <input matInput type="number" placeholder="Fuel Surcharge, %" formControlName="fuelSurcharge" id="fuelSurcharge">
      <!-- <mat-hint align="end">Percents</mat-hint> -->
      <mat-error *ngIf="hasError('fuelSurcharge', 'required')">fuelSurcharge is required</mat-error>
      <mat-error *ngIf="hasError('fuelSurcharge', 'maxlength')">You have more than 60 characters</mat-error>
  </mat-form-field>

  <mat-form-field>
      <input matInput type="number" placeholder="Extra Box Surcharge, GBP" formControlName="extraBoxSurcharge" id="extraBoxSurcharge">
      <!-- <mat-hint align="end">Additional Cost</mat-hint> -->
      <mat-error *ngIf="hasError('extraBoxSurcharge', 'required')">extraBoxSurcharge is required</mat-error>
      <mat-error *ngIf="hasError('extraBoxSurcharge', 'maxlength')">You have more than 60 characters</mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput type="number" placeholder="Surcharge per Kilogram, GBP" formControlName="surchargePerKilogram" id="surchargePerKilogram">
    <!-- <mat-hint align="end">Additional Cost</mat-hint> -->
    <mat-error *ngIf="hasError('surchargePerKilogram', 'required')">Surcharge Per Kilogram is required</mat-error>
    <mat-error *ngIf="hasError('surchargePerKilogram', 'maxlength')">You have more than 60 characters</mat-error>
</mat-form-field>

  <mat-form-field>
    <input matInput type="number" placeholder="Width, cm" formControlName="width" id="width">
    <!-- <mat-hint align="end">Additional Cost</mat-hint> -->
    <mat-error *ngIf="hasError('width', 'required')">width is required</mat-error>
    <mat-error *ngIf="hasError('width', 'maxlength')">You have more than 60 characters</mat-error>
</mat-form-field>

<mat-form-field>
    <input matInput type="number" placeholder="Height, cm" formControlName="height" id="height">
    <!-- <mat-hint align="end">Additional Cost</mat-hint> -->
    <mat-error *ngIf="hasError('height', 'required')">height is required</mat-error>
    <mat-error *ngIf="hasError('height', 'maxlength')">You have more than 60 characters</mat-error>
</mat-form-field>

<mat-form-field>
    <input matInput type="number" placeholder="Depth, cm" formControlName="depth" id="depth">
    <!-- <mat-hint align="end">Additional Cost</mat-hint> -->
    <mat-error *ngIf="hasError('depth', 'required')">depth is required</mat-error>
    <mat-error *ngIf="hasError('depth', 'maxlength')">You have more than 60 characters</mat-error>
</mat-form-field>

<mat-form-field>
    <input matInput type="number" placeholder="Min Weight, kg" formControlName="minWeight" id="minWeight">
    <!-- <mat-hint align="end">Additional Cost</mat-hint> -->
    <mat-error *ngIf="hasError('minWeight', 'required')">minWeight is required</mat-error>
    <mat-error *ngIf="hasError('minWeight', 'maxlength')">You have more than 60 characters</mat-error>
</mat-form-field>

<mat-form-field>
    <input matInput type="number" placeholder="Max Weight, kg" formControlName="maxWeight" id="maxWeight">
    <!-- <mat-hint align="end">Additional Cost</mat-hint> -->
    <mat-error *ngIf="hasError('maxWeight', 'required')">maxWeight is required</mat-error>
    <mat-error *ngIf="hasError('maxWeight', 'maxlength')">You have more than 60 characters</mat-error>
</mat-form-field>

<mat-form-field>
    <input matInput type="number" placeholder="PackagingWeight, kg" formControlName="packagingWeight" id="packagingWeight">
    <!-- <mat-hint align="end">Additional Cost</mat-hint> -->
    <mat-error *ngIf="hasError('packagingWeight', 'required')">packagingWeight is required</mat-error>
    <mat-error *ngIf="hasError('packagingWeight', 'maxlength')">You have more than 60 characters</mat-error>
</mat-form-field>

</mat-card-content>

<mat-card-actions>
  <button mat-raised-button color="primary" [disabled]="!shippingFeeForm.valid">Create</button>
  <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
</mat-card-actions>

</form>
</div>

