import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { saveAs } from "file-saver-es";
import * as moment from "moment";
import { ReportsService } from "../../../shared/services/reports.service";
@Component({
  selector: "app-bed-weekly-sales",
  templateUrl: "./sales-by-channel.component.html",
  styleUrls: ["./sales-by-channel.component.css"],
})
export class SalesByChannelComponent implements OnInit {
  loadingReport = false;
  baseUrl: string;
  hideDownloadButton = true;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  currentDate = new Date();
  //
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  constructor(
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    private _snackBar: MatSnackBar
  ) {
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {}

  downloadFile() {
    if (this.range.value["start"] == null || this.range.value["end"] == null) {
      this.openSnackBar("Pick the date range please.", "");
    } else {
      this.loadingReport = true;

      var dstart = this.range.value["start"];
      var dend = this.range.value["end"];
     
      return this.reportsService
        .getSalesByChannelEXCEL(moment(dstart).format("YYYY-MM-DD"), moment(dend).format("YYYY-MM-DD"))
        .subscribe(
          (result: any) => {
            if (result.type != "text/plain") {
              this.loadingReport = false;
              var blob = new Blob([result]);

              saveAs(blob, `4.1 Sales by channel ${moment(dstart).format("DD-MM-YY")} - ${moment(dend).format("DD-MM-YY")}.xlsx`);
            } else {
              this.openSnackBar("File not found in Blob!", "");
            }
          },
          (error) => {
            console.error(error);
            this.loadingReport = false;
            this.openSnackBar("ERROR", "");
          }
        );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
