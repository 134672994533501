<mat-card>
    <mat-card-content>
        <div class="row">
            <div class="table-container">
                <table mat-table *ngIf="dataSource" [dataSource]="dataSource" class="mat-elevation-z2">

                    <ng-container *ngFor="let column of columns" [matColumnDef]="column.value">
                        <mat-header-cell *matHeaderCellDef> {{column.name}} </mat-header-cell>
                        <mat-cell *matCellDef="let item"> {{item[column.value]}} </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"
                        [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>

                </table>
            </div>
        </div>

    </mat-card-content>

    <mat-card-actions>

        <div class="row">
            <form [formGroup]="addRecForm" (ngSubmit)="add()">

                <mat-form-field appearance="outline">
                    <input matInput type="text" placeholder="username" formControlName="userName" required>
                    <mat-error *ngIf="isFieldInvalid('userName')">
                        Please enter user name
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <input matInput type="email" placeholder="email" formControlName="email" required>
                    <mat-error *ngIf="isFieldInvalid('email')">
                        Please enter the email
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button color="primary" type="submit">Add New</button>
            </form>


        </div>

        <button *ngIf="selection.selected[0]" mat-raised-button color="primary" (click)="remove()" [disabled]="loading">
            Remove
        </button>

    </mat-card-actions>
</mat-card>