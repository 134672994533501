import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin, mergeMap, Observable, Subject, takeUntil, tap } from "rxjs";
import { UserConfig } from 'src/app/shared/models/user-config';
import { UserService } from "src/app/shared/services/user.service";
import { RegistrationUserModel } from '../../models/registration-user.model';
import { AuthService } from '../../services/auth.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { User } from "../../models/user.model";
import { SnackBarService } from "../../services/snack-bar.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  allowedTabsCtrl: UntypedFormControl;
  defaultTabsCtrl: UntypedFormControl;
  public users: RegistrationUserModel[];
  public userConfigs: UserConfig[];
  public tabs: any[] = [];
  public selectedUserAllowedTabs: any[];
  public selectedUserDefaultTabs: any[];
  public selectedUserDefaultTab: any;
  public selectedUser: UserConfig;
  public newUserEmail: string;
  private currentUser: User;
  private destroy$ = new Subject<void>();
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private notifier: SnackBarService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.authService.authenticatedUserSubject.pipe(
      tap((user: User) => {
        this.currentUser = user;
      }),
      takeUntil(this.destroy$),
    )
      .subscribe();
    this.tabs = this.router.config.find(r => r.path === "home")?.children?.map((tab: any) => {
      return { name: tab.data.componentName, path: tab.path };
    });
    this.allowedTabsCtrl = new UntypedFormControl(this.tabs);
    this.defaultTabsCtrl = new UntypedFormControl();
    this.getUserConfigs();
  }

  compare(c1: { path: string }, c2: { path: string }) {
    return c1 && c2 && c1.path === c2.path;
  }

  onSelectedUserChange(params): void {
    this.selectedUser = params.source._value[0];
    this.selectedUserAllowedTabs = this.tabs.filter(x => this.selectedUser.allowedTabs.includes(x.path));
    this.allowedTabsCtrl.setValue(this.selectedUserAllowedTabs);
    
    this.selectedUserDefaultTabs = this.selectedUserAllowedTabs;
    this.selectedUserDefaultTab = this.selectedUserDefaultTabs.filter(x => this.selectedUser.defaultTabs.includes(x.path));
    this.defaultTabsCtrl.setValue(this.selectedUserDefaultTab);
  }

  getUsers(): void {
    this.userService.getUsers().subscribe(users => {
      this.users = users;
    })
  }

  getUserConfigs(): void {
    forkJoin({
      users: this.authService.getAllUsers(),
      userConfigs: this.userService.getUserConfigs()
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ users, userConfigs }) => {
        this.userConfigs = userConfigs.filter(x => users.some(i => i.email === x.email));
      });
  }


  addUser(): void {
    if (this.newUserEmail.length < 5) return;
    const newUserConfig: UserConfig = {
      email: this.newUserEmail,
      allowedTabs: null,
      defaultTabs: null,
      favouriteReports: null,
      exportToExcelAllowed: false,
    };
    this.userService.addUser(newUserConfig).subscribe(() => {
      this.getUserConfigs();
    })
  }

  removeUser(): void {

    if (this.selectedUser?.email === this.currentUser?.email) {
      return this.notifier.error('You can`t remove yourself');
    }
    const dialogData = new ConfirmDialogModel(
      "Confirm User Remove",
      `Are you sure? This action can't be undone. User ${this.selectedUser.email} will be removed from the app (NOT FROM LINNWORKS)`
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().pipe(
      mergeMap((dialogResult: boolean) => {
        if (dialogResult) {
          return this.userService.removeUser(this.selectedUser.email);
        }
        return new Observable<Object>();
      }),
      tap(() => {
        this.getUserConfigs();
        this.selectedUser = null;
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  updateUser(): void {
    if (this.selectedUser.allowedTabs.length < 1 || this.selectedUser.email.length < 3) return;

    this.userService.updateUser(this.selectedUser.email, this.selectedUser).subscribe(() => {
      this.getUserConfigs();
    })
  }

  onSelectedUserAllowedTabsChange(params) {
    this.selectedUserDefaultTabs = params.value;
    this.selectedUser.allowedTabs = params.value.map(x => x.path);
  }

  onSelectedUserDefaultTabsChange(params) {
    this.selectedUser.defaultTabs = params.value.map(x => x.path);
  }

}
