import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ScheduleService } from "../schedule.service";
import { CronOptions } from 'ngx-cron-editor';
import cronstrue from 'cronstrue';
import { Schedule } from '../models/schedule';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';

@Component({
  selector: 'app-schedule-builder',
  templateUrl: './schedule-builder.component.html',
  styleUrls: ['./schedule-builder.component.css']
})
export class ScheduleBuilderComponent implements OnInit {

  public cronOptions: CronOptions = {
       
    defaultTime: "00:00:00",

    hideMinutesTab: true,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: false,
    hideYearlyTab: true,
    hideAdvancedTab: true,
    hideSpecificWeekDayTab : false,
    hideSpecificMonthWeekTab : false,

    use24HourTime: true,
    hideSeconds: false,

    cronFlavor: "standard", //standard or quartz


    formInputClass: '',
    formSelectClass: '',
    formRadioClass: '',
    formCheckboxClass: '',
 };

 cronForm: FormControl;
 cronDescription: string;

  constructor(
    private scheduleService: ScheduleService,
    private snackbar: SnackBarService
  ) { }

  ngOnInit(): void {
    this.cronForm = new FormControl('0 0 1/1 * *');
  }

  descript(cron) {
    return cronstrue.toString(cron);
  }

  addSchedule() {
    let newSchedule: Schedule = {
      cronSchedule: this.cronForm.value,
      description: this.descript(this.cronForm.value)
    }
    this.scheduleService.addSchedule(newSchedule).subscribe(result =>{
      this.snackbar.success('Successfully added');
    })
  }

}
