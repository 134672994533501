import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import * as moment from "moment";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { StockValuationItem } from "../../../shared/models/StockValuationItem";
import { saveAs } from 'file-saver-es';
@Component({
  selector: "app-stock-valuation",
  templateUrl: "./stock-valuation.component.html",
  styleUrls: ["./stock-valuation.component.css"],
})
export class StockValuationComponent implements OnInit {
  baseUrl: string;
  reportItems: StockValuationItem[];
  loadingReport = false;
  hideDownloadButton = true;
  dataSource = new MatTableDataSource<StockValuationItem>();

  displayedColumns: string[] = [
    "category",
    "sku",
    "itemTitle",
    "stockLevel",
    "purchasePrice",
    "totalValue",
  ];
  currentDate = new Date();
  date = new UntypedFormControl(new Date());
  serializedDate = new UntypedFormControl(new Date().toISOString());
  doDate: string;

  constructor(
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    private snackbar: SnackBarService
  ) {
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void { }

  setDate(date: string) {
    this.doDate = date ? date : "";
  }

  getStockValuationReport(): void {
    this.loadingReport = true;
    const reportDate = this.serializedDate.value;

    this.reportsService.getStockValuation(reportDate).subscribe(
      (result) => {
        this.reportItems = result;
        this.dataSource = new MatTableDataSource<StockValuationItem>(
          this.reportItems
        );
        this.loadingReport = false;
        this.hideDownloadButton = false;
      },
      (error) => {
        console.error(error);
        this.loadingReport = false;
        this.snackbar.error("Something went wrong (");
      }
    );
  }

  downloadReport(): any {
    this.loadingReport = true;
    const parsedDate = moment(this.date.value).format("DD-MM-YY");
    return this.reportsService
      .getStockValueExcel(moment(this.date.value).toISOString())
      .subscribe(
        (result: any) => {
          if (result.type != "text/plain") {
            this.loadingReport = false;
            var blob = new Blob([result]);
            let file = "2.1 - Stock Value - " + parsedDate + ".xlsx";
            saveAs(blob, file);
          } else {
            this.snackbar.error("Something went wrong (");
          }
        },
        (error) => {
          console.error(error);
          this.loadingReport = false;
          this.snackbar.error("Something went wrong (");
        }
      );
  }
}
