<div *ngIf="loadingData; else elseBlock">
    <mat-spinner></mat-spinner>
</div>

<ng-template #elseBlock>
    <h1 mat-dialog-title>Last 8 Weeks Sales</h1>
    <div mat-dialog-content>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!-- SKU Column -->
                <ng-container matColumnDef="sku">
                    <th mat-header-cell *matHeaderCellDef> SKU </th>
                    <td mat-cell *matCellDef="let item"> {{data.selectedItem.sku}} </td>
                </ng-container>

                <!-- W1 Column -->
                <ng-container matColumnDef="week1">
                    <th mat-header-cell *matHeaderCellDef> {{columnsWeeksNames[0]}} </th>
                    <td mat-cell *matCellDef="let item"> {{weekSales[0][0]}} </td>
                </ng-container>

                <!-- W1 Column -->
                <ng-container matColumnDef="week2">
                    <th mat-header-cell *matHeaderCellDef> {{columnsWeeksNames[1]}} </th>
                    <td mat-cell *matCellDef="let item"> {{weekSales[0][1]}} </td>
                </ng-container>

                <!-- W1 Column -->
                <ng-container matColumnDef="week3">
                    <th mat-header-cell *matHeaderCellDef> {{columnsWeeksNames[2]}} </th>
                    <td mat-cell *matCellDef="let item"> {{weekSales[0][2]}} </td>
                </ng-container>

                <!-- W1 Column -->
                <ng-container matColumnDef="week4">
                    <th mat-header-cell *matHeaderCellDef> {{columnsWeeksNames[3]}} </th>
                    <td mat-cell *matCellDef="let item"> {{weekSales[0][3]}} </td>
                </ng-container>

                <!-- W1 Column -->
                <ng-container matColumnDef="week5">
                    <th mat-header-cell *matHeaderCellDef> {{columnsWeeksNames[4]}} </th>
                    <td mat-cell *matCellDef="let item"> {{weekSales[0][4]}} </td>
                </ng-container>

                <!-- W1 Column -->
                <ng-container matColumnDef="week6">
                    <th mat-header-cell *matHeaderCellDef> {{columnsWeeksNames[5]}} </th>
                    <td mat-cell *matCellDef="let item"> {{weekSales[0][5]}} </td>
                </ng-container>

                <!-- W1 Column -->
                <ng-container matColumnDef="week7">
                    <th mat-header-cell *matHeaderCellDef> {{columnsWeeksNames[6]}} </th>
                    <td mat-cell *matCellDef="let item"> {{weekSales[0][6]}} </td>
                </ng-container>

                <!-- W1 Column -->
                <ng-container matColumnDef="week8">
                    <th mat-header-cell *matHeaderCellDef> {{columnsWeeksNames[7]}} </th>
                    <td mat-cell *matCellDef="let item"> {{weekSales[0][7]}} </td>
                </ng-container>

                <!-- Avg Sales Column -->
                <ng-container matColumnDef="avgSales">
                    <th mat-header-cell *matHeaderCellDef> Avg Sales </th>
                    <td mat-cell *matCellDef="let item"> {{data.selectedItem.details[0].weekInfo[0]}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
    </div>
</ng-template>