import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { saveAs } from "file-saver-es";
import * as moment from "moment";
import { SnackBarService } from "../../../core/services/snack-bar.service";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { FileService } from "../../../shared/services/file.service";

@Component({
  selector: "app-purchase-docs",
  templateUrl: "./purchase-docs.component.html",
  styleUrls: ["./purchase-docs.component.css"],
})
export class PurchaseDocsComponent implements OnChanges, OnInit {
  @ViewChild("inputFile") inputFile: ElementRef;

  @Input() purchaseId: string;
  @Input() purchaseNumber: string;
  @Input() hblExtendedProperty: string;
  selectedFiles: FileList;
  docsByCategories: any[];
  selected: any;
  category: string;
  isDocumentsChanged: boolean = false;

  categories = [
    "Invoice Packing List",
    "Purchase Invoice",
    "Bill Of Lading",
    "Customs Entry C88",
    "Forwarder Invoice",
    "Payment Proof",
    "Telex",
  ];

  constructor(
    private fileService: FileService,
    private snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PurchaseDocsComponent>
  ) {
    // this.getDocs();
    // this.getDocsV2();
  }

  ngOnChanges(): void {
    this.validateInitialProps();
    this.getDocsV2();
  }
  ngOnInit() {
    this.validateInitialProps();
    this.getDocsV2();
  }
  validateInitialProps() {
    if (!this.isEmpty(this.data)) {
      this.purchaseId = this.data.purchaseId;
      this.purchaseNumber = this.data?.purchaseNumber;
      this.hblExtendedProperty = this.data.hblExtendedProperty.propertyValue;
    }
  }
  isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }

  getDocs(): void {
    this.fileService
      .getPurchaseDocsById(this.purchaseId)
      .subscribe((result) => {
        this.docsByCategories = result;
      });
  }

  getDocsV2(): void {
    if (this.hblExtendedProperty) {
      this.fileService
        .getFileListForOrder(this.purchaseId, "HBL", this.hblExtendedProperty)
        .subscribe((result) => {
          this.docsByCategories = result;
        });
    }
  }

  addDoc(category: string): void {
    this.category = category.replace(/\s/g, "");
    this.inputFile.nativeElement.click();
  }

  uploadFiles(event) {
    this.selectedFiles = event.target.files;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(this.selectedFiles[i]);
    }
  }

  upload(file) {
    if (file.size > 52428800) {
      this.snackBar.warning("File size cannot be more than 50 MB");
      return;
    }
    const formData = new FormData();

    let timeStamp = moment().format("YY_MM_DD_HHmmssSSS");
    let fileExtension = file.name.substring(file.name.indexOf("."));
    let fileKey = `${this.purchaseNumber}_${this.category}_${timeStamp}${fileExtension}`;
    formData.append(fileKey, file);

    this.fileService
      .uploadPurchaseDoc(this.purchaseId, this.category, formData)
      .subscribe(() => {
        this.getDocs();
        this.isDocumentsChanged = true;
        this.snackBar.success(
          `${file.name.replace(/\s/g, "")} was added successfully`
        );
      });
  }

  downloadFile(fileInfo) {
    let category = this.docsByCategories.find((d) =>
      d.images.find((x) => x.name === fileInfo.name)
    );
    let key = `${this.purchaseId}/${category.categoryName.replace(/\s/g, "")}/${
      fileInfo.name
    }`;
    this.fileService
      .downloadPurchaseDoc(fileInfo.name)
      .subscribe((response: any) => {
        const responseBlob = new Blob([response], { type: response.type });
        saveAs(responseBlob, this.prettifyFileName(fileInfo.name));
      });
  }

  remove(key) {
    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fileService
          .removePurchaseDoc(this.purchaseId, key)
          .subscribe(() => {
            this.getDocs();
            this.snackBar.success("Successfully removed.");
            this.isDocumentsChanged = true;
          });
      }
    });
  }

  close() {
    this.dialogRef.close({value: this.isDocumentsChanged});
  }

  prettifyFileName(wideFileName: string) {
    return wideFileName.split("/").pop();
  }
}
