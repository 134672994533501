import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {
  _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) { this._baseUrl = baseUrl;}
  public get = (route: string, options?: any) => {
    return this.http.get<any>(this.createCompleteRoute(route, this._baseUrl), options);
  }

  public getList = (route: string) => {
    return this.http.get<any[]>(this.createCompleteRoute(route, this._baseUrl));
  }

  public getBlob = (route: string) => {
    return this.http.get(this.createCompleteRoute(route, this._baseUrl), {
      responseType: "blob",
      observe: "response",
    });
  }
 
  public create = (route: string, body, options?: any) => {
    return this.http.post<any>(this.createCompleteRoute(route, this._baseUrl), body, options);
  }
 
  public update = (route: string, body) => {
    return this.http.put<any>(this.createCompleteRoute(route, this._baseUrl), body);
  }
 
  public delete = (route: string) => {
    return this.http.delete(this.createCompleteRoute(route, this._baseUrl));
  }
 
  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}${route}`;
  }
 
  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
  }
}
