import { ChannelFeesComponent } from "./channel-fees.component";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AgGridModule } from "ag-grid-angular";
import { MaterialModule } from "../../../material/material.module";

@NgModule({
  declarations: [ChannelFeesComponent],
  exports: [ChannelFeesComponent],
  imports: [CommonModule, MaterialModule, AgGridModule],
})
export class ChannelFeesModule {}
