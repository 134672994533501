import { Component, OnInit } from "@angular/core";
import { ITooltipAngularComp } from "ag-grid-angular";
import { ITooltipParams } from "ag-grid-community";

@Component({
  selector: "app-full-size-image-tooltip",
  templateUrl: "./full-size-image-tooltip.component.html",
  styleUrls: ["./full-size-image-tooltip.component.scss"],
})
export class FullSizeImageTooltipComponent implements ITooltipAngularComp {
  private params: { color: string } & ITooltipParams;
  private data: any;
  imageSource: string;
  sku: string;
  agInit(params: { color: string } & ITooltipParams): void {
    this.params = params;
    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    this.imageSource = this.data?.PrimaryImage;
    this.sku = this.data?.SKU;
  }
}
