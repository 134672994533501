import { Component, OnInit } from "@angular/core";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from 'file-saver-es';

@Component({
  selector: "app-fba-sales-report",
  templateUrl: "./fba-sales-report.component.html",
  styleUrls: ["./fba-sales-report.component.scss"],
})
export class FbaSalesReportComponent implements OnInit {
  loadingReport = false;
  currentDate = new Date();

  constructor(
    private snackBarService: SnackBarService,
    private reportsService: ReportsService
  ) { }

  ngOnInit(): void { }

  downloadFile() {
    this.loadingReport = true;
    return this.reportsService.GetFBASalesReport().subscribe(
      (result: Blob) => {
        if (result.type != "text/plain") {
          this.loadingReport = false;
          var blob = new Blob([result]);
          let file = "7.2 FBASales.xlsx";

          saveAs(blob, file);
        } else {
          this.snackBarService.neutral("File not found in Blob!", "");
        }
      },
      (error) => {
        if (error.status === 404) {
          this.snackBarService.neutral(
            "There is no result for current filter ",
            ""
          );
        }
        this.loadingReport = false;
      }
    );
  }
}
