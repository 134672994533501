import { SnackBarService } from "./../../../core/services/snack-bar.service";
import { ReportsService } from "./../../../shared/services/reports.service";
import { Component, OnInit } from "@angular/core";
import { saveAs } from "file-saver-es";

@Component({
  selector: "app-total-cbm-and-total-weight-by-order",
  templateUrl: "./total-cbm-and-total-weight-by-order.component.html",
  styleUrls: ["./total-cbm-and-total-weight-by-order.component.css"],
})
export class TotalCbmAndTotalWeightByOrderComponent implements OnInit {
  constructor(
    private reportsService: ReportsService,
    private snackBarService: SnackBarService
  ) {}

  loading: boolean = false;
  currentDate = new Date();
  trackingNumbers: string;
  ngOnInit(): void {}

  downloadReport(): void {
    if (!this.trackingNumbers) {
      this.snackBarService.warning("Please, fill in the tracking numbers");
      return;
    }
    this.loading = true;
    this.reportsService
      .GetTotalCbmAndTotalWeightByOrder(this.trackingNumbers)
      .subscribe(
        (result) => {
          if (result.type != "text/plain") {
            this.loading = false;
            var blob = new Blob([result]);
            let file = "9.2 - Total cbm and total weight by order" + ".xlsx";

            saveAs(blob, file);
          } else {
            alert("File not found in Blob!");
          }
        },
        (error) => {
          this.loading = false;
          if (error.status === 404) {
            this.snackBarService.neutral(
              "There are no orders with those tracking numbers",
              ""
            );
          } else {
            this.snackBarService.error(
              "Error has occured. See the dev tools for more information"
            );
            console.error(error);
          }
        }
      );
  }
}
