import { Component, Inject, OnInit } from '@angular/core';
import * as moment from "moment";
import { saveAs } from "file-saver-es";
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { ReportsService } from './../../../shared/services/reports.service';
import { debounceTime, filter, distinctUntilChanged, tap, switchMap, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HelperService } from './../../../shared/services/helper.service';


@Component({
  selector: 'app-entry-documents-received',
  templateUrl: './entry-documents-received.component.html',
  styleUrls: ['./entry-documents-received.component.css']
})
export class EntryDocumentsReceivedComponent implements OnInit {

  loadingReport = false;
  baseUrl: string;
  hideDownloadButton = true;
  searchItemsCtrl = new UntypedFormControl();
  filteredItems: any;
  loadingData = false;
  searchItemField: string;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  currentDate = new Date();
  //
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  constructor(
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private helper: HelperService
  ) {
    this.baseUrl = baseUrl;
  }

  selectedItem: any;
  selectedFiltereditemChange(params) {
    this.selectedItem = this.filteredItems.find((x) => x.sku == params.value);
  }

  ngOnInit(): void {
    
  }

  downloadFile() {
    if (!this.range.value["start"] || !this.range.value["end"]) {
      this.openSnackBar("Pick the date range please.", "");
    } else {
      this.loadingReport = true;

      var dstart = this.range.value["start"];
      var dend = this.range.value["end"];



      return this.reportsService
        .GetEntryDocs(moment(dstart).format("YYYY-MM-DD"), moment(dend).format("YYYY-MM-DD"))
        .subscribe(
          (result: any) => {
            this.loadingReport = false;
            this.helper.saveFile(result);
          }
        );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}
