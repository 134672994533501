<mat-sidenav-container *ngIf="userConfig" fullscreen>
  <mat-sidenav #sidenav mode="side" opened="true" class="app-sidenav">
    <mat-toolbar class="appToolbar" color="primary">
      <!--future svg to go here-->
      <span class="toolbar-filler"></span>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item class="sidenav-link" *ngFor="let r of routes;" routerLinkActive="active"
         [routerLinkActiveOptions]="{exact:
              true}" [routerLink]="r.path">
        <span class="title" mat-line>{{r.data?.componentName}}</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-toolbar class="appToolbar" color="primary">
    <button mat-icon-button (click)="sidenav.toggle()" class="mat-icon-button sidenav-toggle-button"
            [hidden]="sidenav.opened">
      <mat-icon aria-label="Menu" class="material-icons">menu</mat-icon>
    </button>
    <h1 class="component-title">
    </h1>
    <span class="toolbar-filler"></span>
    <div id="user-info-right">
      <span>{{ authenticatedUser.userName }} - {{ authenticatedUser.role }}</span>
      <mat-icon (click)="logOut()">logout</mat-icon>
    </div>
  </mat-toolbar>
  <div class="loader-section">
    <app-loader></app-loader>
  </div>
  <mat-tab-group [(selectedIndex)]="selectedTabIndex" animationDuration="0ms" preserveContent>
    <mat-tab *ngFor="let tab of tabs" [label]="tab.key">
      <ng-template mat-tab-label class="tab-label-container">
        {{tab.key}}
        <button mat-icon-button (click)="disposeTab(tab)" class="tab-close-button">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
      <ng-template *ngComponentOutlet="tab.component;" class="componentContainer"></ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav-container>
