<div class="po-creation-container">

<mat-grid-list cols="5" rowHeight="64px">
  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="selectSupplier">
        <mat-label>Supplier:</mat-label>
        <mat-select [(value)]="selectedSupplier" (selectionChange)="selectedSupplierChange($event.value)"
          [disabled]="loadingData || rowData.length > 1">
          <mat-option *ngFor="let supp of suppliers" [value]="supp">
            {{ supp.supplierName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="poNumber">
        <input matInput placeholder="Order Number" disabled="true" [(ngModel)]="poNumber" />
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="selectContainerSize">
        <mat-label>Container size:</mat-label>
        <mat-select [(value)]="selectedContainerSize" (selectionChange)="selectedSize($event.value)">
          <mat-option *ngFor="let containerSize of containerSizes" [value]="containerSize">
            {{ containerSize }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="selectPort">
        <mat-label>POL:</mat-label>
        <mat-select [(value)]="selectedPOL" [disabled]="loadingData">
          <mat-option *ngFor="let pol of POLs" [value]="pol">
            {{ pol.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="selectStatus">
        <mat-label>Status:</mat-label>
        <mat-select [(value)]="selectedStatus" [disabled]="loadingData">
          <mat-option *ngFor="let status of statuses" [value]="status">
            {{ status }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="dateOrder">
        <mat-label>Order Date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="dateOrder" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="referenceNum">
        <input matInput placeholder="Goods Ref" [(ngModel)]="referenceOfPO" />
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="referenceNum2">
        <input matInput placeholder="Shipping Ref" [(ngModel)]="referenceOfPO2" />
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="referenceNum2">
        <input matInput placeholder="Supp Inv No" [(ngModel)]="suppInvNo" />
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="referenceNum2">
        <input matInput type="number" min="0.0" placeholder="Conversion Rate" [(ngModel)]="conversionRate" />
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="goodsReadyDate">
        <mat-label>Goods Ready Date</mat-label>
        <input matInput [matDatepicker]="pickerGoods" [(ngModel)]="goodsReadyDate" />
        <mat-datepicker-toggle matSuffix [for]="pickerGoods"></mat-datepicker-toggle>
        <mat-datepicker #pickerGoods></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="goodsReadyDate">
        <mat-label>Shipping Date</mat-label>
        <input matInput [matDatepicker]="pickerShipping" [(ngModel)]="shippingDate" />
        <mat-datepicker-toggle matSuffix [for]="pickerShipping"></mat-datepicker-toggle>
        <mat-datepicker #pickerShipping></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="goodsReadyDate">
        <mat-label>Docking Date</mat-label>
        <input matInput [matDatepicker]="pickerDocking" [(ngModel)]="dockingDate" />
        <mat-datepicker-toggle matSuffix [for]="pickerDocking"></mat-datepicker-toggle>
        <mat-datepicker #pickerDocking></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-grid-tile>

  <mat-grid-tile>
    <div class="col">
      <mat-form-field class="goodsReadyDate">
        <mat-label>Expected Delivery Slot</mat-label>
        <input matInput [matDatepicker]="pickerExpDelSlot" [(ngModel)]="expectedDeliverySlot" />
        <mat-datepicker-toggle matSuffix [for]="pickerExpDelSlot"></mat-datepicker-toggle>
        <mat-datepicker #pickerExpDelSlot></mat-datepicker>
      </mat-form-field>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <mat-checkbox class="mg-b16" [(ngModel)]="disableBoxQtyAlign">CTN - </mat-checkbox>
  </mat-grid-tile>
</mat-grid-list>

<div class="grid-wrapper">
  <ag-grid-angular id="grdOrderItems" class="ag-theme-balham"
    [frameworkComponents]="frameworkComponents" [headerHeight]="headerHeight" [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)" (rowDataUpdated)="onRowDataUpdated($event)"
    (cellValueChanged)="onCellValueChanged($event)" [rowData]="rowData" [columnDefs]="columnDefs" [tabToNextCell]="tabToNextCell">
  </ag-grid-angular>

  <!-- Totals -->
  <mat-grid-list [ngStyle]="{
      'background-color':
        isContainerSizeBigger === false ? 'transparent' : '#FFCCBC'
    }" cols="10" rowHeight="38px">
    <mat-grid-tile style="text-align: left"> Total - </mat-grid-tile>
    <mat-grid-tile style="text-align: left">
      Total Qty:
      {{ totalQty }}
    </mat-grid-tile>

    <mat-grid-tile style="text-align: left"> </mat-grid-tile>

    <mat-grid-tile style="text-align: left">
      Total CBM: 
      {{ totalCBM }}
    </mat-grid-tile>

    <mat-grid-tile style="text-align: left">
      Total weight: 
      {{ totalWeight }}
    </mat-grid-tile>

    <mat-grid-tile style="text-align: left">
      Total Line: 
      {{ totalLine }}
    </mat-grid-tile>
    <mat-grid-tile style="text-align: left"> </mat-grid-tile>
  </mat-grid-list>
</div>

<div class="buttons-section-po-creation">
  <button [disabled]="!goodsReadyDate || !settings" class="calculate-button" mat-raised-button color="primary"
    (click)="calculateDates()" [disabled]="loadingData">
    Shipping Date
  </button>
  <button mat-raised-button color="primary" (click)="createPO()" [disabled]="
      loadingData ||
      !goodsReadyDate ||
      !shippingDate ||
      !dockingDate ||
      !expectedDeliverySlot
    ">
    Create PO
  </button>
</div>
</div>
