import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { LoaderService } from "../services/loader.service";
import { SnackBarService } from "./snack-bar.service";
@Injectable({
  providedIn: "root",
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private snackbar: SnackBarService
  ) {}
  amountOfRequests: number = 0;
  private showLoader(): void {
    this.loaderService.show();
    this.amountOfRequests++;
  }
  private hideLoader(): void {
    this.amountOfRequests--;
    if (this.amountOfRequests === 0) {
      this.loaderService.hide();
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.showLoader();

    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        switch (errorResponse.status) {
          case 400:
            this.snackbar.error(errorResponse.error.message);
            break;
          case 404:
            this.snackbar.warning(errorResponse.error.message ?? "Object not found");
            break;
          case 409:
            this.snackbar.warning("Object already exists");
            break;
          default:
            this.snackbar.error("Something went wrong");
            break;
        }
        this.loaderService.hide();
        return throwError(errorResponse);
      }),
      finalize(() => {
        this.hideLoader();
      })
    ) as Observable<HttpEvent<any>>;
  }
}
