<mat-card>
    <mat-card-header>
        <mat-card-title>WOW Allocation/Transfer Report</mat-card-title>
        <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
        <mat-card-subtitle>
            Produces data for allocation/warehouse transfer
        </mat-card-subtitle>
    </mat-card-header>
    <div class="row">
        <div class="col">
            <button mat-raised-button color="primary" (click)="downloadAllocation()" [disabled]="loadingReport"
                style="float: right">
                Download Allocation Report
            </button>
        </div>

        <div class="col">
            <button mat-raised-button color="primary" (click)="downloadTransfer()" [disabled]="loadingReport"
                style="float: right">
                Download Transfer Report
            </button>
        </div>
    </div>
</mat-card>