import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class LandedCostService {
  constructor(private http: HttpClient) {}

  public overrideLandedCost(
    stockItemId: string,
    landedCost: number
  ): Observable<any> {
    return this.http.put<any>(
      `api/cost/overrideLandedCost?stockItemId=${stockItemId}&landedCost=${landedCost}`,
      {}
    );
  }
}
