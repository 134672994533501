<mat-dialog-content class="dialog-container">


    <div *ngIf="results?.length || errors?.length; else elseBlock">


        <div class="button-container">
        </div>


        <mat-card class="errors" *ngIf="errors?.length">

            <div style="margin-left: 10px; margin-top: 3px;">
                Importing errors:
            </div>


            <div role="list" *ngFor="let error of errors; let indexOfelement=index;" class="error">
                <div role="listitem">

                    <ul>
                        <li role="list" *ngFor="let message of error.errors">
                            {{message}}
                        </li>
                    </ul>


                    <mat-expansion-panel hideToggle class="props-container">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{errors[indexOfelement].properties.PONumber}}
                            </mat-panel-title>
                            <mat-panel-description>
                                Click for a summary of properties
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <table class="props-table" border="1" cellspacing="5" cellpadding="10">
                            <tr>
                                <th>Property</th>
                                <th>Value</th>
                            </tr>

                            <tr role="list" *ngFor="let prop of error.properties | keyvalue;">
                                <td> {{prop.key}}</td>
                                <td>"{{prop.value}}"</td>
                            </tr>
                        </table>


                    </mat-expansion-panel>

                </div>
            </div>
        </mat-card>


        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table">
                <ng-container matColumnDef="poNumber">
                    <th mat-header-cell *matHeaderCellDef> PO Number </th>
                    <td mat-cell *matCellDef="let element"> {{element.poNumber}} </td>
                </ng-container>


                <ng-container matColumnDef="goodsReadyDate">
                    <th mat-header-cell *matHeaderCellDef> Goods Ready Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.goodsReadyDate | date: 'dd/MM/yy'}} </td>
                </ng-container>

                <ng-container matColumnDef="shippingDate">
                    <th mat-header-cell *matHeaderCellDef> Shipping Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.shippingDate | date: 'dd/MM/yy'}} </td>
                </ng-container>


                <ng-container matColumnDef="dockingDate">
                    <th mat-header-cell *matHeaderCellDef> Docking Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.dockingDate | date: 'dd/MM/yy'}} </td>
                </ng-container>


                <ng-container matColumnDef="expectedDeliverySlot">
                    <th mat-header-cell *matHeaderCellDef> Expected Delivery Slot </th>
                    <td mat-cell *matCellDef="let element"> {{element.expectedDeliverySlot | date: 'dd/MM/yy'}} </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" class="paginator" showFirstLastButtons></mat-paginator>
        </div>



    </div>
    <ng-template #elseBlock>
        <div class="button-container">
            <button mat-button color="primary" class="upload-button" (click)="selectFile()">Upload</button>
        </div>
        <input name="file" id="file" (change)="uploadFile($event)" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" #inputFile hidden>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="submit()">Submit</button>
</mat-dialog-actions>