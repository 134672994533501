import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { LoaderService } from "src/app/core/services/loader.service";
import { RepositoryService } from "src/app/core/services/repository.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";

export class EmailRecepient {
  id?: number;
  userName: string;
  email: string;
}
@Component({
  selector: "app-email-recepients",
  templateUrl: "./email-recepients.component.html",
  styleUrls: ["./email-recepients.component.css"],
})
export class EmailRecepientsComponent implements OnInit {
  dataSource = new MatTableDataSource<EmailRecepient>();
  selection = new SelectionModel<EmailRecepient>(false, []);
  columns: any[];
  displayedColumns: string[];
  loading: false;

  newRecepient: any;

  addRecForm: FormGroup;

  constructor(
    private repo: RepositoryService,
    private snackBar: SnackBarService,
    private loader: LoaderService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {
    this.columns = [
      { name: "Username", value: "userName" },
      { name: "Email", value: "email" },
    ];

    this.addRecForm = this.formBuilder.group({
      userName: ['', Validators.required],
      email: ['', Validators.compose(
        [Validators.email, Validators.required])]
    })


    this.newRecepient = new EmailRecepient();

    this.displayedColumns = this.columns.map((col) => col.value);
  }

  isFieldInvalid(field: string) {
    return (
      (!this.addRecForm.get(field).valid && this.addRecForm.get(field).touched) ||
      (this.addRecForm.get(field).untouched)
    );
  }

  ngOnInit(): void {
    this.getRecepients();
  }

  remove() {
    this.repo
      .delete("api/scheduledTasks/removeRecepient/" + this.selection.selected[0].id)
      .subscribe((result) => {
        this.snackBar.neutral("Succesfully removed", "Recepient");
        this.getRecepients();
        this.selection.selected[0] = null;
      },
      (error) => {
        this.snackBar.error("Cant remove recepient");
      }
      );
  }

  add() {

    if (this.addRecForm.invalid) {
      this.snackBar.warning('Please fill all fields!');
      return;
    }

    this.repo.create("api/scheduledTasks/addRecepient/", this.addRecForm.value)
    .subscribe((result) => {
      this.snackBar.success("Succesfully added");
      this.getRecepients();
      this.addRecForm.reset();
    },
    (error) => {
      this.snackBar.error("Cant add recepient");
    }
    );
  }

  getRecepients() {
    this.repo.getList("api/scheduledTasks/getRecepients").subscribe(
      (result) => {
        this.dataSource = new MatTableDataSource<EmailRecepient>(result);
        
      },
      (error) => {
        this.snackBar.error("Cant load recepients");
      }
    );
  }
}
