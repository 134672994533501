<form
  class="example-form"
  (ngSubmit)="onSubmit()"
  [formGroup]="registerNewUserForm"
>
  <table class="example-full-width" cellspacing="0">
    <tr>
      <td>
        <mat-form-field class="example-full-width">
          <mat-label>User Name</mat-label>
          <input matInput formControlName="userNameInput" required />
          <mat-error
            *ngIf="
              registerNewUserForm.controls.userNameInput.hasError('required')
            "
          >
            Please, provide name for new user
          </mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width">
          <mat-label>User E-Mail</mat-label>
          <input matInput type="email" formControlName="emailInput" required />
          <mat-error
            *ngIf="registerNewUserForm.controls.emailInput.hasError('required')"
          >
            E-Mail is required.
          </mat-error>
          <mat-error
            *ngIf="registerNewUserForm.controls.emailInput.hasError('email')"
          >
            Please, enter valid e-mail
          </mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field>
          <mat-label>Role</mat-label>
          <mat-select formControlName="roleInput">
            <mat-option
              *ngFor="let role of rolesArray"
              [value]="rolesArray.indexOf(role) + 1"
              (click)="supplierSelection(role)"
            >
              {{ role }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              registerNewUserForm.controls.roleInput?.touched &&
              registerNewUserForm.controls.roleInput?.hasError('required')
            "
          >
            Please choose a role for user</mat-error
          >
        </mat-form-field>
      </td>
    </tr>
    <!-- <tr *ngIf="registerNewUserForm.controls.roleInput?.value === 1">
      <td colspan="3">
        <mat-form-field class="example-chip-list example-full-width">
          <mat-label>Supplier Codes</mat-label>
          <mat-chip-list #chipList aria-label="Supplier codes">
            <mat-chip
              *ngFor="
                let code of registerNewUserForm.controls.supplierCodesInput
                  ?.value
              "
              [selectable]="false"
              [removable]="true"
              (removed)="removeSupplierCode(code)"
            >
              {{ code }}
              <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
            </mat-chip>
            <input
              placeholder="Type supplier code and press enter..."
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addSupplierCode($event)"
            />
          </mat-chip-list>
        </mat-form-field>
      </td>
    </tr> -->
    <tr>
      <td>
        <mat-form-field class="example-full-width">
          <mat-label>Password</mat-label>
          <input
            matInput
            [type]="hidePasswordInput ? 'password' : 'text'"
            formControlName="passwordInput"
            required
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hidePasswordInput = !hidePasswordInput"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePasswordInput"
          >
            <mat-icon>{{
              hidePasswordInput ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
          <mat-error
            *ngIf="
              registerNewUserForm.controls.passwordInput.hasError('required')
            "
          >
            Please enter password for new user
          </mat-error>
          <mat-error
            *ngIf="
              registerNewUserForm.controls.passwordInput.hasError(
                'minlength'
              ) &&
              !registerNewUserForm.controls.passwordInput.hasError('required')
            "
          >
            Your password needs to be at least 8 characters long
          </mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width">
          <mat-label>Confirm password</mat-label>
          <input
            matInput
            [type]="hidePasswordConfirmationInput ? 'password' : 'text'"
            formControlName="passwordConfirmationInput"
            [errorStateMatcher]="matcher"
            required
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="
              hidePasswordConfirmationInput = !hidePasswordConfirmationInput
            "
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePasswordConfirmationInput"
          >
            <mat-icon>{{
              hidePasswordConfirmationInput ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
          <mat-error
            *ngIf="
              registerNewUserForm.controls.passwordConfirmationInput.dirty &&
              registerNewUserForm.hasError('notSame')
            "
          >
            Passwords do not match
          </mat-error>
        </mat-form-field>
      </td>
      <td class="button-container">
        <button
          mat-raised-button
          color="primary"
          [disabled]="registerNewUserForm.invalid"
          type="submit"
        >
          Create
        </button>
        <button
          mat-raised-button
          color="basic"
          [disabled]="!registerNewUserForm.dirty"
          type="reset"
          (click)="supplierSelection(' ')"
        >
          Cancel
        </button>
      </td>
    </tr>
  </table>
</form>
