<app-loader></app-loader>
<mat-card>
  <div *ngFor="let category of categories" class="section">
    <div class="row">
      <button *ngIf="category.value !== 'specsheet'" mat-mini-fab (click)="add(category.value)" class="add-btn"
        color="primary">
        <mat-icon>plus_one</mat-icon>
      </button>
      <h3>{{ category.name }}</h3>
    </div>
    <div id="category.name" class="container" *ngIf="imageData && imageData[category.value]" gallerize>
      <div *ngFor="let img of imageData[category.value].images" class="image-holder">
        <mat-card>
          <mat-card-subtitle>{{
            img.dateModified | date: "dd/MM/yy hh:mm"
            }}</mat-card-subtitle>
          <div *ngIf="category.value === 'videos'; else image">
            <video controls #videoPlayer preload="metadata" [matTooltip]="img.key.split('/').pop().split('.').shift()">
              <source [src]="img.url" type="video/mp4" />
              Browser not supported
            </video>
          </div>
          <ng-template #image>
            <img mat-card-image [src]="img.url" (click)="loadCategoryToLightBox(category.value)" />
          </ng-template>
          <mat-card-actions>
            <button class="remove-btn" color="secondary" (click)="remove(category.value, img.url)">
              <mat-icon>delete_forever</mat-icon>
            </button>

            <button *ngIf="
                category.value !== 'specsheet' && category.value !== 'videos'
              " class="copy-btn" color="secondary" (click)="copyToSpecSheet(category.value, img.url)">
              <mat-icon> copy_all</mat-icon>
            </button>

            <button class="open-btn" color="secondary" style="float: right" (click)="downloadFile(img)">
              <mat-icon>get_app</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
  <input name="file" id="file" (change)="uploadFiles($event)" type="file" multiple accept="image/*, video/*, application/pdf" #inputFile
    hidden />
</mat-card>
<div class="download" (click)="downloadAllPhotos()">
  <div>
    Download
    <span class="spinner" *ngIf="downloadingImageCounter != 0"></span>
  </div>
</div>