<mat-card>
  <mat-card-header>
    <mat-card-title>Go 2 Stream Orders</mat-card-title>
    <mat-card-subtitle
    >Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle
    >
    <mat-card-subtitle>
     Orders from folders: 'Route 1 Leeds', 'Route 2 Man / Live', 'Route 3 Birmingham'
    </mat-card-subtitle>
  </mat-card-header>


  <div class="d-flex-vert-center gap-16 mg-l16">
    <mat-form-field>
      <mat-label>Select an option</mat-label>
      <mat-select [(value)]="selected">
        <mat-option value="0">All orders (all routes)</mat-option>
        <mat-option value="1">Route 1 Leeds</mat-option>
        <mat-option value="2">Route 2 Man / Live</mat-option>
        <mat-option value="3">Route 3 Birmingham</mat-option>
        <mat-option value="4">Route 4 Midlands</mat-option>
        <mat-option value="100">Northern Ireland</mat-option>
      </mat-select>
    </mat-form-field>
    <button
      mat-raised-button
      color="primary"
      (click)="downloadReport()"
      [disabled]="loading"
      class="download"
    >
      Get Report
    </button>
  </div>
</mat-card>
