import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../../shared/services/helper.service';
import { ReportsService } from "../../../shared/services/reports.service";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: 'app-amazon-sales-europe',
  templateUrl: './amazon-sales-europe.component.html',
  styleUrls: ['./amazon-sales-europe.component.scss']
})
export class AmazonSalesEuropeComponent implements OnInit {

  currentDate = new Date();
  loading: boolean = false;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  constructor(
    private reportsService: ReportsService,
    private helper: HelperService
  ) { }

  ngOnInit(): void {
  }

  downloadReport(): void {
    const dstart = this.range.value["start"];
    const dend = this.range.value["end"];
    this.reportsService
      .GetAmazonSalesEuropeReport(
        moment(dstart).format("YYYY-MM-DD"),
      moment(dend).format("YYYY-MM-DD"))
      .subscribe((response) => {
        this.helper.saveFile(response);
      });
  }

}
