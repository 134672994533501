<app-loader></app-loader>
<mat-card>
    <mat-card-title>Instructions</mat-card-title>
    <button mat-raised-button color="primary" (click)="downloadAllInstructions()">Download all</button>

    <mat-card-content>
        <mat-selection-list #docs [multiple]="false">
            <mat-list-option *ngFor="let i of instructions" [value]="i">
                {{ humanaze(i) }}
                <mat-divider></mat-divider>
            </mat-list-option>
        </mat-selection-list>
    </mat-card-content>

    <div class="button-row">
        <button mat-raised-button color="primary" (click)="add()">Upload</button>

        <button *ngIf="docs.selectedOptions.selected[0]" mat-raised-button color="primary"
            (click)="open(docs.selectedOptions.selected[0]?.value)">
            Open
        </button>

        <button *ngIf="docs.selectedOptions.selected[0]" mat-raised-button color="primary"
            (click)="remove(docs.selectedOptions.selected[0]?.value)">
            Remove
        </button>
    </div>

    <input name="file" id="file" (change)="uploadFiles($event)" type="file" multiple #inputFile hidden />
</mat-card>