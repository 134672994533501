import { Component, OnInit } from "@angular/core";
import { HttpResponse } from "@angular/common/http";
import { ReportsService } from "../../../shared/services/reports.service";
import { HelperService } from "../../../shared/services/helper.service";

@Component({
  selector: "app-go-to-stream",
  templateUrl: "./go-to-stream.component.html",
  styleUrls: ["./go-to-stream.component.css"],
})
export class GoToStreamComponent implements OnInit {
  public currentDate = new Date();
  public selected = "0";
  public loading = false;

  constructor(
    private reportsService: ReportsService,
    private helper: HelperService,
  ) { }

  ngOnInit(): void {
  }

  public downloadReport(): void {
    this.reportsService
      .getGo2StreamReport(this.selected)
      .subscribe((response: HttpResponse<Blob>) => {
        this.helper.saveFile(response, this.getReportName(this.selected));
      });
  }

  private getReportName(route: string): string {
    switch (route) {
      case "0":
        return "All_orders.csv";
      case "1":
        return "Route 1 Leeds.csv";
      case "2":
        return "Route 2 Man Live.csv";
      case "3":
        return "Route 3 Birmingham.csv";
      case "4":
        return "Route 4 Midlands.csv";
      case "100":
        return "Northern_Ireland_Orders.csv";
    }
  }
}
