<mat-tab-group>
    <mat-tab label="Scheduled Tasks">
        <ng-template matTabContent>
            <app-email-scheduler></app-email-scheduler>
        </ng-template>
    </mat-tab>

    <mat-tab label="Recepients">
        <ng-template matTabContent>
            <app-email-recepients></app-email-recepients>
        </ng-template>
    </mat-tab>

    <mat-tab label="Schedule Builder">
        <ng-template matTabContent>
            <app-schedule-builder></app-schedule-builder>
        </ng-template>
    </mat-tab>

  </mat-tab-group>
