<h2 mat-dialog-title>Add Price Rule</h2>
<mat-dialog-content class="content">


    <div class="top-row">

        <mat-form-field appearance="standard">
            <!-- <input matInput placeholder="Search" aria-label="State" [matAutocomplete]="auto" [formControl]="searchItemsCtrl" [(ngModel)]="searchItemField" [disabled]="!selectedChannel"> -->
            <input matInput placeholder="Search" aria-label="State" [matAutocomplete]="auto"
                [formControl]="searchItemsCtrl" [(ngModel)]="searchItemField">
            <!-- <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedFiltereditemChange($event.option); searchItemField = '';"> -->
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedFiltereditemChange($event.option);">
                <mat-option *ngIf="loading" class="is-loading">Loading...</mat-option>
                <ng-container *ngIf="!loading">
                    <mat-option *ngFor="let item of filteredItems" [value]="item.sku">
                        <span><b>{{item.sku}}</b> ({{item.title}})</span>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="standard" *ngIf="this.selectedItem && this.selectedItem.channelPrice">
            <mat-label>Current Price</mat-label>
            <input matInput readonly="true" type="number" placeholder="0" value=0 [value]="selectedItem.channelPrice">
        </mat-form-field>

        <mat-form-field appearance="standard" *ngIf="this.selectedItem && this.selectedItem.channelPrice">
            <mat-label>Amount to change</mat-label>
            <input matInput type="number" placeholder="0" step="0.01" [(ngModel)]="amountToChange"
                (change)="amountChanged()">
            <mat-hint align="start">negtive if you want to reduce</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="standard" *ngIf="this.selectedItem && this.selectedItem.channelPrice">
            <mat-label>Price after change</mat-label>
            <input matInput readonly="true" type="number" placeholder="0" [value]="getNewPriceForSelectedItem()">
        </mat-form-field>

    </div>

    <div *ngIf="dataSource.data.length && dataSource.data.length > 0">
        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2" [hidden]="!dataSource.data">

            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>
            <ng-container matColumnDef="sku">
                <mat-header-cell *matHeaderCellDef mat-sort-header> SKU </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.sku}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="source">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Source </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.source}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="subSource">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Subsource </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.subSource}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tag">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Tag </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.tag}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="price">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Combo Price </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.price}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)"  (click)="setSelectedRow(row)"
                [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
        </mat-table>
    </div>

    <div class="add-rule-row" *ngIf="this.selection.selected && this.selection.selected.length > 0">
        <mat-form-field appearance="standard">
            <mat-label>Amount to change</mat-label>
            <input matInput type="number" placeholder="0" step="0.01" [(ngModel)]="amountToChange">
            <mat-hint align="start">negtive if you want to reduce</mat-hint>
        </mat-form-field>

        <mat-slide-toggle [(ngModel)]="ruleTypeToggle" class="total">
            Set rule type to percentage
          </mat-slide-toggle>

          <mat-form-field appearance="standard">
            <mat-label>Duration in days</mat-label>
            <input matInput type="number" placeholder="0" step="1" min="1" [(ngModel)]="ruleDuration">
        </mat-form-field>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
    <button mat-raised-button color="primary" (click)="addNewRule()">Add new rule</button>
</mat-dialog-actions>