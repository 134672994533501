import { Component, OnInit, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, throwError } from "rxjs";
import { retry, catchError, tap } from "rxjs/operators";
import { POService } from "../../po-creation-tab/po-view/po.service";
import { Router } from "@angular/router";
import { GeneralSettings } from "src/app/core/models/general-settings.model";
import { GeneralSettingsService } from "src/app/core/services/general-settings.service";
import { parseDateTime } from "src/app/shared/utils/functions";
import * as moment from "moment";

interface PurchaseOrder {
  sku: string;
  purchaseOrderId: string;
  dateOfDelivery: string;
  dateOfPurchase: string;
  quotedDeliveryDate: string;
  externalInvoiceNumber: string;
  status: string;
  quantity: number;
  deliveryDate: ExtendedProperty;
  shippingDate: ExtendedProperty;
  dockingDate: ExtendedProperty;
  expectedDeliverySlot: ExtendedProperty;
  goodsReadyDate: ExtendedProperty;
  shipped: ExtendedProperty;
  booked: ExtendedProperty;
  vesselName: ExtendedProperty;
}

export interface ExtendedProperty {
  rowId: number;
  purchaseId: string;
  propertyName: string;
  propertyValue: string;
}

@Component({
  selector: "app-intake-dialog",
  templateUrl: "./intake-dialog.component.html",
  styleUrls: ["./intake-dialog.component.css"],
})
export class IntakeDialogComponent implements OnInit {
  http: HttpClient;
  baseUrl: string;
  loadingData = false;
  purchaseOrders: PurchaseOrder[] = [];
  purchaseOrderExtendedProps$: Observable<any[]>;
  exProps: ExtendedProperty[];
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  public settings: GeneralSettings;
  isPropsUpdated = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<IntakeDialogComponent>,
    http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private _snackBar: MatSnackBar,
    private poService: POService,
    private router: Router,
    private settingsService: GeneralSettingsService,
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    this.settingsService.get().pipe(
      tap((settings: GeneralSettings) => {
        this.settings = settings;
      })
    ).subscribe();
    // Get PO
    this.loadingData = true;

    let itemIds = "";
    let purchaseIds = "";

    if (
      this.data.stockItem.childItems &&
      this.data.stockItem.childItems.length > 0 &&
      this.data.stockItem.childItems[0].stockOnOrder > 0
    ) {
      for (let i = 0; i < this.data.stockItem.childItems.length; i++) {
        if (i === this.data.stockItem.childItems.length - 1) {
          itemIds += this.data.stockItem.childItems[i].stockItemId;
        } else {
          itemIds += this.data.stockItem.childItems[i].stockItemId + ",";
        }
      }
    } else {
      itemIds = this.data.stockItem.stockItemId;
    }

    if (
      this.data.stockItem.purchaseOrdersIds &&
      this.data.stockItem.purchaseOrdersIds.length > 0
    ) {
      purchaseIds = this.data.stockItem.purchaseOrdersIds[this.data.weekNumber];
    } else {
      purchaseIds = this.data.stockItem.purchaseOrders[this.data.weekNumber];
    }

    this.http
      .get<PurchaseOrder[]>(
        this.baseUrl +
          "api/procurement/purchaseOrder/" +
          itemIds +
          "/" +
          purchaseIds +
          "/" +
          parseInt(this.data.weekNumber)
      )
      .subscribe(
        (result) => {
          this.purchaseOrders = result;
          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;
          console.error(error);
          this.openSnackBar("Something went wrong (", "");
        }
      );
  }

  editPurchaseOrder(purchaseOrder: PurchaseOrder): void {
    this.poService.currentPurchase = purchaseOrder.purchaseOrderId;
    this.poService.returnUrl = "/po-props";
    this.dialogRef.close();
    this.router.navigate(["/home/po-view"], { queryParams: { componentIndex: purchaseOrder.externalInvoiceNumber } });
  }

  setPropsToSpecificDate(po: PurchaseOrder): void {
    po.goodsReadyDate.propertyValue = "01/01/20";
    po.expectedDeliverySlot.propertyValue = "01/01/20 00:00";
    po.shippingDate.propertyValue = "01/01/20";
    po.dockingDate.propertyValue = "01/01/20";
  }

  public calculateDates(po): void {
    po.shippingDate.propertyValue =
          this.generateShippingDate(
            parseDateTime(po.goodsReadyDate.propertyValue)
          );
        po.dockingDate.propertyValue = this.generateDockingDate(
          parseDateTime(po.shippingDate.propertyValue),
          this.settings.intervalShippingAndDocking
        );
        po.expectedDeliverySlot.propertyValue =
          this.generateExpectedDeliverySlot(
            parseDateTime(po.dockingDate.propertyValue),
            this.settings.intervalDockingAndExpectedDelivery
          ) + " TBC";

          let props = new Array<ExtendedProperty>();
    props.push(po.goodsReadyDate);
    props.push(po.expectedDeliverySlot);
    props.push(po.shippingDate);
    props.push(po.dockingDate);
    props.push(po.booked);
    props.push(po.shipped);
    props.push(po.vesselName);

    this.loadingData = true;
    this.http
      .post(
        this.baseUrl +
          "api/procurement/updatePurchaseOrderExtendedProperties/" +
          po.purchaseOrderId,
        props
      )
      .subscribe(
        (result) => {
          this.loadingData = false;
          this.openSnackBar("Succesfully updated", "");
        },
        (error) => {
          this.loadingData = false;
          console.error(error);
          this.openSnackBar("Something went wrong (", "");
        }
      );
  }

  generateShippingDate(goodsReadyDate: any): any {
    return moment(goodsReadyDate).add(this.settings.intervalGoodsReadyAndShipping, "days").format("DD/MM/YY");
  }

  generateDockingDate(shippingDate: any, transitTime: number): any {
    return moment(shippingDate).add(transitTime, "days").format("DD/MM/YY");
  }

  generateExpectedDeliverySlot(dockingDate: any, transitTime: number): any {
    return moment(dockingDate).add(transitTime, "days").format("DD/MM/YY");
  }

  saveProperty(po: PurchaseOrder) {
    let props = new Array<ExtendedProperty>();
    props.push(po.goodsReadyDate);
    props.push(po.expectedDeliverySlot);
    props.push(po.shippingDate);
    props.push(po.dockingDate);
    props.push(po.booked);
    props.push(po.shipped);
    props.push(po.vesselName);

    this.loadingData = true;
    this.http
      .post(
        this.baseUrl +
          "api/procurement/updatePurchaseOrderExtendedProperties/" +
          po.purchaseOrderId,
        props
      )
      .subscribe(
        (result) => {
          this.loadingData = false;
          this.openSnackBar("Succesfully updated", "");

          this.dialogRef.close({ value: true });
        },
        (error) => {
          this.loadingData = false;
          console.error(error);
          this.openSnackBar("Something went wrong (", "");
        }
      );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  getExtendedProps(): Observable<any[]> {
    return this.http
      .get<any[]>(
        this.baseUrl +
          "api/procurement/purchaseOrderExtendedProps/" +
          this.purchaseOrders[0].purchaseOrderId
      )
      .pipe(retry(1), catchError(this.errorHandler));
  }

  errorHandler(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
