import { Component, Input, isDevMode, OnChanges, OnInit } from "@angular/core";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { MatDialog } from "@angular/material/dialog";
import { SpecSheetImagesComponent } from "./spec-sheet-images/spec-sheet-images.component";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ExtendedProperty } from "../../../shared/models/extendedProperty";
import { HttpResponse } from "@angular/common/http";
import { HelperService } from "src/app/shared/services/helper.service";

@Component({
  selector: "app-spec-sheet",
  templateUrl: "./spec-sheet.component.html",
  styleUrls: ["./spec-sheet.component.css"],
})
export class SpecSheetComponent implements OnInit, OnChanges {
  changeValues$: Subject<any> = new Subject();
  @Input() stockItemId: string;
  stockItem: any;
  props: ExtendedProperty[];
  productDetails: ExtendedProperty;
  packagingDetails: ExtendedProperty;
  generalComments: ExtendedProperty;

  sheetImages: any;

  constructor(
    private snackBar: SnackBarService,
    private dialog: MatDialog,
    private inventoryService: InventoryService,
    private helper: HelperService
  ) {}

  ngOnChanges() {
    this.initialData();
  }

  ngOnInit(): void {
    if (this.stockItemId) {
      return;
    }

    this.stockItemId = isDevMode()
      ? "b94d1ea0-5133-48ba-b345-deebadda1204"
      : sessionStorage.getItem("lwkr-stockitemid-ss");
    this.initialData();
  }

  initialData() {
    this.getStockItemFullInfoById(this.stockItemId);
    this.getStockItemProps(this.stockItemId);
    this.changeValues$
      .pipe(debounceTime(1000))
      .subscribe(() => this.saveProps());
  }

  captureChange(value) {
    this.changeValues$.next(value);
  }

  getStockItemFullInfoById(stockItemId: string) {
    this.inventoryService.getStockItemFullInfoById(stockItemId).subscribe(
      (result) => {
        this.stockItem = result;
      },
      (error) => {
        this.snackBar.error("Something went wrong");
      }
    );
  }

  getStockItemProps(stockItemId: string) {
    this.inventoryService.getStockItemPropsById(stockItemId).subscribe(
      (result) => {
        this.props = result;

        this.productDetails = this.props.find(
          (x) => x.properyName === "Product Details"
        );
        this.packagingDetails = this.props.find(
          (x) => x.properyName === "Packaging Details"
        );
        this.generalComments = this.props.find(
          (x) => x.properyName === "General Comments"
        );
      },
      (error) => {
        this.snackBar.error("Something went wrong");
      }
    );
  }

  saveProps() {
    let updatedProps = [
      this.productDetails,
      this.packagingDetails,
      this.generalComments
    ];

    this.inventoryService.updateStockItemProps(updatedProps).subscribe(
      () => {
        this.snackBar.success("Successfully updated");
      },
      (error) => {
        this.snackBar.error("Something went wrong");
      }
    );
  }

  openDialogImages(): void {
    const dialogRef = this.dialog.open(SpecSheetImagesComponent, {
      width: "600px",
      data: { stockItemId: this.stockItemId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.sheetImages = result;
    });
  }

  createSpecSheet(): void {
    let childIds = [this.stockItemId];
    this.inventoryService.getParentsByChilds(childIds).subscribe((result) => {
      result.forEach((i) => {
        this.inventoryService
          .getSpecSheet(i, "")
          .subscribe((response: HttpResponse<Blob>) => {
            this.helper.saveFile(response);
          });
      });
    });
  }

  
}
