<div class="main-form-container">
    <div *ngIf="loadingData" class="row" style="align-items: center;">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <mat-grid-list cols="8" rowHeight="40px">

        <mat-grid-tile [colspan]="2" [rowspan]="2">

            <mat-form-field class="selectType">
                <mat-label>Range:</mat-label>
                <mat-select [(value)]="selectedRange" (selectionChange)="selectedRangeChange($event.value)">
                    <mat-option *ngFor="let range of ranges" [value]="range">
                        {{range.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </mat-grid-tile>

        <mat-grid-tile [colspan]="2" [rowspan]="2">
            <mat-form-field class="selectType">
                <mat-label>Type:</mat-label>
                <mat-select [(value)]="selectedType">
                    <mat-option *ngFor="let type of types" [value]="type">
                        {{type.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2" [rowspan]="2">
            <mat-form-field class="selectType">
                <mat-label>Production time, days:</mat-label>
                <input matInput placeholder="0" type="number" [value]="productionTime" [min]="0"
                    [(ngModel)]="productionTime">
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2" [rowspan]="2">
            <mat-form-field class="selectType">
                <mat-label>Transit time, days:</mat-label>
                <input matInput placeholder="0" type="number" [value]="transitTime" [min]="0"
                    [(ngModel)]="transitTime">
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2" [rowspan]="2">
            <mat-form-field class="selectType">
                <mat-label>Minimum weeks cover:</mat-label>
                <input matInput placeholder="0" type="number" [value]="transitTime" [min]="2"
                    [(ngModel)]="minimumWeeksCover">
            </mat-form-field>
        </mat-grid-tile>

        <!-- <mat-grid-tile [colspan]="2" [rowspan]="1">
            <button mat-raised-button color="primary">Create forecast</button>
        </mat-grid-tile> -->

    </mat-grid-list>
    <mat-card>
        <div class="item-table-container" *ngIf="orderDataSource">
            <table mat-table [dataSource]="orderDataSource" class="mat-elevation-z0">

                <!-- Title Column -->
                <ng-container matColumnDef="reference">
                    <th mat-header-cell *matHeaderCellDef> PO Number </th>
                    <td mat-cell *matCellDef="let item"> {{item.reference}} </td>
                </ng-container>

                <!-- SKU Column -->
                <ng-container matColumnDef="containerSize">
                    <th mat-header-cell *matHeaderCellDef> Size </th>
                    <td mat-cell *matCellDef="let item"> {{item.containerSize}} </td>
                </ng-container>

                <!-- Qty Column -->
                <ng-container matColumnDef="deliveryDate">
                    <th mat-header-cell *matHeaderCellDef> Delivery Date </th>
                    <td mat-cell *matCellDef="let item"> {{item.deliveryDate}} </td>
                </ng-container>

                <!-- Usage Column -->
                <ng-container matColumnDef="contUsage">
                    <th mat-header-cell *matHeaderCellDef> Usage, CBM </th>
                    <td mat-cell *matCellDef="let item"> {{item.containerUsage | number : '1.2-2'}} </td>
                </ng-container>

                <!-- Usage % Column -->
                <ng-container matColumnDef="contUsagePercentage">
                    <th mat-header-cell *matHeaderCellDef> Usage, % </th>
                    <td mat-cell *matCellDef="let item"> {{item.containerUsagePercentage | number : '1.2-2'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="orderDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: orderDisplayedColumns;"
                    (click)="orderSelection.toggle(row)" (click)="setSelectedRow(i)"
                    [ngClass]="{hovered: row.hovered, highlighted: orderSelection.isSelected(row)}"
                    (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>
            </table>
        </div>

        <button mat-raised-button *ngIf="selectedContainerIndex > -1" (click)="removeContainer()">Remove
            Container</button>

            <button mat-raised-button *ngIf="selectedContainerIndex > -1" (click)="confirmContainer()">Confirm and create PO</button>
            <button mat-button mat-dialog-close>Close</button>
    </mat-card>


    <!-- Items in selected container -->
    <mat-card *ngIf=" orders && selectedContainerIndex > -1">
        <mat-card-header>
            <mat-card-title>{{ orders[selectedContainerIndex].poNumber }} - Items:</mat-card-title>
          </mat-card-header>
          <mat-card-content>
        <div class="item-table-container" *ngIf="selectedContainerIndex > -1">
            <table mat-table [dataSource]="itemDataSource" class="mat-elevation-z0">

                <!-- SKU Column -->
                <ng-container matColumnDef="sku">
                    <th mat-header-cell *matHeaderCellDef> SKU </th>
                    <td mat-cell *matCellDef="let item"> {{item.sku}} </td>
                </ng-container>

                <!-- Title Column -->
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef> Title </th>
                    <td mat-cell *matCellDef="let item"> {{item.title}} </td>
                </ng-container>

                <!-- Qty Column -->
                <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef> Qty </th>
                    <td mat-cell *matCellDef="let item"> {{item.qty}} </td>
                </ng-container>

                <!-- CBM Column -->
                <ng-container matColumnDef="cbm">
                    <th mat-header-cell *matHeaderCellDef> CBM per Item </th>
                    <td mat-cell *matCellDef="let item"> {{item.cbm | number : '1.2-2'}} </td>
                </ng-container>

                <!-- TotalCBM Column -->
                <ng-container matColumnDef="totalCbm">
                    <th mat-header-cell *matHeaderCellDef> Total CBM per Item </th>
                    <td mat-cell *matCellDef="let item"> {{(item.cbm * item.qty) | number : '1.2-2'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="itemDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: itemDisplayedColumns;"></tr>
            </table>
        </div>
    </mat-card-content>
    </mat-card>

</div>