import { Component, OnInit } from "@angular/core";
import { saveAs } from "file-saver-es";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";

@Component({
  selector: "app-wayfair-sales-weekly",
  templateUrl: "./wayfair-sales-weekly.component.html",
  styleUrls: ["./wayfair-sales-weekly.component.scss"],
})
export class WayfairSalesWeeklyComponent implements OnInit {
  constructor(
    private snackBarService: SnackBarService,
    private reportsService: ReportsService
  ) {}

  currentDate = new Date();
  loading: boolean = false;

  ngOnInit(): void {}

  downloadReport() {
    this.loading = true;

    this.reportsService.GetWayfairSalesWeeklyReport().subscribe(
      (result) => {
        if (result.type != "text/plain") {
          this.loading = false;
          var blob = new Blob([result]);
          let file = "4.5 - Wayfair Sales Weekly.xlsx";

          saveAs(blob, file);
        } else {
          alert("File not found in Blob!");
        }
      },
      (error) => {
        this.loading = false;
        if (error.status === 404) {
          this.snackBarService.neutral(
            "There is no result for current filter",
            ""
          );
        }
      }
    );
  }
}
