import { Component, OnInit } from "@angular/core";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { Supplier } from "src/app/shared/models/Supplier";
import { HelperService } from "src/app/shared/services/helper.service";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { ReportsService } from "src/app/shared/services/reports.service";

@Component({
  selector: "app-po-schedule-aivan-report",
  templateUrl: "./po-schedule-aivan-report.component.html",
  styleUrls: ["./po-schedule-aivan-report.component.scss"],
})
export class PoScheduleAivanReportComponent implements OnInit {
  constructor(
    private inventoryService: InventoryService,
    private reportsService: ReportsService,
    private helper: HelperService,
    private snackBarService: SnackBarService
  ) {}
  loadingData = false;
  suppliers: Supplier[];
  selectedSupplier: Supplier;
  currentDate = new Date();

  ngOnInit(): void {
    this.loadingData = true;
    this.inventoryService.getSuppliers().subscribe(
      (result) => {
        this.suppliers = result;
        this.suppliers.unshift({
          pkSupplierID: "00000000-0000-0000-0000-000000000000",
          supplierName: "All",
        });
        this.loadingData = false;
      },
      (error) => {
        this.loadingData = false;
        console.error(error);
      }
    );
  }

  downloadFile() {
    if (this.selectedSupplier == null) {
      this.snackBarService.warning("Choose supplier please.");
    } else {
      this.loadingData = true;

      return this.reportsService
        .getPOScheduleAIvanReport(this.selectedSupplier)
        .subscribe(
          (result: any) => {
            if (result.type != "text/plain") {
              this.loadingData = false;
              this.helper.saveFile(result);
            } else {
              this.snackBarService.error("File not found in Blob!");
            }
          },
          (error) => {
            this.snackBarService.error("Items not found. List is empty.");

            this.loadingData = false;
          }
        );
    }
  }
}
