<div class="holder" *ngIf="!loading">
  <div class="item-section" *ngFor="let item of grouppedItems">
    <div class="header" (click)="item.isExpanded = !item.isExpanded">
      <span>{{ item.categoryName }}</span>

      <svg _ngcontent-ng-cli-universal-c13="" class="wm-icon wm-arrow" height="12px" viewBox="0 0 451.847 451.847"
        width="12px" style="transform: rotate(0deg)" [ngStyle]="{
          transform: item.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
        }">
        <path _ngcontent-ng-cli-universal-c13=""
          d="M225.923 354.706c-8.098 0-16.195-3.092-22.37-9.263L9.27 151.157c-12.36-12.36-12.36-32.397 0-44.75 12.354-12.355 32.388-12.355 44.748 0L225.923 278.32 397.83 106.413c12.358-12.354 32.39-12.354 44.743 0 12.365 12.354 12.365 32.392 0 44.75L248.293 345.45c-6.178 6.17-14.275 9.256-22.37 9.256z"
          fill="#7C7C7C"></path>
      </svg>
    </div>
    <div class="body-section" *ngIf="item.isExpanded">
      <div class="comments" *ngIf="item.categoryName.toLowerCase().includes('comment')">
        <div class="comment" *ngFor="let comment of item.images">
          <!-- <mat-form-field> -->
          <textarea matInput rows="7" [ngModel]="comment" (ngModelChange)="changed($event)"></textarea>
          
            <!-- <mat-label>Product Details:</mat-label> -->
            <!-- <textarea matInput [ngModel]="comment" (ngModelChange)="changed($event)" maxlength="2000"
                cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea> -->

                <!-- <textarea matInput [(ngModel)]="comment.propertyValue" (input)="captureChange()" maxlength="2000"
                    cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea> -->
        <!-- </mat-form-field> -->
        </div>
      </div>

      <div class="photos" *ngIf="item.categoryName.toLowerCase().includes('photo')">
        <button mat-button color="primary" class="upload-button" (click)="addDoc(item.categoryName)">
          Upload
        </button>
        <div class="images">
          <img [src]="image.url" alt="{{ item.categoryName }}-image" *ngFor="let image of item.images" (click)="
              selectedImage = { url: image.url, group: item.categoryName }
            " />
        </div>
      </div>

      <div class="files" *ngIf="item.categoryName.toLowerCase().includes('file')">
        <button mat-button color="primary" class="upload-button" (click)="addDoc(item.categoryName)">
          Upload
        </button>

        <div class="file" *ngFor="let file of item.images" (click)="downloadFile(file)">
          <span>
            {{ this.prettifyFileName(file.name) }}
          </span>
          <mat-icon (click)="
              deleteFile(
                purchaseId +
                  '/' +
                  item.categoryName.split(' ').join('') +
                  '/' +
                  this.prettifyFileName(file.key),
                $event
              )
            ">
            clear
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="viewer" *ngIf="selectedImage && selectedImage.url" #viewerContainer>
    <img [src]="selectedImage.url" alt="" #selectedImageRef />
    <div class="manipulation-menu" #manipulationMenuRef>
      <div class="icon">
        <mat-icon #deleteIconRef (click)="
            deleteFile(
              purchaseId +
                '/' +
                selectedImage.group.split(' ').join('') +
                '/' +
                this.prettifyFileName(this.selectedImage.url)
            )
          " [ngClass]="{ loading: deletingImage }">delete_forever</mat-icon>
      </div>
      <div class="icon">
        <mat-icon #downloadIconRef (click)="downloadImage()" [ngClass]="{ loading: downloadingImage }">cloud_download
        </mat-icon>
      </div>
    </div>
  </div>
  <button (click)="sendViaEmail()" class="send-email" *ngIf="!this.selectedImage">
    Send report via Email
  </button>
</div>

<input name="file" id="file" (change)="uploadFiles($event)" type="file" multiple #inputFile hidden />