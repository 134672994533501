import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { PriceService } from 'src/app/shared/services/price.service';

@Component({
  selector: 'app-all-price-rules',
  templateUrl: './all-price-rules.component.html',
  styleUrls: ['./all-price-rules.component.css']
})
export class AllPriceRulesComponent implements OnInit {

  public dataSource = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  columns: any[];
  displayedColumns: string[];

  selectedRule: any;

  constructor(private priceService: PriceService, private notify: SnackBarService) {
    this.columns = [
      { name: 'SKU', value: 'sku' },
      { name: 'Description', value: 'description' },
      { name: 'Source', value: 'source' },
      { name: 'Subsource', value: 'subSource' },
      { name: 'Tag', value: 'tag' },
      { name: 'Type', value: 'type' },
      { name: 'Value', value: 'amountToChange' },
      { name: 'Duration', value: 'durationInDays' },
      { name: 'Created Date', value: 'createdDate' }
    ];
    this.selection = new SelectionModel<any>(false, []);
    this.displayedColumns = this.columns.map((col) => col.value);
   }

  ngOnInit(): void {
    this.getPriceChangeRules();
  }

  getPriceChangeRules(): void {
    this.priceService.getPriceChangeRules().subscribe(result => {
      this.dataSource = new MatTableDataSource(result);
    })
  }

  setSelectedRow(item: any) {
    this.selectedRule = item;
  }

  removeRule(): void {
    this.priceService.deletePriceChangeRule(this.selectedRule.stockItemId, this.selectedRule.id).subscribe(
      result => {
        this.getPriceChangeRules();
        this.notify.success("Rule removed");
      }
    );
  }

}
