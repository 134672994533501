import { HttpResponse } from "@angular/common/http";
import * as moment from 'moment';
import { saveAs } from 'file-saver-es';

export function getFileName(response: HttpResponse<Blob>, expectedExtension: string) {
    let filename: string;
    try {
        const contentDisposition: string = response.headers.get('content-disposition');
        const r = /(?:filename=")(.+)(?:")/;
        filename = r.exec(contentDisposition)[1];
    }
    catch (e) {
        filename = 'myfile.' + expectedExtension;
    }
    return filename;
}

export function getFilenameFromContentDisposition(contentDisposition: string): string {
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
    return 'unknown';
  }

export function saveFile(file: Blob, fileName: string) {
    saveAs(file, fileName);
}

export function parseBooleanValue(value: any) {
    if (!value) return false;
    const sanitizedValue = value.toString().trim().toLowerCase();
    if (sanitizedValue === 'true' || sanitizedValue === 'yes' || sanitizedValue === '1') return true;
    return false;
}

export function parseDateTime(value: string) {
    if (!value) return moment("01/01/01", "DD/MM/YY", true).toDate();
    let formats = [
        'DD/MM/YY',
        'DD/MM/YYYY h:mm a',
        'DD/MM/YYYY hh:mm a',
        'DD/MM/YY HH:mm',
        'DD/MM/YYYY',
        'DD/MM/YYYY HH:mm',
        'DD/MM/YY hh:mm a',
        'DD/MM/YY h:mm a',
    ];
    let result = moment(value.replace(' TBC', '').trim(), formats, true).toDate();
    return (moment(result).isValid() ? result : moment("01/01/01", "DD/MM/YY", true).toDate());
}

export function parseDateTimeSimple(value: string) {
    return moment(value.replace(' TBC', ''), 'DD/MM/YY', true).toDate();
}

export function purchaseOrderNumberComparator(value1, value2) {
    const a = parseInt(value1.replace(/\D/g, ''));
    const b = parseInt(value2.replace(/\D/g, ''));
    if (a == b) {
        return 0;
    }
    return (a > b) ? 1 : -1;
}

export function dateTimeComparator(a, b) {
    var date1 = parseDateTime(a);
    var date2 = parseDateTime(b);
    var date1Number = date1 && new Date(date1).getTime();
    var date2Number = date2 && new Date(date2).getTime();

    if (date1Number == null && date2Number == null) {
        return 0;
    }

    if (date1Number == null) {
        return -1;
    } else if (date2Number == null) {
        return 1;
    }

    return date1Number - date2Number;
}


export function dateDiffInDays(date1, date2) {
    var diff = Math.floor(date1.getTime() - date2.getTime());
    var day = 1000 * 60 * 60 * 24;
    return Math.floor(diff / day);
}

export function SKUComparator(val1, val2) {
    return parseFloat(val1) > parseFloat(val2) ? 1 : -1;
  }