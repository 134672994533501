import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, debounceTime, filter, distinctUntilChanged, tap, takeUntil, switchMap, finalize } from 'rxjs';
import { InventoryService } from '../../../shared/services/inventory.service';
import { ReportsService } from "src/app/shared/services/reports.service";
import { HelperService } from "src/app/shared/services/helper.service";

@Component({
  selector: 'app-landed-cost-by-stockitem',
  templateUrl: './landed-cost-by-stockitem.component.html',
  styleUrls: ['./landed-cost-by-stockitem.component.css']
})
export class LandedCostByStockitemComponent implements OnInit {

  currentDate = new Date();
  searchItemsCtrl = new UntypedFormControl();
  filteredItems: any;
  public searchItemField: string;
  destroyedComponentSubj = new Subject<void>();
  loading: boolean = false;
  selectedItem: any;

  constructor(
    private inventoryService: InventoryService,
    private reportsService: ReportsService,
    private helper: HelperService
    ) { }

  ngOnInit(): void {

    this.searchItemsCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((term) => term && term.toString().trim().length > 0),
        distinctUntilChanged(),
        tap(() => {
          this.filteredItems = [];
          this.loading = true;
        }),
        takeUntil(this.destroyedComponentSubj),
        switchMap((value: string) =>
          this.inventoryService.searchStockITemsByKey(value)
            .pipe(
              finalize(() => {
                this.loading = false;
              })
            )
        )
      ).subscribe((data) => {
        if (data == undefined) {
          this.filteredItems = [];
        } else {
          this.filteredItems = data;
        }
      });
  }

  selectedFiltereditemChange(params) {
    this.selectedItem = this.filteredItems.find((x) => x.sku == params.value);
  }

  downloadReport(): void {
    this.reportsService
      .getLandedCostReportByStockItem(this.selectedItem.id)
      .subscribe((response) => {
        this.helper.saveFile(response, 'LandedCostReport.xlsx');
      });
  }
}


