export class User {
    constructor(
        public email: string,
        public role: string,
        public userName: string,
        private _applicationToken: string,
        private _linnworksToken: string,
        public _expirationDate: Date) { }

    get applicationToken() {
        if (!this._expirationDate || (new Date() > this._expirationDate)) {
            return null;
        }
        return this._applicationToken;
    }

    get linnworksToken() {
        if (!this._expirationDate || (new Date() > this._expirationDate)) {
            return null;
        }
        return this._linnworksToken;
    }
}
