import { Component, Inject, OnInit } from '@angular/core';
import * as moment from "moment";
import { saveAs } from "file-saver-es";
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { ReportsService } from 'src/app/shared/services/reports.service';
import { debounceTime, filter, distinctUntilChanged, tap, switchMap, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HelperService } from 'src/app/shared/services/helper.service';


@Component({
  selector: 'app-sales-by-channel-for-sku',
  templateUrl: './sales-by-channel-for-sku.component.html',
  styleUrls: ['./sales-by-channel-for-sku.component.css']
})
export class SalesByChannelForSkuComponent implements OnInit {
  loadingReport = false;
  baseUrl: string;
  hideDownloadButton = true;
  searchItemsCtrl = new UntypedFormControl();
  filteredItems: any;
  loadingData = false;
  searchItemField: string;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  currentDate = new Date();
  //
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  constructor(
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private helper: HelperService
  ) {
    this.baseUrl = baseUrl;
  }

  selectedItem: any;
  selectedFiltereditemChange(params) {
    this.selectedItem = this.filteredItems.find((x) => x.sku == params.value);
  }

  ngOnInit(): void {
    this.searchItemsCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((term) => term && term.toString().trim().length > 0),
        distinctUntilChanged(),
        tap(() => {
          this.filteredItems = [];
          this.loadingData = true;
        }),
        switchMap((value) =>
          this.http
            .get(this.baseUrl + "api/inventory/getStockItemsByKey/" + value)
            .pipe(
              finalize(() => {
                this.loadingData = false;
              })
            )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.filteredItems = [];
        } else {
          this.filteredItems = data;
        }
      });
  }

  downloadFile() {
    if (!this.range.value["start"] || !this.range.value["end"] || !this.selectedItem) {
      this.openSnackBar("Pick the date range please.", "");
    } else {
      this.loadingReport = true;

      var dstart = this.range.value["start"];
      var dend = this.range.value["end"];



      return this.reportsService
        .getSalesByChannelForSKUExcel(this.selectedItem.id, moment(dstart).format("YYYY-MM-DD"), moment(dend).format("YYYY-MM-DD"))
        .subscribe(
          (result: any) => {
            this.loadingReport = false;
            this.helper.saveFile(result);
          }
        );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
