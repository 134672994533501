import { Component, OnInit } from '@angular/core';
import { HelperService } from "../../../shared/services/helper.service";
import { ReportsService } from "../../../shared/services/reports.service";
import { saveAs } from 'file-saver-es';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-seller-flex-allocation',
  templateUrl: './seller-flex-allocation.component.html',
  styleUrls: ['./seller-flex-allocation.component.css']
})
export class SellerFlexAllocationComponent implements OnInit {
  loadingReport = false;
  currentDate = new Date();

  constructor(
    private helper: HelperService,
    private reportsService: ReportsService
  ) { }

  ngOnInit(): void {
  }

  downloadAllocation() {
    this.reportsService
      .getSellerFlexAllocationReport()
      .subscribe((response) => {
        this.helper.saveFile(response);
      });
  }

  downloadTransfer() {
    this.reportsService
      .getWowcherTransferReport()
      .subscribe((response) => {
        this.helper.saveFile(response);
      });
  }

}
