import { Component, OnInit } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { IAfterGuiAttachedParams } from "ag-grid-community";

@Component({
  selector: "app-image-renderer",
  templateUrl: "./image-renderer.component.html",
  styleUrls: ["./image-renderer.component.scss"],
})
export class ImageRendererComponent implements ICellRendererAngularComp {
  //fullSizeImageRef
  displayFullSizeImage: boolean = false;
  imageSource: string;
  sku: string;
  agInit(params: any): void {
    this.imageSource = params.data.PrimaryImage;
    this.sku = params.data.SKU;
  }

  refresh(params: any): boolean {
    return false;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error("Remove btn: Method not implemented.");
  }
}
