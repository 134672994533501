import { Component, OnInit } from '@angular/core';
import { GeneralSettings } from 'src/app/core/models/general-settings.model';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { takeUntil, tap } from 'rxjs/operators';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { Subject } from 'rxjs';
import { Category } from 'src/app/shared/models/category';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {

  public settings: GeneralSettings;
  public categories: Category[];
  public selectedCategory: Category;
  private destroy$ = new Subject<void>();

  constructor(
    private settingsService: GeneralSettingsService,
    private inventoryService: InventoryService,
    private notifier: SnackBarService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getSettings();
    this.getCategories();
  }

  private getSettings(): void {
    this.settingsService.get().pipe(
      tap((settings: GeneralSettings) => {
        this.settings = settings;
      })
    ).subscribe();
  }

  getCategories() {
    this.inventoryService
      .getCategories()
      .pipe(
        tap(result => {
          this.categories = result;
        }),
        takeUntil(this.destroy$)
        )
      .subscribe();
  }

  selectedCategoryChange(value): void {
    this.selectedCategory = value;
    
  }

  removeCategory(): void {
    const dialogData = new ConfirmDialogModel(
      "Confirm Category Delete",
      "Are you sure? Items with selected category will be moved to Default category"
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.settingsService.removeCategory(this.selectedCategory.categoryId).pipe(
          tap(() => {
            this.notifier.success('Category removed');
            this.categories = this.categories.filter(i => i.categoryId !== this.selectedCategory.categoryId);
            this.selectedCategory = null;
          }),
          takeUntil(this.destroy$)
        ).subscribe();
      }
    });
    
  }

  public save(): void {
    this.settingsService.update(this.settings).pipe(
      tap(() => {
        this.notifier.success('Settings updated');
      })
    ).subscribe();
  }

}
