import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StockItem } from 'src/app/shared/models/purchase.models';
@Injectable({
    providedIn: 'root'
})
export class POItemsBySupplierService {
    counter = 1;
    count: BehaviorSubject<number>;
    stockItems: StockItem[];
    containerSizes: string[] = ["N/A", "20 FT", "40 FT", "40 FT HQ"];
    selectedContainerSize: string;
    currentStatus: string;
    procurementSupplierId: string;
    procurementPurchaseItems: object[] = [];
    purchaseDate: Date;

    constructor() {
        this.stockItems = [];
        this.count = new BehaviorSubject(this.counter);
        this.selectedContainerSize = this.containerSizes[2];
        this.purchaseDate = new Date();
    }

    nextCount() {
        this.count.next(++this.counter);
    }
}
