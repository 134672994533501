import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject, debounceTime, filter, distinctUntilChanged, tap, takeUntil, switchMap, finalize } from 'rxjs';
import { InventoryService } from '../../../shared/services/inventory.service';
import { ReportsService } from "../../../shared/services/reports.service";
import { HelperService } from "../../../shared/services/helper.service";
import * as moment from "moment";

@Component({
  selector: 'app-return-reason-for-each-sku',
  templateUrl: './return-reason-for-each-sku.component.html',
  styleUrls: ['./return-reason-for-each-sku.component.css']
})
export class ReturnReasonForEachSkuComponent implements OnInit {

  currentDate = new Date();
  searchItemsCtrl = new UntypedFormControl();
  filteredItems: any;
  public searchItemField: string;
  destroyedComponentSubj = new Subject<void>();
  loading: boolean = false;
  selectedItem: any;
  selectedCategory: any;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  categories: any[];

  constructor(
    private inventoryService: InventoryService,
    private reportsService: ReportsService,
    private helper: HelperService
    ) { }

  ngOnInit(): void {

    this.inventoryService.getCategories().subscribe(result => {
      this.categories = result;
      this.categories.unshift({
        categoryName: "All",
        categoryId: "",
      });
    });

    this.searchItemsCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((term) => term && term.toString().trim().length > 0),
        distinctUntilChanged(),
        tap(() => {
          this.filteredItems = [];
          this.loading = true;
        }),
        takeUntil(this.destroyedComponentSubj),
        switchMap((value: string) =>
          this.inventoryService.searchStockITemsByKey(value)
            .pipe(
              finalize(() => {
                this.loading = false;
              })
            )
        )
      ).subscribe((data) => {
        if (data == undefined) {
          this.filteredItems = [];
        } else {
          this.filteredItems = data;
        }
      });
  }

  selectedFiltereditemChange(params) {
    this.selectedItem = this.filteredItems.find((x) => x.sku == params.value);
    this.selectedCategory = this.categories[0];
  }

  selectedCategoryChange(value): void {
    this.selectedCategory = value;
    this.selectedItem = null;
  }

  downloadReport(): void {
    const from = this.range.value["start"];
    const to = this.range.value["end"];

    const stockItemId = this.selectedItem?.id ?? '00000000-0000-0000-0000-000000000000';
    const categoryId = (!this.selectedCategory || this.selectedCategory?.categoryId === '') ? '00000000-0000-0000-0000-000000000000' : this.selectedCategory?.categoryId;

    this.reportsService
      .getReturnReasonForEachSKUReport(moment(from).format("YYYY-MM-DD"), moment(to).format("YYYY-MM-DD"), stockItemId, categoryId)
      .subscribe((response) => {
        this.helper.saveFile(response, 'ReturnReasonForEachSKU.xlsx');
      });
  }

}
