<div mat-dialog-content>
    <mat-form-field class="chip-list" appearance="fill">
        <mat-label>Recepients</mat-label>
        <mat-chip-list #chipList aria-label="Recepients selection">
            <mat-chip *ngFor="let r of recepientsArr" (removed)="remove(r)">
                {{r}}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <input placeholder="New recepient..." [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
    </mat-form-field>

</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()" cdkFocusInitial>Cancel</button>
    <button mat-button (click)="sendToMe()">Only me - Kabir</button>
    <button mat-button (click)="sendToRecepients()" [disabled]="recepientsArr.length < 1">Only recepients above</button>
    <button mat-button (click)="sendToMeAndRecepients()" [disabled]="recepientsArr.length < 1">Me + Recepients
        above</button>
</div>