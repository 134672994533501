import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { LoaderService } from "src/app/core/services/loader.service";
import { RepositoryService } from "src/app/core/services/repository.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import {
  AddNewChannelFeeComponent,
  ChannelFee,
} from "./add-new-channel-fee/add-new-channel-fee.component";
import { getHeaderTemplate } from "src/app/shared/utils/ag-grid-helper";
import { CostSheetService } from "../../../shared/services/cost-sheet.service";

@Component({
  selector: "app-channel-fees",
  templateUrl: "./channel-fees.component.html",
  styleUrls: ["./channel-fees.component.css"],
})
export class ChannelFeesComponent implements OnInit {
  channelFees: any[];
  loadingData = false;
  selectedItem: any;

  public gridApi;
  public gridColumnApi;
  public columnDefs;
  public defaultColDef;
  public rowData = [];
  public headerHeight;
  public columnTypes;
  public rowSelection;
  channels: { id: number; name: string }[];
  feeTypes: { id: number; name: string }[];

  constructor(
    private repo: RepositoryService,
    private costSheetService: CostSheetService,
    private snackBar: SnackBarService,
    public dialog: MatDialog,
    private loader: LoaderService
  ) {
    this.defaultColDef = {
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      headerComponentParams: {
        template: getHeaderTemplate(),
      },
    };
    this.columnDefs = [
      { headerName: "Channel", field: "channelName", resizable: true },
      { headerName: "Title", field: "title", resizable: true, editable: true },
      { headerName: "Type", field: "channelFeeTypeName", resizable: true },
      {
        headerName: "Value",
        field: "feeValue",
        resizable: true,
        editable: true,
      },
    ];
    this.headerHeight = 60;

    this.rowSelection = "single";

    this.columnTypes = {
      numberColumn: {
        filter: "agNumberColumnFilter",
      },
    };
  }

  ngOnInit(): void {
    this.channels = [
      { id: 1, name: "AmazonUK" },
      { id: 2, name: "Ebay" },
      { id: 3, name: "Wayfair" },
      { id: 4, name: "Amazon FBA" },
      { id: 8, name: "Seller Flex" },
    ];

    this.feeTypes = [
      { id: 0, name: "Constant" },
      { id: 1, name: "Percentage" },
    ];
  }

  addChannelFee() {
    const dialogRef = this.dialog.open(AddNewChannelFeeComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.value === true) {
        this.getChannelFees();
      }
    });
  }

  updateChannelFee(channelFee: ChannelFee) {
    this.costSheetService.updateChannelFee(channelFee).subscribe(
      (result) => {
        let api = this.gridApi;
        let itemsToUdpate = [];

        let rowData = this.getRowData();
        let i = rowData.find((x) => x.id === result.id);
        i.title = result.title;
        i.feeValue = result.feeValue;
        itemsToUdpate.push(i);
        api.applyTransaction({ update: itemsToUdpate });
        this.snackBar.openSnackBar("Successfully updated", "Shipping Fee");
      },
      (error) => {
        this.snackBar.openSnackBar("Something went wrong", "Error");
      }
    );
  }

  removeSelectedFee() {
    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.loader.show();
        this.costSheetService.removeChannelFee(this.selectedItem.id).subscribe(
          (result) => {
            let itemsToRemove: ChannelFee[] = [];
            itemsToRemove.push(this.selectedItem);
            this.gridApi.applyTransaction({ remove: itemsToRemove });
            this.loader.hide();
            this.snackBar.openSnackBar("Succesfully removed", "Channel Fee");
          },
          (error) => {
            this.loader.hide();
            this.snackBar.openSnackBar("Something went wrong", "Error");
          }
        );
      }
    });
  }

  getChannelFees() {
    this.loadingData = true;
    this.costSheetService.getChannelFees().subscribe(
      (result) => {
        result.forEach((r) => {
          let channel = this.channels.find((x) => x.id === r.channelId);
          r.channelName = channel.name;
          let feeType = this.feeTypes.find((x) => x.id === r.channelFeeType);
          r.channelFeeTypeName = feeType.name;
        });
        this.channelFees = result;
        let api = this.gridApi;
        api.setRowData([]);
        api.applyTransaction({ add: this.channelFees });
        this.loadingData = false;
      },
      (error) => {
        this.snackBar.openSnackBar("Something went wrong", "Error");
        this.loadingData = false;
      }
    );
  }

  // // // Grid actions
  onCellValueChanged(event) {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    this.updateChannelFee(event.data);
  }

  getRowData() {
    let rowData = [];
    this.gridApi.forEachNode(function (node) {
      rowData.push(node.data);
    });
    return rowData;
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onSelectionChanged(params) {
    let selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow) {
      this.selectedItem = selectedRow[0];
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(30);
    this.gridApi.sizeColumnsToFit();

    this.getChannelFees();
  }

  getRowHeight(params) {
    return 30;
  }
}
