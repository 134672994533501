import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-last-weeks-sales',
  templateUrl: './last-weeks-sales.component.html',
  styleUrls: ['./last-weeks-sales.component.css'],
  providers: [DatePipe]
})
export class LastWeeksSalesComponent implements OnInit {
  http: HttpClient;
  baseUrl: string;
  loadingData = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  weekSales: any[];

  displayedColumns: string[] = ['sku', 'week1', 'week2', 'week3', 'week4', 'week5', 'week6', 'week7', 'week8', 'avgSales'];

  dataSource = new MatTableDataSource<any>();

  columnsWeeksNames = new Array<string>(8);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private _snackBar: MatSnackBar,
    public datepipe: DatePipe) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    this.getLastWeekSales();
  }

  getLastWeekSales(): void {
    this.loadingData = true;
    this.http.get<any[]>(this.baseUrl + 'api/procurement/lastWeeksSales/' + this.data.stockItemId)
      .subscribe(result => {
        this.weekSales = result;
        this.dataSource = new MatTableDataSource<any[]>(this.weekSales);
        this.fillForecastColumns();
        this.loadingData = false;

      }, error => {
        this.loadingData = false;
        console.error(error);
        this.openSnackBar('Something went wrong (', '');
      });
    this.fillForecastColumns();
    this.dataSource = new MatTableDataSource<any>(this.weekSales);
  }

  fillForecastColumns(): void {
    for (let i = 0; i < 8; i++) {

      for (let j = 8; j > 0; j--) {
        var now = new Date();
        now.setDate(now.getDate() - ((j + 1) * 7));
        var startDay = 5; //0 = sunday, 1 = monday etc.
        var d = now.getDay(); //get the current day

        var weekStart = new Date(now.valueOf() + 7 * 86400000); //rewind to start day

        var weekEnd = new Date(weekStart.valueOf() + 6 * 86400000);

        const formattedDateFrom = this.datepipe.transform(weekStart, 'dd');
        const formattedDateTo = this.datepipe.transform(weekEnd, 'dd/MM');

        this.columnsWeeksNames[i] = j.toString() + ' - ' + formattedDateFrom + '-' + formattedDateTo.toString();
        i = i + 1;
      }

    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }

}
