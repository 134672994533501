<mat-card>
  <mat-card-header>
    <mat-card-title>Monthly Sales Report</mat-card-title>
    <mat-card-subtitle
      >Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle
    >
    <mat-card-subtitle
      >Shows sales during last year groupped by month</mat-card-subtitle
    >
  </mat-card-header>
  <div class="row">
    <div class="col">
      <button
        mat-raised-button
        color="primary"
        (click)="downloadFile()"
        [disabled]="loadingReport"
      >
        Download report
      </button>
    </div>
  </div>
</mat-card>
<br />
