
<div class="main-form-container">

<mat-grid-list cols="2" rowHeight="100px">
    <mat-grid-tile [colspan]="2" [rowspan]="1">
        <mat-form-field appearance="standard">
            <mat-label>Forecast to override:</mat-label>
            <input matInput placeholder="0" type="number" [value]="forecastOverride" [min]="0"
                [(ngModel)]="forecastOverride">
        </mat-form-field>

    </mat-grid-tile>

    <mat-grid-tile [colspan]="2" [rowspan]="1">
        <button mat-raised-button color="primary" (click)="passValue()" mat-dialog-close>Override Forecast</button>

    </mat-grid-tile>

</mat-grid-list>
</div>
