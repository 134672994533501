<div id="settings-container">

    <mat-tab-group>
        <mat-tab label="Purchase Orders Settings">
            <div class="d-flex-column gap-16">
                <p>Purchase Orders Settings</p>
                <mat-form-field *ngIf="settings">
                    <mat-label>Exchange Rate:</mat-label>
                    <input matInput [(ngModel)]="settings.defaultExchangeRate" type="number" min="0">
                </mat-form-field>
                <mat-form-field *ngIf="settings">
                    <mat-label>Interval between Goods Ready & Shipping:</mat-label>
                    <input matInput [(ngModel)]="settings.intervalGoodsReadyAndShipping" type="number" min="0">
                </mat-form-field>
                <mat-form-field *ngIf="settings">
                    <mat-label>Interval between Shipping & Docking:</mat-label>
                    <input matInput [(ngModel)]="settings.intervalShippingAndDocking" type="number" min="0">
                </mat-form-field>
                <mat-form-field *ngIf="settings">
                    <mat-label>Interval between Docking & Expected Delivery Slot:</mat-label>
                    <input matInput [(ngModel)]="settings.intervalDockingAndExpectedDelivery" type="number" min="0">
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="save()">Save</button>
            </div>

        </mat-tab>

        <mat-tab label="Channels & Prices">
            <div class="d-flex-column gap-8 w-half">
                <p>Sales Channels price difference (percentage) </p>
                <span class="mg-b24"><b>Amazon price is base price - 100 %</b></span>

                <p>Amazon Seller Flex, %</p>
                <mat-form-field *ngIf="settings">
                    <mat-label>Amazon Seller Flex</mat-label>
                    <input matInput [(ngModel)]="settings.amazonSellerFlexPricePercentage" type="number" min="0">
                </mat-form-field>

                <p>Ebay, %</p>
                <mat-form-field *ngIf="settings">
                    <mat-label>Ebay</mat-label>
                    <input matInput [(ngModel)]="settings.ebayPricePercentage" type="number" min="0">
                </mat-form-field>

                <p>Mirakl, %</p>
                <mat-form-field *ngIf="settings">
                    <mat-label>Mirakl</mat-label>
                    <input matInput [(ngModel)]="settings.miraklPricePercentage" type="number" min="0">
                </mat-form-field>

                <p>Hometreats, %</p>
                <mat-form-field *ngIf="settings">
                    <mat-label>Hometreats</mat-label>
                    <input matInput [(ngModel)]="settings.hometreatsPricePercentage" type="number" min="0">
                </mat-form-field>

                <p>Range, %</p>
                <mat-form-field *ngIf="settings">
                    <mat-label>Range</mat-label>
                    <input matInput [(ngModel)]="settings.rangePricePercentage" type="number" min="0">
                </mat-form-field>

                <p>Debenhams, %</p>
                <mat-form-field *ngIf="settings">
                    <mat-label>Debenhams</mat-label>
                    <input matInput [(ngModel)]="settings.debenhamsPricePercentage" type="number" min="0">
                </mat-form-field>

                <p>Tesco, %</p>
                <mat-form-field *ngIf="settings">
                    <mat-label>Tesco</mat-label>
                    <input matInput [(ngModel)]="settings.tescoPricePercentage" type="number" min="0">
                </mat-form-field>

                
                <button mat-raised-button color="primary" (click)="save()">Save</button>
            </div>
        </mat-tab>

        <mat-tab label="Tools">
            <div class="category-dropdown">
                <mat-form-field class="po-form-field">
                    <mat-label>Category:</mat-label>
                    <mat-select [(value)]="selectedCategory" (selectionChange)="selectedCategoryChange($event.value)">
                        <mat-option *ngFor="let cat of categories" [value]="cat">
                            {{ cat.categoryName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <button mat-raised-button color="primary" (click)="removeCategory()">Move items to Default and remove
                category</button>
        </mat-tab>
    </mat-tab-group>
</div>