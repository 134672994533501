import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { saveAs } from 'file-saver-es';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  GetStartEndOfPeriodByWeekStart(weekStart: number, days: number): [Date, Date] {
    var start = new Date();

    start.setDate(start.getDate() - (start.getDay() + weekStart) % 7);

    start.setDate(start.getDate() - days);

    var end = new Date(start.getFullYear(), start.getMonth(), start.getDate() + (days - 1));

    return [start, end];
  }

  GetStartEndOfPeriodFromYesterday(days: number): [Date, Date] {
    let end = new Date();
    end.setDate(end.getDate() - 1);
    let start = new Date(end.getFullYear(), end.getMonth(), end.getDate() - days)
    return [start, end];
  }

  getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get('content-disposition');
      const r = /(?:filename=")(.+)(?:")/;
      filename = r.exec(contentDisposition)[1];
    }
    catch (e) {
      filename = 'myfile.pdf';
    }
    return filename;
  }

  saveFile(response: HttpResponse<Blob>, expectedFileName: string = null) {
    const fileName = expectedFileName ?? this.getFileName(response);
    saveAs(response.body, fileName);
  }

  valueIsNaN(value: number) {
    return isNaN(value) ? 0 : value;
  }

  roundNumber(value: number): number {
    return Math.round(value * 100) / 100;
  }

  generateShippingDate(goodsReadyDate: any): any {
    return moment(goodsReadyDate).add(7, 'days').format('DD/MM/YY');
  }

  generateDockingDate(shippingDate: any, transitTime: number): any {
    return moment(shippingDate).add(transitTime, 'days').format('DD/MM/YY');
  }

  generateExpectedDeliverySlot(dockingDate: any, transitTime: number): any {
    return moment(dockingDate).add(transitTime, 'days').format('DD/MM/YY hh:mm');
  }

  asDate(value) {
    // Parse date from string format 'dd/MM/yy HH:mm'
    if (value.length === 14) {
      const year = 20 + value.substr(6, 2);
      const month = value.substr(3, 2) - 1;
      const day = value.substr(0, 2);
      const hour = value.substr(9, 2);
      const mins = value.substr(12, 2);
      return new Date(year, month, day, hour, mins);
    }
    // Parse date from string format 'dd/MM/yy'
    if (value.length === 8) {
      const year = 20 + value.substr(6, 2);
      const month = value.substr(3, 2) - 1;
      const day = value.substr(0, 2);
      return new Date(year, month, day);
    }
    return null;
  }

  isValidDate(dateValue) {
    // 'dd/MM/yy'
    var pattern = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{2}$/;
    var m = dateValue.match(pattern);
    // if (!m)
    //     return false;
    // var d = new Date(dateValue);
    // if (d.getMonth()+1 == parseInt(m[1], 10) && d.getDate() ==parseInt(m[2], 10)) {
    //     return true;
    // }
    // return false;
    if (m) {
      return true;
    }
    return false;
  }
}
