<mat-card>
  <mat-card-header>
    <mat-card-title>Stock Value Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>Stock value report with date choice. Category listed also. Total stock value in Cell F2
    </mat-card-subtitle>
  </mat-card-header>

  <div class="row">
    <div class="col">
      <mat-form-field appearance="fill">
        <mat-label>Report date</mat-label>
        <input matInput [matDatepicker]="picker3" [formControl]="date" (dateChange)="setDate($event.value)" />
        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
        <mat-datepicker #picker3></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col">
      <button mat-raised-button color="primary" (click)="downloadReport()" [disabled]="loadingReport"
        style="float: left">
        Download Report
      </button>
    </div>

    <div class="col">
      <button mat-raised-button color="primary" (click)="getStockValuationReport()" [disabled]="loadingReport"
        style="float: right">
        Show Report
      </button>
    </div>
  </div>
</mat-card>
<br />

<div [hidden]="!reportItems">
  <mat-card>
    <div class="row">
      <div [hidden]="!reportItems">
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Category Column -->
            <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef>Category</th>
              <td mat-cell *matCellDef="let item">{{ item.category }}</td>
            </ng-container>

            <!-- SKU Column -->
            <ng-container matColumnDef="sku">
              <th mat-header-cell *matHeaderCellDef>SKU</th>
              <td mat-cell *matCellDef="let item">{{ item.sku }}</td>
            </ng-container>

            <!-- StockLevel Column -->
            <ng-container matColumnDef="stockLevel">
              <th mat-header-cell *matHeaderCellDef>Stock Level</th>
              <td mat-cell *matCellDef="let item">{{ item.stockLevel }}</td>
            </ng-container>

            <!-- Title Column -->
            <ng-container matColumnDef="itemTitle">
              <th mat-header-cell *matHeaderCellDef>Title</th>
              <td mat-cell *matCellDef="let item">{{ item.itemTitle }}</td>
            </ng-container>

            <!-- Cost Price Column -->
            <ng-container matColumnDef="purchasePrice">
              <th mat-header-cell *matHeaderCellDef>Cost Price</th>
              <td mat-cell *matCellDef="let item">
                {{ item.purchasePrice | number: "1.2-2" }}
              </td>
            </ng-container>

            <!-- TotalValue Column -->
            <ng-container matColumnDef="totalValue">
              <th mat-header-cell *matHeaderCellDef>Total Value</th>
              <td mat-cell *matCellDef="let item">
                {{ item.totalValue | number: "1.2-2" }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; let even = even; columns: displayedColumns" [ngClass]="{ gray: even }"
              class="table-row"></tr>
          </table>
        </div>
      </div>
    </div>
  </mat-card>
</div>