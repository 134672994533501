import { MaterialModule } from "./../../../material/material.module";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PurchaseDocsComponent } from "./purchase-docs.component";

@NgModule({
  declarations: [PurchaseDocsComponent],
  exports: [PurchaseDocsComponent],
  imports: [CommonModule, MaterialModule],
})
export class PurchaseDocsModule {}
