import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-qc-photos-dialog",
  templateUrl: "./qc-photos-dialog.component.html",
  styleUrls: ["./qc-photos-dialog.component.css"],
})
export class QcPhotosDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}
}
