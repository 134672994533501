import { Injectable } from "@angular/core";
import * as ExcelJS from "exceljs";
import * as FileSaver from "file-saver-es";

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  constructor() {}

  public exportToExcel(data: Array<Object>, columns?: Array<{ header: string, key: string }>, fileName?: string): void {
    if (!data?.length) return;
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(fileName);
    sheet.columns = columns ?? Object.keys(data[0]).map((x) => ({ header: x, key: x }));
    data.forEach((jsonRow: any, i: number) => {
      let cellValues = { ...jsonRow };
      sheet.addRow(cellValues);
    });
    workbook.xlsx.writeBuffer().then((data: ExcelJS.Buffer) => {
      this.saveAsExcelFile(data, fileName ?? "Export");
    });
  }

  private saveAsExcelFile(buffer: ExcelJS.Buffer, fileName: string): void {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, `${fileName}${EXCEL_EXTENSION}`);
  }
}
