import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  isDevMode,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import {
  Gallery,
  GalleryItem,
  ImageItem,
  ImageSize,
  ThumbnailsPosition,
} from "ng-gallery";
import { Lightbox } from "ng-gallery/lightbox";
import { LoaderService } from "src/app/core/services/loader.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { FileService } from "src/app/shared/services/file.service";
import { InventoryService } from "src/app/shared/services/inventory.service";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { saveAs } from "file-saver-es";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-inventory-images",
  templateUrl: "./inventory-images.component.html",
  styleUrls: ["./inventory-images.component.scss"],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryImagesComponent implements OnInit, OnChanges {
  @ViewChild("inputFile") inputFile: ElementRef;

  categories = [
    { name: "White Background", value: "white-background" },
    { name: "White Background with size", value: "white-background-with-size" },
    { name: "Life Style Photo", value: "lifestyle" },
    { name: "Close Up Photo", value: "closeup" },
    { name: "High Res Photo", value: "highres" },
    { name: "Spec Sheet", value: "specsheet" },
    { name: "Infographics", value: "infographics" },
    { name: "Video", value: "videos" },
    { name: "A+ Content", value: "a-plus-content" },
    { name: "General Photos", value: "general-photos" },
    { name: "AI Files", value: "ai-files" },
    { name: "Instructions", value: "instructions" }
  ];

  @Input() stockItemId: string;
  category: string;
  items: GalleryItem[];
  imageData: any = null;
  itemInfo: any;
  selectedFiles: FileList;

  constructor(
    private fileService: FileService,
    private inventoryService: InventoryService,
    private snackBar: SnackBarService,
    public gallery: Gallery,
    public lightbox: Lightbox,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private loader: LoaderService,
    private http: HttpClient
  ) {}

  ngOnChanges() {
    this.initialData();
  }

  ngOnInit(): void {
    if (this.stockItemId) {
      return;
    }
    this.stockItemId = isDevMode()
      ? "3cd49804-1584-4997-8cde-2788ec015ceb" //KA720
      : sessionStorage.getItem("lwkr-stockitemid-ii");
    this.initialData();
  }

  initialData() {
    this.inventoryService
      .getItemTitleByid(this.stockItemId)
      .subscribe((result) => {
        this.itemInfo = result;
      });

    this.getImages();

    const lightboxRef = this.gallery.ref("lightbox");
    lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Top,
      dots: true,
      nav: true,
    });
    lightboxRef.load(this.items);
  }

  selectFiles(event) {
    this.selectedFiles = event.target.files;
  }

  uploadFiles(event) {
    this.selectedFiles = event.target.files;
    const baseCategory: string = this.category === "videos" ? "videos" : "images";

    for (let i = 0; i < this.selectedFiles.length; i++) {
      let category: string = "images";
      if (this.selectedFiles[i].type.includes("video")) {
        category = "videos";
      }
      if (baseCategory != category) {
        this.snackBar.warning(
          `You can't upload ${category} to ${this.category} section`
        );
        continue;
      }

      if (this.selectedFiles[i].type.includes("pdf") && (this.category !== "a-plus-content"  && this.category !== "instructions")){
        this.snackBar.warning(
          `You can't upload PDF files to ${this.category} section`
        );
        continue;
      }

      
      this.uploadFile(this.selectedFiles[i], category);
    }
  }

  add(category: string) {
    this.category = category;
    this.inputFile.nativeElement.click();
  }

  remove(category, img) {
    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fileService.removeImageByItemId(this.stockItemId, img).subscribe(
          () => {
            this.imageData[category].images = this.imageData[
              category
            ].images.filter((x) => x.url !== img);
            this.cd.detectChanges();
            this.snackBar.success("Successfully removed.");
          },
          () => {
            this.snackBar.error("Error");
          }
        );
      }
    });
  }

  getImages() {
    this.fileService.getItemImagesByItemId(this.stockItemId).subscribe(
      (result) => {
        this.imageData = {};
        result.forEach((r) => {
          this.imageData[r.categoryName] = r;
          this.items = [];
          r.images.forEach((image) => {
            this.items.push(
              new ImageItem({ src: image.url, thumb: image.url })
            );
          });
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  loadCategoryToLightBox(category: string) {
    this.items = [];
    this.imageData[category].images.forEach((image) => {
      this.items.push(new ImageItem({ src: image.url, thumb: image.url }));
    });

    const lightboxRef = this.gallery.ref("lightbox");

    lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Right,
      dots: true,
      nav: true,
    });

    lightboxRef.load(this.items);
  }

  copyToSpecSheet(currentCategory, key) {
    if (
      this.imageData.specsheet &&
      this.imageData.specsheet.images.length >= 6
    ) {
      this.snackBar.warning(
        "You cant select more than 3 images for spec sheet!"
      );
      return;
    }

    let newKey = key.replace(currentCategory, "specsheet");
    this.fileService.copyToSpecSheet(key, newKey).subscribe((result) => {
      if (!this.imageData.specsheet) {
        this.imageData.specsheet = { images: [result] };
      } else {
        this.imageData["specsheet"].images.push(result);
      }
      this.snackBar.success("Image was successfuly selected for Spec Sheet");
      this.loadCategoryToLightBox("specsheet");
      this.cd.detectChanges();
      this.loader.hide();
    });
  }

  uploadFile(file, category: string) {
    let limitSize: number = 52428800;
    if (category === "videos") {
      limitSize = 209715200;
    }
    if (file.size > limitSize) {
      this.snackBar.warning(
        `File size cannot be more than ${Math.floor(limitSize / 1000000)} MB`
      );
      return;
    }

    const formData = new FormData();
    if (!this.itemInfo) {
      this.itemInfo.sku = "_";
      this.itemInfo.title = "_";
    }

    let itemTitle = this.itemInfo.title.replace(/\s/g, "_");
    let timeStamp = moment().format("YYMMDDHHmmssSSS");
    let fileExtension = file.name.substring(file.name.indexOf("."));
    let fileKey = `${this.stockItemId}/${this.category}/${this.itemInfo.sku}_${itemTitle}_${this.category}_${timeStamp}${fileExtension}`;

    formData.append(fileKey, file);

    this.fileService.upload(formData, category).subscribe((result) => {
      if (!this.imageData[this.category]) {
        this.imageData[this.category] = { images: [result] };
      } else {
        this.imageData[this.category].images.push(result);
      }
      if (category === "images") {
        this.loadCategoryToLightBox(this.category);
      }
      this.cd.detectChanges();
      this.loader.hide();
    });
  }

  downloadAllPhotos() {
    const array = Object.entries(this.imageData);
    for (let index = 0; index < array.length; index++) {
      const category: [string, any] = array[index];
      if (category[0] === "videos") {
        return;
      }
      category[1].images.forEach((image) => {
        this.downloadFile(image);
      });
    }
  }

  downloadingImageCounter: number = 0;
  downloadImage(image) {
    this.downloadingImageCounter++;
    var imageByteArray = this.getBase64ImageFromURL(
      image.url.toString().replaceAll('+', '%2B') + "?x-request=html"
    );
    imageByteArray.then((result: any) => {
      const fileName = image.key.split("/").pop();
      saveAs(result, fileName);
      this.downloadingImageCounter--;
    });
  }

  downloadFile(file: any) {
    this.fileService
      .downloadInventoryFile(file.key.replaceAll('&', '%26'))
      .subscribe((response) => {
        const responseBlob = new Blob([response], { type: response.type });
        saveAs(responseBlob, this.prettifyFileName(file.key));
      });
  }

  prettifyFileName(fileName: string) {
    return fileName.split("/").pop();
  }

  downloadImageByURL(url: any, filename: string): void {
    this.http.get(url, { responseType: 'blob', observe: "response" }).subscribe(blob => {
      saveAs(blob, filename.split("/").pop());
    });
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/jpg");
        resolve(dataURL);
      };
      img.onerror = (error) => {
        window.open(url);
        reject(error);
      };
      img.src = url;
    });
  }
}
