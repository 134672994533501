import { CostSheetService } from "src/app/shared/services/cost-sheet.service";
import { Component, Inject, OnInit, Optional } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { CostingChannelConfig } from "src/app/shared/models/CostingChannelConfig";

@Component({
  selector: "app-channel-configuration-mat-window",
  templateUrl: "./channel-configuration-mat-window.component.html",
  styleUrls: ["./channel-configuration-mat-window.component.scss"],
})
export class ChannelConfigurationMatWindowComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChannelConfigurationMatWindowComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private costSheetService: CostSheetService
  ) {}

  profileForm: UntypedFormGroup;
  validator = [Validators.required, Validators.min(0)];
  ngOnInit(): void {
    this.costSheetService
      .getCostingChannelConfig(this.data.channelId)
      .subscribe((model: CostingChannelConfig) => {
        this.profileForm = new UntypedFormGroup({
          channelFee: new UntypedFormControl(model?.channelFee ?? 0, this.validator),
          advertisingFee: new UntypedFormControl(
            model?.advertisingFee ?? 0,
            this.validator
          ),
          transactionFee: new UntypedFormControl(
            model?.transactionFee ?? 0,
            this.validator
          ),
        });
      });
  }

  onSubmit() {
    const model: CostingChannelConfig = {
      channelId: this.data.channelId,
      advertisingFee: this.profileForm.value.advertisingFee,
      channelFee: this.profileForm.value.channelFee,
      transactionFee: this.profileForm.value.transactionFee,
    };
    this.costSheetService
      .updateCostingChannelConfiguration(model)
      .subscribe(() => this.dialogRef.close());
  }

  cancel() {
    this.dialogRef.close();
  }
}
