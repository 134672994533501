<mat-card>
    <mat-card-header>
        <mat-card-title>Arrow Reports</mat-card-title>
        <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
        <mat-card-subtitle>Reports by depots</mat-card-subtitle>
    </mat-card-header>
    <div style="display: flex; flex-direction: row; align-items: flex-end; gap: 20px">


            <button mat-raised-button color="primary" (click)="downloadReportAirdrie()" [disabled]="loading">
                Download Airdrie
            </button>

            <button mat-raised-button color="primary" (click)="downloadReportEnfield()" [disabled]="loading">
                Download Einfield
            </button>

            <button mat-raised-button color="primary" (click)="downloadReportWigan()" [disabled]="loading">
                Download Wigan
            </button>

            <button mat-raised-button color="primary" (click)="downloadReportWorcester()" [disabled]="loading">
                Download Worcester
            </button>
    </div>
</mat-card>
<br />