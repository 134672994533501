import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { RepositoryService } from "src/app/core/services/repository.service";
import { DatePipe } from "@angular/common";
import { UntypedFormControl } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { Router } from "@angular/router";
import { POService } from "../po-view/po.service";
import { PurchaseService } from "src/app/shared/services/purchase.service";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { purchaseOrderNumberComparator } from "src/app/shared/utils/functions";
import * as moment from "moment";
import { getHeaderTemplate } from "src/app/shared/utils/ag-grid-helper";
import { POPropsDialogComponent } from "../po-view/po-props-dialog/po-props-dialog.component";
import { Supplier } from "src/app/shared/models/Supplier";
import { Category } from "src/app/shared/models/category";
import { InventoryService } from "src/app/shared/services/inventory.service";

class Purchase {
  purchaseID: string;
  poNumber: string;
  supplierName: string;
  orderRaisedDate: Date;
  lines: number;
  totalUSD: number;
  status: string;
  stringItems: string;
  user: string;
  createdDate: Date;
}

@Component({
  selector: "app-po-list",
  templateUrl: "./po-list.component.html",
  styleUrls: ["./po-list.component.scss"],
  providers: [DatePipe],
})
export class POListComponent implements OnInit, OnDestroy {
  loadingData = false;

  // mat-autocomplete
  filteredPOs: Observable<Purchase[]>;
  purchaseOrders: Purchase[];

  itemsCtrl = new UntypedFormControl();

  // mat-select search item (search POs by selected item)
  searchItemsCtrl = new UntypedFormControl();
  filteredItems: any;
  public searchItemField: string;

  // suppliers mat-select
  suppliers: Supplier[];
  selectedSupplier: Supplier;

  // category mat-select
  categories: Category[];
  selectedCategory: Category;

  // mat-checkbox
  statusAll;
  statusPending;
  statusOpen;
  statusDelivered;

  destroyedComponentSubj = new Subject<void>();

  //grid: search
  searchQuery;

  // grid: extension components - remove button and dropdown
  frameworkComponents: any = {};

  // grid: orderItems
  rowData: Purchase[];

  timeFormatter(params) {
    return moment(params.value).format("DD/MM/YYYY HH:mm:ss");
    // let date = new Date(params.value);
    // let minDate = new Date("01/01/2000");
    // if (date < minDate) {
    //   return "undefined";
    // }
    // let dd = String(date.getDate()).padStart(2, "0");
    // let mm = String(date.getMonth() + 1).padStart(2, "0");
    // let yyyy = date.getFullYear();

    // let timePart: string = null;

    // if (params.colDef.headerName === "CreatedDate") {
    //   let hh = date.getHours();
    //   let mins = date.getMinutes();
    //   let sec = date.getSeconds();
    //   timePart = ` ${hh}:${mins}:${sec} `;
    // }

    // let result = dd + "/" + mm + "/" + yyyy;

    // if (timePart) {
    //   result += timePart;
    // }

    // return result;
  }

  valueLineTotal = function (params) {
    return params.data.totalUSD.toFixed(2);
  };

  // grid: Columns
  columnDefs = [
    {
      headerName: "#",
      field: "#",
      colId: "#",
      valueGetter: "node.rowIndex + 1",
      maxWidth: 50,
    },
    {
      headerName: "PO Number",
      field: "poNumber",
      width: 20,
      sortable: true,
      sort: "asc",
      comparator: purchaseOrderNumberComparator,
    },
    { headerName: "Supplier code and name", field: "supplierName", width: 60 },
    {
      headerName: "Order Date",
      field: "orderRaisedDate",
      width: 40,
      sortable: true,
      valueFormatter: this.timeFormatter,
    },
    { headerName: "Lines", field: "lines", width: 20, sortable: true },
    {
      headerName: "Total (USD)",
      field: "totalUSD",
      width: 40,
      sortable: true,
      valueGetter: this.valueLineTotal,
    },
    { headerName: "Status", field: "status", width: 30, sortable: true },
    { headerName: "Goods", field: "stringItems", hide: true },
    { headerName: "User", field: "user", width: 45 },
    {
      headerName: "CreatedDate",
      field: "createdDate",
      width: 35,
      valueFormatter: this.timeFormatter,
    },
  ];

  // grid: headers height
  public headerHeight;

  // grid: def header styles
  public defaultColDef;

  // grid: for updating and editing grid order items data
  public gridApi;

  //grid: selection
  public rowSelection = "single";
  public selectedItem: any;

  constructor(
    private repo: RepositoryService,
    private router: Router,
    private poService: POService,
    private purchaseService: PurchaseService,
    private inventoryService: InventoryService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private snackBar: SnackBarService
  ) {
    this.selectedItem = null;

    this.headerHeight = 50;

    this.defaultColDef = {
      resizable: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      tooltipComponent: "GridTooltipComponent",
      headerComponentParams: {
        template: getHeaderTemplate(),
      },
    };

    this.statusAll = true;
    this.getPurchaseOrders();

    this.searchItemsCtrl.valueChanges
      .pipe(
        debounceTime(500),
        filter((term) => term && term.toString().trim().length > 0),
        distinctUntilChanged(),
        tap(() => {
          this.filteredItems = [];
          this.loadingData = true;
        }),
        takeUntil(this.destroyedComponentSubj),
        switchMap((value) =>
          repo.getList("api/inventory/getStockItemsByKey/" + value).pipe(
            finalize(() => {
              this.loadingData = false;
            })
          )
        )
      )
      .subscribe((data) => {
        if (data == undefined) {
          this.filteredItems = [];
        } else {
          this.filteredItems = data;
        }
      });

    this.getSuppliers();
  }

  // Select suppliers and locations on load
  ngOnInit(): void {
    this.statusAll = true;
    this.statusPending = false;
    this.statusDelivered = false;
    this.statusOpen = false;
  }

  openExtPropsDialog() {
    const dialogRef = this.dialog.open(POPropsDialogComponent, {
      height: "75%",
      width: "70%",
      data: {
        purchaseID: this.selectedItem.purchaseID,
      },
    });
  }

  showQuickFilter: boolean = false;
  dataIsRendered() {
    this.showQuickFilter = true;
  }

  ngOnDestroy() {
    // this.poService.currentPurchase = null;
    this.destroyedComponentSubj.next();
    this.destroyedComponentSubj.complete();
  }

  // grid init
  onGridReady(params) {
    this.gridApi = params.api;

    this.gridApi.sizeColumnsToFit();
  }

  onSelectionChanged(params) {
    let selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow) {
      this.selectedItem = selectedRow[0];
    }
  }

  clearGrid(): void {
    var itemsToUpdate = [];

    this.gridApi.setRowData(itemsToUpdate);
    this.gridApi.redrawRows();
  }

  editPO() {
    if (this.selectedItem != null) {
      this.poService.currentPurchase = this.selectedItem.purchaseID;
      this.poService.returnUrl = "/po-list";
      this.router.navigate(["/home/po-view"], { queryParams: { componentIndex: this.selectedItem.poNumber } });
    }
  }

  removePurchaseOrder(purchase: any): void {
    const dialogData = new ConfirmDialogModel(
      "Confirm Purchase Delete",
      `Are you really want to delete purchase ${purchase.poNumber}?`
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.purchaseService
          .removePurchaseOrderById(purchase.purchaseID)
          .subscribe(() => {
            this.gridApi.applyTransaction({ remove: [purchase] });
            this.snackBar.neutral("Purchase successfully deleted", "");
          });
      }
    });
  }

  //#region Suppliers and categories

  getSuppliers() {

    this.inventoryService
      .getSuppliersForProductScreen()
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe((result) => {
        this.suppliers = result.filter((x) => x.active);

        this.suppliers.unshift({
          supplierName: "All",
          pkSupplierID: ""
        });

        this.selectedSupplier = this.suppliers[0];

        this.selectedSupplierChange(this.selectedSupplier);

        this.loadingData = false;
      });
    // this.repo
    //   .getList("api/inventory/suppliers/")
    //   .pipe(takeUntil(this.destroyedComponentSubj))
    //   .subscribe((result) => {
    //     this.suppliers = result;
    //     this.suppliers.unshift({
    //       supplierName: "All",
    //       pkSupplierID: "00000000-0000-0000-0000-000000000001",
    //     });

    //     this.selectedSupplier = this.suppliers[0];

    //     this.selectedSupplierChange(this.selectedSupplier);

    //     this.loadingData = false;
    //   });
  }

  // mat-select: Cascade cmbx
  selectedSupplierChange(value): void {
    this.getCategories();

    this.gridApi.resetQuickFilter();

    if (this.statusPending) {
      this.getPurchaseByStatusAndSupplier(0);
    } else if (this.statusDelivered) {
      this.getPurchaseByStatusAndSupplier(3);
    } else if (this.statusOpen) {
      this.getPurchaseByStatusAndSupplier(1);
    } else {
      this.getPurchaseOrders();
    }

    this.searchQuery = "";
  }

  getCategories(): void {
    this.loadingData = true;

    if (this.selectedSupplier.supplierName == "All") {
      this.repo.getList("api/inventory/categories").subscribe(
        (result) => {
          this.categories = result;

          this.categories.unshift({
            categoryName: "All",
            categoryId: this.selectedSupplier.pkSupplierID,
          });
          this.selectedCategory = this.categories[0];

          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Cannot get categories");
        }
      );
    } else {
      this.repo
        .getList(
          "api/inventory/categories/" + this.selectedSupplier.pkSupplierID
        )
        .subscribe(
          (result) => {
            this.categories = result;

            this.categories.unshift({
              categoryName: "All",
              categoryId: this.selectedSupplier.pkSupplierID,
            });
            this.selectedCategory = this.categories[0];

            this.loadingData = false;
          },
          (error) => {
            this.loadingData = false;

            this.snackBar.error("Cannot get categories");
          }
        );
    }
  }

  selectedCategoryChange(value): void {
    this.setRowDataBySupplierAndCategory();

    if (this.searchQuery != null || this.searchQuery != "") {
      this.gridApi.setQuickFilter(this.searchQuery);
    }
  }
  //#endregion

  // DELIVER ALL ITEMS (if PO have OPEN Status) and refresh in grid
  deliverAllItems() {
    if (this.selectedItem.status == "OPEN") {
      this.repo
        .create("api/po/deliverAllItems/" + this.selectedItem.purchaseID, null)
        .subscribe(
          (result) => {
            // update in grid
            var poDelivered = this.rowData.filter((item) => {
              return item.purchaseID == this.selectedItem.purchaseID;
            })[0];

            if (this.statusAll == true) {
              poDelivered.status = "DELIVERED";

              var itemsToUpd = [];
              itemsToUpd.push(poDelivered);

              this.gridApi.applyTransaction({ update: itemsToUpd });
              this.gridApi.redrawRows();
            } else if (this.statusOpen == true) {
              var itemsToDel = [];
              itemsToDel.push(poDelivered);

              this.gridApi.applyTransaction({ remove: itemsToDel });
              this.gridApi.redrawRows();
            }

            this.snackBar.success(
              "Items were delivered. " + this.selectedItem.poNumber
            );
          },
          (error) => {
            this.snackBar.error("Something went wrong.");
          }
        );
    } else {
      this.snackBar.warning("Only OPEN POs can be delivered. ");
    }
  }

  //#region GET POs
  // mat-checkbox select by STATUS
  getAllPOs() {
    if (this.statusAll == true) {
      this.statusPending = false;
      this.statusDelivered = false;
      this.statusOpen = false;

      this.getPurchaseOrders();
    }
  }

  setRowDataBySupplierAndCategory() {
    if (this.selectedCategory.categoryName != "All") {
      this.clearGrid();
      var filterData = this.purchaseOrders.filter((item) => {
        return item.stringItems.includes(this.selectedCategory.categoryId);
      });
      this.rowData = filterData;

      if (filterData != null && filterData.length > 0) {
        this.gridApi.applyTransaction({ add: filterData });
        this.gridApi.redrawRows();
      }
    } else {
      this.clearGrid();
      this.gridApi.applyTransaction({ add: this.purchaseOrders });
      this.gridApi.redrawRows();
    }

    if (this.searchQuery != null || this.searchQuery != "") {
      this.gridApi.setQuickFilter(this.searchQuery);
    }
  }

  getPendingPOs() {
    if (this.statusPending == true) {
      this.statusAll = false;
      this.statusDelivered = false;
      this.statusOpen = false;

      this.getPurchaseByStatus(0);
    } else {
      this.statusAll = true;
      this.getAllPOs();
    }
  }

  getOpenPOs() {
    if (this.statusOpen == true) {
      this.statusAll = false;
      this.statusDelivered = false;
      this.statusPending = false;

      this.getPurchaseByStatus(1);
    } else {
      this.statusAll = true;
      this.getAllPOs();
    }
  }

  getDeliveredPOs() {
    if (this.statusDelivered == true) {
      this.statusAll = false;
      this.statusPending = false;
      this.statusOpen = false;

      this.getPurchaseByStatus(3);
    } else {
      this.statusAll = true;
      this.getAllPOs();
    }
  }

  // get Purchase order by status
  getPurchaseByStatus(status) {
    if (this.selectedSupplier.supplierName != "All") {
      this.getPurchaseByStatusAndSupplier(status);
    } else {
      this.repo.getList("api/po/getPurchaseOrdersByStatus/" + status).subscribe(
        (result) => {
          this.purchaseOrders = result;
          this.rowData = result;

          this.setRowDataBySupplierAndCategory();
          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Something went wrong");
        }
      );
    }
  }

  // get Purchase order by status and supplier
  getPurchaseByStatusAndSupplier(status) {
    this.repo
      .getList(
        "api/po/getPurchaseOrdersByStatusAndSupplier/" +
          status +
          "/" +
          this.selectedSupplier.pkSupplierID
      )
      .subscribe(
        (result) => {
          this.purchaseOrders = result;
          this.rowData = result;
          this.setRowDataBySupplierAndCategory();

          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Something went wrong");
        }
      );
  }

  // get all Purchase orders (FOR STATUS 'ALL')
  getPurchaseOrders(): void {
    this.loadingData = true;

    if (
      this.selectedSupplier != null &&
      this.selectedSupplier.supplierName != "All"
    ) {
      this.getPurchaseOrdersBySupplier();
    } else {
      this.repo
        .getList("api/po/getAllPurchaseOrders")
        .pipe(takeUntil(this.destroyedComponentSubj))
        .subscribe(
          (result) => {
            this.purchaseOrders = result;
            this.rowData = result;

            this.clearGrid();

            this.gridApi.setRowData(this.rowData);
            this.gridApi.redrawRows();

            this.setRowDataBySupplierAndCategory();
            this.loadingData = false;
          },
          (error) => {
            this.loadingData = false;

            this.snackBar.error("Something went wrong");
          }
        );
    }
  }

  getPurchaseOrdersBySupplier(): void {
    this.loadingData = true;
    this.repo
      .getList(
        "api/po/getPurchaseOrdersBySupplier/" +
          this.selectedSupplier.pkSupplierID
      )
      .subscribe(
        (result) => {
          this.purchaseOrders = result;
          this.rowData = result;

          this.clearGrid();

          this.gridApi.setRowData(this.rowData);
          this.gridApi.redrawRows();

          this.setRowDataBySupplierAndCategory();

          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Something went wrong");
        }
      );
  }
  //#endregion

  // mat-autocomplete - search and choose item to find POs
  selectedFiltereditemChange(params) {
    this.loadingData = true;

    if (this.statusAll == true) {
      this.getPurchaseBySKU(params.value);
    } else {
      this.getPurchasesBySKUandStatus();
    }
  }

  // SEARCH PO or item
  onFilterTextBoxChanged(value) {
    this.searchQuery = value;
    this.gridApi.setQuickFilter(this.searchQuery);
  }

  // clear search field
  clearSearchFilter() {
    this.searchQuery = "";
    this.onFilterTextBoxChanged(this.searchQuery);
  }

  // get ALL POs by SKU
  getPurchaseBySKU(SKU) {
    this.repo.getList("api/po/getPOsBySKU/" + SKU).subscribe(
      (result) => {
        this.purchaseOrders = result;
        this.rowData = result;

        this.clearGrid();

        this.gridApi.setRowData(this.rowData);
        this.gridApi.redrawRows();

        this.loadingData = false;
      },
      (error) => {
        this.loadingData = false;

        this.snackBar.error("Something went wrong");
      }
    );
  }

  getPurchasesBySKUandStatus() {
    var currentStatus = 0; // PENDING = 0, OPEN = 1, PARTIAL = 2, DELIVERED = 3

    if (this.statusDelivered == true) {
      currentStatus = 3;
    } else if (this.statusOpen == true) {
      currentStatus = 1;
    }

    this.repo
      .getList(
        "api/po/getPurchaseOrdersByStatusANDSKU/" +
          currentStatus +
          "/" +
          this.searchItemField
      )
      .subscribe(
        (result) => {
          this.purchaseOrders = result;
          this.rowData = result;

          this.clearGrid();

          this.gridApi.setRowData(this.rowData);
          this.gridApi.redrawRows();

          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Something went wrong");
        }
      );
  }

  onRowDoubleClick(event) {
    if (event.data.purchaseID != null) {
      this.poService.currentPurchase = event.data.purchaseID;
      this.poService.returnUrl = "/po-list";
      this.router.navigate(["/home/po-view"], { queryParams: { componentIndex: this.selectedItem.poNumber } });
    }
  }
}
