import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Schedule } from './models/schedule';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private http: HttpClient
  ) { }

  addSchedule(schedule: Schedule) {
    return this.http.post('api/scheduledTasks/addSchedule', schedule);
  }
}
