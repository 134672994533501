import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ColumnApi, GridApi, RowNode } from "ag-grid-community";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LoaderService } from "src/app/core/services/loader.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { Category } from "src/app/shared/models/category";
import { StockItemCosting } from "src/app/shared/models/costing-sheet.models";
import { CostSheetNewService } from "src/app/shared/services/cost-sheet-new.service";
import { HelperService } from "src/app/shared/services/helper.service";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { LandedCostService } from "src/app/shared/services/landed-cost.service";
import { getHeaderTemplate } from "src/app/shared/utils/ag-grid-helper";
import { Tab } from "./../../shared/models/Tab";
import { ChannelConfigurationMatWindowComponent } from "./channel-configuration-mat-window/channel-configuration-mat-window.component";

@Component({
  selector: "app-costing-sheet",
  templateUrl: "./costing-sheet.component.html",
  styleUrls: ["./costing-sheet.component.scss"],
})
export class CostingSheetComponent implements OnInit, OnDestroy {
  constructor(
    private snackBar: SnackBarService,
    private costSheetService: CostSheetNewService,
    private loaderService: LoaderService,
    public dialog: MatDialog,
    private landedCostService: LandedCostService,
    private helper: HelperService,
    private inventroyService: InventoryService
  ) { }

  //#region properties
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  selectedRow;
  rowData: StockItemCosting[] = [];
  rowDataBase: StockItemCosting[] = [];
  quickFilter: string;
  period: [Date, Date];
  categories: Category[];
  selectedCategory: Category;

  isProfitMarginFilter: boolean = false;
  profitMarginFilterValue: number = 0;

  getRowStyle = function (params) {
    if (params.data.landedCost == 0) {
      return { background: "pink" };
    }
  };

  defaultColDef = {
    resizable: true,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    width: 110,
    tooltipComponent: "GridTooltipComponent",
    headerComponentParams: {
      template: getHeaderTemplate(),
    },
    getQuickFilterText: function(params) {
      return "";
    }
  };
  columnDefsBase: any = [
    {
      headerName: "SKU",
      field: "sku",
      resizable: true,
      tooltipField: "sku",
      pinned: "left",
      getQuickFilterText: function(params) {
        return params.value;
      }
    },
    {
      headerName: "Title",
      field: "title",
      resizable: true,
      headerClass: "header-green",
      pinned: "left",
      getQuickFilterText: function(params) {
        return params.value;
      }
    },
    {
      headerName: "Stock Level",
      field: "stockLevel",
      resizable: true,
      pinned: "left",
    },
    {
      headerName: "Last Week Sales",
      field: "lastWeekTotalSales",
      resizable: true,
    },
    {
      headerName: "Weeks Cover",
      field: "weeksCover",
      resizable: true,
    },
    {
      headerName: "Landed Cost GBP",
      field: "landedCost",
      editable: true,
      resizable: true,
      cellStyle: function (params) {
        if (params.data.landedCostIsManualOverrided) {
          return { backgroundColor: "yellow" };
        } else {
          return { backgroundColor: "" };
        }
      },
      valueFormatter: (params) => currencyFormatter(params.value, "£"),
    },
    {
      headerName: "Avverage Landed Cost GBP",
      field: "averageLandedCost",
      resizable: true,
      valueFormatter: (params) => currencyFormatter(params.value, "£"),
    },
  ];
  columnDefs: any;

  tabs: Tab[] = [
    {
      isActive: true,
      title: "All",
      headersToInclude: [],
    },
    {
      isActive: false,
      title: "Overview",
      headersToInclude: [
        "lastWeekTotalSales",
        "overallWeekProfit",
        "landedCost",
        "salesAmazonUK",
        "priceAmazonUK",
        "profitAmazonUK",
        "weekProfitAmazonUK",
        "salesAmazonFBA",
        "priceAmazonFBA",
        "profitAmazonFBA",
        "weekProfitAmazonFBA",
        "salesSellerFlex",
        "priceSellerFlex",
        "profitSellerFlex",
        "weekProfitSellerFlex",
        "salesEBAY",
        "priceEBAY",
        "profitEBAY",
        "weekProfitEBAY",
        "salesWayfair",
        "priceWayfair",
        "profitWayfair",
        "weekProfitWayfair",
        "salesWowcher",
        "priceWowcher",
        "profitWowcher",
        "weekProfitWowcher",
        "salesHometreats",
        "priceHometreats",
        "profitHometreats",
        "weekProfitHometreats",
        "salesBedsale",
        "priceBedsale",
        "profitBedsale",
        "weekProfitBedsale",
      ],
    },
    {
      isActive: false,
      title: "AmazonUK",
      headersToInclude: [
        "salesAmazonUK",
        "priceAmazonUK",
        "channelFeeAmazonUK",
        "advertisingFeeAmazonUK",
        "postageCostAmazonUK",
        "profitAmazonUK",
        "profitPercentageAmazonUK",
        "weekProfitAmazonUK",
        "postalServiceNameAmazonUK",
      ],
    },
    {
      isActive: false,
      title: "AmazonFBA",
      headersToInclude: [
        "salesAmazonFBA",
        "priceAmazonFBA",
        "channelFeeAmazonFBA",
        "advertisingFeeAmazonFBA",
        "postageCostAmazonFBA",
        "profitAmazonFBA",
        "profitPercentageAmazonFBA",
        "weekProfitAmazonFBA",
        "postalServiceNameAmazonFBA",
      ],
    },

    {
      isActive: false,
      title: "SellerFlex",
      headersToInclude: [
        "salesSellerFlex",
        "priceSellerFlex",
        "channelFeeSellerFlex",
        "advertisingFeeSellerFlex",
        "postageCostSellerFlex",
        "profitSellerFlex",
        "profitPercentageSellerFlex",
        "weekProfitSellerFlex",
        "postalServiceNameSellerFlex",
      ],
    },
    {
      isActive: false,
      title: "EBAY",
      headersToInclude: [
        "salesEBAY",
        "priceEBAY",
        "channelFeeEBAY",
        "advertisingFeeEBAY",
        "postageCostEBAY",
        "profitEBAY",
        "profitPercentageEBAY",
        "weekProfitEBAY",
        "postalServiceNameEBAY",
      ],
    },
    {
      isActive: false,
      title: "Wayfair",
      headersToInclude: [
        "salesWayfair",
        "priceWayfair",
        "channelFeeWayfair",
        "advertisingFeeWayfair",
        // "postageCostWayfair",
        "profitWayfair",
        "profitPercentageWayfair",
        "weekProfitWayfair",
        "postalServiceNameWayfair",
      ],
    },
    {
      isActive: false,
      title: "Wowcher",
      headersToInclude: [
        "salesWowcher",
        "priceWowcher",
        "channelFeeWowcher",
        "advertisingFeeWowcher",
        "postageCostWowcher",
        "profitWowcher",
        "profitPercentageWowcher",
        "weekProfitWowcher",
        "postalServiceNameWowcher",
      ],
    },
    {
      isActive: false,
      title: "Hometreats",
      headersToInclude: [
        "salesHometreats",
        "priceHometreats",
        "channelFeeHometreats",
        "advertisingFeeHometreats",
        "postageCostHometreats",
        "profitHometreats",
        "profitPercentageHometreats",
        "weekProfitHometreats",
        "postalServiceNameHometreats",
      ],
    },
    {
      isActive: false,
      title: "Bedsale",
      headersToInclude: [
        "salesBedsale",
        "priceBedsale",
        "channelFeeBedsale",
        "advertisingFeeBedsale",
        "postageCostBedsale",
        "profitBedsale",
        "profitPercentageBedsale",
        "weekProfitBedsale",
        "postalServiceNameBedsale",
      ],
    },
  ];

  channels: any[];
  shippingFees: any = [];
  resettingPostalServices: boolean = false;

  destroyedComponentSubj = new Subject<void>();
  //#endregion

  //#region lifecycle hooks
  ngOnInit(): void {
    this.period = this.helper.GetStartEndOfPeriodFromYesterday(6);
    this.addChannelsInfo();
    this.getCostInfo();
    this.getCategories();
  }

  ngOnDestroy(): void {
    this.destroyedComponentSubj.next();
    this.destroyedComponentSubj.complete();
  }
  //#endregion

  //#region initial data
  getCostInfo() {
    setTimeout(() => {
      this.loaderService.show();
    }, 1000);
    this.costSheetService
      .getCostInfo()
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe((result) => {
        this.rowData = result;
        this.rowDataBase = this.rowData;
        // this.fillChannelsData();
        this.loaderService.hide();
      });
  }

  addChannelsInfo() {
    // Get channels
    this.costSheetService.getChannels().subscribe((result) => {
      this.costSheetService.getShippingFees().subscribe((shippingFees) => {
        this.shippingFees = shippingFees;
        this.channels = result;
        if (this.channels && this.channels.length > 0) {
          let hidden = false;
          let additionalTag = "";
          const channelFees = shippingFees.map((x) => x.name);

          this.channels.forEach((channel) => {
            if (channel.isActive) {
              // if (channel.title === "Wayfair") {
              //   hidden = true;
              //   additionalTag = " - 5%";
              // } else {
              //   hidden = false;
              //   additionalTag = "";
              // }
              const salesColumn = {
                headerName: "Sales " + channel.title,
                field: "sales" + channel.title,
                editable: true,
                resizable: true,
              };

              const salePriceColumn = {
                headerName: "Sales Price " + channel.title + additionalTag,
                field: "price" + channel.title,
                editable: true,
                resizable: true,
                valueFormatter: (params) =>
                  currencyFormatter(params.value, "£"),
              };
              const channelFeeColumn = {
                headerName: "Fees " + channel.title,
                field: "channelFee" + channel.title,
                editable: true,
                resizable: true,
                hide: hidden,
                valueFormatter: (params) =>
                  currencyFormatter(params.value, "£"),
              };
              const advertisingColumn = {
                headerName: "Advertising Fees " + channel.title,
                field: "advertisingFee" + channel.title,
                editable: true,
                hide: hidden,
                resizable: true,
                valueFormatter: (params) =>
                  currencyFormatter(params.value, "£"),
              };
              const postageCostColumn = {
                headerName: "Postage Cost " + channel.title,
                field: "postageCost" + channel.title,
                editable: true,
                resizable: true,
                hide: hidden,
                valueFormatter: (params) =>
                  currencyFormatter(params.value, "£"),
                cellStyle: function (params) {
                  if (!params.colDef.field.includes("postageCost")) {
                    return;
                  }

                  if (params.data[`isOverrided${channel.title}`]) {
                    return { backgroundColor: "#F1948A" };
                  } else {
                    return { backgroundColor: "" };
                  }
                },
              };

              const profitColumn = {
                headerName: "Item Profit " + channel.title,
                field: "profit" + channel.title,
                resizable: true,
                // valueGetter: (params) => this.profitGetter(params),
                valueFormatter: (params) =>
                  currencyFormatter(params.value, "£"),
              };

              const profitPercentageColumn = {
                headerName: channel.title + " GP %",
                field: "profitPercentage" + channel.title,
                resizable: true,
                filter: 'agNumberColumnFilter',
                valueGetter: profitPercentageGetter,
                valueFormatter: (params) => percentFormatter(params.value, "%"),
                // cellStyle: function (params) {
                //   if (params.value < 5) {
                //     return { backgroundColor: "#F1948A" };
                //   } else {
                //     return { backgroundColor: "" };
                //   }
                // },
              };

              const weekProfitColumn = {
                headerName: "Week Profit " + channel.title,
                field: "weekProfit" + channel.title,
                resizable: true,
                // valueGetter: weekProfitGetter,
                valueFormatter: (params) =>
                  currencyFormatter(params.value, "£"),
              };

              const postalServiceNameColumn = {
                headerName: "Postal Service " + channel.title,
                field: "postalServiceName" + channel.title,
                resizable: true
              };
              const postalServiceColumn = {
                headerName: "Shipping Fee Rule Name " + channel.title,
                field: "shippingFeeRuleName" + channel.title,
                resizable: true,
                width: 300,
                hide: hidden,
                editable: true,
                cellEditor: "agSelectCellEditor",
                cellEditorParams: {
                  values: channelFees,
                },
                cellStyle: function (params) {
                  if (!params.colDef.field.includes("postalServiceName")) {
                    return { backgroundColor: "" };
                  }

                  if (params.data[`isOverrided${channel.title}`]) {
                    return { backgroundColor: "#F1948A" };
                  } else {
                    return { backgroundColor: "" };
                  }
                },
              };

              this.columnDefsBase.push(salesColumn);
              this.columnDefsBase.push(salePriceColumn);

              if (!["All", "Wayfair"].includes(channel.title)) {
                this.columnDefsBase.push(channelFeeColumn);
                this.columnDefsBase.push(postageCostColumn);
              }

              this.columnDefsBase.push(postalServiceNameColumn);
              this.columnDefsBase.push(profitColumn);
              this.columnDefsBase.push(advertisingColumn);
              this.columnDefsBase.push(profitPercentageColumn);
              this.columnDefsBase.push(weekProfitColumn);
              this.columnDefsBase.push(postalServiceColumn);
            }
          });

          this.columnDefsBase.push({
            headerName: "Category",
            field: "categoryName",
            editable: false,
            resizable: true,
          });
        }
        this.columnDefs = this.columnDefsBase;
      });
    }),
      (error) => {
        console.error(error);
      };
  }

  fillChannelsData() {
    let rows = this.rowData;
    this.gridApi.setRowData(rows);
  }

  getCategories() {
    this.inventroyService
      .getCategories()
      .subscribe((categories: Category[]) => {
        this.categories = categories;
        this.categories.unshift({
          categoryName: "All",
          categoryId: "",
        });
      });
  }
  //#endregion

  //#region buttons
  onTabClick(tab: Tab) {
    this.tabs.forEach((tab: Tab) => (tab.isActive = false));
    tab.isActive = true;
    if (tab.title === "All") {
      this.columnDefs = this.columnDefsBase;
    } else if (tab.title === "Overview") {
      // let overviewColumnDefs = [];
      let overviewColumnDefs = this.columnDefsBase.filter((x) =>
        ["sku", "title", "stockLevel"].includes(x.field)
      );
      for (let index = 0; index < tab.headersToInclude.length; index++) {
        const element = tab.headersToInclude[index];
        let overviewColDef = null;
        if (element === "overallWeekProfit") {
          overviewColDef = {
            headerName: "Overall Week Profit",
            field: element,
            resizable: true,
            valueGetter: overallWeekProfitGetter,
            valueFormatter: (params) => currencyFormatter(params.value, "£"),
          };
        } else {
          overviewColDef = {
            ...this.columnDefsBase.find((x) => x.field === element),
          };
        }
        if (overviewColDef) {
          overviewColDef.pinned = null;
          overviewColDef.cellClassRules = {
            "last-of-section": (params) => {
              return [
                "landedCost",
                "weekProfitAmazonUK",
                "weekProfitAmazonFBA",
                "weekProfitEBAY",
                "weekProfitWayfair",
                "weekProfitWowcher",
                "weekProfitHometreats",
                "weekProfitBedsale",
                "weekProfitSellerFlex",
              ].includes(params.colDef.field);
            },
          };
          overviewColumnDefs.push(overviewColDef);
        }
      }
      overviewColumnDefs
        .filter((x) =>
          [
            "landedCost",
            "weekProfitAmazonUK",
            "weekProfitAmazonFBA",
            "weekProfitEBAY",
            "weekProfitWayfair",
            "weekProfitWowcher",
            "weekProfitHometreats",
            "weekProfitBedsale",
            "weekProfitSellerFlex",
          ].includes(x.field)
        )
        .forEach((x) => (x.headerClass = "last-of-section"));

      this.columnDefs = overviewColumnDefs;
    } else {
      this.columnDefs = this.columnDefsBase.filter(
        (x) =>
          ["sku", "title", "stockLevel"].includes(x.field) ||
          tab.headersToInclude.includes(x.field)
      );
    }
    setTimeout(() => {
      this.gridApi.refreshHeader();
      this.gridApi.redrawRows();
    }, 0);
  }

  resetPostalServices() {
    if (!this.selectedRow) {
      this.snackBar.neutral("Select please a row", "");
      return;
    }
    let channelCostingInfo: any[] = [];
    this.channels.forEach((channel) => {
      channelCostingInfo.push({
        channelId: channel.channelId,
        title: channel.title,
        postalServiceId: this.selectedRow[`postalServiceId${channel.title}`],
      });
    });
    let costingSheetUserData = {
      stockItemId: this.selectedRow.stockItemId,
      depth: this.selectedRow.depth,
      weight: this.selectedRow.weight,
      channelInfo: channelCostingInfo,
    };
    this.channels
      .map((x) => x.title)
      .forEach((channel) => {
        this.selectedRow[`isOverrided${channel}`] = false;
      });
    this.costSheetService
      .resetOverridedPostageCost(costingSheetUserData)
      .subscribe((response) => {
        this.resettingPostalServices = true;
        const rowNode = this.gridApi.getSelectedNodes()[0];
        
        for (let index = 0; index < response.length; index++) {
          const item = response[index];

          const postageCostColumn = this.gridColumnApi.getColumn(`postageCost${item.channelTitle}`);
          if (postageCostColumn) {
            rowNode.setDataValue(postageCostColumn, item.postageCost);
          }
          
          const shippingFeeRuleNameColumn = this.gridColumnApi.getColumn(`shippingFeeRuleName${item.channelTitle}`);
          if (shippingFeeRuleNameColumn) {
            rowNode.setDataValue(shippingFeeRuleNameColumn,item.postalService);
          }

          if (index === response.length - 1) {
            setTimeout(() => {
              this.resettingPostalServices = false;
            }, 500);
          }
        }
      });
  }

  getTotalForActiveTab(): number {
    const activeTab: string = this.tabs.find((x) => x.isActive).title;
    if (
      ![
        "AmazonUK",
        "AmazonFBA",
        "EBAY",
        "Wayfair",
        "Wowcher",
        "Hometreats",
        "Bedsale",
        "SellerFlex"
      ].includes(activeTab)
    ) {
      return -1;
    }

    let total: number = 0;

    const columnTitle: string =
      activeTab === "Overview" ? "overallWeekProfit" : `weekProfit${activeTab}`;
    this.gridApi.forEachNode((x: RowNode) => {
      let rowProfit = parseFloat(this.gridApi.getValue(columnTitle, x));
      rowProfit = rowProfit > 0 ? rowProfit : 0;
      total += rowProfit;
    });

    return isNaN(total) ? 0 : total;
  }

  openChannelConfiguration() {
    const activeTab: string = this.tabs.filter((x) => x.isActive)[0].title;
    let channelId: number =
      this.channels.filter((x) => x.title === activeTab)[0]?.channelId ?? -1;
    this.dialog.open(ChannelConfigurationMatWindowComponent, {
      height: "290px",
      width: "290px",

      data: {
        channelId,
      },
    });
  }

  
  profitMarginFilter(value: number): void {
    this.isProfitMarginFilter = true;
    this.profitMarginFilterValue = value;
    this.gridApi.onFilterChanged();
    const activeTab: string = this.tabs.filter((x) => x.isActive)[0].title;
    let channelId: number = this.channels.filter((x) => x.title === activeTab)[0]?.channelId ?? -1;

    let filteredRowData = [];

    this.rowData.forEach(n => {

      const itemProfitPercentage = n['profitPercentage' + activeTab];
      if (itemProfitPercentage <= value) {
        filteredRowData.push(n);
      }
      
    });

    this.gridApi.setRowData(filteredRowData);
  }

  resetProfitMarginFilter(): void {
    this.isProfitMarginFilter = false;
    this.gridApi.setRowData(this.rowDataBase);
    const activeTab = this.tabs[0];
    this.onTabClick(activeTab);
  }

  

  //#endregion

  //#region filtering
  executeFilteringProccess() {
    if (this.selectedCategory) {
      if (this.selectedCategory.categoryName === "All") {
        this.rowData = this.rowDataBase;
      } else {
        this.rowData = this.rowDataBase.filter(
          (x) => x.categoryName === this.selectedCategory.categoryName
        );
      }
    }
    if (this.quickFilter) {
      this.rowData = this.rowData.filter((x: any) =>
        x.sku.toLowerCase().includes(this.quickFilter.toLowerCase())
      );
    }
    if (!this.selectedCategory && !this.quickFilter) {
      this.rowData = this.rowDataBase;
    }
  }
  //#endregion

  //#region ag-grid
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
    this.selectedRow = selectedRows[0];
  }

  onCellValueChanged(event) {
    if (this.resettingPostalServices) {
      return;
    }
    if (event.colDef.headerName.toLowerCase().includes("postal service")) {
      this.snackBar.neutral("That feature is on developing stage", "");
      return;
    }
    if (event.colDef.headerName.toLowerCase().includes("postage cost")) {
      const channelInfo = this.channels.find((x) =>
        event.colDef.headerName.includes(x.title)
      );
      let costingSheetUserData = {
        stockItemId: event.data.stockItemId,
        channelId: channelInfo.channelId,
        overridedPostageCost: event.newValue,
        overridedShippingFeeRuleId: -1,
      };

      this.costSheetService
        .overridePostageCost(costingSheetUserData)
        .subscribe((response) => {
          channelInfo.isOverrided = true;
          this.resettingPostalServices = true;
          event.node.setDataValue(
            this.gridColumnApi.getColumn(`postageCost${channelInfo.title}`),
            response.overridedPostageCost
          );
          setTimeout(() => {
            this.resettingPostalServices = false;
          }, 1000);
        });
    }

    if (event.colDef.headerName.toLowerCase().includes("landed cost")) {
      if (isNaN(event.value) || +event.value < 0) {
        this.snackBar.warning(
          "Landed cost could not contain non-numeric characters or be lower than 0"
        );
        event.data.landedCost = event.oldValue;
        this.gridApi.refreshCells();
        return;
      } else {
        this.landedCostService
          .overrideLandedCost(event.data.stockItemId, +event.value)
          .subscribe((response) => {
            event.data.landedCostIsManualOverrided = true;
            this.gridApi.redrawRows();
          });
      }
    }
  }

  //#endregion

  //#region value getters
  profitGetter(params) {
    let channelTitle = params.colDef.field.replace("profit", "");
    if (channelTitle = 'SellerFlex') channelTitle = 'AmazonFBA';
    let sales = params.getValue("sales" + channelTitle);
    if (sales === 0) return 0;
    let channel = this.channels.find((x) => x.title === channelTitle);
    if (
      params.colDef.field === "profit" + channelTitle &&
      params.data.stockItemId !== "total"
    ) {
      let price = params.data["price" + channelTitle];
      let landedCost = params.data["averageLandedCost"];
      let postageCost = params.data["postageCost" + channelTitle];

      if (channel.channelId == 3 || channel.channelId == 5) {
        return price - landedCost;
      } else {
        return price / 1.2 - landedCost - price * 0.15 - postageCost;
      }
    } else {
      return params.data["profit" + channelTitle];
    }
  }
  //#endregion
}

function salesPriceGetter(params) {
  const channelId: number = params.colDef.field.split("-").pop();
  return params.data.channelCosting.find((x) => x.channelId == channelId)
    .salesPrice;
}

function profitPercentageGetter(params) {
  let channelTitle = params.colDef.field.replace("profitPercentage", "");
  if (channelTitle = 'SellerFlex') channelTitle = 'AmazonFBA';
  let sales = params.getValue("sales" + channelTitle);
  if (sales === 0) return 0;

  if (
    params.colDef.field === "profitPercentage" + channelTitle &&
    params.data.stockItemId !== "total"
  ) {
    let price = params.getValue("price" + channelTitle);
    let profit = params.getValue("profit" + channelTitle);
    let result = isNaN(roundNumber((profit / (price - price * 0.2)) * 100))
      ? 0
      : roundNumber((profit / (price - price * 0.2)) * 100);
    return isFinite(result) ? result : 0;
  } else {
    return params.data["profitPercentage" + channelTitle];
  }
}

function overallWeekProfitGetter(params) {
  let total: number = 0;

  [
    "AmazonUK",
    "EBAY",
    "Wayfair",
    "AmazonFBA",
    "Wowcher",
    "Bedsale",
    "Hometreats",
  ].forEach((channel) => {
    total +=
      parseFloat(params.getValue("weekProfit" + channel)) > 0
        ? params.getValue("weekProfit" + channel)
        : 0;
  });

  return roundNumber(total);
}

function weekProfitGetter(params) {
  let channelTitle = params.colDef.field.replace("weekProfit", "");
  let sales = params.getValue("sales" + channelTitle);
  if (sales === 0) return 0;

  if (
    params.colDef.field === "weekProfit" + channelTitle &&
    params.data.stockItemId !== "total"
  ) {
    let profit = params.getValue("profit" + channelTitle);
    return roundNumber(profit * sales);
  } else {
    return params.data["weekProfit" + channelTitle];
  }
}

function roundNumber(value: number): number {
  return Math.round(value * 100) / 100;
}

function currencyFormatter(currency, sign) {
  if (currency !== undefined) {
    return sign + `${roundNumber(currency)}`;
  }
}

function percentFormatter(value, sign) {
  if (value !== undefined) {
    return `${roundNumber(value)} ${sign}`;
  }
}
