import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
// import to pdf file
import { UntypedFormControl } from "@angular/forms";
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { HelperService } from "src/app/shared/services/helper.service";
import { PurchaseOrder } from "../../../shared/models/PurchaseOrder";

@Component({
  selector: "app-container-pallet-report",
  templateUrl: "./container-pallet-report.component.html",
  styleUrls: ["./container-pallet-report.component.css"],
})
export class ContainerPalletReportComponent implements OnInit {
  loadingReport = false;
  baseUrl: string;

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  POs: PurchaseOrder[];
  selectedPO: PurchaseOrder;

  comment: string;
  currentDate = new Date();
  commentFormControl = new UntypedFormControl("", []);

  constructor(
    @Inject("BASE_URL") baseUrl: string,
    private reportsService: ReportsService,
    private helper: HelperService,
    private snackBar: SnackBarService
  ) {
    this.baseUrl = baseUrl;
  }

  //Initializing and get suppliers
  ngOnInit(): void {
    this.loadingReport = true;
    this.reportsService.getPurchaseOrdersByStatus().subscribe(
      (result) => {
        this.POs = result;
        this.loadingReport = false;
      },
      (error) => {
        this.loadingReport = false;
        console.error(error);
        this.snackBar.openSnackBar("Connection Error", "");
      }
    );
  }

  downloadReport(): void {
    this.reportsService
      .getContainerPalletReportById(this.selectedPO.pkPurchaseID)
      .subscribe((response) => {
        this.helper.saveFile(response);
      });
  }
}
