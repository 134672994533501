import { Component, OnInit } from "@angular/core";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from "file-saver-es";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "app-uk-freight-cost",
  templateUrl: "./uk-freight-cost.component.html",
  styleUrls: ["./uk-freight-cost.component.css"],
})
export class UkFreightCostComponent implements OnInit {
  loadingReport = false;
  currentDate = new Date();
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  constructor(
    private snackBarService: SnackBarService,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {}

  downloadFile() {
    if (this.range.value["start"] == null || this.range.value["end"] == null) {
      this.snackBarService.warning("Pick the date range please.");
      return;
    }

    var dstart = this.range.value["start"];
    var dend = this.range.value["end"];

    this.loadingReport = true;
    return this.reportsService
      .GetUkFreightCostReport(
        moment(dstart).format("YYYY-MM-DD"),
        moment(dend).format("YYYY-MM-DD")
      )
      .subscribe(
        (result: Blob) => {
          if (result.type != "text/plain") {
            this.loadingReport = false;
            var blob = new Blob([result]);
            let file = "12.1 UK Freight Cost Report.xlsx";

            saveAs(blob, file);
          } else {
            this.snackBarService.neutral("File not found in Blob!", "");
          }
        },
        (error) => {
          if (error.status === 404) {
            this.snackBarService.neutral(
              "There is no result for current filter ",
              ""
            );
          }
          this.loadingReport = false;
        }
      );
  }
}
