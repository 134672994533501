<div class="dialog-container">
    <h2 mat-dialog-title>Select Images:</h2>
    <mat-dialog-content class="mat-typography" *ngIf="images; else elseBlock">
    <mat-grid-list cols="4" rowHeight="2:1">
        <div *ngFor="let category of categories">
            <div *ngIf="images[category.value]">
            <mat-grid-tile [colspan]="4">
                <p>{{ category.name }}</p>
            </mat-grid-tile>

            
                <div *ngFor="let img of images[category.value].images">
                    <mat-grid-tile  [rowspan]="2" (click)="selectImage(img.url)" [ngClass]="{ selected: selected.includes(img.url) }"><img
                            class="img-preview" src="{{ img.url }}" alt="image" /></mat-grid-tile>
                </div>
            </div>
        </div>

        <!-- <mat-grid-tile [colspan]="4">
            <button mat-raised-button color="primary" (click)="confirmSelection()">Confirm</button>
        </mat-grid-tile> -->
    </mat-grid-list>
</mat-dialog-content>
<ng-template #elseBlock><h4>No images uploaded yet</h4></ng-template>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="confirmSelection()">Confirm</button>  
</mat-dialog-actions>
    

</div>