import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { RepositoryService } from "src/app/core/services/repository.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportDto } from "src/app/shared/models/ReportDto";
import { ReportsService } from "src/app/shared/services/reports.service";

export class ReportSchedule {
  id?: number;
  name: string;
  scheduleId: number;
  recepients: string;
  attachmentTypes: string;
}

@Component({
  selector: "app-email-scheduler",
  templateUrl: "./email-scheduler.component.html",
  styleUrls: ["./email-scheduler.component.css"],
})
export class EmailSchedulerComponent implements OnInit {
  dataSource = new MatTableDataSource<ReportSchedule>();
  selection = new SelectionModel<ReportSchedule>(false, []);
  columns: any[];
  displayedColumns: string[];

  // schedules: any[];
  selectedItem: any;
  loading = false;

  selectedSchedule: any;
  selectedRecepients: any;
  selectedReports: ReportDto[];

  newSchedule = {
    name: "",
    scheduleId: 0,
    recepients: "",
    attachments: "",
  };

  reports: ReportDto[];

  schedules: any;

  recepients: any;

  constructor(
    private reportsService: ReportsService,
    private repo: RepositoryService,
    private snackBar: SnackBarService,
    public dialog: MatDialog
  ) {
    this.columns = [
      { name: "Schedule", value: "schedule" },
      { name: "Reports", value: "attachmentTypes" },
      { name: "Recepients", value: "recepients" },
    ];

    this.displayedColumns = this.columns.map((col) => col.value);
  }

  ngOnInit(): void {
    this.getTasks();
    this.getRecepients();
    this.getSchedules();
    this.getAttachmentTypes();
  }

  getTasks() {
    this.repo.getList("api/scheduledTasks/getTasks").subscribe(
      (result) => {
        this.dataSource = new MatTableDataSource<ReportSchedule>(result);
      },
      () => {
        this.snackBar.error("Cant load report schedules");
      }
    );
  }

  sendNow(id: number) {
    this.repo.get("api/scheduledTasks/sendNow/" + id).subscribe((result) => {
      this.snackBar.neutral("Reports will be send in few minutes.", "");
    });
  }

  getSchedules() {
    this.repo.getList("api/scheduledTasks/getSchedules").subscribe(
      (result) => {
        this.schedules = result;
      },
      () => {
        this.snackBar.error("Cant load schedules");
      }
    );
  }

  getRecepients() {
    this.repo.getList("api/scheduledTasks/getRecepients").subscribe(
      (result) => {
        this.recepients = result;
      },
      () => {
        this.snackBar.error("Cant load recepients");
      }
    );
  }

  getAttachmentTypes() {
    this.repo.getList("api/scheduledTasks/getAttachmentTypes").subscribe(
      (result: ReportDto[]) => {
        this.reports = result.filter((x) => x.canBeScheduled);
      },
      () => {
        this.snackBar.error("Cant load report types");
      }
    );
    // this.reports = this.reportsService.reports.filter(
    //   (report: ReportDto) => report.canBeScheduled === true
    // );
  }

  addTask() {
    if (
      !this.selectedSchedule ||
      this.selectedRecepients.length < 1 ||
      this.selectedReports.length < 1
    ) {
      this.snackBar.warning("Please fill all fields!");
      return;
    }
    let ns = {
      scheduleId: this.selectedSchedule,
      recepients: this.selectedRecepients.map((x) => x.id).join(","),
      attachmentTypes: this.selectedReports.map((x) => x.id).join(","),
    };
    this.repo.create("api/scheduledTasks/addTask", ns).subscribe(
      () => {
        this.getTasks();
        this.snackBar.success("Successfully added");
      },
      () => {
        this.snackBar.error("Something went wrong");
      }
    );
  }

  editSchedule() {}

  removeTask() {
    this.repo
      .delete("api/scheduledTasks/removeTask/" + this.selection.selected[0].id)
      .subscribe(
        () => {
          this.snackBar.neutral("Succesfully removed", "Report Schedule");
          this.getTasks();
          this.selection.selected[0] = null;
        },
        () => {
          this.snackBar.error("Cant remove report schedule");
        }
      );
  }
}
