import { OnDestroy } from "@angular/core";
import { Component, OnInit, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { RepositoryService } from "src/app/core/services/repository.service";

import { DatePipe } from "@angular/common";
import { UntypedFormControl } from "@angular/forms";

import { Observable, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { getHeaderTemplate } from "src/app/shared/utils/ag-grid-helper";

class PurchaseData {
  purchaseID: string;
  poNumber: string;
  freight: number;
  freightUSD: number;

  profitValue: number;
  profitPercent: number;

  goodsValueUSD: number;
  goodsValue: number;
  duty: number;

  dockingDateString: string;

  //profitPercent: number;
  exchangeRate: number;
  totalCBM: number;

  items: PurchaseDataItem[];
}

class PurchaseDataItem {
  stockItemID: string;
  sku: string;
  description: string;
  qty: number;
  unitPrice: number;
  ctnqty: number;
  cbm: number;

  cbmPerLine: number;

  landedCost: number;
  sellingPrice: number;
  profitPerPiece: number;
  totalProfit: number;
}

class Purchase {
  externalInvoiceNumber: string;
  pkPurchaseID: string;
  supplierName: string;
}

@Component({
  selector: "app-po-cost",
  templateUrl: "./po-cost.component.html",
  styleUrls: ["./po-cost.component.css"],
  providers: [DatePipe],
})
export class POCostComponent implements OnInit, OnDestroy {
  loadingData = false;

  http: HttpClient;
  baseUrl: string;
  hideDownloadButton = true;

  // mat-autocomplete
  filteredPOs: Observable<Purchase[]>;
  purchaseOrders: Purchase[];

  itemsCtrl = new UntypedFormControl();

  currentPO: PurchaseData;

  searchItemField;

  // mat-field input
  freightCost;
  freightUSD;
  goodsValueUSD;
  profitValue;
  dutyCost;
  goodsValue;
  profitPercent;
  exchangeRate;
  totalCBM;
  currentPONumber;
  dockingDate;

  destroyedComponentSubj = new Subject<void>();

  // grid: extension components - remove button and dropdown
  frameworkComponents: any = {};

  // grid: orderItems
  rowData: PurchaseDataItem[];

  valueCBMFormatter = function (params) {
    return parseFloat(params.data.cbmPerLine).toFixed(4);
  };

  // grid: value getters: show empty row on total
  totalValueGetterDescription = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.description;
    }
  };

  totalValueGetterUnitPrice = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.unitPrice.toFixed(2);
    }
  };

  totalValueGetterCtnqty = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.ctnqty == 0 ? 1 : params.data.ctnqty;
    }
  };

  totalValueGetterLandedCost = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.landedCost.toFixed(2);
    }
  };

  totalValueGetterSelPrice = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.sellingPrice.toFixed(2);
    }
  };

  totalValueGetterProfitPerPiece = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.profitPerPiece.toFixed(2);
    }
  };

  totalValueGetterTotalProfit = function (params) {
    if (params.data.stockItemID == "total") {
      return "";
    } else {
      return params.data.totalProfit.toFixed(2);
    }
  };

  // SKU Sort - from lowest to highest
  SKUComparator(val1, val2) {
    var po1 = val1.substring(0, 2);
    var isCA1 = /[a-zA-Z]/.test(po1); // IF PO starts with 'CA%'

    var po2 = val2.substring(0, 2);
    var isCA2 = /[a-zA-Z]/.test(po2); // IF PO starts with 'CA%'

    let a = 0;
    let b = 0;
    var num1 = "";
    var num2 = "";

    if (isCA1) {
      num1 = val1.substr(2);
    } else {
      num1 = val1;
    }

    a = parseInt(num1);

    if (isNaN(a)) {
      a = -1;
    }

    if (isCA2) {
      num2 = val2.substr(2);
    } else {
      num2 = val2;
    }

    b = parseInt(num2);

    if (isNaN(b)) {
      b = -1;
    }

    if (a == b) {
      return 0;
    }
    return a > b ? 1 : -1;
  }

  // grid: Columns
  columnDefs = [
    {
      headerName: "Code",
      field: "sku",
      minWidth: 60,
      sortable: true,
      sort: "asc",
      comparator: this.SKUComparator,
    },
    {
      headerName: "Name",
      field: "description",
      minWidth: 70,
      valueGetter: this.totalValueGetterDescription,
    },
    { headerName: "Quantity", field: "qty", minWidth: 50, sortable: true },
    {
      headerName: "Unit price USD",
      field: "unitPrice",
      minWidth: 50,
      sortable: true,
      valueGetter: this.totalValueGetterUnitPrice,
    },
    {
      headerName: "Carton QTY",
      field: "ctnqty",
      minWidth: 50,
      sortable: true,
      valueGetter: this.totalValueGetterCtnqty,
    },
    {
      headerName: "CBM Per Line",
      field: "cbmPerLine",
      minWidth: 50,
      sortable: true,
      valueFormatter: this.valueCBMFormatter,
    },
    {
      headerName: "Landed Cost GBP",
      field: "landedCost",
      minWidth: 50,
      sortable: true,
      valueGetter: this.totalValueGetterLandedCost,
    },
    {
      headerName: "Selling Price",
      field: "sellingPrice",
      minWidth: 50,
      sortable: true,
      valueGetter: this.totalValueGetterSelPrice,
    },
    {
      headerName: "Profit Per Piece",
      field: "profitPerPiece",
      minWidth: 50,
      sortable: true,
      valueGetter: this.totalValueGetterProfitPerPiece,
    },
    {
      headerName: "Total Profit",
      field: "totalProfit",
      minWidth: 50,
      sortable: true,
    },
  ];

  // grid: headers height
  public headerHeight;

  // grid: def header styles
  public defaultColDef;

  // grid: for updating and editing grid order items data
  public gridApi;

  // grid - pin to bottom Total
  public pinnedBottomRowData;

  constructor(
    http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    public datepipe: DatePipe,
    private repo: RepositoryService,
    private snackBar: SnackBarService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.headerHeight = 50;

    this.defaultColDef = {
      resizable: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      tooltipComponent: "GridTooltipComponent",
      headerComponentParams: {
        template: getHeaderTemplate(),
      },
    };
    this.currentPONumber = "";

    this.dockingDate = "";

    this.getPurchaseOrders();

    this.pinnedBottomRowData = [];
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroyedComponentSubj.next();
    this.destroyedComponentSubj.complete();
  }
  // grid init
  onGridReady(params) {
    this.gridApi = params.api;

    this.gridApi.sizeColumnsToFit();
    this.getTotalRow();
  }

  // grid: add total row
  getTotalRow() {
    let totalRow = {
      stockItemID: "total",
      sku: "Total - ",
      description: "",
      qty: 0,
      unitPrice: 0,
      ctnqty: 0,
      cbm: 0,

      cbmPerLine: 0,

      landedCost: 0,
      sellingPrice: 0,
      profitPerPiece: 0,
      totalProfit: 0,
    };

    if (this.rowData != null) {
      totalRow.qty = this.rowData.reduce(
        (prev, cur) => prev + parseInt(cur.qty.toString(), 0),
        0
      );
      totalRow.cbmPerLine = parseFloat(
        this.rowData
          .reduce(
            (prev, cur) => prev + parseFloat(cur.cbmPerLine.toString()),
            0
          )
          .toString()
      );
      totalRow.totalProfit = parseFloat(
        this.rowData
          .reduce(
            (prev, cur) => prev + parseFloat(cur.totalProfit.toString()),
            0
          )
          .toFixed(2)
      );
    }

    var data = [];
    data.push(totalRow);
    this.pinnedBottomRowData = data;
    this.gridApi.setPinnedBottomRowData(data);
  }

  // grid: recalculating total
  updateTotalRow() {
    if (this.rowData.length && this.rowData.length > 0) {
      var totalIndex = this.rowData.findIndex((x) => x.sku == "Total - ");

      if (totalIndex != -1) {
        this.rowData.splice(totalIndex, 1);
        this.gridApi.setRowData(this.rowData);
      }

      this.getTotalRow();
    }
  }

  // GET and SET selected purchase data
  selectedPOChange(value) {
    var selectedPO = this.purchaseOrders.filter(function (item) {
      return item.externalInvoiceNumber == value;
    })[0];

    if (selectedPO != null) {
      this.loadingData = true;

      this.http
        .get<PurchaseData>(
          this.baseUrl +
            "api/po/getPurchaseOrderByID/" +
            selectedPO.pkPurchaseID
        )
        .subscribe(
          (result) => {
            if (result != null) {
              this.currentPO = result;
              this.currentPONumber = result.poNumber;

              this.freightCost = result.freight;
              this.freightUSD = result.freightUSD;

              this.goodsValueUSD = result.goodsValueUSD.toFixed(2);
              this.goodsValue = (
                result.goodsValueUSD / result.exchangeRate
              ).toFixed(2);

              this.dutyCost = result.duty;

              this.exchangeRate = result.exchangeRate;

              this.dockingDate = result.dockingDateString;

              //fill by data
              this.rowData = [];

              this.gridApi.applyTransaction({ update: this.rowData });
              this.gridApi.redrawRows();

              this.clearGrid();

              if (result.items != null) {
                result.items.forEach((item, index) => {
                  item.cbmPerLine = parseFloat(
                    ((item.qty / item.ctnqty) * item.cbm).toString()
                  );

                  item.cbmPerLine = isNaN(item.cbmPerLine)
                    ? 0
                    : item.cbmPerLine;

                  this.rowData.push(item);
                });
              }

              this.totalCBM = parseFloat(
                this.rowData
                  .reduce(
                    (prev, cur) => prev + parseFloat(cur.cbmPerLine.toString()),
                    0
                  )
                  .toFixed(4)
              );
              this.profitValue = parseFloat(
                this.rowData
                  .reduce(
                    (prev, cur) =>
                      prev + parseFloat(cur.totalProfit.toString()),
                    0
                  )
                  .toFixed(2)
              );
              this.profitPercent =
                result.profitValue > 0
                  ? ((100 / result.goodsValue) * this.profitValue).toFixed(2)
                  : "";

              this.gridApi.applyTransaction({ add: this.rowData });
              this.gridApi.redrawRows();

              this.updateTotalRow();
            } else {
              this.snackBar.error("There is no PO with this number.");
            }

            this.loadingData = false;
          },
          (error) => {
            this.loadingData = false;

            this.snackBar.error("Something went wrong");
          }
        );
    }
  }

  clearGrid(): void {
    var itemsToUpdate = [];

    this.gridApi.setRowData(itemsToUpdate);
    this.gridApi.redrawRows();
  }

  private _filterItems(value: string): Purchase[] {
    if (value == "" || value == null) return this.purchaseOrders;

    const filterValue = value.toLowerCase();
    return this.purchaseOrders.filter(
      (option) =>
        option.externalInvoiceNumber.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  // get Purchase orders
  getPurchaseOrders(): void {
    this.loadingData = true;

    this.repo
      .getList("api/po/getPurchases")
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(
        (result) => {
          this.purchaseOrders = result;

          //this.selectedPO = result[0];

          this.filteredPOs = this.itemsCtrl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filterItems(value))
          );

          this.loadingData = false;
        },
        (error) => {
          this.loadingData = false;

          this.snackBar.error("Something went wrong");
        }
      );
  }

  // matInput in mat-select-autocomplete
  clearSearchField() {
    this.searchItemField = "";
  }

  roundNumber(value: number): number {
    return Math.round(value * 100) / 100;
  }
}
