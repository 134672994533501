<app-loader></app-loader>
<mat-card>
    <div *ngFor="let category of categories">
        <div class="row">
            <button mat-mini-fab (click)="add(category.value)" class="add-btn" color="primary">
                <mat-icon>plus_one</mat-icon>
            </button>
            <h3>{{category.name}}</h3>
        </div>
        <div id="category.name" class="container-packaging" *ngIf="imageData[category.value]" gallerize>
            <div *ngFor="let img of imageData[category.value].images">
                <mat-card>
                    <mat-card-subtitle>{{img.dateModified | date: 'dd/MM/yy hh:mm'}}</mat-card-subtitle>
                    <img mat-card-image [src]="img.url" (click)="loadCategoryToLightBox(category.value)">
                    <mat-card-actions>
                        <button mat-raised-button class="remove-btn" color="secondary"
                            (click)="remove(category.value, img.url)">
                            <mat-icon>delete_forever</mat-icon>
                        </button>

                        <button mat-raised-button class="open-btn" color="secondary" style="float: right;"
                            (click)="openImage(img.url)">
                            <mat-icon>get_app</mat-icon>
                        </button>

                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div>

    <input name="file" id="file" (change)="uploadFiles($event)" type="file" multiple accept="image/*" #inputFile hidden>

</mat-card>