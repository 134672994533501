import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { ErrorStateMatcher } from "@angular/material/core";
import { SnackBarService } from "./../../../../core/services/snack-bar.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { RegistrationUserModel } from "./../../../../core/models/registration-user.model";
import { AuthService } from './../../../services/auth.service';

@Component({
  selector: "app-new-user",
  templateUrl: "./new-user.component.html",
  styleUrls: ["./new-user.component.css"],
})
export class NewUserComponent implements OnInit, OnDestroy {
  @Output() userAdded = new EventEmitter();
  rolesArray = ["User"];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  matcher = new MyErrorStateMatcher();

  hidePasswordInput = true;
  hidePasswordConfirmationInput = true;

  registerNewUserForm = new UntypedFormGroup(
    {
      userNameInput: new UntypedFormControl("", [Validators.required]),
      emailInput: new UntypedFormControl("", [Validators.required, Validators.email]),
      supplierCodesInput: new UntypedFormControl([], []),
      roleInput: new UntypedFormControl(this.rolesArray, [Validators.required]),
      passwordInput: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8),
      ]),
      passwordConfirmationInput: new UntypedFormControl(""),
    },
    {
      validators: this.comparePasswords,
    }
  );

  destroySubj = new Subject();

  constructor(
    private authenticationWebService: AuthService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {}

  private comparePasswords(group: UntypedFormGroup) {
    const password = group.get("passwordInput")?.value;
    const confirmPassword = group.get("passwordConfirmationInput")?.value;

    return password === confirmPassword ? null : { notSame: true };
  }

  addSupplierCode(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      if (!this.registerNewUserForm.value.supplierCodesInput) {
        this.registerNewUserForm.value.supplierCodesInput = [];
        this.registerNewUserForm.controls.supplierCodesInput.setValue([]);
      }
      this.registerNewUserForm.value.supplierCodesInput.push(value.trim());
    }

    if (input) {
      input.value = "";
    }
  }

  removeSupplierCode(supplierCode: string): void {
    const index = this.registerNewUserForm.value.supplierCodesInput.indexOf(
      supplierCode
    );

    if (index >= 0) {
      this.registerNewUserForm.value.supplierCodesInput.splice(index, 1);
    }
  }

  clearSupplierCodes() {
    this.registerNewUserForm.value.supplierCodesInput = [];
  }

  @Output() newUserIsSupplier = new EventEmitter<boolean>();
  supplierSelection(role: string) {
    const value = role.toLowerCase() === "supplier" ? true : false;
    this.newUserIsSupplier.emit(value);
  }
  
  onSubmit() {
    const userToRegister: RegistrationUserModel = {
      email: this.registerNewUserForm.value.emailInput,
      name: this.registerNewUserForm.value.userNameInput,
      role: this.registerNewUserForm.value.roleInput,
      password: this.registerNewUserForm.value.passwordConfirmationInput,
    };
    this.authenticationWebService
      .registerNewUser(userToRegister)
      .pipe(takeUntil(this.destroySubj))
      .subscribe(
        () => {
          this.registerNewUserForm.reset();
          this.snackBarService.success(`${userToRegister.email} was added`);
          this.userAdded.emit();
        },
      );
  }

  ngOnDestroy(): void {
    this.destroySubj.next(null);
    this.destroySubj.complete();
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
    const invalidParent = !!(
      control?.parent?.invalid && control?.parent?.dirty
    );

    return invalidCtrl || invalidParent;
  }
}
