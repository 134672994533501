import { Component, OnInit } from "@angular/core";
import { saveAs } from "file-saver-es";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";

@Component({
  selector: "app-sales-wtih-price-changes",
  templateUrl: "./sales-wtih-price-changes.component.html",
  styleUrls: ["./sales-wtih-price-changes.component.css"],
})
export class SalesWtihPriceChangesComponent implements OnInit {
  loading = false;
  currentDate = new Date();
  constructor(
    private reportsService: ReportsService,
    private snackbar: SnackBarService
  ) { }

  ngOnInit(): void { }

  downloadReport() {
    this.loading = true;
    return this.reportsService.salesWithPriceChange().subscribe(
      (result: any) => {
        if (result.type != "text/plain") {
          this.loading = false;
          let filename = result.headers
            .get("content-disposition")
            .split(";")[1]
            .split("=")[1]
            .replace(/\"/g, "");
          var blob = new Blob([result.body]);
          saveAs(blob, filename);
        } else {
          this.snackbar.error("Something went wrong (");
        }
      },
      (error) => {
        console.error(error);
        this.loading = false;
        this.snackbar.error("Something went wrong (");
      }
    );
  }
}
