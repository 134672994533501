import { Inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  constructor(@Inject("BASE_URL") private baseUrl: string,
  private http: HttpClient) { }

  getPriceChangeRules() {
    return this.http.get<any>("api/price/priceChangeRules");
  }

  addPriceChangeRules(rules: any[]) {
    return this.http.post<any[]>("api/price/addPriceChangeRules", rules);
  }

  deletePriceChangeRule(stockItemId: string, ruleId: number) {
    return this.http.delete(`api/price/deletePriceChangeRule/${stockItemId}/${ruleId}`);
  }
}
