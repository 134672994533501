import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-inventory-images-dialog",
  templateUrl: "./inventory-images-dialog.component.html",
  styleUrls: ["./inventory-images-dialog.component.css"],
})
export class InventoryImagesDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}
}
