<mat-card>
  <!-- <mat-progress-bar *ngIf="loadingData" mode="indeterminate"></mat-progress-bar> -->

  <mat-card-header>
    <mat-card-title>PO Schedule Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>POs with shipping dates and if booked/shipped</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">

      <div class="col">

        <mat-form-field class="selectSupplier">
          <mat-label>Supplier:</mat-label>
          <mat-select [(value)]="selectedSupplier" (selectionChange)="selectedSupplierChange($event.value)"
            [disabled]="loadingData">
            <mat-option *ngFor="let supp of suppliers" [value]="supp">
              {{supp.supplierName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>


      <div class="col">
        <button mat-raised-button color="primary" (click)="downloadFile()" [disabled]="loadingData"
          style="float: right;">Download report</button>
      </div>

    </div>
  </mat-card-content>
</mat-card>