<mat-card>
  <mat-card-header>
    <mat-card-title>Cash Flow Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>Containers coming in and the cash payments for these</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <button mat-raised-button color="primary" (click)="downloadReport()" style="float: right">
        Download report
      </button>
    </div>
  </mat-card-content>
</mat-card>