import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectEmailRecepientsDialogComponent } from '../../../shared/components/select-email-recepients-dialog/select-email-recepients-dialog.component';
import * as moment from "moment";
import { SnackBarService } from "./../../../core/services/snack-bar.service";
import { ReportsService } from "./../../../shared/services/reports.service";
import { tap } from 'rxjs';

@Component({
  selector: 'app-stock-value-working',
  templateUrl: './stock-value-working.component.html',
  styleUrls: ['./stock-value-working.component.scss']
})
export class StockValueWorkingComponent implements OnInit {

  public date = new Date();
  currentDate = new Date();
  loading = false;

  constructor(
    private dialog: MatDialog,
    private notifier: SnackBarService,
    private reportService: ReportsService
  ) { }

  ngOnInit(): void {
  }

  public downloadWorkingReport(): void {
    const dialogRef = this.dialog.open(SelectEmailRecepientsDialogComponent, {
      width: '900px',
      height: '400px',
    });
    dialogRef.afterClosed()
      .pipe(
        tap(recepients => {
          if (recepients?.length) {
            this.loading = true;
            this.notifier.success('Report will be sent in few minutes');
            this.reportService.sendStockValueWorking(moment(this.date).format('YYYY-MM-DD'), recepients).pipe(
              tap(() => {
                this.loading = false;
              }),
            ).subscribe();
          }
        }),
      )
      .subscribe();
  }

}
