import { map } from "rxjs/operators";
import { Component, Inject, OnInit } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { Supplier } from "src/app/shared/models/Supplier";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from "file-saver-es";
@Component({
  selector: "app-supplier-product-items",
  templateUrl: "./supplier-product-items.component.html",
  styleUrls: ["./supplier-product-items.component.css"],
})
export class SupplierProductItemsComponent implements OnInit {
  loadingReport = false;
  baseUrl: string;
  hideDownloadButton = true;
  currentDate = new Date();
  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  suppliers: Supplier[];
  selectedSupplier: Supplier;

  constructor(
    private inventoryService: InventoryService,
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    private _snackBar: MatSnackBar
  ) {
    this.baseUrl = baseUrl;
  }

  //Initializing and get suppliers
  ngOnInit(): void {
    this.loadingReport = true;
    this.inventoryService
      .getSuppliers()
      .pipe(
        map((suppliers: Supplier[]) => {
          suppliers.unshift({ pkSupplierID: "all", supplierName: "All" });
          return suppliers;
        })
      )
      .subscribe(
        (result) => {
          this.suppliers = result;
          this.loadingReport = false;
        },
        (error) => {
          this.loadingReport = false;
          console.error(error);
        }
      );
  }

  selectedSupplierChange(value): void { }

  downloadFile() {
    if (this.selectedSupplier == null) {
      this.openSnackBar("Choose supplier please.", "");
    } else {
      this.loadingReport = true;

      return this.reportsService
        .getSupplierProductInfoEXCEL(this.selectedSupplier.pkSupplierID)
        .subscribe(
          (result: any) => {
            if (result.type != "text/plain") {
              this.loadingReport = false;
              var blob = new Blob([result]);
              let file =
                "10.1 - product details by supplier or all (" +
                this.selectedSupplier.supplierName +
                ").xlsx";

              saveAs(blob, file);
            } else {
              this.openSnackBar("File not found in Blob!", "");
            }
          },
          (error) => {
            this._snackBar.open("Items not found. List is empty.", "", {
              duration: 3000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
            //console.error(error);
            this.loadingReport = false;
          }
        );
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
