import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { GeneralSettings } from '../models/general-settings.model';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingsService {

  constructor(@Inject("BASE_URL") private baseUrl: string,
  private http: HttpClient) { }

  update(settings: GeneralSettings) {
    return this.http.post<GeneralSettings>(`api/settings`, settings);
  }

  get() {
    return this.http.get<GeneralSettings>(`api/settings`);
  }

  removeCategory(categoryId: string) {
    return this.http.get('api/settings/removeCategory/' + categoryId);
  }
}
