<mat-card>
  <!-- <mat-progress-bar *ngIf="loadingReport" mode="indeterminate"></mat-progress-bar> -->
  <mat-card-header>
    <mat-card-title>Pallet Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>Shows figures for the number of pallets in stock</mat-card-subtitle>
  </mat-card-header>
  <div class="row">
    <div class="col">
      <button mat-raised-button color="primary" (click)="downloadFile()" [disabled]="loadingReport">
        Download report
      </button>
    </div>
  </div>
</mat-card>
<br />