//#region Models PO VIEW
export class PurchaseCmbx {
  externalInvoiceNumber: string;
  pkPurchaseID: string;
  supplierName: string;
}

export class PurchaseData {
  purchaseID: string;
  supplierID: string;
  supplierName: string;
  conversionRate: number;
  orderNumber: string;
  containerSize: string;
  reference: string;
  reference2: string;
  supplierInvoiceNumber: string;
  orderDate: Date;

  orderDateString: string;
  goodsReadyDateString: string;

  port: string;
  status: string;

  items: PurchaseDataItem[];
}

export class PurchaseDataItem {
  stockItemID: string;
  sku: string;
  description: string;
  qty: number;
  ctnqty: number;
  cbm: number;
  unitPrice: number;
factoryItemNo: string;
  taxRate: number;
  weight: number;

  lineTotal: number;
}

export interface Location {
  stockLocationId: string;
  locationName: string;
}

export interface StockItem {
  stockItemID: string;
  sku: string;
  description: string;
  purchasePrice: number;
  cbm: number;
  weight: number;
  ctnqty: number;
  taxRate: number;
  factoryItemNo: string;
}

export interface IOrderItem {
  stockItemID: string;
  sku: string;
  description: string;
  unitCost: number;
  cbm: number;
  quantity: number;
  cartonQty: number;
  weight: number;
  totalCBM: number;
  lineTotal: number;
  factoryItemNo: string;
}

export class POL {
  name: string;
  transitTime: number;
}

export class PurchaseItem {
  stockItemID: string;
  qty: number;
  unitPrice: number;
  taxRate: number;
  lineTotal: number;
}

export class OrderItem implements IOrderItem {
  stockItemID: string;
  sku: string;
  description: string;
  unitCost: number;
  cbm: number;
  quantity: number;
  cartonQty: number;
  weight: number;
  weightPerItem: number;
  factoryItemNo: string;
  taxRate: number;
  hide: boolean;
  totalCBM: number;
  lineTotal: number;
}

export class PurchaseOrderData {
  supplierAddress: string;
  orderNo: string;
  orderDate: string;
  goodsReadyDate: string;
  accountRef: string;
  totalValue: number;
  reference2: string;
  itemsInfo: PurchaseItemInfo[];
}

export class PurchaseItemInfo {
  stockCode: string;
  productDescription: string;
  comodityCode: string;
  qtyOrdered: number;
  unitPrice: number;
  value: number;
}

export class updatePOResult {
  poNumber: string;
  result: string;
}

//#endregion
