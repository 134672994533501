<div class="costing-container">
<mat-tab-group mat-align-tabs="start" animationDuration="0ms">
  <mat-tab label="Costing sheet">
    <div class="config-section">
      <div class="tabs">
        <div class="button" [ngClass]="{ active: tab.isActive }" *ngFor="let tab of tabs" (click)="onTabClick(tab)">
          {{ tab.title }}
        </div>
      </div>
      <div class="filters-section">

        <div class="search-container">
          <!-- <mat-form-field>
            <input matInput type="text" [(ngModel)]="quickFilter" (ngModelChange)="executeFilteringProccess()"
              placeholder="Search" class="search-field" />
            
            <button *ngIf="quickFilter" matSuffix mat-icon-button aria-label="Clear"
              (click)="quickFilter=''; executeFilteringProccess()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field> -->

          <mat-form-field class="select-form-field">
            <input matInput type="text" [(ngModel)]="quickFilter" placeholder="Search" />
            <button *ngIf="quickFilter" matSuffix mat-icon-button aria-label="Clear" (click)="quickFilter=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <mat-form-field class="categories-selection">
          <mat-label>Category:</mat-label>
          <mat-select [(value)]="selectedCategory" (selectionChange)="executeFilteringProccess()">
            <!-- [disabled]="!showQuickFilter" -->
            <mat-option *ngFor="let cat of categories" [value]="cat">
              {{ cat.categoryName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span class="time-range">Time range: {{ period[0] | date: "dd/MM/yy" }} -
          {{ period[1] | date: "dd/MM/yy" }}</span>
      </div>
    </div>

    <ag-grid-angular id="costSheetGrid" class="ag-theme-balham ag-grid" [rowData]="rowData" [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef" rowSelection="single" (selectionChanged)="onSelectionChanged()"
      (gridReady)="onGridReady($event)" [enableCellChangeFlash]="true" (cellValueChanged)="onCellValueChanged($event)"
      [stopEditingWhenGridLosesFocus]="true" rowHeight="32" applyColumnDefOrder="true" [getRowStyle]="getRowStyle"
      animateRows="true" headerHeight="50" [quickFilterText]="quickFilter">
    </ag-grid-angular>

    <div class="color-explanation-line">
      <div class="pink">Without a landed cost</div>
      <div class="red">Overrided postal service & postage cost</div>
    </div>
    <div class="buttons-section">
      <button color="primary" (click)="resetPostalServices()" *ngIf="selectedRow" mat-raised-button color="primary">
        Reset Postage Cost & Postal Service
      </button>
      <div class="channel-configuration" *ngIf="
          getTotalForActiveTab() !== -1 &&
          this.channels &&
          this.channels.length > 0
        ">
        <button mat-raised-button color="primary" (click)="openChannelConfiguration()">
          Channel configuration
        </button>

        <button mat-raised-button color="primary" (click)="profitMarginFilter(5)">
          Margin GP less than 5%
        </button>

        <button mat-raised-button color="primary" (click)="profitMarginFilter(10)">
          Margin GP less than 10%
        </button>

        <button mat-raised-button color="primary" (click)="resetProfitMarginFilter()">
          Reset Profit Filter
        </button>
      </div>
      <div class="total" *ngIf="getTotalForActiveTab() !== -1 && rowData && rowData.length > 0">
        Week profit total: {{ getTotalForActiveTab() | number }}
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Fees by channel">
    <ng-template matTabContent>
      <app-channel-fees></app-channel-fees>
    </ng-template>
  </mat-tab>

  <mat-tab label="Shipping fees">
    <ng-template matTabContent>
      <app-shipping-fees></app-shipping-fees>
    </ng-template>
  </mat-tab>
</mat-tab-group>

</div>