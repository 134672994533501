<div class="form-wrapper">
    <form [formGroup]="channelFeeForm" autocomplete="off" novalidate (ngSubmit)="createChannelFee(channelFeeForm.value)" fxLayout="column wrap"
    fxLayoutAlign="center center" fxLayoutGap="10px">

    <mat-card-title>
        Add new channel fee
    </mat-card-title>

<mat-card-content>

    <mat-form-field>
        <mat-label>Channel:</mat-label>
        <mat-select [(value)]="selectedChannel">
            <mat-option *ngFor="let c of channels" [value]="c">
                {{c.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Fee type:</mat-label>
        <mat-select [(value)]="selectedFeeType">
            <mat-option *ngFor="let ft of feeTypes" [value]="ft">
                {{ft.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <input matInput type="text" placeholder="Title" formControlName="title" id="title">
        <!-- <mat-hint align="end">GBP</mat-hint> -->
        <mat-error *ngIf="hasError('title', 'required')">title is required</mat-error>
        <mat-error *ngIf="hasError('title', 'maxlength')">You have more than 60 characters</mat-error>
    </mat-form-field>

  <mat-form-field>
      <input matInput type="number" placeholder="Fee Value" formControlName="feeValue" id="feeValue">
      <!-- <mat-hint align="end">GBP</mat-hint> -->
      <mat-error *ngIf="hasError('feeValue', 'required')">feeValue is required</mat-error>
      <mat-error *ngIf="hasError('feeValue', 'maxlength')">You have more than 60 characters</mat-error>
  </mat-form-field>

</mat-card-content>

<mat-card-actions>
  <button mat-raised-button color="primary" [disabled]="!channelFeeForm.valid">Create</button>
  <button mat-raised-button color="warn" mat-dialog-close>Cancel</button>
</mat-card-actions>

</form>
</div>


