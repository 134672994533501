import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';
@Component({
  selector: 'checkbox-renderer',
  template: `
    <input 
      type="checkbox" 
      (click)="checkedHandler($event)"
      [checked]="innerParams.value"
    />
`,
})
export class CheckboxRenderer implements ICellRendererAngularComp {
  refresh(params: any): boolean {
    return false;
  }
  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    throw new Error('Method not implemented.');
  }

  public innerParams: any;

  agInit(params: any): void {
    this.innerParams = params;
  }

  checkedHandler(event) {
    let checked = event.target.checked;
    let colId = this.innerParams.column.colId;
    this.innerParams.node.setDataValue(colId, checked);
  }
}
