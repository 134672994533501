import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import * as moment from "moment";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from "file-saver-es";
import { Supplier } from "src/app/shared/models/Supplier";

@Component({
  selector: "app-delivered-purchase-orders",
  templateUrl: "./delivered-purchase-orders.component.html",
  styleUrls: ["./delivered-purchase-orders.component.scss"],
})
export class DeliveredPurchaseOrdersComponent implements OnInit {
  constructor(
    private snackBarService: SnackBarService,
    private reportsService: ReportsService
  ) {}

  currentDate = new Date();
  loading: boolean = false;
  suppliers: Supplier[];
  selectedSupplier: Supplier;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });

  ngOnInit(): void {}

  downloadReport() {
    if (this.range.value["start"] == null || this.range.value["end"] == null) {
      this.snackBarService.warning("Pick the date range please.");
      return;
    }

    this.loading = true;

    var dstart = this.range.value["start"];
    var dend = this.range.value["end"];
    this.reportsService
      .GetDeliveredPurchaseOrders(
        moment(dstart).format("YYYY-MM-DD"),
        moment(dend).format("YYYY-MM-DD")
      )
      .subscribe(
        (result) => {
          if (result.type != "text/plain") {
            this.loading = false;
            var blob = new Blob([result]);
            let file = "3.6 Stock delivered between certain dates.xlsx";

            saveAs(blob, file);
          } else {
            alert("File not found in Blob!");
          }
        },
        (error) => {
          this.loading = false;
          if (error.status === 404) {
            this.snackBarService.neutral(
              "There is no result for current filter",
              ""
            );
          }
        }
      );
  }
}
