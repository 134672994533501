<div class="purchase-screen">
  <div class="d-flex-vert-center d-flex-sp-btw">
    <mat-radio-group aria-label="Select an option">
      <mat-radio-button *ngFor="let filter of periodFilters; let i = index" (change)="periodFilterChanged(filter)"
        [checked]="filter.isChecked" [value]="i">{{ filter.period }}</mat-radio-button>
    </mat-radio-group>

    <div class="d-flex-vert-center">
      <mat-form-field class="po-form-field" [floatLabel]="'never'">
        <mat-label>Search</mat-label>
        <input #searchInput matInput type="text" [(ngModel)]="searchQuery"
          (keyup)="onFilterTextBoxChanged(searchInput.value)" [disabled]="!showQuickFilter" />
        <span matSuffix
          matTooltip="Search by PO Number, Supplier, Container Number, Dates, SKUs, Port, POL, HBL etc">&#63;</span>
        <button mat-button *ngIf="searchQuery" matSuffix mat-icon-button aria-label="Clear"
          (click)="clearSearchFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="po-form-field">
        <mat-label>Supplier:</mat-label>
        <mat-select [(value)]="selectedSupplier" (selectionChange)="selectedSupplierChange($event.value)"
          [disabled]="!showQuickFilter">
          <mat-option *ngFor="let supp of suppliers" [value]="supp">
            {{ supp.supplierName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="po-form-field">
        <mat-label>Category:</mat-label>
        <mat-select [(value)]="selectedCategory" (selectionChange)="selectedCategoryChange($event.value)"
          [disabled]="!showQuickFilter">
          <mat-option *ngFor="let cat of categories" [value]="cat">
            {{ cat.categoryName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="po-form-field" [floatLabel]="'never'">
        <mat-label>Status:</mat-label>
        <mat-select [(value)]="selectedStatus" (selectionChange)="selectedStatusChange($event.value)"
          [disabled]="!showQuickFilter">
          <mat-option *ngFor="let s of statuses" [value]="s">
            {{ s }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-checkbox class="mg-l4" [(ngModel)]="showUnpaid" (change)="filterByPaidChange()">Show Unpaid
      </mat-checkbox>
      <mat-checkbox class="mg-l4" [(ngModel)]="showGoodsReady" (change)="filterByGoodsReadyChange()">Goods Ready Only
      </mat-checkbox>
    </div>


    <div class="d-flex-sp-btw">
      <button style="height: 32px; margin-left: 4px" mat-raised-button color="primary" [routerLink]="'/home/po-creation'" routerLinkActive="active-list-item">
        Add PO
      </button>

      <button mat-icon-button (click)="refreshPoScreen()" color="primary">
        <mat-icon>cached</mat-icon>
      </button>

      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More actions" matTooltip="More actions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openPODates()">
          <span>Upload PO Dates sheet</span>
        </button>
        <button mat-menu-item (click)="openPorts()">
          <span>Ports & POLs</span>
        </button>
        <button mat-menu-item (click)="exportGridToExcelSheet()">
          <span>Export to Excel</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <ag-grid-angular id="purchase-table-container" class="ag-theme-balham mg-b4" [rowData]="rowData"
    [columnDefs]="columnDefs" [getRowStyle]="getRowStyle" [defaultColDef]="defaultColDef"
    (gridReady)="onGridReady($event)" [headerHeight]="headerHeight" [rowSelection]="rowSelection"
    (selectionChanged)="onSelectionChanged($event)" (cellValueChanged)="onCellValueChanged($event)"
    (cellClicked)="onCellClick($event)" [enableCellChangeFlash]="true" [frameworkComponents]="frameworkComponents"
    [undoRedoCellEditing]="true" [undoRedoCellEditingLimit]="1" [animateRows]="true"
    (sortChanged)="onSortChanged($event)" (filterChanged)="onFilterChanged($event)"
    (firstDataRendered)="dataIsRendered()" (columnMoved)="saveTabsConfig()" (columnResized)="saveTabsConfig()">
    <!-- (sortChanged)="onColumnStateChange($event)"
      (columnResized)="onColumnStateChange($event)"
      (columnVisible)="onColumnStateChange($event)"
      (columnPivotChanged)="onColumnStateChange($event)"
      (columnRowGroupChanged)="onColumnStateChange($event)"
      (columnValueChanged)="onColumnStateChange($event)"
      (columnMoved)="onColumnStateChange($event)"
      (columnPinned)="onColumnStateChange($event)" -->
  </ag-grid-angular>

  <div *ngIf="selectedItem" class="d-flex-vert-center gap-4 mg-b4">
    <button mat-stroked-button (click)="editPurchaseOrder()">
      Edit
    </button>
    <button mat-stroked-button (click)="setPurchaseDatesToCertainDate()">
      {{ selectedItem.poNumber }} - Set 01/01/20
    </button>
    <button mat-stroked-button (click)="calculateAndUpdatePurchaseDates()">
      {{ selectedItem.poNumber }} - Shipping Date
    </button>
    <button mat-stroked-button (click)="setPurchaseToDelivered()">
      {{ selectedItem.poNumber }} - Receipt
    </button>
    <button mat-stroked-button (click)="removePurchaseOrder(selectedItem)">
      {{ selectedItem.poNumber }} - Delete
    </button>

    <button mat-stroked-button (click)="generatePurchaseSpecSheets()">
      Spec Sheets
    </button>
    <button mat-stroked-button (click)="sendToEmail(selectedItem.purchaseId)">
      Email
    </button>
    <button mat-stroked-button (click)="openPurchaseDocuments()">
      {{ selectedItem.poNumber }} - Docs
    </button>
    <button mat-stroked-button (click)="openQcPhotosDialogComponent()">
      QC-Photos
    </button>
    <!-- <button
        mat-stroked-button
        *ngIf="currentGridView === 'custom'"
        (click)="restoreState()"
      >
        Restore Columns
      </button>
      <button
        mat-stroked-button
        *ngIf="currentGridView === 'custom'"
        (click)="resetState()"
      >
        Reset Columns
      </button> -->
  </div>



  <div class="d-flex-vert-center d-flex-sp-btw">
    <mat-button-toggle-group class="view-toggler" name="views" [(ngModel)]="currentGridView"
      (change)="changeView($event.value)">
      <mat-button-toggle value="all">All</mat-button-toggle>
      <mat-button-toggle value="numbers">Numbers</mat-button-toggle>
      <mat-button-toggle value="vessels">Vessels</mat-button-toggle>
      <mat-button-toggle value="dates">Dates</mat-button-toggle>
      <mat-button-toggle value="docs">Docs</mat-button-toggle>
      <mat-button-toggle value="freightCost">Freight</mat-button-toggle>
      <mat-button-toggle value="production">Prod</mat-button-toggle>
      <!-- <mat-button-toggle value="custom">Custom</mat-button-toggle> -->
    </mat-button-toggle-group>

    <div class="d-flex-vert-center d-flex-sp-btw">
      <div class="d-flex-vert-center">
        <div class="wording d-flex-end d-flex-wrap gap-4">
          <div class="wording-chip" id="booked">booked</div>
          <div class="wording-chip" id="goods-ready">goods ready</div>
          <div class="wording-chip" id="container-loaded">loaded</div>
          <div class="wording-chip" id="so-released">so released</div>
          <div class="wording-chip" id="shipped">shipped</div>
          <div class="wording-chip" id="docs-received">docs received</div>
          <div class="wording-chip" id="paid">paid</div>
          <div class="wording-chip" id="docked">docked</div>
          <div class="wording-chip" id="invalid-dates">invalid dates</div>
        </div>
      </div>
    </div>
  </div>
</div>