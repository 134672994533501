import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { QcPhotosEmail } from "../models/QcPhotos";

@Injectable({
  providedIn: "root",
})
export class PurchaseService {
  constructor(private http: HttpClient) {}

  updatePurchaseProperties(properties) {
    return this.http.put(
      "api/purchaseProps/updatePurchaseOrderExtendedProperties",
      properties
    );
  }

  removePurchaseOrderById(purchaseId: string) {
    return this.http.post(`api/po/removePurchaseOrderById/${purchaseId}`, {});
  }

  getPurchasesWithExtProperties(status: string) {
    return this.http.get<any>(`api/purchaseProps/getPurchaseOrdersWithProps/${status}`);
  }

  getPurchasesWithProps() {
    return this.http.get<any>("api/purchaseProps/getPurchaseOrdersWithPropsV2");
  }

  setFavourite(purchaseId: string) {
    return this.http.get<any>("api/purchaseProps/setFavourite/" + purchaseId);
  }

  unsetFavourite(purchaseId: string) {
    return this.http.get<any>("api/purchaseProps/unsetFavourite/" + purchaseId);
  }

  updatePurchaseOrderHeader(purchaseOrderHeader) {
    return this.http.put(
      "api/purchaseProps/updatePurchaseOrderHeader",
      purchaseOrderHeader
    );
  }

  getExcelSheetFromCurrentPOScreenView(rowData: any, headers: any) {
    return this.http
      .post(
        "/api/PurchaseProps/generatePurchaseOrdersWithPropsExcel/",
        { PurchaseOrders: rowData, HeadersAndFields: headers },
        { responseType: "blob" }
      )
  }

  deliverAllPurchaseItems(purchaseId: string) {
    return this.http.post("api/po/deliverAllItems/" + purchaseId, null);
  }

  getPorts() {
    return this.http.get<any>(`api/purchaseProps/ports`);
  }

  getPOLs() {
    return this.http.get<any>(`api/purchaseProps/POLs`);
  }

  getNewPurchaseOrderNumber() {
    return this.http.get<any>("api/po/getNewPONumber");
  }

  getPurchaseOrderExtendedPropertyByPropertyName(
    purchaseId: string,
    propertyName: string
  ) {
    return this.http.get<any>(
      `api/po/getPurchaseOrderExtendedPropertyByPropertyName?purchaseId=${purchaseId}&propertyName=${propertyName}`
    );
  }

  sendPurchaseWithSpecSheetsToEmail(purchaseId: string, recepients: string[]) {
    return this.http.post("api/purchaseProps/sendPurchaseWithSpecSheets/", {purchaseId: purchaseId, recepients: recepients});
  }

  sendQcPhotosViaEmail(qcPhotosEmail: QcPhotosEmail) {
    return this.http.post("api/po/sendQcPhotosViaEmail/", qcPhotosEmail);
  }

  private getAllPurchasesUrl = "api/po/getAllPurchases";
  getAllPurchases() {
    return this.http.get<any>(this.getAllPurchasesUrl);
  }

  private getPurchaseOrdersBySubstringUrl =
    "api/po/getPurchaseOrdersBySubstring";
  getPurchaseOrdersBySubstring(purchaseOrderSubstring: string) {
    return this.http.get<any>(
      this.getPurchaseOrdersBySubstringUrl +
        `?purchaseOrderSubstring=${purchaseOrderSubstring}`
    );
  }
}
