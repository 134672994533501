import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';


interface ItemExtendedProperty {
  rowId: number;
  stockItemId: string;
  propertyName: string;
  propertyValue: string;
  applicableToOther: boolean;
}

@Component({
  selector: 'app-edit-procurement-item-props',
  templateUrl: './edit-procurement-item-props.component.html',
  styleUrls: ['./edit-procurement-item-props.component.css']
})
export class EditProcurementItemPropsComponent implements OnInit {

  http: HttpClient;
  baseUrl: string;
  loadingData = false;

  propsForm: UntypedFormGroup;

  extProps: ItemExtendedProperty[];

  productionTime: ItemExtendedProperty;
  shippingTime: ItemExtendedProperty;
  minimalOrderedQty: ItemExtendedProperty;
  cartonQty: ItemExtendedProperty;
  stockHolding: ItemExtendedProperty;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  http: HttpClient,
  @Inject('BASE_URL') baseUrl: string,
  private _snackBar: MatSnackBar) {
    this.http = http;
    this.baseUrl = baseUrl;
   }

  ngOnInit(): void {

    this.http.get<ItemExtendedProperty[]>(this.baseUrl + 'api/procurement/extendedProps/' + this.data.stockItemId)
      .subscribe(result => {
        result.forEach(prop => {
          if (prop.propertyName === 'Production Time' && parseInt(prop.propertyValue, 10) < 1) {
            prop.propertyValue = '35';
          }
          if (prop.propertyName === 'Shipping Time' && parseInt(prop.propertyValue, 10) < 1) {
            prop.propertyValue = '30';
          }
        });
        this.extProps = result;
        this.loadingData = false;
      }, error => {
        this.loadingData = false;
        console.error(error);
        this.openSnackBar('Something went wrong (', '');
      });
  }

  updateProperties() {
    this.loadingData = true;
    this.http.post(this.baseUrl + 'api/procurement/updateStockItemExtendedProps/' + this.data.stockItemId, this.extProps).subscribe(result => {
      this.loadingData = false;
      this.openSnackBar('Succesfully updated', '');
    }, error => {
      this.loadingData = false;
      console.error(error);
      this.openSnackBar('Something went wrong (', '');
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }
}
