import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { combineLatest } from "rxjs";
import { LoaderService } from "src/app/core/services/loader.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { CostSheetNewService } from "src/app/shared/services/cost-sheet-new.service";
import { getHeaderTemplate } from "src/app/shared/utils/ag-grid-helper";
import { CostSheetService } from "../../../shared/services/cost-sheet.service";
import {
  AddNewShippingFeeComponent,
  ShippingFee,
} from "./add-new-shipping-fee/add-new-shipping-fee.component";

@Component({
  selector: "app-shipping-fees",
  templateUrl: "./shipping-fees.component.html",
  styleUrls: ["./shipping-fees.component.css"],
})
export class ShippingFeesComponent implements OnInit {
  public gridApi;
  public gridColumnApi;
  public columnDefs;
  public defaultColDef;
  public rowData = [];
  public headerHeight;
  public columnTypes;
  public rowSelection;

  postalServices: any[];
  packageGroups: any[];
  channels: any[];

  selectedService: any;
  selectedPackageGroup: any;
  shippingFees: any;
  selectedItem: ShippingFee;
  loadingData = false;

  constructor(
    private snackBar: SnackBarService,
    private loader: LoaderService,
    public dialog: MatDialog,
    private costSheetService: CostSheetService,
    private costingSheetNewService: CostSheetNewService
  ) {
    this.defaultColDef = {
      flex: 1,
      resizable: true,
      wrapText: true,
      // autoHeight: true,
      sortable: true,
      filter: true,
      headerComponentParams: {
        template: getHeaderTemplate(),
      },
    };
    this.columnDefs = [
      {
        headerName: "Postal Service",
        field: "postalServiceName",
        resizable: true,
        width: 180,
      },
      // { headerName: "Tag", field: "title", resizable: true, editable: true },
      { headerName: "Name", field: "name", resizable: true, editable: true },
      {
        headerName: "Base Cost",
        field: "baseCost",
        resizable: true,
        editable: true,
      },
      {
        headerName: "Fuel Surcharge, %",
        field: "fuelSurchargePercentage",
        resizable: true,
        editable: true,
        minWidth: 50,
      },
      {
        headerName: "Extra Box Surcharge",
        field: "extraBoxSurcharge",
        resizable: true,
        editable: true,
        minWidth: 50,
      },
      {
        headerName: "Surcharge per kilo",
        field: "surchargePerKilogram",
        resizable: true,
        editable: true,
        minWidth: 50,
      },
      // {
      //   headerName: "Surcharge %",
      //   field: "surchargePercentage",
      //   resizable: true,
      //   editable: true,
      //   minWidth: 50,
      // },
      {
        headerName: "Driver Cost, %",
        field: "driverCostPercentage",
        resizable: true,
        editable: true,
        minWidth: 50,
      },
      {
        headerName: "Total",
        field: "total",
        resizable: true,
        valueGetter: totalGetter,
        minWidth: 50,
      },
      {
        headerName: "Width",
        field: "width",
        resizable: true,
        editable: true,
      },
      {
        headerName: "Height",
        field: "height",
        resizable: true,
        editable: true,
      },
      {
        headerName: "Depth",
        field: "depth",
        resizable: true,
        editable: true,
      },
      {
        headerName: "Min Weight",
        field: "minWeight",
        resizable: true,
        editable: true,
      },
      {
        headerName: "Max Weight",
        field: "maxWeight",
        resizable: true,
        editable: true,
      },
      {
        headerName: "Packaging Weight",
        field: "packagingWeight",
        resizable: true,
        editable: true,
      },
    ];
    this.headerHeight = 180;

    this.rowSelection = "single";

    this.columnTypes = {
      numberColumn: {
        filter: "agNumberColumnFilter",
      },
    };
  }

  ngOnInit(): void {

    combineLatest([
      this.costingSheetNewService.getChannels(),
      this.costSheetService.getPostalServices(),
      this.costSheetService.getPackageGroups(),
      this.costSheetService.getShippingFees(),
    ]).subscribe(([channels, providers, packageGroups, shippingFees]) => {
      this.channels = channels;
      this.postalServices = providers;
      this.packageGroups = packageGroups;
      this.shippingFees = shippingFees;
      this.updatePostalServiceNames(this.postalServices);

      this.gridApi.setRowData(this.shippingFees);
    });
    this.getChannels();
  }

  getChannels() {
    // this.costSheetService.getChannels().subscribe((result) => {
    //   this.channels = result;
    // });

    this.costingSheetNewService.getChannels().subscribe((result) => {
      this.channels = result;
    })
  }

  getProviders() {
    this.costSheetService.getPostalServices().subscribe((result) => {
      this.postalServices = result;
      // if (this.postalServices && this.packageGroups) {
      //   this.getShippingFees();
      // }
    });
  }

  getPackageGroups(): void {
    this.costSheetService.getPackageGroups().subscribe((result) => {
      this.packageGroups = result;
    });
  }

  selectedServiceChange(params) { }

  getShippingFees() {
    this.costSheetService.getShippingFees().subscribe(
      (result) => {
        this.shippingFees = result;
        // this.updatePostalServiceNames(this.postalServices);
        // let api = this.gridApi;
        // api.setRowData([]);
        // api.applyTransaction({ add: this.shippingFees });
      });
  }

  updatePostalServiceNames(postalServices: any[]) {
    this.shippingFees?.forEach((sf) => {
      let postalService = this.postalServices.find(
        (x) => x.id === sf.postalServiceId
      );
      if (
        postalService?.postalServiceName === "Default" &&
        sf.specialChannelId === 4
      ) {
        sf.postalServiceName = "";
      } else {
        sf.postalServiceName = postalService?.postalServiceName;
      }
    });
  }

  addShippingFee() {
    const dialogRef = this.dialog.open(AddNewShippingFeeComponent, {
      data: {
        postalServices: this.postalServices,
        packageGroups: this.packageGroups,
        channels: this.channels,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.value === true) {
        this.getShippingFees();
      }
    });
  }

  updateShippingFee(shippingFee: ShippingFee) {
    this.costSheetService.updateShippingFee(shippingFee).subscribe(
      (result) => {
        let api = this.gridApi;
        let itemsToUdpate = [];

        let rowData = this.getRowData();
        let i = rowData.find((x) => x.id === result.id);
        i.baseCost = result.baseCost;
        i.fuelSurchargePercentage = result.fuelSurchargePercentage;
        i.extraBoxSurcharge = result.extraBoxSurcharge;
        i.surchargePerKilogram = result.surchargePerKilogram;
        i.surchargePercentage = result.surchargePercentage;
        i.width = result.width;
        i.height = result.height;
        i.depth = result.depth;
        i.minWeight = result.minWeight;
        i.maxWeight = result.maxWeight;
        itemsToUdpate.push(i);
        api.applyTransaction({ update: itemsToUdpate });
        this.snackBar.openSnackBar("Successfully updated", "Shipping Fee");
      },
      (error) => {
        this.snackBar.openSnackBar("Something went wrong", "Error");
      }
    );
  }

  // // // Grid actions
  onCellValueChanged(event) {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    this.updateShippingFee(event.data);
  }

  getRowData() {
    let rowData = [];
    this.gridApi.forEachNode(function (node) {
      rowData.push(node.data);
    });
    return rowData;
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onSelectionChanged(params) {
    let selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow) {
      this.selectedItem = selectedRow[0];
    }
  }

  removeSelectedRule() {
    const dialogData = new ConfirmDialogModel(
      "Confirm Action",
      "Are you sure?"
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.loader.show();
        this.costSheetService
          .removeShippingFeeRule(this.selectedItem.id)
          .subscribe(
            (result) => {
              let itemsToRemove: ShippingFee[] = [];
              itemsToRemove.push(this.selectedItem);
              this.gridApi.applyTransaction({ remove: itemsToRemove });
              this.loader.hide();
              this.snackBar.openSnackBar(
                "Succesfully removed",
                "Shipping Fee Rule"
              );
            },
            (error) => {
              this.loader.hide();
              this.snackBar.openSnackBar("Something went wrong", "Error");
            }
          );
      }
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(50);
    this.gridApi.sizeColumnsToFit();

    this.getProviders();
    this.getPackageGroups();
  }

  getRowHeight(params) {
    return 30;
  }
}

function totalGetter(params) {
  return roundNumber(
    parseFloat(params.data.baseCost) +
    parseFloat(params.data.extraBoxSurcharge) +
    (parseFloat(params.data.baseCost) / 100) *
    parseFloat(params.data.fuelSurchargePercentage)
  );
}

function roundNumber(value: number): number {
  return Math.round(value * 100) / 100;
}
