<div class="po-view-container">
  <mat-grid-list cols="5" rowHeight="60px">
    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="selectPO">
          <mat-label>Purchase Orders:</mat-label>

          <input
            type="text"
            placeholder="Search item"
            [disabled]="loadingData || currentPO == null"
            matInput
            [formControl]="posCtrl"
            [matAutocomplete]="auto"
            [(ngModel)]="searchPOField"
          />

          <mat-autocomplete
            autoActiveFirstOption
            #auto="matAutocomplete"
            (optionSelected)="
            selectedPOChange($event.option.value); searchPOField = ''
          "
          >
            <mat-option
              *ngFor="let option of filteredPOs | async"
              [value]="option.externalInvoiceNumber"
            >
              {{ option.externalInvoiceNumber }} - {{ option.supplierName }}
            </mat-option>
          </mat-autocomplete>

          <button
            mat-button
            mat-icon-button
            matSuffix
            (click)="clearSearchField()"
            *ngIf="searchPOField"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="poNumber">
          <input
            matInput
            placeholder="Order Number"
            disabled="true"
            [(ngModel)]="poNumber"
            (change)="updatePO()"
          />
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="dateOrder">
          <mat-label>Order Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [disabled]="true"
            [(ngModel)]="dateOrder"
            (change)="updatePO()"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="goodsReadyDate">
          <mat-label>Goods Ready Date</mat-label>
          <input
            matInput
            [matDatepicker]="pickerGoods"
            [disabled]="currentPO == null"
            [(ngModel)]="goodsReadyDate"
            (change)="updatePO()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerGoods"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerGoods></mat-datepicker>
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="selectSupplier">
          <mat-label>Supplier:</mat-label>
          <mat-select
            [(value)]="selectedSupplier"
            (selectionChange)="selectedSupplierChange($event.value)"
            [disabled]="
            loadingData || currentPO == null || currentPO.status == 'DELIVERED'
          "

          >
            <mat-option *ngFor="let supp of suppliers" [value]="supp">
              {{ supp.supplierName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="selectContainerSize">
          <mat-label>Container size:</mat-label>
          <mat-select
            [(value)]="selectedContainerSize"
            [disabled]="currentPO == null"
            (change)="updatePO()"
          >
            <mat-option
              *ngFor="let containerSize of containerSizes"
              [value]="containerSize"
            >
              {{ containerSize }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="selectPort">
          <mat-label>POL:</mat-label>
          <mat-select
            [(value)]="selectedPort"
            [disabled]="loadingData || currentPO == null"
            (change)="updatePO()"
          >
            <mat-option *ngFor="let port of ports" [value]="port">
              {{ port.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="selectStatus">
          <mat-label>Status:</mat-label>
          <mat-select
            [(value)]="selectedStatus"
            [disabled]="loadingData || currentPO == null"
            (change)="updatePO()"
          >
            <mat-option *ngFor="let status of statuses" [value]="status">
              {{ status }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="referenceNum">
          <input
            matInput
            placeholder="Goods Ref"
            [disabled]="currentPO == null"
            [(ngModel)]="referenceOfPO"
            (change)="updatePO()"
          />
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="referenceNum2">
          <input
            matInput
            placeholder="Shipping Ref"
            [disabled]="currentPO == null"
            [(ngModel)]="referenceOfPO2"
            (change)="updatePO()"
          />
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="referenceNum2">
          <input
            matInput
            placeholder="Supp Inv No"
            [disabled]="currentPO == null"
            [(ngModel)]="supplierInvoiceNumber"
            (change)="updatePO()"
          />
        </mat-form-field>
      </div>
    </mat-grid-tile>

    <mat-grid-tile *ngIf="this.currentPO">
      <div class="col">
        <mat-form-field class="referenceNum2">
          <input
            matInput
            type="number"
            min="0.0"
            placeholder="Conversion Rate"
            [disabled]="currentPO == null"
            [(ngModel)]="currentPO.conversionRate"
            (change)="updatePO()"
          />
        </mat-form-field>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-checkbox class="mg-b16" [(ngModel)]="disableBoxQtyAlign">CTN - </mat-checkbox>
    </mat-grid-tile>
  </mat-grid-list>

  <div class="grid-wrapper">
    <ag-grid-angular
      id="grdOrderItems"
      class="ag-theme-balham"
      [headerHeight]="headerHeight"
      [pinnedBottomRowData]="pinnedBottomRowData"
      (rowDataUpdated)="onRowDataUpdated($event)"
      [frameworkComponents]="frameworkComponents"
      [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)"
      (cellValueChanged)="onCellValueChanged($event)"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [stopEditingWhenGridLosesFocus]="true"
      [tabToNextCell]="tabToNextCell"
    >
    </ag-grid-angular>
  </div>

    <div id="button-row" class="d-flex-vert-center gap-8 mg-t8 mg-b8">
      <button
              mat-raised-button
              color="primary"
              [disabled]="loadingData || currentPO == null"
              (click)="this.router.navigate(['/po-props'])"
              *ngIf="this.poService.goBack">
        Go back
      </button>
      <button
        mat-raised-button
        [disabled]="loadingData || currentPO == null"
        color="primary"
        (click)="sendToEmail(currentPO.purchaseID)"
      >
        Email
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="loadingData || currentPO == null"
        (click)="openQcPhotosDialog()"
      >
        QC-Photos
      </button>

      <button
        mat-raised-button
        color="primary"
        [disabled]="loadingData || currentPO == null"
        (click)="openExtPropsDialog()"
      >
        Ext props
      </button>

      <button
        mat-raised-button
        color="primary"
        [disabled]="loadingData || currentPO == null"
        (click)="openPurchaseDocuments()"
      >
        Docs
      </button>

      <div class="save-changes">
        <button
          mat-raised-button
          color="accent"
          [disabled]="loadingData || currentPO == null"
          [ngClass]="{ 'blink-button': changesWereMade && !loadingData }"
          (click)="updatePO()"
        >
          SAVE CHANGES
        </button>
      </div>
      <button
        mat-raised-button
        color="primary"
        [disabled]="loadingData || currentPO == null"
        (click)="duplicatePO()"
      >
        Duplicate
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="loadingData || currentPO == null"
        (click)="getPurchasePDF()"
      >
        PDF
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="loadingData || currentPO == null"
        (click)="getPurchasePdfWithPhotos()"
      >
        PDF&Photos
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="loadingData || currentPO == null"
        (click)="createPOToExcel()"
      >
        Excel
      </button>

      <button
        mat-raised-button
        color="primary"
        [disabled]="loadingData || currentPO == null"
        (click)="createInvoiceExcel()"
      >
        Custom Invoice
      </button>

      <button
        mat-raised-button
        color="primary"
        [disabled]="loadingData || currentPO == null"
        (click)="createPackingListExcel()"
      >
        Custom Packing List
      </button>
    </div>
</div>
