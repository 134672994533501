import { Component, OnInit } from '@angular/core';
import { OrderService } from "../../services/order.service";
import { Observable, mergeMap, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SendEmailsDialogComponent } from '../send-emails-dialog/send-emails-dialog.component';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';

@Component({
  selector: 'app-runs-overview',
  templateUrl: './runs-overview.component.html',
  styleUrls: ['./runs-overview.component.scss']
})
export class RunsOverviewComponent implements OnInit {

  public runs: any[];
  public selectedRun: any;


  constructor(
    private streamService: OrderService,
    public dialog: MatDialog,
    private snackbar: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.streamService.getRuns()
    .pipe(
      tap((runs: any[]) => {
          this.runs = runs;
      })
    )
    .subscribe();
  }

  openDialog() {
    const dialogRef = this.dialog.open(SendEmailsDialogComponent, {
      width: '800px',
      height: '460px'
    });

    dialogRef.afterClosed().pipe(
      mergeMap((result) => {
        if (result) {
          result.recepients = this.selectedRun.customers.map(x => x.email);
          return this.streamService.sendEmailsToCustomers(result);
        }
        return new Observable();
      }),
      tap(() => {
        this.snackbar.success('Emails were sent');
      })
    ).subscribe();
  }

}
