<div class="dialog-wrapper">

  <mat-tab-group mat-align-tabs="start" animationDuration="0ms">
    <mat-tab label="POLs">
      <app-pol></app-pol>
    </mat-tab>

    <mat-tab label="Ports">
      <div class="grid-wrapper">
        <ag-grid-angular style="width: 100%; height: 240px" id="PortsGrid" class="ag-theme-balham" [rowData]="rowData"
          [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [getRowHeight]="getRowHeight"
          [rowSelection]="rowSelection" (selectionChanged)="onSelectionChanged($event)"
          (cellValueChanged)="onCellValueChanged($event)" [headerHeight]="headerHeight"
          (firstDataRendered)="onFirstDataRendered($event)" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>

      <mat-card>
        <button mat-raised-button color="primary" *ngIf="selectedItem" (click)="removeSelectedPort()"
          style="float: right">
          Remove Port
        </button>

        <form [formGroup]="portForm" autocomplete="off" novalidate (ngSubmit)="addPort(portForm.value)">
          <mat-form-field>
            <input matInput type="text" placeholder="Name" formControlName="name" id="name" />
            <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
            <mat-error *ngIf="hasError('name', 'maxlength')">You have more than 60 characters</mat-error>
          </mat-form-field>
          <mat-card-actions>
            <button mat-raised-button color="primary" [disabled]="!portForm.valid">
              Create
            </button>
            <button mat-raised-button mat-dialog-close>Close</button>
          </mat-card-actions>
        </form>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</div>