import { LandedCostService } from '../../shared/services/landed-cost.service';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { saveAs } from 'file-saver-es';
import { getHeaderTemplate } from 'src/app/shared/utils/ag-grid-helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InventoryService } from 'src/app/shared/services/inventory.service';
import { UserService } from "../../shared/services/user.service";

@Component({
  selector: 'app-landed-cost-sheet',
  templateUrl: './landed-cost-sheet.component.html',
  styleUrls: ['./landed-cost-sheet.component.css'],
  providers: [DatePipe],
})
export class LandedCostSheetComponent implements OnInit, OnDestroy {
  static _datepipe: DatePipe;
  quickFilter: string = '';

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private snackbar: SnackBarService,
    private landedCostService: LandedCostService,
    private inventoryService: InventoryService,
    private userConfigService: UserService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;

    this.defaultColDef = {
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      headerComponentParams: {
        template: getHeaderTemplate(),
      },
    };
    this.columnDefs = [
      {headerName: 'SKU', field: 'sku', resizable: true, width: 240},

      {headerName: 'Title', field: 'title', resizable: true},
      {
        headerName: 'Purchase Price USD',
        field: 'purchasePriceUSD',
        resizable: true,
        cellStyle: function (params) {
          if (params.value === 0) {
            return {backgroundColor: '#F5B7B1'};
          } else {
            return {backgroundColor: ''};
          }
        },
        valueFormatter: (params) => currencyFormatter(params.value, '$'),
      },
      {
        headerName: 'Landed Cost GBP',
        field: 'landedCost',
        resizable: true,
        editable: true,
        valueFormatter: (params) => currencyFormatter(params.value, '£'),
      },
      {
        headerName: 'Average Landed Cost GBP',
        field: 'averageLandedCost',
        resizable: true,
        valueFormatter: (params) => currencyFormatter(params.value, '£'),
        width: 240,
        cellStyle: function (params) {
          if (params.data.landedCost !== params.data.averageLandedCost) {
            return {backgroundColor: '#E8DAEF'};
          } else {
            return {backgroundColor: ''};
          }
        },
      },
      {headerName: 'Freight GBP', field: 'freightGBP', resizable: true, valueFormatter: (params) => currencyFormatter(params.value, '£')},
      {headerName: 'CBM', field: 'cbm', resizable: true},
      {headerName: 'CTN QTY', field: 'ctnQty', resizable: true},
      {
        headerName: 'Last PO Freight',
        field: 'lastPurchaseOrderFreight',
        resizable: true,
        valueFormatter: (params) => currencyFormatter(params.value, '£'),
      },
      {
        headerName: 'Last PO Freight USD',
        field: 'lastPurchaseOrderFreightUSD',
        resizable: true,
        valueFormatter: (params) => currencyFormatter(params.value, '$'),
      },
      {
        headerName: 'Last PO Duty',
        field: 'lastPurchaseOrderDuty',
        resizable: true,
      },
      {
        headerName: 'Last Update',
        field: 'lastUpdate',
        resizable: true,
        valueFormatter: timeFormatter,
      },
      {
        headerName: 'Last PO Date',
        field: 'lastPurchaseOrderDate',
        resizable: true,
        valueFormatter: timeFormatter,
        cellStyle: function (params) {
          if (new Date(params.value) < new Date('01/01/2018')) {
            return {backgroundColor: '#F5B7B1'};
          } else {
            return {backgroundColor: ''};
          }
        },
      },
      {
        headerName: 'Last PO Number',
        field: 'lastPurchaseOrderNumber',
        resizable: true,
      },
      {
        headerName: 'Manual Overrided',
        field: 'isManualOverrided',
        resizable: true,
        cellStyle: function (params) {
          if (params.value === true) {
            return {backgroundColor: '#F5B041'};
          } else {
            return {backgroundColor: ''};
          }
        },
      },
    ];
    this.headerHeight = 100;

    this.rowSelection = 'single';

    this.columnTypes = {
      numberColumn: {
        filter: 'agNumberColumnFilter',
      },
    };
  }

  http: HttpClient;
  baseUrl: string;
  public gridApi;
  public gridColumnApi;
  public columnDefs;
  public defaultColDef;
  public rowData = [];
  public headerHeight;
  public columnTypes;
  public rowSelection;
  public datepipe: DatePipe;
  public loadingData = false;
  public rowsCount = 0;
  public landedCosts: any[];
  baseRowData: any[];
  public selectedItem: any;
  destroyedComponentSubj = new Subject<void>();

  public suppliers: any[];
  public categories: any[];

  selectedSupplier: any;
  selectedCategory: any;

  ngOnInit(): void {
    this.getLandedCosts();
    this.inventoryService.getSuppliers().subscribe(suppliers => {
      this.suppliers = suppliers;
      this.suppliers.unshift({supplierName: 'All', pkSupplierID: ''});
    });

    this.inventoryService.getCategories().subscribe(categories => {
      this.categories = categories;
      this.categories.unshift({
        categoryName: 'All',
        categoryId: '',
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyedComponentSubj.next();
    this.destroyedComponentSubj.complete();
  }

  showQuickFilter: boolean = false;

  dataIsRendered() {
    this.showQuickFilter = true;
  }

  getLandedCosts() {
    this.http.get<any[]>(this.baseUrl + 'api/cost/getLandedCosts/')
      .pipe(takeUntil(this.destroyedComponentSubj))
      .subscribe(
        (result) => {
          this.landedCosts = result;
          this.baseRowData = result;
          this.tableSeeder(this.landedCosts);
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getRowData() {
    let rowData = [];
    this.gridApi.forEachNode(function (node) {
      rowData.push(node.data);
    });
    return rowData;
  }

  dateGetter(params) {
    return this.datepipe.transform(
      params.getValue('lastUpdate'),
      'dd/MM/yyyy hh:mm:ss'
    );
  }

  tableSeeder(values: any[]) {
    if (values.length > 0) {
      this.rowsCount = this.landedCosts.length;
      this.gridApi.setRowData([]);
      this.gridApi.applyTransaction({add: this.landedCosts});
    }
  }

  overrideItemLandedCost(itemToUpdate) {
    let itu = {
      Id: itemToUpdate.id,
      StockItemId: itemToUpdate.stockItemId,
      SKU: itemToUpdate.sku,
      Title: itemToUpdate.title,
      PurchasePriceUSD: itemToUpdate.purchasePriceUSD,
      freightGBP: itemToUpdate.freightGBP,
      CBM: itemToUpdate.cbm,
      CtnQty: itemToUpdate.ctnQty,
      LandedCost: parseFloat(itemToUpdate.landedCost),
      LastUpdate: itemToUpdate.lastUpdate,
      IsManualOverrided: itemToUpdate.isManualOverrided,
      LastPurchaseOrderDate: itemToUpdate.lastPurchaseOrderDate,
      LastPurchaseOrderNumber: itemToUpdate.lastPurchaseOrderNumber,
      LastPurchaseOrderFreight: parseFloat(
        itemToUpdate.lastPurchaseOrderFreight
      ),
      LastPurchaseOrderDuty: parseFloat(itemToUpdate.lastPurchaseOrderDuty),
    };

    this.landedCostService
      .overrideLandedCost(itemToUpdate.stockItemId, itu.LandedCost)
      .subscribe(
        (result) => {
          let rowData = this.getRowData();

          let i = rowData.find((x) => x.stockItemId === result.stockItemId);
          let itemsToUpdate = [];
          i.isManualOverrided = result.isManualOverrided;
          i.lastUpdate = result.lastUpdate;
          i.landedCost = result.landedCost;
          itemsToUpdate.push(i);
          this.gridApi.applyTransaction({update: itemsToUpdate});

          this.snackbar.success('Succesfully updated');
        },
        (error) => {
          console.error(error);
          this.snackbar.error('Something went wrong (');
        }
      );
  }

  onCellValueChanged(event) {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);

    this.overrideItemLandedCost(event.data);
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onSelectionChanged(params) {
    let selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow) {
      this.selectedItem = selectedRow[0];
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(48);
    this.gridApi.sizeColumnsToFit();
  }

  getRowHeight(params) {
    return 30;
  }

  // This will update all landed costs!!!
  updateLandedCosts() {
    this.loadingData = true;
    this.http.get<any>(this.baseUrl + 'api/cost/updateLandedCosts/').subscribe(
      (result) => {
        this.snackbar.success('Succesfully updated');
        this.getLandedCosts();
        this.loadingData = false;
      },
      (error) => {
        this.loadingData = false;
        this.snackbar.error('Something went wrong (');
      }
    );
  }

  // Update single item landed cost
  recalculateItemLandedCost() {
    let stockItemId = this.selectedItem.stockItemId;

    this.loadingData = true;
    this.http
      .get<any>(
        this.baseUrl + 'api/cost/recalculateItemLandedCost/' + stockItemId
      )
      .subscribe(
        (result) => {
          this.snackbar.success('Successfully updated');
          this.loadingData = false;
          let itemsToUpdate = [];
          let rowData = this.getRowData();
          let i = rowData.find((x) => x.stockItemId === result.stockItemId);
          i.sku = result.sku;
          i.title = result.title;
          i.lastPurchaseOrderDate = result.lastPurchaseOrderDate;
          i.lastPurchaseOrderDuty = result.lastPurchaseOrderDuty;
          i.freightGBP = result.freightGBP;
          i.lastPurchaseOrderFreight = result.lastPurchaseOrderFreight;
          i.lastPurchaseOrderNumber = result.lastPurchaseOrderNumber;
          i.isManualOverrided = result.isManualOverrided;
          i.lastUpdate = result.lastUpdate;
          i.landedCost = result.landedCost;
          i.purchasePriceUSD = result.purchasePriceUSD;
          i.cbm = result.cbm;
          i.ctnQty = result.ctnQty;
          itemsToUpdate.push(i);
          this.gridApi.applyTransaction({update: itemsToUpdate});
        },
        (error) => {
          this.loadingData = false;
          this.snackbar.warning('Something went wrong (');
        }
      );
  }

  downloadExcel() {
    if (!this.userConfigService?.currentUserConfig?.exportToExcelAllowed) {
      return this.snackbar.warning('You dont have permissions to download Excel sheets');
    }
    this.loadingData = true;
    return this.http
      .get(this.baseUrl + 'api/cost/getLandedCostsExcel', {
        responseType: 'blob',
      })
      .subscribe(
        (result: any) => {
          if (result.type !== 'text/plain') {
            this.loadingData = false;
            var blob = new Blob([result]);
            let file = 'LandedCostSheet.xlsx';
            saveAs(blob, file);
          } else {
            alert('File not found in Blob!');
          }
        },
        (error) => {
          this.loadingData = false;
          this.snackbar.error('Something went wrong (');
        }
      );
  }

  selectedSupplierChange(value): void {
    this.selectedSupplier = value;
    this.filteringProcess();
  }

  selectedCategoryChange(value): void {
    this.selectedCategory = value;
    this.filteringProcess();
  }

  filteringProcess() {
    const allKeysPath = this.propertiesToArray(this.baseRowData[0]);
    let filteredArray = [];
    for (let i = 0; i < this.baseRowData.length; i++) {
      const element = this.baseRowData[i];
      let exit: boolean = false;
      for (let index = 0; index < allKeysPath.length; index++) {
        const key = allKeysPath[index];
        let prop = this.getPropertyByStringPath(element, key);
        if (
          prop &&
          !Array.isArray(prop) &&
          prop
            .toLowerCase()
            .includes(this.quickFilter.toLowerCase())
        ) {
          filteredArray.push(element);
          exit = true;
          break;
        }
      }
      if (exit) {
        exit = false;
        continue;
      }
    }

    if (this.selectedSupplier && this.selectedSupplier.pkSupplierID) {
      filteredArray = filteredArray.filter(
        (x) => x.supplierId === this.selectedSupplier.pkSupplierID
      );
    }
    if (this.selectedCategory && this.selectedCategory.categoryId) {
      filteredArray = filteredArray.filter((x) =>
        x.categoryId === this.selectedCategory.categoryId
      );
    }


    this.rowData = filteredArray;

  }

  propertiesToArray(obj) {
    const isObject = (val) => typeof val === 'object' && !Array.isArray(val);

    const addDelimiter = (a, b) => (a ? `${a}.${b}` : b);

    const paths = (obj = {}, head = '') => {
      return Object.entries(obj).reduce((product, [key, value]) => {
        let fullPath = addDelimiter(head, key);
        return isObject(value)
          ? product.concat(paths(value, fullPath))
          : product.concat(fullPath);
      }, []);
    };

    return paths(obj);
  }

  getPropertyByStringPath(object, keyPath) {
    keyPath = keyPath.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    keyPath = keyPath.replace(/^\./, ''); // strip a leading dot
    var a = keyPath.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in object) {
        object = object[k];
      } else {
        return;
      }
    }
    return object;
  }
}


function timeFormatter(params) {
  let date = new Date(params.value);
  let minDate = new Date('01/01/2018');
  if (date < minDate) {
    return 'undefined';
  }
  let dd = String(date.getDate()).padStart(2, '0');
  let mm = String(date.getMonth() + 1).padStart(2, '0');
  let yyyy = date.getFullYear();
  let result = dd + '/' + mm + '/' + yyyy;
  return result;
}

function currencyFormatter(value, sign) {
  var roundedValue = parseFloat(value).toFixed(2);
  var formatted = roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return sign + `${roundedValue}`;
}
