import { filter } from "rxjs/operators";
import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GridApi, GridOptions } from "ag-grid-community";
import { Column } from "src/app/shared/models/product-screen.models";

@Component({
  selector: "app-add-column-dialog",
  templateUrl: "./add-column-dialog.component.html",
  styleUrls: ["./add-column-dialog.component.scss"],
})
export class AddColumnDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddColumnDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  quickFilter: string;

  ngOnInit(): void {
    this.data.forEach((column) => {
      if (column) {
        this.rowData.push({
          name: column.name,
        });
      }
    });
  }

  gridApi: GridApi;
  gridOptions: GridOptions;

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();

    const sortModel = [{ colId: "name", sort: "asc" }];
    this.gridApi.setSortModel(sortModel);
  }

  columnDefs = [
    {
      field: "name",
    },
  ];

  rowData = [];

  selectedItems;
  onSelectionChanged(event) {
    let selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows) {
      this.selectedItems = selectedRows;
    }
  }

  addColumn() {
    if (this.selectedItems) {
      this.dialogRef.close(
        this.data.filter((x) =>
          this.selectedItems.map((x) => x.name).includes(x.name)
        )
        // this.data.find((x) => x.name === this.selectedItems.name)
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
