<app-loader></app-loader>
<mat-card *ngIf="props && stockItem">
    <mat-card-content>
        <div class="row">
            <mat-form-field>
                <mat-label>Product Details:</mat-label>
                <textarea matInput [(ngModel)]="productDetails.propertyValue" (input)="captureChange()" maxlength="2000"
                    cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field>
                <mat-label>Packaging Details:</mat-label>
                <textarea matInput [(ngModel)]="packagingDetails.propertyValue" (input)="captureChange()"
                    maxlength="2000" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>
        </div>

        <div class="row">
            <mat-form-field>
                <mat-label>General Comments:</mat-label>
                <textarea matInput [(ngModel)]="generalComments.propertyValue" (input)="captureChange()"
                    maxlength="2000" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>
        </div>

    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="primary" (click)="createSpecSheet()">Generate Spec Sheet</button>

        <!-- <button mat-raised-button color="primary" (click)="openDialogImages()">Test</button> -->
    </mat-card-actions>
</mat-card>