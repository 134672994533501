<div class="dialog-container">
    <h4>Confirm PO creation</h4>
    <mat-grid-list cols="4" rowHeight="80px">

        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-form-field appearance="standard">
                <mat-label>PO number:</mat-label>
                <input matInput placeholder="0" type="number" [value]="newPurchaseOrder.poNumber" [min]="0"
                    [(ngModel)]="newPurchaseOrder.poNumber" disabled>
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-form-field appearance="standard">
                <mat-label>Delivery Date:</mat-label>
                <input matInput type="text" [value]="newPurchaseOrder.deliveryDate"
                    [(ngModel)]="newPurchaseOrder.deliveryDate">
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-form-field appearance="standard">
                <mat-label>Container Type:</mat-label>
                <input matInput type="text" [value]="newPurchaseOrder.containerType"
                    [(ngModel)]="newPurchaseOrder.containerType" disabled>
            </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <mat-form-field appearance="standard">
                <mat-label>Container Size, CBM:</mat-label>
                <input matInput type="text" [value]="newPurchaseOrder.containerSize"
                    [(ngModel)]="newPurchaseOrder.containerSize" disabled>
            </mat-form-field>
        </mat-grid-tile>

    </mat-grid-list>


    <div class="item-table-container">
        <table mat-table [dataSource]="itemDataSource" class="mat-elevation-z0">

            <!-- SKU Column -->
            <ng-container matColumnDef="sku">
                <th mat-header-cell *matHeaderCellDef> SKU </th>
                <td mat-cell *matCellDef="let item"> {{item.sku}} </td>
            </ng-container>

            <!-- Title Column -->
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef> Title </th>
                <td mat-cell *matCellDef="let item"> {{item.title}} </td>
            </ng-container>

            <!-- Qty Column -->
            <ng-container matColumnDef="qty">
                <th mat-header-cell *matHeaderCellDef> Quantity </th>
                <td mat-cell *matCellDef="let item"> {{item.qty}} </td>
            </ng-container>

            <!-- CBM Column -->
            <ng-container matColumnDef="cbm">
                <th mat-header-cell *matHeaderCellDef> CBM per Item </th>
                <td mat-cell *matCellDef="let item"> {{item.cbm}} </td>
            </ng-container>

            <!-- TotalCBM Column -->
            <ng-container matColumnDef="totalCbm">
                <th mat-header-cell *matHeaderCellDef> Total CBM per Item </th>
                <td mat-cell *matCellDef="let item"> {{(item.cbm * item.qty) | number : '1.2-2'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="itemDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: itemDisplayedColumns;"></tr>
        </table>
    </div>

    <button mat-raised-button (click)="createPurchaseOrder()">Confirm and create PO</button>
    <button mat-button mat-dialog-close>Close</button>

</div>