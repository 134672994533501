<div class="login-container">
  <form (ngSubmit)="onSubmit()" [formGroup]="authorizationForm" class="authentication-form">
    <div class="form-group">
      <mat-form-field>
        <input matInput type="email" id="emailInput" formControlName="emailInput" placeholder="Enter email"
               class="form-control">
        <mat-error>
          <div
            *ngIf="authorizationForm.controls.emailInput.invalid && (authorizationForm.controls.emailInput.dirty || authorizationForm.controls.emailInput.touched)"
            class="alert alert-danger">
            <div *ngIf="authorizationForm.controls.emailInput.errors.required">
              E-Mail is required.
            </div>
            <div *ngIf="authorizationForm.controls.emailInput.errors.email">
              Please, enter valid e-mail
            </div>
          </div>
        </mat-error>

      </mat-form-field>

      <mat-form-field>
        <input matInput type="password" id="passwordInput" formControlName="passwordInput" placeholder="Password"
               class="form-control">
        <mat-error>
          <div
            *ngIf="authorizationForm.controls.passwordInput.invalid && (authorizationForm.controls.passwordInput.dirty || authorizationForm.controls.passwordInput.touched)"
            class="alert alert-danger">
            <div *ngIf="authorizationForm.controls.passwordInput.errors.required">
              Password is required
            </div>
            <div *ngIf="authorizationForm.controls.passwordInput.errors.invalid">
              Password must contain at least 8 characters
            </div>
          </div>
        </mat-error>


      </mat-form-field>

    </div>
    <button mat-raised-button color="primary" type="submit">Log In</button>
    <br>
    <mat-error>
      <div *ngIf="errorMessage" class="alert alert-danger">{{errorMessage}}</div>
    </mat-error>
  </form>
</div>
