<mat-card>
  <mat-card-header>
    <mat-card-title>Sales Report with Prices changes</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>Sales of items, with retail price changes marked on these</mat-card-subtitle>
  </mat-card-header>

  <div class="row">
    <mat-card-content>
      <p>Price changes shown in cells comments.</p>
    </mat-card-content>
  </div>

  <div class="row">
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="downloadReport()" [disabled]="loading">
        Download report
      </button>
    </mat-card-actions>
  </div>
</mat-card>