import {
  QcPhotosEmail,
  GrouppedItem,
  SelectedImage,
  Comment,
  File,
  CategoryWithLinks,
} from "./../../../shared/models/QcPhotos";
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { saveAs } from "file-saver-es";
import * as moment from "moment";
import { combineLatest, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { FileService } from "src/app/shared/services/file.service";
import { PurchaseService } from "src/app/shared/services/purchase.service";

@Component({
  selector: "app-qc-photos",
  templateUrl: "./qc-photos.component.html",
  styleUrls: ["./qc-photos.component.scss"],
})
export class QcPhotosComponent implements  OnInit, OnChanges {
  selectedImage: SelectedImage = null;
  @ViewChild("selectedImageRef") selectedImageRef: ElementRef;
  @ViewChild("viewerContainer") viewerContainerRef: ElementRef;
  @ViewChild("manipulationMenuRef") manipulationMenuRef: ElementRef;
  @ViewChild("deleteIconRef") deleteIconRef: ElementRef;
  @ViewChild("downloadIconRef") downloadIconRef: ElementRef;
  @Input() purchaseId: string;
  @Input() purchaseNumber: string;
  public comment: Comment;

  changeValues$: Subject<any> = new Subject();

  constructor(
    private renderer: Renderer2,
    public dialog: MatDialog,
    private purchaseService: PurchaseService,
    private route: ActivatedRoute,
    private fileService: FileService,
    private snackBarService: SnackBarService
  ) {}

  loading: boolean = false;
  ngOnInit(): void {
    this.getInitialData();

    this.renderer.listen("window", "click", (e: Event) => {
      if (
        this.selectedImageRef &&
        ((e.target !== this.selectedImageRef.nativeElement &&
          e.target === this.viewerContainerRef.nativeElement) ||
          (e.target === this.manipulationMenuRef.nativeElement &&
            e.target !== this.downloadIconRef.nativeElement &&
            e.target !== this.downloadIconRef.nativeElement))
      ) {
        this.selectedImage = null;
      }
    });
    this.changeValues$
      .pipe(debounceTime(1000))
      .subscribe(() => this.purchaseService
      .updatePurchaseProperties([this.comment])
      .subscribe(() => {}));
      //   .subscribe((text: string) => {
      //   this.grouppedItems
      //     .filter((x) => x.categoryName === "Comment")
      //     .filter((x) => (x.images[0] = text));
      //   this.comment.propertyValue = text;

      //   this.purchaseService
      //     .updatePurchaseProperties([this.comment])
      //     .subscribe(() => {});
      // });

    this.modelChanged
      .pipe(
        debounceTime(1000), // wait 300ms after the last event before emitting last event
        distinctUntilChanged() // only emit if value is different from previous value
      )
      .subscribe((text: string) => {
        this.grouppedItems
          .filter((x) => x.categoryName === "Comment")
          .filter((x) => (x.images[0] = text));
        this.comment.propertyValue = text;

        this.purchaseService
          .updatePurchaseProperties([this.comment])
          .subscribe(() => {});
      });
  }
  ngOnChanges() {
    this.getInitialData();
  }

  captureChange(value) {
    this.changeValues$.next(value);
  }

  getInitialData() {
    if (!this.purchaseId) {
      this.route.params.subscribe((params) => {
        this.purchaseId = sessionStorage.getItem("qc-purchaseId");
      });
    }

    if (!this.purchaseNumber) {
      this.route.params.subscribe((params) => {
        this.purchaseNumber = sessionStorage.getItem("qc-purchaseNumber");
      });
    }

    this.loading = true;
    combineLatest([
      this.purchaseService.getPurchaseOrderExtendedPropertyByPropertyName(
        this.purchaseId,
        "comment"
      ),
      this.fileService.getPurchaseQcPhotosById(this.purchaseId),
    ]).subscribe((response: any) => {
      this.grouppedItems = [];
      const comment = response[0];
      this.comment = comment;
      this.grouppedItems.unshift({
        categoryName: "Comment",
        images: [this.comment.propertyValue],
      });

      response[1].forEach((x) => {
        this.grouppedItems.push(x);
      });
      this.loading = false;
    });
  }
  qcPhotosEmail: QcPhotosEmail;
  sendViaEmail() {
    this.snackBarService.success(`Email is going to be send`);
    let qcPhotos: QcPhotosEmail;
    qcPhotos = {
      purchaseId: this.purchaseId,
      title: `PO ${this.purchaseNumber} - QC Report`,
      categoryWithLinks: [],
    };
    this.grouppedItems.forEach((item: GrouppedItem) => {
      qcPhotos.categoryWithLinks.push({
        category: item.categoryName,
        links: item.images.map((x: any) => (x as File).url ?? x),
      });
    });
    this.qcPhotosEmail = qcPhotos;
    this.purchaseService.sendQcPhotosViaEmail(qcPhotos).subscribe(() => {});
  }

  deletingImage: boolean = false;
  deleteFile(key: string, event?) {
    event?.stopPropagation();
    const dialogData = new ConfirmDialogModel(
      "Confirm File Deleting",
      `Are you really want to delete the file ?`
    );
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        if (this.selectedImage) {
          this.deletingImage = true;
        }
        this.fileService
          .removePurchaseQcPhotosDoc(this.purchaseId, key)
          .subscribe(() => {
            if (this.selectedImage) {
              this.grouppedItems
                .filter(
                  (grouppedItem: GrouppedItem) =>
                    grouppedItem.categoryName === this.selectedImage.group
                )
                .filter(
                  (grouppedItem: GrouppedItem) =>
                    (grouppedItem.images = grouppedItem.images.filter(
                      (file: File) => file.url != this.selectedImage.url
                    ))
                );
              this.selectedImage = null;
              this.deletingImage = false;
            } else {
              this.grouppedItems
                .filter(
                  (grouppedItem: GrouppedItem) =>
                    grouppedItem.categoryName === "Additional Files"
                )
                .filter(
                  (grouppedItem: GrouppedItem) =>
                    (grouppedItem.images = grouppedItem.images.filter(
                      (file: File) => file.key != key
                    ))
                );
            }
            this.snackBarService.success(`The file was delete successfully`);
          });
      }
    });
  }

  downloadingImage: boolean = false;
  downloadImage() {
    this.downloadingImage = true;
    var imageByteArray = this.getBase64ImageFromURL(
      this.selectedImage.url + "?x-request=html"
    );
    imageByteArray.then((result: any) => {
      saveAs(result, "Image.jpg");
      this.downloadingImage = false;
    });
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/jpg");
        resolve(dataURL);
      };
      img.onerror = (error) => {
        this.downloadingImage = false;
        window.open(url);
        reject(error);
      };
      img.src = url;
    });
  }

  modelChanged: Subject<string> = new Subject<string>();
  changed(text: string) {
    this.modelChanged.next(text);
  }

  @ViewChild("inputFile") inputFile: ElementRef;
  categoryToAddFileOrImage: string;
  addDoc(category: string): void {
    this.categoryToAddFileOrImage = category.replace(/\s/g, "");
    this.inputFile.nativeElement.click();
  }

  uploadFiles(event) {
    const selectedFiles = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      if (
        this.categoryToAddFileOrImage.toLowerCase().includes("file") &&
        selectedFiles[i].type.includes("image")
      ) {
        this.snackBarService.warning("Only files are allowed");
        return;
      }
      this.upload(selectedFiles[i]);
    }
  }

  upload(file) {
    if (file.size > 52428800) {
      return;
    }
    const formData = new FormData();

    let timeStamp = moment().format("YY_MM_DD_HHmmssSSS");
    let fileExtension = file.name.substring(file.name.indexOf("."));
    let fileKey: string;

    if (this.categoryToAddFileOrImage.toLocaleLowerCase().includes("file")) {
      fileKey = `${this.purchaseNumber}_${timeStamp}__${file.name}`;
    } else {
      fileKey = `${this.purchaseNumber}_${this.categoryToAddFileOrImage}_${timeStamp}${fileExtension}`;
    }
    formData.append(fileKey, file);

    this.fileService
      .uploadPurchaseQcPhotosDoc(
        this.purchaseId,
        this.categoryToAddFileOrImage,
        formData
      )
      .subscribe((x) => {
        this.grouppedItems
          .filter(
            (item: GrouppedItem) =>
              item.categoryName.split(" ").join("").toLocaleLowerCase() ===
              this.categoryToAddFileOrImage.toLowerCase()
          )[0]
          .images.push({
            name: fileKey,
            key: `${this.purchaseId}/${this.categoryToAddFileOrImage}/${fileKey}`,
            url: `https://qc-photos.s3-eu-west-1.amazonaws.com/${this.purchaseId}/${this.categoryToAddFileOrImage}/${fileKey}`,
            size: 0,
            dateModified: "",
          });

        this.snackBarService.success(`The file was added successfully`);
      });
  }

  downloadFile(file: File) {
    this.fileService
      .downloadPurchaseQcPhotosDoc(file.key)
      .subscribe((response) => {
        const responseBlob = new Blob([response], { type: response.type });
        saveAs(responseBlob, this.prettifyFileName(file.name));
      });
  }

  prettifyFileName(fileName: string) {
    return fileName.split("/").pop();
  }

  grouppedItems: GrouppedItem[] = [];
}
