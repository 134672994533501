import { Supplier } from "src/app/shared/models/Supplier";

export class Column {
  id: number;
  name: string;
  propertyName: string;
  propertyType: PropertyType;
  sortDirection: SortDirection;
  sortIndex: number;
}

export class View {
  id: number;
  title: string;
  columns: Column[];
  filters: Filter[];
  isDefault?: boolean = false;
}

export class Filter {
  id: number;
  viewId: number;
  name: string; //displayedColumnName
  columnName: string;
  filterType: number;
  value: string;
}

export enum FilterType {
  Contains = 1,
  NotContains,
  Equals,
  NotEquals,
}

export enum SortDirection {
  None,
  Asc,
  Desc,
}

export enum PropertyType {
  Property = 1,
  ExtendedProperty,
}

export class PagedList {
  pageNumber: number;
  totalCount: number;
  itemsPerPage: number;
  items: any[];
}

export class StockValueModel {
  stockItemId: string;
  stockValue: number;
}

export class SupplierProductScreen extends Supplier {
  active?: boolean;
  portId: number;
}

export class UpdateStockItemPrice {
  stockItemId: string;
  channelId: number;
  price: number;
  oldPrice: number;
  channel: string;
}
