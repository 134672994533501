import { AfterViewInit, Component, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { parseDateTime } from 'src/app/shared/utils/functions';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [DatePipe],
})
export class DateTimePickerComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy {

  @ViewChild('container', { read: ViewContainerRef })
  public container!: ViewContainerRef;
  public selectedDateTime = new Date();
  public hoursControl = new FormControl(0);
  public minutesControl = new FormControl(0);
  public isTBC: boolean;
  public params: ICellEditorParams;
  private originalValue: object;
  private selectedValue: object;

  constructor(
    private datePipe: DatePipe,
  ) { }


  ngOnDestroy(): void {
    this.params.stopEditing();
  }

  public agInit(params: ICellEditorParams): void {
    this.params = params;
    this.originalValue = this.params.value;
    this.selectedDateTime = parseDateTime(params.value);
    this.isTBC = params.value?.toString().includes('TBC');
    this.hoursControl.setValue(this.selectedDateTime.getHours());
    this.minutesControl.setValue(this.selectedDateTime.getMinutes());
  }

  public getValue(): object {
    return this.selectedValue ?? this.originalValue;
  }

  public isPopup?(): boolean {
    return true;
  }

  public getPopupPosition(): 'over' | 'under' {
    return 'under';
  }

  public isCancelBeforeStart?(): boolean {
    return false;
  }

  public isCancelAfterEnd?(): boolean {
    return false;
  }

  public ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.container.element.nativeElement.focus();
    });
  }

  public setTime(hours: number, minutes: number): void {
    this.hoursControl.setValue(hours);
    this.minutesControl.setValue(minutes);
    this.selectedDateTime.setHours(hours);
    this.selectedDateTime.setMinutes(minutes);
  }

  public cancel(): void {
    this.selectedValue = this.originalValue;
    this.params.stopEditing();
  }

  public set(): void {
    this.selectedDateTime.setHours(this.hoursControl.value);
    this.selectedDateTime.setMinutes(this.minutesControl.value);

    this.selectedValue = (this.isTBC 
      ? (this.datePipe.transform(this.selectedDateTime, 'dd/MM/yy') + ' TBC')
      : this.params['allowTime'] 
        ? this.datePipe.transform(this.selectedDateTime, 'dd/MM/yy HH:mm') 
        : this.datePipe.transform(this.selectedDateTime, 'dd/MM/yy')) as Object;
    this.params.stopEditing();
  }

}

