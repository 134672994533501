<mat-card>
    <mat-card-header>
        <mat-card-title>Landed Costs by SKU Report</mat-card-title>
        <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
        <mat-card-subtitle>Landed cost report for containers when delivered by
        </mat-card-subtitle>
    </mat-card-header>
    <div class="row">
        <div class="col">
            <mat-form-field class="search-field">
                <input matInput placeholder="Search" aria-label="State" [matAutocomplete]="auto"
                    [formControl]="searchItemsCtrl" [(ngModel)]="searchItemField">
                <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="selectedFiltereditemChange($event.option); searchItemField = '';">
                    <mat-option *ngIf="loading" class="is-loading">Loading...</mat-option>
                    <ng-container *ngIf="!loading">
                        <mat-option *ngFor="let item of filteredItems" [value]="item.sku">
                            <span><b>{{item.sku}}</b> ({{item.title}})</span>
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col">
            <button *ngIf="selectedItem" mat-raised-button color="primary" (click)="downloadReport()"
                [disabled]="loading">
                Download report
            </button>
        </div>
    </div>
</mat-card>