export class POExtProps {
  rowId: number;
  propertyValue: string;
  propertyName: string;
  purchaseId: string;
}

export class Update_POExtProps {
  purchaseID: string;

  items: POExtProps[];
}

export class POL {
  id?: number;
  name: string;
  transitTime: number;

  constructor(model: Partial<POL>) {
    this.id = model.id;
    this.name = model.name;
    this.transitTime = model.transitTime;
  }
}

export interface Port {
  id?: number;
  name: string;
}

export class ExtendedProperty {
  fkStockItemId: string;
  pkRowId: string;
  propertyType: string;
  propertyValue: any;
  properyName: string;
}
