import { Component, Inject, OnInit } from "@angular/core";
import { LoaderService } from "src/app/core/services/loader.service";
import { RepositoryService } from "src/app/core/services/repository.service";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { HelperService } from "src/app/shared/services/helper.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-po-info",
  templateUrl: "./po-info.component.html",
  styleUrls: ["./po-info.component.css"],
})
export class PoInfoComponent implements OnInit {
  private gridApi;
  private gridColumnApi;
  public columnDefs;
  public defaultColDef;
  public defaultColGroupDef;
  public columnTypes;
  public rowData: [];

  public pdfSrc: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PoInfoComponent>,
    private repo: RepositoryService,
    private snackBar: SnackBarService,
    private reportsService: ReportsService,
    private helper: HelperService,
    private loader: LoaderService
  ) {
    this.columnDefs = [
      {
        headerName: "SKU",
        field: "sku",
        width: 120,
      },
      {
        headerName: "Title",
        field: "itemTitle",
        width: 200,
        tooltipField: "itemTitle",
      },
      {
        headerName: "Sup. Code",
        field: "supplierCode",
        width: 110,
        tooltipField: "supplierCode",
      },
      {
        headerName: "Quantity",
        field: "quantity",
        width: 100,
      },
      {
        headerName: "Unit Cost",
        valueGetter: this.valueGetterUnitCost,
        width: 100,
      },
      {
        headerName: "Line Total",
        field: "cost",
      },
    ];

    this.defaultColDef = {
      width: 120,
      sortable: true,
      filter: "agTextColumnFilter",
      resizable: true,
    };
  }

  ngOnInit(): void {
    this.rowData = this.data.items;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  valueGetterUnitCost = function (params) {
      return (parseFloat(params.data.cost) / parseFloat(params.data.quantity)).toFixed(2);
  };

  generatePDF(): void {
    this.reportsService
      .getContainerPalletReportById(this.data.purchaseOrder.purchaseId)
      .subscribe((response) => {
        this.helper.saveFile(response);
      });
  }
}
