<div id="runs-overview" class="d-flex-vert-base gap-16 pd-8 mg-8" *ngIf="runs">
    <div id="runs-list">
        <mat-list>
            <mat-list-item *ngFor="let run of runs" (click)="selectedRun = run"
                [ngClass]="selectedRun === run ? 'selectedRunItem': ''">
                {{run.name}}
            </mat-list-item>
        </mat-list>
    </div>

    <div id="selected-run" *ngIf="selectedRun && selectedRun.customers?.length">
        <mat-list>
            <mat-list-item *ngFor="let customer of selectedRun.customers">

                <h3 matLine> {{customer.fullname}} </h3>
                <p matLine>
                    <span> {{customer.email}} </span>
                </p>
                <p matLine>
                    <span>{{customer.phone}} </span>
                </p>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="d-flex-vert-center gap-16" *ngIf="selectedRun && selectedRun.customers?.length">
        <button mat-raised-button color="primary" (click)="openDialog()">Send emails</button>
    </div>
</div>