import { Component, OnInit } from '@angular/core';
import { ReportsService } from "../../../shared/services/reports.service";
import { HelperService } from "../../../shared/services/helper.service";
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-new-items-weekly-sales',
  templateUrl: './new-items-weekly-sales.component.html',
  styleUrls: ['./new-items-weekly-sales.component.css']
})
export class NewItemsWeeklySalesComponent implements OnInit {

  constructor(
    private reportsService: ReportsService,
    private helper: HelperService
  ) {}

  ngOnInit(): void {}

  downloadReport() {
    this.reportsService
      .getNewItemsWeeklySales()
      .subscribe((response: HttpResponse<Blob>) => {
        this.helper.saveFile(response);
      });
  }
}
