<mat-card>
    <mat-card-header>
      <mat-card-title>Weekly Sales Report</mat-card-title>
      <mat-card-subtitle>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
    <div class="row">
      <div class="col">
        <button mat-raised-button color="primary" (click)="downloadReport()"
          style="float: right;">Download report</button>
      </div>
    </div>
    </mat-card-content>
  </mat-card>
  <br>
