<div class="landed-cost-container">
  <div class="top-row">
    <mat-form-field class="select-form-field">
      <input matInput type="text" [(ngModel)]="quickFilter" placeholder="Search" [disabled]="!showQuickFilter"/>
      <button *ngIf="quickFilter" matSuffix mat-icon-button aria-label="Clear" (click)="quickFilter=''">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field class="select-form-field">
      <mat-label>Supplier:</mat-label>
      <mat-select [(value)]="selectedSupplier" (selectionChange)="selectedSupplierChange($event.value)"
                  [disabled]="!showQuickFilter">
        <mat-option *ngFor="let supp of suppliers" [value]="supp">
          {{ supp.supplierName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="select-form-field">
      <mat-label>Category:</mat-label>
      <mat-select [(value)]="selectedCategory" (selectionChange)="selectedCategoryChange($event.value)"
                  [disabled]="!showQuickFilter">
        <mat-option *ngFor="let cat of categories" [value]="cat">
          {{ cat.categoryName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="updateLandedCosts()" [disabled]="loadingData">
      Update All Items
    </button>
    <button mat-raised-button color="primary" (click)="downloadExcel()" [disabled]="loadingData" align="end">
      Download Excel Sheet
    </button>
  </div>
  <ag-grid-angular style="width: 100%; height: calc(85vh - 40px)" id="landedCostSheetGrid" class="ag-theme-balham"
                   [rowData]="rowData" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
                   [getRowHeight]="getRowHeight"
                   [rowSelection]="rowSelection" (selectionChanged)="onSelectionChanged($event)"
                   (cellValueChanged)="onCellValueChanged($event)" [headerHeight]="headerHeight"
                   (firstDataRendered)="onFirstDataRendered($event)" (gridReady)="onGridReady($event)"
                   [quickFilterText]="quickFilter"
                   (firstDataRendered)="dataIsRendered()">
  </ag-grid-angular>
</div>
