import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { LoaderService } from 'src/app/core/services/loader.service';
import { RepositoryService } from 'src/app/core/services/repository.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { getHeaderTemplate } from 'src/app/shared/utils/ag-grid-helper';

export interface POL {
  id?: number;
  name: string;
  transitTime: number;
}

@Component({
  selector: 'app-pol',
  templateUrl: './pol.component.html',
  styleUrls: ['./pol.component.css']
})
export class POLComponent implements OnInit {

  pols: POL[];
  loadingData = false;
  selectedItem: POL;

  public gridApi;
  public gridColumnApi;
  public columnDefs;
  public defaultColDef;
  public rowData = [];
  public headerHeight;
  public columnTypes;
  public rowSelection;

  polForm: UntypedFormGroup;

  constructor(
    private repo: RepositoryService,
    private snackBar: SnackBarService,
    public dialog: MatDialog,
    private loader: LoaderService) {
    this.defaultColDef = {
      flex: 1,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      sortable: true,
      filter: true,
      headerComponentParams: {
        template: getHeaderTemplate()
      },
    };
    this.columnDefs = [
      { headerName: 'Name', field: 'name', resizable: true, editable: true, width: 160 },
      { headerName: 'Transit Time', field: 'transitTime', resizable: true, editable: true, width: 100 }
    ];
    this.headerHeight = 60;

    this.rowSelection = 'single';

    this.columnTypes = {
      numberColumn: {
        filter: 'agNumberColumnFilter',
      }
    };


  }

  ngOnInit(): void {
    this.polForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.maxLength(60)]),
      transitTime: new UntypedFormControl(31, [Validators.required, Validators.maxLength(3)])
    });
  }

  addPOL(params) {

    let newPOL: POL = {
      name: params.name,
      transitTime: params.transitTime
    };

    let apiUrl = 'api/purchaseProps/addPOL';
    this.repo.create(apiUrl, newPOL).subscribe(result => {
      this.snackBar.openSnackBar('Successfully added', 'POL');
      this.getPOLs();
    },
      error => {
        this.snackBar.openSnackBar('Something went wrong', 'Error');
        console.error();
      });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.polForm.controls[controlName].hasError(errorName);
  }

  updatePOL(pol: any) {
    this.repo
      .update("api/purchaseProps/updatePOL", pol)
      .subscribe((result) => {

        this.snackBar.openSnackBar("Successfully updated", "POL");
      },
        error => {
          this.snackBar.openSnackBar("Something went wrong", "Error");
        });
  }

  removeSelectedPOL() {
    const dialogData = new ConfirmDialogModel('Confirm Action', 'Are you sure?');

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loader.show();
        this.repo.delete(`api/purchaseProps/removePOL/${this.selectedItem.id}`).subscribe(result => {
          let itemsToRemove: any[] = [];
          itemsToRemove.push(this.selectedItem);
          this.gridApi.applyTransaction({ remove: itemsToRemove });
          this.loader.hide();
          this.snackBar.openSnackBar('Succesfully removed', 'POL');
        },
          error => {
            this.loader.hide();
            this.snackBar.openSnackBar('Something went wrong', 'Error');
          })
      }
    });
  }

  getPOLs() {
    this.loadingData = true;
    this.repo.getList('api/purchaseProps/POLs').subscribe(result => {
      this.pols = result;
      let api = this.gridApi;
      api.setRowData([]);
      api.applyTransaction({ add: this.pols });
      this.loadingData = false;
    },
      error => {
        this.snackBar.openSnackBar('Something went wrong', 'Error');
        this.loadingData = false;
      })
  }

  // // // Grid actions
  onCellValueChanged(event) {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    this.updatePOL(event.data);
  }

  getRowData() {
    let rowData = [];
    this.gridApi.forEachNode(function (node) {
      rowData.push(node.data);
    });
    return rowData;
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onSelectionChanged(params) {
    let selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow) {
      this.selectedItem = selectedRow[0];
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(30);
    this.gridApi.sizeColumnsToFit();

    this.getPOLs();
  }

  getRowHeight(params) {
    return 30;
  }

}
