import {
  Component,
  ElementRef,
  isDevMode,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SnackBarService } from "src/app/core/services/snack-bar.service";

import {
  Gallery,
  GalleryItem,
  ImageItem,
  ThumbnailsPosition,
  ImageSize,
} from "ng-gallery";
import { Lightbox } from "ng-gallery/lightbox";
import { ChangeDetectorRef } from '@angular/core';
import { ConfirmDialogComponent, ConfirmDialogModel } from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import * as moment from 'moment';
import { FileService } from "src/app/shared/services/file.service";
import { InventoryService } from "src/app/shared/services/inventory.service";

@Component({
  selector: 'app-item-packaging',
  templateUrl: './item-packaging.component.html',
  styleUrls: ['./item-packaging.component.scss']
})
export class ItemPackagingComponent implements OnInit {

  @ViewChild("inputFile") inputFile: ElementRef;

  categories = [
    { name: "Packaging", value: "packaging" }
  ];

  stockItemId: string;
  category: string;
  items: GalleryItem[];
  imageData: any;
  itemInfo: any;
  selectedFiles: FileList;

  constructor(
    private inventoryService: InventoryService,
    private fileService: FileService,
    private snackBar: SnackBarService,
    public gallery: Gallery,
    public lightbox: Lightbox,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    setTimeout(() => {
      this.stockItemId = isDevMode() ? 'b94d1ea0-5133-48ba-b345-deebadda1204' : sessionStorage.getItem("lwkr-stockitemid-packaging");

      this.inventoryService.getItemTitleByid(this.stockItemId).subscribe(result => {
        this.itemInfo = result;
      });

      this.getImages();

      const lightboxRef = this.gallery.ref("lightbox");
      lightboxRef.setConfig({
        imageSize: ImageSize.Contain,
        thumbPosition: ThumbnailsPosition.Top,
        dots: true,
        nav: true,
      });
      lightboxRef.load(this.items);
    }, 500);

  }

  ngOnInit(): void { }

  openImage(url) {
    window.open(url);
  }

  selectFiles(event) {
    this.selectedFiles = event.target.files;
  }

  uploadFiles(event) {

    this.selectedFiles = event.target.files;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.uploadImage(this.selectedFiles[i]);
    }
  }

  add(category: string) {
    this.category = category;
    this.inputFile.nativeElement.click();
  }

  remove(category, img) {
    const dialogData = new ConfirmDialogModel('Confirm Action', 'Are you sure?');
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fileService.removeImageByItemId(this.stockItemId, img).subscribe(
          () => {
            this.imageData[category].images = this.imageData[category].images.filter(x => x.url !== img);
            this.cd.detectChanges();
            this.snackBar.success('Successfully removed.');
          },
          () => {
            this.snackBar.error('Error');
          }
        )
      }
    });
  }

  getImages() {
    this.fileService.getItemImagesByItemId(this.stockItemId).subscribe(result => {
      this.imageData = {};
      result.forEach(r => {
        this.imageData[r.categoryName] = r;
        this.items = [];
        r.images.forEach(image => {
          this.items.push(new ImageItem({ src: image.url, thumb: image.url }));
        });
      });
    }, error => {
    })
  }

  loadCategoryToLightBox(category: string) {
    this.items = [];
    this.imageData[category].images.forEach(img => {
      this.items.push(new ImageItem({ src: img.url, thumb: img.url }));
    });

    const lightboxRef = this.gallery.ref("lightbox");

    lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumbPosition: ThumbnailsPosition.Top,
      dots: true,
      nav: true,
    });

    lightboxRef.load(this.items);
  }

  uploadImage(file) {
    if (file.size > 52428800) {
      this.snackBar.warning("File size cannot be more than 50 MB");
      return;
    }

    const formData = new FormData();
    if (!this.itemInfo) {
      this.itemInfo.sku = '_';
      this.itemInfo.title = '_'
    }

    let itemTitle = this.itemInfo.title.replace(/\s/g, "_");
    let timeStamp = moment().format('YYMMDDHHmmssSSS');
    let fileExtension = file.name.substring(file.name.indexOf("."));
    let fileKey = `${this.stockItemId}/${this.category}/${this.itemInfo.sku}_${itemTitle}_${this.category}_${timeStamp}${fileExtension}`;

    formData.append(fileKey, file);

    this.fileService.upload(formData, "images").subscribe(result => {
      if (!this.imageData[this.category]) {
        this.imageData[this.category] = { images: [result] };
      } else {
        this.imageData[this.category].images.push(result);
      }
      this.loadCategoryToLightBox(this.category);
      this.cd.detectChanges();
    });
  }

}
