import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { SnackBarService } from "src/app/core/services/snack-bar.service";
import { Supplier } from "src/app/shared/models/Supplier";
import { InventoryService } from "src/app/shared/services/inventory.service";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from "file-saver-es";
import { Category } from "src/app/shared/models/category";

@Component({
  selector: "app-supplier-product-items-with-photos",
  templateUrl: "./supplier-product-items-with-photos.component.html",
  styleUrls: ["./supplier-product-items-with-photos.component.css"],
})
export class SupplierProductItemsWithPhotosComponent implements OnInit {
  loadingReport = false;
  http: HttpClient;
  baseUrl: string;
  hideDownloadButton = true;
  currentDate = new Date();

  suppliers: Supplier[];
  selectedSupplier: Supplier;

  categories: Category[];
  selectedCategory: Category;

  constructor(
    http: HttpClient,
    @Inject("BASE_URL") baseUrl: string,
    private inventoryService: InventoryService,
    private snackBarService: SnackBarService,
    private reportsService: ReportsService
  ) {
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
    this.loadingReport = true;
    this.inventoryService.getSuppliers().subscribe(
      (result) => {
        this.suppliers = result;
        this.loadingReport = false;
      },
      (error) => {
        this.loadingReport = false;
        console.error(error);
      }
    );
  }

  selectedSupplierChange(value): void {
    this.getCategories();
  }

  getCategories() {
    this.inventoryService
      .getCategoriesBySupplierId(this.selectedSupplier.pkSupplierID)
      .subscribe(
        (result) => {
          this.categories = result;
          this.categories.unshift({
            categoryName: "All",
            categoryId: "all",
          });
          this.selectedCategory = this.categories[0];
        },
        (error) => {
          console.error(error);
        }
      );
  }

  selectedCategoryChange(value): void {
    this.selectedCategory = value;
  }

  downloadFile() {
    if (this.selectedSupplier == null) {
      this.snackBarService.warning("Choose supplier please.");
    } else {
      this.loadingReport = true;

      return this.reportsService
        .getSupplierProductInfoWithPhotosEXCEL(
          this.selectedSupplier.pkSupplierID,
          this.selectedCategory.categoryId
        )
        .subscribe(
          (result: any) => {
            if (result.type != "text/plain") {
              this.loadingReport = false;
              var blob = new Blob([result]);
              let file =
                "10.2 - product details by supplier or all with photos (" +
                this.selectedSupplier.supplierName +
                ").xlsx";

              saveAs(blob, file);
            } else {
              this.snackBarService.neutral("File not found in Blob!", "");
            }
          },
          (error) => {
            if (error.status === 404) {
              this.snackBarService.neutral(
                "There is no result for current filter ",
                ""
              );
            }
            this.loadingReport = false;
          }
        );
    }
  }
}
