import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SnackBarService } from "../../../services/snack-bar.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Credentials } from "../../../models/credentials.model";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: "app-update-password",
  templateUrl: "./update-password.component.html",
  styleUrls: ["./update-password.component.css"],
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
  @Input() email: string;
  hidePasswordInput = true;
  hidePasswordConfirmationInput = true;
  destroySubj = new Subject();

  updatepasswordForm = new UntypedFormGroup(
    {
      passwordInput: new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(8),
      ]),
      passwordConfirmationInput: new UntypedFormControl(""),
    },
    {
      validators: this.comparePasswords,
    }
  );

  matcher = new MyErrorStateMatcher();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBarService: SnackBarService,
    private authenticationWebService: AuthService
  ) {}

  ngOnInit(): void {
  }

  updatePassword() {
    const newCredentials: Credentials = {
      email: this.email,
      password: this.updatepasswordForm.value.passwordConfirmationInput,
    };
    this.authenticationWebService
      .changePassword(newCredentials)
      .pipe(takeUntil(this.destroySubj))
      .subscribe(
        (response) => {
          this.snackBarService.success(response.responseText);
          this.router.navigate(["../../"], { relativeTo: this.route });
        },
        (errorResponse) => {
          this.snackBarService.error(errorResponse.error.errorText);
        }
      );
  }

  closeUpdatePassword() {
    this.router.navigate(["../../"], { relativeTo: this.route });
  }

  private comparePasswords(group: UntypedFormGroup) {
    const password = group.get("passwordInput")?.value;
    const confirmPassword = group.get("passwordConfirmationInput")?.value;

    return password === confirmPassword ? null : { notSame: true };
  }

  ngOnDestroy(): void {
    this.destroySubj.next(null);
    this.destroySubj.complete();
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
    const invalidParent = !!(
      control?.parent?.invalid && control?.parent?.dirty
    );

    return invalidCtrl || invalidParent;
  }
}
