<mat-card>
  <mat-card-header>
    <mat-card-title>Container Report</mat-card-title>
    <mat-card-subtitle>Date: {{ currentDate | date: "dd/MM/yy HH:mm" }}</mat-card-subtitle>
    <mat-card-subtitle>Time range: {{ period[0] | date: "dd/MM/yy" }} -
      {{ period[1] | date: "dd/MM/yy" }}
    </mat-card-subtitle>

    <mat-card-subtitle>List of containers arriving in the next week</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <button mat-raised-button color="primary" (click)="downloadContainerReport()" [disabled]="loadingReport">
          Download report
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br />