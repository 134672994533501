import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { HelperService } from "src/app/shared/services/helper.service";
import { BedWeeklySaleCategory } from "../../../shared/models/BedWeeklySaleCategory";
import { Column } from "../../../shared/models/Column";
import { ReportsService } from "src/app/shared/services/reports.service";
import { saveAs } from 'file-saver-es';
@Component({
  selector: "app-bed-sales-category",
  templateUrl: "./bed-sales-category.component.html",
  styleUrls: ["./bed-sales-category.component.css"],
  providers: [DatePipe]
})
export class BedSalesCategoryComponent implements OnInit {
  loadingReport = false;
  baseUrl: string;
  reportItems: BedWeeklySaleCategory[];
  hideDownloadButton = true;
  period: [Date, Date];

  dataSource = new MatTableDataSource<BedWeeklySaleCategory>();

  horizontalPosition: MatSnackBarHorizontalPosition = "center";
  verticalPosition: MatSnackBarVerticalPosition = "top";

  date = new UntypedFormControl(new Date());
  serializedDate = new UntypedFormControl(new Date().toISOString());

  dateString = new Date().toISOString();

  displayedColumns: Column[] = [
    { name: "sku", displayedName: "SKU" },
    { name: "itemDescription", displayedName: "Description" },

    { name: "cbmPerItem", displayedName: "CBM Per Item" },
    { name: "cbmStock", displayedName: "CBM Stock" },
  ];

  columnsToDisplayDates: Column[] = [
    { name: "1", displayedName: "1" },
    { name: "2", displayedName: "2" },
    { name: "3", displayedName: "3" },
    { name: "4", displayedName: "4" },
  ];

  columnsToDisplay: string[] = this.displayedColumns.map((col) => col.name);

  currentDate = new Date();

  constructor(
    private reportsService: ReportsService,
    @Inject("BASE_URL") baseUrl: string,
    public datepipe: DatePipe,
    private helper: HelperService
  ) {
    this.baseUrl = baseUrl;
    this.period = this.helper.GetStartEndOfPeriodFromYesterday(7 * 4 - 1);
  }

  ngOnInit(): void { }

  fillColumns(): void {
    this.displayedColumns = [];
    this.displayedColumns = new Array<Column>(25);

    this.columnsToDisplayDates = [];
    this.columnsToDisplayDates = new Array<Column>(4);

    this.displayedColumns[0] = { name: "sku", displayedName: "SKU" };
    this.displayedColumns[1] = {
      name: "itemDescription",
      displayedName: "Description",
    };
    this.displayedColumns[2] = {
      name: "stockLvl",
      displayedName: "Stock Level",
    };

    let i = 3;
    let y = 0;

    for (let j = 4; j > 0; j--) {
      var now = new Date();
      //now.setDate(now.getDate() - ((j + 1) * 7));
      now.setDate(now.getDate() - j * 7);
      var startDay = 5; //0 = sunday, 1 = monday etc.
      var d = now.getDay(); //get the current day

      //var weekStart = new Date(now.valueOf() - (d <= 0 ? 7 - startDay : d - startDay) * 86400000); //rewind to start day
      //var weekEnd = new Date(weekStart.valueOf() + 6 * 86400000);

      // ====

      var weekStart = new Date(now.valueOf());
      var weekEnd = new Date(weekStart.valueOf() + 6 * 86400000);

      const formattedDateFrom = this.datepipe.transform(weekStart, "dd/MM");
      const formattedDateTo = this.datepipe.transform(weekEnd, "dd/MM");

      this.displayedColumns[i] = {
        name: "week" + j.toString() + "Total",
        displayedName: "Total",
      };
      i = i + 1;
      this.displayedColumns[i] = {
        name: "week" + j.toString() + "Amazon",
        displayedName: "Amazon",
      };
      i = i + 1;
      this.displayedColumns[i] = {
        name: "week" + j.toString() + "Ebay",
        displayedName: "Ebay ",
      };
      i = i + 1;
      this.displayedColumns[i] = {
        name: "week" + j.toString() + "WayFair",
        displayedName: "WayFair",
      };
      i = i + 1;
      this.displayedColumns[i] = {
        name: "week" + j.toString() + "Other",
        displayedName: "Hometreats",
      };
      i = i + 1;

      //
      this.columnsToDisplayDates[y] = {
        name: "",
        displayedName:
          formattedDateFrom.toString() + " - " + formattedDateTo.toString(),
      };
      y = y + 1;
    }

    this.displayedColumns[23] = {
      name: "cbmPerItem",
      displayedName: "CBM Per Item",
    };
    this.displayedColumns[24] = {
      name: "cbmStock",
      displayedName: "CBM Stock",
    };

    this.columnsToDisplay = this.displayedColumns.map((col) => col.name);
  }

  ChangeDate(event: MatDatepickerInputEvent<Date>) {
    //alert(`${event.value}`);
    this.dateString = event.value.toISOString();
    // alert(event.value.toISOString());
    //alert(typeof event.value);
  }

  getBedWeeklySalesByCategory(): void {
    const reportDate = this.serializedDate.value;
    //alert(this.dateString);
    this.loadingReport = true;

    this.reportsService.getTotalBedSalesCategory().subscribe(
      (result: BedWeeklySaleCategory[]) => {
        this.reportItems = result;
        this.fillColumns();
        this.dataSource = new MatTableDataSource<BedWeeklySaleCategory>(
          this.reportItems
        );
        this.loadingReport = false;
        this.hideDownloadButton = false;
      },
      (error) => {
        console.error(error);
        this.loadingReport = false;
      }
    );
  }

  downloadFile() {
    this.loadingReport = true;
    return this.reportsService.getTotalBedSalesCategoryExcel().subscribe(
      (result: any) => {
        if (result.type != "text/plain") {
          this.loadingReport = false;
          var blob = new Blob([result]);
          let file = "4.2- Total bed sales by category.xlsx";
          saveAs(blob, file);
        } else {
          alert("File not found in Blob!");
        }
      },
      (error) => {
        console.error(error);
        this.loadingReport = false;
      }
    );
  }
}
